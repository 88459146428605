import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import RSVP from 'rsvp'
import { VIRUS_DETECTED_MESSAGE } from '../../../../constants'

const { computed, get, inject, set, getWithDefault } = Ember

export default Ember.Component.extend(DynamicElement, {
  filesService: inject.service('files'),

  // Name for the ember-file-upload file queue, make it unique by appending
  // the elementId.
  queueName: computed('formElement.name', 'elementId', function () {
    return [get(this, 'formElement.name'), get(this, 'elementId')].join('_')
  }),

  init () {
    this._super(...arguments)

    // Init values
    set(this, 'file', false) // File metadata
    set(this, 'loading', false) // Loading file metadata
    set(this, 'uploading', false) // Uploading a file to the server
    set(this, 'fileInfoError', false) // An error retrieving file metadata
    set(this, 'uploadError', false) // An error uploading a file
    set(this, 'avScanStatus', null) // Current antivirus scan status

    // Load file metadata if set
    const fileId = get(this, 'stateValue')
    if (fileId) {
      set(this, 'loading', true)
      this.loadFileMetadata()
    }
  },

  scanning: Ember.computed('file.status', 'loading', 'uploading', function () {
    return !this.loading && !this.uploading && this.file && !this.file.status
  }),

  // Load file metadata
  loadFileMetadata () {
    const fileId = get(this, 'stateValue')

    if (this.isDestroyed === true || fileId === undefined) {
      return
    }

    return get(this, 'filesService').getInfo(fileId)
      .then((file) => {
        if (!get(this, `state.${this.formElement.name}.0.val`)) return
        this.set('file', file)
        set(this, 'loading', false)

        // keep re-fetching the metadata until the file has been scanned
        if (!file.status) {
          return new RSVP.Promise(resolve => window.setTimeout(resolve, 1000))
            .then(() => this.loadFileMetadata())
        }

        if (file.status === 'INFECTED' && getWithDefault(this, `state.${this.formElement.name}.0`, undefined) !== undefined) {
          // note: the state object might no longer exist after the file info is returned, so need to check for it above
          // (this can happen when the component is cleared by the user clicking the delete button)
          set(this, `state.${this.formElement.name}.0.errors`, [{
            description: VIRUS_DETECTED_MESSAGE,
            type: 'INFECTED'
          }])
          // Hack to get the validation message to update after setting the above value, which is deep down in the state tree
          set(this, 'state', Ember.copy(this.state))
        }

        if (file.status === 'CLEAN' &&
          getWithDefault(this, `state.${this.formElement.name}.0`, undefined) !== undefined &&
          getWithDefault(this, `state.${this.formElement.name}.0.errors`, undefined) !== undefined) {
          // Clear any validation error, if exists, once file status is CLEAN
          set(this, `state.${this.formElement.name}.0.errors`, [])
          // Hack to get the validation message to update after setting the above value, which is deep down in the state tree
          set(this, 'state', Ember.copy(this.state))
        }
      })
      .catch(err => {
        console.error(err)
        set(this, 'fileInfoError', 'Failed to load file information')
        set(this, 'loading', false)
      })
  },

  actions: {
    /**
     * Upload a file as returned from ember-file-upload
     * @param {Object} file
     */
    upload (file) {
      set(this, 'uploading', true)
      set(this, 'uploadError', false)
      get(this, 'filesService').upload(file, 'form_instance', get(this, 'formInstanceId'))
        .then(uploadedFile => {
          set(this, 'file', uploadedFile)
          this.setValue(uploadedFile.id)
          new RSVP.Promise(resolve => window.setTimeout(resolve, 1000))
            .then(() => this.loadFileMetadata())
        })
        .catch(response => {
          set(this, 'uploadError', response.body.error)
        })
        .finally(() => {
          set(this, 'uploading', false)
        })
    },

    /**
     * Download the file
     * @param {Number} id
     */
    download (id) {
      get(this, 'filesService').download(id)
    },

    /**
     * Unset the current file.
     * @param {Number} id
     */
    clear (id) {
      set(this, 'file', false)
      set(this, 'avScanStatus', null)
      this.clearValue()
    }
  }
})
