import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import Table from 'ember-light-table'

const { get, computed } = Ember
const whiteList = [
  'address', 'checkbox', 'checkbox-group', 'file-upload', 'location',
  'number', 'person-name', 'question-with-assessment', 'radio',
  'restricted-rich-text', 'select', 'text', 'text-area', 'date'
]

export default Ember.Component.extend(DynamicElement, {
  table: null,

  /**
   * Table columns in the format that Ember Light Table uses.
   */
  columns: computed('state', 'formElement.formElements', function () {
    const formElements = get(this, 'formElement.formElements.0.formElements')

    // Loop through the form elements, remove any non-whiteList form element types and convert them into Ember Light Table
    // column objects.
    const columns = formElements
      .filter(fe => {
        return whiteList.includes(fe.type)
      })
      .map(fe => {
        // Defaults
        let label = fe.label
        let cellComponent = 'dynamic-form/view/table-view/field'
        const valuePath = `val.${fe.name}.0.val`
        const classNames = 'c-table__cell c-table__cell--heading c-table__cell--view-heading'
        const cellClassNames = `c-table__cell c-table__cell--view c-table__cell--wrap c-table__cell_${fe.type}`
        let format

        // Form Element type specifics
        if (fe.type === 'date') {
          cellComponent = 'dynamic-form/view/table-view/date-field'
        }
        if (fe.type === 'select') {
          // Use a format function to display the correct text for the select option.
          format = (value) => {
            const option = fe.options.find(option => get(option, 'value') === value)
            return (option && option.label) ? option.label : ''
          }
        }
        if (fe.type === 'file-upload') {
          cellComponent = 'dynamic-form/view/table-view/file-field'
        }

        // Extended attributes
        if (get(fe, 'extendedAttributes.tableColumnLabel')) {
          label = get(fe, 'extendedAttributes.tableColumnLabel')
        }

        return {
          label,
          valuePath,
          cellComponent,
          format,
          classNames: classNames,
          cellClassNames: cellClassNames,
          sortable: false,
          align: 'inherit'
        }
      })

    return columns
  }),

  /**
   * Rows for the table extracted from the state.
   */
  rows: computed('state', 'formElement.formElements', function () {
    const state = get(this, 'state')
    const name = get(this, 'formElement.name')
    const sectionName = get(this, 'formElement.formElements.0.name')
    return get(state, `${name}.0.val.${sectionName}`) || []
  }),

  errors: computed('rows.@each.errors', function () {
    // Assumption made that the child of a table-view type is a repeatable section.  Errors will have been
    // bubbled up to that section, but can't be displayed there as each section is a row, with no space to display
    // errors
    const errors = get(this, 'rows').reduce((errors, row) => {
      if (row.errors) {
        // Add each unique error to the errors object
        row.errors.forEach(error => {
          errors[error.description] = error.description
        })
      }
      return errors
    }, {})

    // Each key is a description, so just return those
    return Object.keys(errors)
  }),

  noContentMessage: computed('formElement.extendedAttributes.noContentMessage', function () {
    return get(this, 'formElement.extendedAttributes.noContentMessage')
  }),

  /**
   * Instantiate the Ember Light Table.
   */
  init () {
    this._super(...arguments)

    const table = Table.create({ columns: this.columns, rows: this.rows })
    this.set('table', table)
  }
})
