import Controller from '@ember/controller'
import GenericSearchController from 'client/mixins/controllers/generic-search'
import { action } from '@ember/object'
import { inject } from '@ember/service'

export default class ProgrammeSubscriberSearch extends Controller.extend(GenericSearchController) {
  @inject('generic-search-remote-methods') remoteMethods

  searchTypeKey = 'programme-subscriber-search'

  @action async getSearchTemplate (searchTypeKey) {
    const { searchTemplate } = await this.remoteMethods.getSearchConfig(searchTypeKey)
    return searchTemplate
  }
}
