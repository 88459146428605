/* global Raygun */
import { inject } from '@ember/service'
import AuthenticatedRoute from '../../../authenticated-route'
import {
  ROUTINE_ANNUAL_NON_CONFORMANCE_ABBREVIATIONS
} from 'client/constants/values'
import { capitalize } from 'lodash'

export default class HomeReportsServiceOverviewRoute extends AuthenticatedRoute {
  @inject servicesOverviewRemoteMethods
  @inject cookies
  @inject intl
  @inject router
  @inject toastMessages

  routePermissions = ['view__reports_services_overview']

  searchQuery = {
    viewKey: 'lwpAuditInfo',
    searchTypeKey: 'lwp-audit-info',
    requestedPage: 1,
    requestedPageLength: 10,
    filterSet: {
      booleanOperator: 'AND',
      filters: []
    }
  }

  get csrfToken () {
    return this.cookies.read('csrftoken')
  }

  beforeModel () {
    // Indicate to PDF service that the page is loading
    document.REPORTS_PRINT_PAGE_LOADED = false
  }

  async model () {
    // + 1 to month as JS months start at 0
    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()

    try {
      const season = await this.servicesOverviewRemoteMethods.fetchServicesOverviewForSeason()
      const searchableYears = await this.servicesOverviewRemoteMethods.fetchSearchableYears()
      const servicesForCurrentMonth = await this.servicesOverviewRemoteMethods.fetchServicesOverviewForMonth(currentMonth, currentYear)
      const routineAnnualNonConformances = await this.servicesOverviewRemoteMethods.fetchRoutineAnnualNonConformances()
      const initialRatingTableArray = this.createDataStructureForNcInitialRatingTable(routineAnnualNonConformances.data)
      servicesForCurrentMonth.hasFilters = true
      servicesForCurrentMonth.searchableYears = searchableYears

      const tradingName = season.tradingName
      // Time zone is required to be set here as this is going to be using the servers timezone which is utc by default
      // A future enhancement would be required in future to handle dynamic timezones
      const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Pacific/Auckland' }
      const dateString = new Date().toLocaleDateString('en-GB', options)
      const printHeaderString = this.intl.t(
        'reports.services_overview_pdf_header',
        { tradingName: tradingName, date: dateString }
      )
      return {
        printHeader: printHeaderString,
        services: [servicesForCurrentMonth, season],
        servicesData: { servicesForCurrentMonth, season },
        exportQuery: JSON.stringify({ searchQuery: { ...this.searchQuery, requestedExport: true } }),
        csrfToken: this.csrfToken,
        routineAnnualNonConformances: routineAnnualNonConformances,
        initialRatingTableArray: initialRatingTableArray,
        routePermissions: this.routePermissions
      }
    } catch (e) {
      Raygun.send(e)
      this.toastMessages.danger(this.intl.t('messages.unable_to_fetch_data'))
      this.router.transitionTo('base.home.dashboard')
      throw e
    }
  }

  async afterModel (model, transition) {
    await super.afterModel(model, transition)
    // Indicate to PDF service that the page has finished loading
    document.REPORTS_PRINT_PAGE_LOADED = true
  }

  createDataStructureForNcInitialRatingTable (data) {
    // Get list of NC categories within data object
    const ncCategoryArray = Object.keys(Object.values(data)[0])
    const tableObject = {}

    ncCategoryArray.map(ncCategory => {
      tableObject[ncCategory] = {
        heading: capitalize(ncCategory)
      }
      // Insert abbreviation of ncSector and value for nc count
      Object.keys(data).map(ncSector => {
        const ncSectorTranslation = ROUTINE_ANNUAL_NON_CONFORMANCE_ABBREVIATIONS[ncSector]
        tableObject[ncCategory][ncSectorTranslation] = data[ncSector][ncCategory]
      })
    })

    // Return an array of the objects values
    return Object.values(tableObject)
  }

  setupController (controller) {
    controller.setReportsController(this.controllerFor('base/home/reports'))
  }
}
