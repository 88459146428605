/*
Farm Maps Service

*/

/* global L */

import Service, { inject } from '@ember/service'
import { htmlSafe } from '@ember/template'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { cloneDeep } from 'lodash'
import { MAP_CONFIG, SVG_PATH } from 'client/constants/mapping'

export default class FarmMaps extends Service {
  @inject mappingRemoteMethods
  @inject router

  @tracked map
  @tracked farmData = {}
  @tracked config = {}
  @tracked features = {}
  @tracked configGroupsFeatures = {}
  @tracked layerIds = {}
  @tracked enterpriseClientId
  @tracked leafletLayers = {}
  @tracked selectedFeature = {}
  @tracked zoomControlElementInserted = false
  @tracked isFeatureSubPanelVisible = false

  // Map panel props
  @tracked isPanelVisible = true
  @tracked showPanel = 'features'

  zoomControlId = 'leafletZoomControlId'

  mapOptions = {
    center: MAP_CONFIG.defaultCenter,
    zoom: MAP_CONFIG.defaultZoom,
    maxZoom: MAP_CONFIG.maxZoom,
    minZoom: MAP_CONFIG.minZoom,
    zoomControl: false,
    sleep: false
  }

  get configFeatures () {
    return this.configGroupsFeatures?.features
  }

  get configGroups () {
    return this.configGroupsFeatures?.groups
  }

  get farmHasFeatures () {
    return Boolean(Object.keys(this.features)?.length)
  }

  get farmHasConfig () {
    return Boolean(Object.keys(this.config)?.length)
  }

  get funcFindFeatureByLeafletLayer () {
    return this.findFeatureByLeafletLayer.bind(this)
  }

  get funcMapOnClick () {
    return this.mapOnClick.bind(this)
  }

  mapOnClick () {
    this.isFeatureSubPanelVisible = false
  }

  async fetchMapData (enterpriseClientId) {
    this.checkForExistingMapData(enterpriseClientId)
    if (this.existingMapData) {
      // Use existing farm map data if it is persisted
      this.isFeatureSubPanelVisible = false
      return
    }
    try {
      this.resetMapData()
      this.farmData = await this.mappingRemoteMethods.fetchFarmMapBoundaries(enterpriseClientId)
      if (this.farmData) {
        this.config = await this.mappingRemoteMethods.fetchFarmMapFeaturesConfig(enterpriseClientId)
        this.features = await this.mappingRemoteMethods.fetchFarmMapFeatures(enterpriseClientId)
      }
    } catch (e) {
      return e
    }
  }

  resetMapData () {
    this.farmData = {}
    this.config = {}
    this.feature = {}
    this.configGroupsFeatures = {}
  }

  checkForExistingMapData (enterpriseClientId) {
    if (this.enterpriseClientId === enterpriseClientId && this.farmHasConfig) {
      this.existingMapData = true
    } else {
      this.enterpriseClientId = enterpriseClientId
      this.existingMapData = false
    }
  }

  createMap (selector, tilePath) {
    this.map = L.map(selector, this.mapOptions)
    this.map.on('click', this.funcMapOnClick)
    L.tileLayer(tilePath).addTo(this.map)
    this.addZoomControlToMap()
    this.addGeometry()
  }

  addZoomControlToMap () {
    const ZoomControlClass = L.Control.extend({
      // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
      options: {
        position: 'topright'
      },
      onAdd: () => {
        const controlElementTag = 'div'
        const controlElementClass = 'c-full-sreen-map-zoom-controls'
        const container = L.DomUtil.create(controlElementTag, controlElementClass)
        container.id = this.zoomControlId
        return container
      },
      onRemove () {}
    })

    if (this.map.zoomControl) {
      this.map.zoomControl.remove()
    }
    this.map.zoomControl = new ZoomControlClass({ position: 'topright' })
    this.map.zoomControl.addTo(this.map)
    this.zoomControlElementInserted = true
  }

  addGeometry () {
    const geometry = L.geoJSON(this.farmData)
    geometry.addTo(this.map)
    this.animatedFitBounds(geometry.getBounds())
  }

  animatedFitBounds (bounds) {
    this.map.fitBounds(bounds, { padding: [80, 80] })
    this.map.flyToBounds(bounds)
  }

  addFeatures () {
    if (!this.farmHasFeatures) { // If farm has no features
      return
    }
    this.matchConfigGroupsToFeatures()
    this.addFeaturesToMap()
  }

  filterFeaturesFromQueryParams (selectedFeatures) {
    if (selectedFeatures) {
      const specifiedFeatures = {}
      selectedFeatures.forEach((key) => {
        specifiedFeatures[key] = this.features[key]
      })
      this.features = specifiedFeatures
    }
  }

  matchConfigGroupsToFeatures () {
    if (this.existingMapData) { // If map data exists, return
      return
    }

    this.configGroupsFeatures = {
      groups: {},
      features: {}
    }

    this.config.groups.forEach(groupConfig => {
      if (!groupConfig.features.some(featureConfig => this.features[featureConfig.name])) {
        return
      }

      let featureCount = 0
      this.configGroupsFeatures.groups[groupConfig.name] = groupConfig
      this.configGroupsFeatures.groups[groupConfig.name].isActive = true

      groupConfig.features.forEach(featureConfig => {
        if (this.features[featureConfig.name]) {
          featureCount++
          featureConfig.isActive = true
          featureConfig.groupName = groupConfig.name
          featureConfig.groupDescription = groupConfig.description
          featureConfig.color = featureConfig.color || groupConfig.color
          featureConfig.iconHtml = this.setCSSForMapLegendIcon(featureConfig)

          featureConfig.geometries = []
          this.features[featureConfig.name].forEach(feature => {
            featureConfig.geometries.push(feature.geometry)
          })
          this.configGroupsFeatures.features[featureConfig.name] = featureConfig
        }
      })
      this.configGroupsFeatures.groups[groupConfig.name].featureCount = featureCount
      this.configGroupsFeatures.groups[groupConfig.name].activeFeatures = featureCount
    })
    this.configGroupsFeatures = cloneDeep(this.configGroupsFeatures)
  }

  addFeaturesToMap () {
    Object.keys(this.configFeatures).forEach(feature => {
      if (!this.configFeatures[feature].geometries?.length) {
        return
      }

      const featureGroup = new L.FeatureGroup()
      this.configFeatures[feature].geometries.forEach(featureGeoJSON => {
        if (featureGeoJSON.type?.toLowerCase() === 'point') { // Create HTML marker from GeoJSON coordinates
          const icon = new L.DivIcon({
            className: 'farm-map-feature-icon',
            html: htmlSafe(`<img src="${SVG_PATH}${this.configFeatures[feature].iconUrl}" style="background: ${this.configFeatures[feature].color}" class="farm-legend-svg"/>`)
          })
          const latLngs = L.GeoJSON.coordsToLatLng(featureGeoJSON.coordinates)
          const marker = L.marker(latLngs, { icon })
          featureGroup.addLayer(marker)
        } else { // Draw polygons and linestrings
          const style = {
            color: this.configFeatures[feature].color?.toUpperCase(),
            weight: this.configFeatures[feature].weight,
            opacity: this.configFeatures[feature].opacity
          }
          const lineString = L.geoJSON(featureGeoJSON, { style: style })
          featureGroup.addLayer(lineString)
        }
      })

      this.leafletLayers[this.configFeatures[feature].name] = featureGroup
      featureGroup.on('click', this.funcFindFeatureByLeafletLayer)
      this.map.addLayer(featureGroup)
    })
  }

  findFeatureByLeafletLayer (e) {
    // TODO: Add hover state to icon class
    const layerGroup = this.getLayerGroup(e.layer)

    const featureKey = Object.keys(this.leafletLayers).find(layerName => {
      return this.leafletLayers[layerName]._leaflet_id === layerGroup._leaflet_id
    })

    this.selectedFeature = this.configFeatures[featureKey]
    this.showPanel = 'features'
    this.isPanelVisible = true
    this.isFeatureSubPanelVisible = true
  }

  getLayerGroup (layer) {
    const layerKey = Object.keys(this.leafletLayers).find(layerGroup => {
      return this.leafletLayers[layerGroup].hasLayer(layer)
    })
    return this.leafletLayers[layerKey]
  }

  @action toggleFeature (feature) {
    this.configFeatures[feature.name].isActive = !feature.isActive
    this.toggleLayer(feature)
    this.getActiveFeatures(feature.groupName)
    this.configGroupsFeatures = cloneDeep(this.configGroupsFeatures)
  }

  @action toggleFeatureGroup (group) {
    Object.keys(this.configFeatures).forEach(feature => {
      if (this.configFeatures[feature].groupName === group.name) {
        if (group.activeFeatures === group.featureCount) {
          this.configFeatures[feature].isActive = false
        } else {
          this.configFeatures[feature].isActive = true
        }
        this.toggleLayer(this.configFeatures[feature])
      }
    })
    this.getActiveFeatures(group.name)
    this.configGroupsFeatures = cloneDeep(this.configGroupsFeatures)
  }

  @action toggleFeatureSubPanel () {
    this.isFeatureSubPanelVisible = !this.isFeatureSubPanelVisible
  }

  toggleLayer (feature) {
    if (!feature.isActive) {
      this.map.removeLayer(this.leafletLayers[feature.name])
    } else {
      this.map.addLayer(this.leafletLayers[feature.name])
    }
  }

  getActiveFeatures (nameOfGroup) {
    const activeFeatures = Object.values(this.configFeatures).filter(feature => feature.groupName === nameOfGroup && feature.isActive).length
    this.configGroups[nameOfGroup].activeFeatures = activeFeatures
    this.configGroups[nameOfGroup].isActive = (activeFeatures >= 1)
  }

  setCSSForMapLegendIcon (feature) {
    return htmlSafe(`<img src="${SVG_PATH}${feature.iconUrl}" class="farm-legend-svg" alt="${feature.description}" style="background: ${feature.color}"/>`)
  }

  @action zoomIn () {
    this.map.setZoom(this.map.getZoom() + 1)
  }

  @action zoomOut () {
    this.map.setZoom(this.map.getZoom() - 1)
  }

  @action noop (e) {
    // Prevents action on double click
    e.stopPropagation()
  }

  navToNotFound () {
    this.router.transitionTo('base.not-found')
  }

  goToLatLng () { }

  getLayers () { }

  showLayer () { }

  hideLayer () { }
}
