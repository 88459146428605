import { inject as service } from '@ember/service'
import AuthenticatedRoute from 'client/routes/authenticated-route'

export default class HomeSearchProgrammeRoute extends AuthenticatedRoute {
  @service router
  @service warden

  routePermissions = [
    'view__search__admin',
    'view__search__programme_search',
    'view__search__programme_search__dairy_restricted'
  ]

  renderTemplate (controller, model) {
    this.render('generic-search/filters', { outlet: 'filters', controller, model })
    this.render('generic-search/table', { outlet: 'table', controller, model })
  }

  afterModel (resolvedModel, transition) {
    super.afterModel(resolvedModel, transition)
    this.controller && this.controller.buildSearchQuery()
  }

  setupController (controller) {
    // used to delay initial search until query params have had a chance to be read from url
    controller.initialLoad()
  }
}
