import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { select } from 'd3-selection'
import { scaleTime, scaleLinear } from 'd3-scale'
import { max, extent } from 'd3-array'
import { axisLeft, axisBottom } from 'd3-axis'
import { timeMonth } from 'd3-time'
import { format } from 'd3-format'
import { line } from 'd3-shape'

export default class LineChartComponent extends Component {
  @inject intl

  @tracked width
  @tracked height
  @tracked isMobile = false
  @tracked mobileWidth = 650
  @tracked svgContainer

  resizeFunc = this.setDimensions.bind(this)

  constructor () {
    super(...arguments)
    this.setDimensions()
    window.addEventListener('resize', this.resizeFunc)
  }

  setDimensions () {
    if (window.innerWidth < this.mobileWidth) {
      this.isMobile = true
      this.height = 300
      this.width = 300
    } else {
      this.isMobile = false
      this.height = (this.args.height || 300)
      this.width = (this.args.width || 600)
    }
  }

  formatDate (startDate) {
    const date = new Date(startDate)
    return date.toLocaleString('en-UK', {
      year: '2-digit',
      month: 'short'
    })
  }

  indexOfCurrentMonth (line) {
    let currentMonthIndex = null
    line.forEach((month, i) => {
      if (new Date().getTime() > new Date(month.date).getTime()) {
        currentMonthIndex = i
      }
    })
    return currentMonthIndex
  }

  @action
  drawChart (element) {
    const adj = 25

    // Sets the view box sizing for the svg element
    this.svgContainer = select(element)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '-' + 40 + ' -' + adj + ' ' + (this.width + adj * 3) + ' ' + (this.height + adj * 3))
      .style('padding', 0)
      .style('margin', 0)
      .style('margin-top', -25)

    const lineData = this.args.lines

    const xScale = scaleTime().range([0, this.width])
    const yScale = scaleLinear().rangeRound([this.height, 0])

    xScale.domain(extent(lineData.flat(), d => d.date))
    yScale.domain([(0), max(lineData.flat(), d => d.count)
    ])

    this.svgContainer.append('g')
      .classed('timeline-graph__yaxis', true)
      .call(axisLeft(yScale).ticks(5).tickSizeInner(-this.width - 3).tickPadding(6).tickSizeOuter(0).tickFormat(format('d')))

    this.svgContainer.append('g')
      .classed('timeline-graph__xaxis', true)
      .attr('transform', `translate(0, ${this.height})`)
      .call(axisBottom(xScale).tickSizeOuter(0).tickFormat(this.formatDate.bind(this)).ticks(timeMonth.every(1)))
      .selectAll('text')
      // Break tick label onto two lines:
      .html((d) => {
        const dateStringParts = this.formatDate(d).split(' ')
        if (dateStringParts.length === 2) {
          return `<tspan x="0" dy="1.2em">${dateStringParts[0]}</tspan><tspan x="0" dy="1.1em">${dateStringParts[1]}</tspan>`
        } else {
          return this.formatDate(d)
        }
      })
      .style('text-anchor', 'middle')

    const createLine = line()
      .x(d => xScale(d.date))
      .y(d => yScale(d.count))

    let count = 0
    const classes = function () {
      return 'line line-' + count++
    }

    lineData[1] = lineData[1].slice(0, this.indexOfCurrentMonth(lineData[1]) + 1)

    const lines = this.svgContainer.selectAll('lines')
      .data(lineData)
      .enter()
      .append('g')

    lines.append('path')
      .attr('class', classes)
      .attr('d', createLine)
  }
}
