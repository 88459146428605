import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './primary-heading.notes.md'

export default {
  title: 'Primary Heading',
  component: 'PrimaryHeading',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const heading = () => ({
  template: hbs`<PrimaryHeading>{{heading}}</PrimaryHeading>`,
  context: {
    heading: text('Heading text', 'Content area heading')
  }
})

export const noMarginTop = () => ({
  template: hbs`<PrimaryHeading @noMarginTop={{true}}>{{heading}}</PrimaryHeading>`,
  context: {
    heading: text('Heading text', 'Content area heading')
  }
})
