import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import { action } from '@storybook/addon-actions'
import markdown from './section-action.notes.md'

export default {
  title: 'Section Action',
  component: 'SectionAction',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const standard = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <SectionActionsContainer @headingText={{headingText}}>
        <SectionAction @clickAction={{action clickAction}} @iconType="download">{{downloadLabel}}</SectionAction>
        <SectionAction @clickAction={{action clickAction}} @iconType="upload">{{uploadLabel}}</SectionAction>
      </SectionActionsContainer>
    </ContentContainer>
  `,
  context: {
    headingText: text('Heading text', 'Results'),
    downloadLabel: text('Download label text', 'Download CSV'),
    uploadLabel: text('Upload label text', 'Upload CSV'),
    clickAction: action('button-click')
  }
})

export const disabled = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <SectionActionsContainer @headingText={{headingText}}>
        <SectionAction @clickAction={{action clickAction}} @isDisabled={{true}} @iconType="download">{{downloadLabel}}</SectionAction>
        <SectionAction @clickAction={{action clickAction}} @isDisabled={{true}} @iconType="upload">{{uploadLabel}}</SectionAction>
      </SectionActionsContainer>
    </ContentContainer>
  `,
  context: {
    headingText: text('Heading text', 'Results'),
    downloadLabel: text('Download label text', 'Download CSV'),
    uploadLabel: text('Upload label text', 'Upload CSV'),
    clickAction: action('button-click')
  }
})
