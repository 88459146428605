/* global Raygun */
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { inject } from '@ember/service'
import config from 'client/config/environment' // eslint-disable-line no-unused-vars

export default class AuditDocuments extends Component {
  @inject AuditDocumentRemoteMethods
  @inject router

  @tracked lettersAndReports = []
  @tracked isLoading = false
  @tracked hasError = false
  @tracked externalAuditId
  @tracked documentSource
  @tracked documentsUrl = '/api/aqdocs/get-document/'

  constructor () {
    super(...arguments)
    const componentState = this.args.state['audit-documents']

    // documentSource and externalAuditId are passed through from dynamic forms
    this.documentSource = componentState?.[0]?.val?.documentSource?.[0]?.val
    this.externalAuditId = componentState?.[0]?.val?.externalAuditId?.[0]?.val

    this.getAuditDocuments(this.documentSource, this.externalAuditId)
  }

  getAuditDocuments () {
    if (this.externalAuditId) {
      this.hasError = false
      this.isLoading = true
      this.AuditDocumentRemoteMethods.fetchAuditDocuments(this.externalAuditId, this.documentSource).then((data) => {
        // currently we filter out non-pdf files, for example the .html email files
        const reports = data.reports || []
        const documents = data.documents || []
        const documentsSa = data.documents_sa || []

        this.lettersAndReports = [reports, documents, documentsSa].flat()

        this.isLoading = false
      }).catch((e) => {
        Raygun.send(e)
        this.hasError = true
        this.isLoading = false
      })
    }
  }

  @action
  onClickAuditDocument (report) {
    this.toggleSpinner(report)
    this.getAuditDocument(report)
  }

  @action
  onClickAuditDocumentAttachment (report, attachment) {
    this.toggleSpinner(report, attachment)
    this.getAuditDocument(report, attachment)
  }

  toggleSpinner (report, attachment) {
    this.lettersAndReports = this.lettersAndReports.map(r => {
      if (report.title === r.title) {
        if (attachment) { // Toggle spinner on attachment only
          r.attachments.map(a => {
            if (attachment.filename === a.filename) {
              a.isDownloading = !attachment.isDownloading
            }
          })
        } else { // Toggle spinner on report only
          r.isDownloading = !report.isDownloading
        }
      }
      return { ...r }
    })
  }

  getAuditDocument (report, attachment) {
    const selectedDocument = attachment || report
    const fileName = selectedDocument.title || selectedDocument.filename
    const url = `${this.documentsUrl}${selectedDocument.filepath}/${this.externalAuditId}/${this.documentSource}`
    this.AuditDocumentRemoteMethods.fetchAuditDocument(url, `${fileName}${selectedDocument.file_extension}`)
      .then(() => {
        this.toggleSpinner(report, attachment)
      })
      .catch(() => {
        this.toggleSpinner(report, attachment)
      })
  }
}
