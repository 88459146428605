/* global Raygun */

import { alias } from '@ember/object/computed'
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin'
import ObjectProxy from '@ember/object/proxy'
import { inject } from '@ember/service'
import RemoteMethodService from 'client/services/remote-methods'
import config from '../config/environment'
import { AUTH_EXCEPTIONS } from '../constants/values'

const CurrentUserProxy = ObjectProxy.extend(PromiseProxyMixin)

const POLLING_INTERVAL = 30 // Check for session expiry every n seconds

/**
 * Manages the user session for the client app.
 */
export default class Session extends RemoteMethodService {
  @inject router
  @inject toastMessages
  @inject intl
  @alias('currentUser.username') username
  @alias('currentUser.isActive') isActive
  @alias('currentUser.email') email
  @alias('currentUser.allowedActions') allowedActions
  @alias('currentUser.organisation') organisation
  @alias('currentUser.isImpersonated') isImpersonated
  @alias('currentUser.hasAgreedToTerms') hasAgreedToTerms
  @alias('currentUser.entitiesIsAuditeeFor') entitiesIsAuditeeFor

  // An internal cache used when fetching the currentUser
  currentUserCache

  // Stores the current timeout.
  currentSessionPollTimeout = null

  constructor () {
    super(...arguments)
    // Start the heartbeat check
    this.tick()
  }

  get isAuthenticated () {
    return this.username && this.isActive && this.allowedActions?.length
  }

  get isArchived () {
    return this.isActive === false
  }

  /**
  * Sets the user for Raygun.
  *
  * @param userData As returned from the current-user endpoint.
  */
  setRaygunUser = (userData) => {
    const id = userData.username || ''
    const firstName = userData.firstName || ''
    const lastName = userData.lastName || ''
    const email = userData.email || ''
    const fullName = `${firstName} ${lastName}`

    if (config.ENV_VARS && config.ENV_VARS.RAYGUN_API_KEY) {
      Raygun.setUser(
        id,
        false, // `isAnonymous`
        email,
        fullName,
        firstName
      )
    }
  }

  updateUserCache () {
    this.currentUserCache = CurrentUserProxy.create({
      promise: this.requestNoHandle('/api/current-user/')
        .then((userData) => {
          if (userData) {
            this.setRaygunUser(userData)
          }
          return userData
        })
        .catch((err) => {
          if (err && err.payload && AUTH_EXCEPTIONS[err.payload]) {
            this.toastMessages.warning(this.intl.t(AUTH_EXCEPTIONS[err.payload]))
          }
          return err
        })
    })
    return this.currentUserCache
  }

  get currentUser () {
    if (this.currentUserCache) return this.currentUserCache

    return this.updateUserCache()
  }

  /**
    * Poll the `session-expiry` endpoint every `POLLING_INTERVAL` seconds.
    *
    * Call `sessionExpired()` if the session has expired
    */
  tick () {
    const timeout = setTimeout(() => {
      this
        .request('/session-expiry/session-expiry')
        .then(sessionExpiry => {
          const expiresIn = sessionExpiry.expires_in
          if (expiresIn < 0 && this.isAuthenticated) {
            this.sessionExpired()
          }
        })
        .finally(() => this.tick())
    }, POLLING_INTERVAL * 1000)
    this.currentSessionPollTimeout = timeout
  }

  /**
    * Enable a user to switch view to appear as though they are logged in as a different user
    */
  async switchUser (userId) {
    const switchUserRes = await this.request(`/roles/switch-user/${userId}`, { method: 'POST' })
    await this.updateUserCache()
    return switchUserRes
  }

  /**
    * Handle Session Expiry.
    *
    * Go back to the landing page.
    * `returnTo` is set to return the user to the page they were on before their session expired.
    * `sessionExpired` is set to display a notification to the user on the landing page.
    *
    * Will also clear an outstanding poll timeout to prevent errors.
    */
  sessionExpired () {
    if (this.currentSessionPollTimeout) {
      clearTimeout(this.currentSessionPollTimeout)
    }
    this.currentUserCache = null

    this.router.transitionTo('login', {
      queryParams: {
        sessionExpired: true,
        returnTo: encodeURIComponent(window.location.pathname)
      }
    })
  }

  /**
    * Logs the user out of Assurance Hub
    * Optionally reminds them they've not been logged out of the IdP
    * @param alertUserOfIdp
    * @returns {Promise}
    */
  logout (remindUserOfIdp = true) {
    return this.request('/api/auth/logout')
      .then(() => {
        this.currentUserCache = null
        if (remindUserOfIdp) {
          this.toastMessages.info(this.intl.t('messages.you_will_stay_logged_in'))
        }

        this.router.transitionTo('login')
      })
  }

  get fullName () {
    // currentUser is a proxy object, and that still needs the `.get` function to get child properties
    return `${this.currentUser.get('firstName')} ${this.get('currentUser.lastName')}`
  }
}
