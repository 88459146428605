import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"c-page-header\">\n  <div class=\"c-page-header__container\">\n    {{#if this.crumbs.length}}\n      <Breadcrumbs @crumbs={{this.crumbs}} />\n    {{/if}}\n\n    {{#if (or @heading @subHeading)}}\n      <div class=\"c-page-header__heading-container\">\n        {{#if @heading}}\n          <h1 class=\"c-page-header__heading\">{{@heading}}</h1>\n        {{/if}}\n\n        {{#if @subHeading}}\n          <h3 class=\"c-page-header__sub-heading\">{{@subHeading}}</h3>\n        {{/if}}\n      </div>\n    {{/if}}\n\n    {{!-- Allow displaying custom content within header area --}}\n    {{yield}}\n\n    {{#if @tabs.length}}\n      <TabNav @tabs={{@tabs}} />\n    {{/if}}\n  </div>\n</div>\n", {"contents":"<div class=\"c-page-header\">\n  <div class=\"c-page-header__container\">\n    {{#if this.crumbs.length}}\n      <Breadcrumbs @crumbs={{this.crumbs}} />\n    {{/if}}\n\n    {{#if (or @heading @subHeading)}}\n      <div class=\"c-page-header__heading-container\">\n        {{#if @heading}}\n          <h1 class=\"c-page-header__heading\">{{@heading}}</h1>\n        {{/if}}\n\n        {{#if @subHeading}}\n          <h3 class=\"c-page-header__sub-heading\">{{@subHeading}}</h3>\n        {{/if}}\n      </div>\n    {{/if}}\n\n    {{!-- Allow displaying custom content within header area --}}\n    {{yield}}\n\n    {{#if @tabs.length}}\n      <TabNav @tabs={{@tabs}} />\n    {{/if}}\n  </div>\n</div>\n","moduleName":"client/components/page-header/index.hbs","parseOptions":{"srcName":"client/components/page-header/index.hbs"}});
import Component from '@glimmer/component'
import { inject as service } from '@ember/service'

export default class PageHeader extends Component {
  @service router

  getCurrentTabCrumb = () => {
    const currentTab = this.args.tabs.find(tab => this.router.currentRouteName.startsWith(tab.route))
    if (currentTab != null) {
      return { label: currentTab.translationKey }
    }
    return null
  }

  get crumbs () {
    // Check if there is a crumb trail being passed in
    if (this.args.crumbs != null && Array.isArray(this.args.crumbs) && this.args.crumbs.length > 0) {
      // If there are tabs, add the current tab to the crumb trail
      if (this.args.tabs) {
        return [
          ...this.args.crumbs,
          this.getCurrentTabCrumb()
        ]
      } else {
        // If not, just return the crumbs that were passed in
        return this.args.crumbs
      }
    }

    // If there are tabs, add crumbs for the tabs
    if (this.args.tabs != null && Array.isArray(this.args.tabs) && this.args.tabs.length > 0) {
      const currentCrumb = this.getCurrentTabCrumb()
      return currentCrumb == null ? [] : [currentCrumb]
    }

    return []
  }
}
