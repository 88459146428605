
/* global Raygun */
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'

export default class AddCustomFieldCallToActionComponent extends Component {
  @inject session
  @inject customFieldsRemoteMethods

  @tracked numberCustomFields

  constructor () {
    super(...arguments)
    this.customFieldsRemoteMethods.fetchTotalCustomFields()
      .then(fieldCount => {
        this.numberCustomFields = fieldCount
      })
      .catch(e => {
        Raygun.send(e)
      })
  }

  get isButtonDisabled () {
    return this.numberCustomFields >= this.session?.organisation?.maxCustomFields
  }
}
