import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import moment from 'moment'
import config from '../../../../config/environment'

export default Ember.Component.extend(DynamicElement, {
  tagName: 'div',

  // The format used to display the currently set date
  dateFormat: config.dateFormat,

  // Use a custom function to parse dates entered by the user as text
  parseDate (dateString, format) {
    const dateFormats = [
      format, // The same format as used for display
      'D/M/YYYY', // Standard NZ date formatting
      'YYYY-M-D' // ISO standard date formatting
    ]
    return moment(dateString, dateFormats).toDate()
  },

  dateValue: Ember.computed('stateValue', function () {
    /**
     * If the date picker is configured to display the current date by default,
     * `getCurrentDate` in `default-functions.js` returns the date parsed and formatted in local time.
     *
     * If a date is selected by the user, `setDate` parses and stores the date in local time.
     *
     * In both the cases, we can safely return the date value without any additional parsing or formatting.
     *
     */
    return this.stateValue ? this.stateValue : null
  }),

  actions: {
    setDate (date) {
      /**
       * pikaday has been configured to return date with the UTC timezone.
       * We use moment to parse the date to local time using the UTC timezone information and
       * then convert the parsed date to an ISO 8601 string which the database will accept.
      */
      if (date === null) {
        this.clearValue()
      } else {
        this.setValue(date === null ? undefined : moment(date).toISOString())
      }
    }
  }
})
