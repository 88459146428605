import Component from '@glimmer/component'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import RouteAction from 'client/helpers/route-action'
import { formatDateFromShortString } from 'client/utils/date'

export default class SupplierSummary extends Component {
    @inject insightsRemoteMethods
    @inject router
    @inject dynamicFormsMethods

    @tracked supplierData = [];

    constructor () {
      super(...arguments)
      const parentId = RouteAction.create(this).compute(['getParentId'])()
      this.insightsRemoteMethods.fetchSupplierSummary(parentId).then((supplierData) => {
        supplierData.programmes.map((programme) => {
          programme.hasLastCertifiedDate = programme.lastCertifiedDate
          programme.lastCertifiedDate = formatDateFromShortString(programme.lastCertifiedDate)
          programme.certificateExpiryDate = formatDateFromShortString(programme.certificateExpiryDate)
          programme.status = this.calculateProgrammeStatus(programme)
          return programme
        })
        this.supplierData = supplierData
      })
    }

    @action
    navigateToAuditForm (latestAuditFormInstanceId) {
      const transitionData = { id: latestAuditFormInstanceId }
      this.dynamicFormsMethods.transitionToForm(transitionData)
    }

    calculateProgrammeStatus (programme) {
      const statuses = {
        notCertified: { cardState: 'error-major', iconName: 'cross-circle', iconColor: 'red' },
        certified: { cardState: 'success', iconName: 'tick-circle', iconColor: 'green' }
      }

      return programme.isCertified ? statuses.certified : statuses.notCertified
    }
}
