import hbs from 'htmlbars-inline-precompile'
import { withKnobs, number, select, boolean } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './card.notes.md'

export default {
  title: 'Card',
  component: 'Card',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

const states = {
  none: '',
  info: 'info',
  warning: 'warning',
  errorMinor: 'error-minor',
  errorMajor: 'error-major',
  success: 'success'
}

export const card = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <Card @state={{state}}>
        <TertiaryHeading @noMarginTop={{true}}>Card title</TertiaryHeading>
        Some information about something goes here.
      </Card>
    </ContentContainer>
  `,
  context: {
    state: select('state', states, 'info')
  }
})

export const withExpandableContent = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <Card @state={{state}} @hasFooter={{true}}>
        <TertiaryHeading @noMarginTop={{true}}>Card title</TertiaryHeading>
        Some information about something goes here.

        {{#animated-if isExpanded use=this.resize}}
          <p>Example content to show hide/show behaviour...</p>
        {{/animated-if}}

        <Card::Footer
          @showText="Show"
          @hideText="Hide"
          @isExpanded={{isExpanded}}
          @toggleAction={{action (mut isExpanded) (not isExpanded)}}
        />
      </Card>
    </ContentContainer>
  `,
  context: {
    state: select('state', states, 'info'),
    isExpanded: boolean('isExpanded', false)
  }
})

export const cardsInsideFeaturedSection = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <FeaturedSection>
        <Grid @cols={{cols}}>
          <Card @state={{state}}>
            <TertiaryHeading @noMarginTop={{true}}>Card title</TertiaryHeading>
            Some information about something goes here.
          </Card>
          <Card @state={{state}}>
            <TertiaryHeading @noMarginTop={{true}}>Card title</TertiaryHeading>
            Some information about something goes here.
          </Card>
          <Card @state={{state}}>
            <TertiaryHeading @noMarginTop={{true}}>Card title</TertiaryHeading>
            Some information about something goes here.
          </Card>
        </Grid>
      </FeaturedSection>
    </ContentContainer>
  `,
  context: {
    state: select('state', states, 'info'),
    cols: number('grid cols', 3)
  }
})
