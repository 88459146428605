// REFACTOR: If we move away from using this as a DF component
// change to using Octane syntax

import { equal } from '@ember/object/computed'

import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'
import { get, computed } from '@ember/object'
import Component from '@ember/component'

export default Component.extend(DynamicElement, ComplexElement, {
  classNames: ['c-form-location-field'],
  showOrganisation: computed(function () {
    return !!get(this, 'formElement.formElements').find(obj => obj.name === 'org')
  }),
  org: computed(function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'org')
  }),
  role: computed(function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'role')
  }),
  date: computed(function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'date')
  }),
  hasFullName: equal('formElement.extendedAttributes.hasFullName', true),
  hasPermissionToEditSelectedRole: computed(function () {
    const isEditableFunction = get(this, 'formElement.extendedAttributes.editable')
    if (isEditableFunction) {
      return isEditableFunction(this.itemState)
    } else {
      return true
    }
  })
})
