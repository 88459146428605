import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import config from '../config/environment'
import { SUPPORTED_LOCALES } from '../constants/locales'

export default class ApplicationRoute extends Route {
  @inject intl
  @inject remoteMethods
  @inject pdfRemoteMethods
  @inject metrics
  @inject router

  constructor () {
    super(...arguments)
    this.router.on('routeDidChange', () => {
      const page = this.router.currentURL
      const title = this.router.currentRouteName || 'unknown'

      this.metrics.trackPage({ page, title })
    })
  }

  async beforeModel () {
    await super.beforeModel(...arguments)

    // Errors most likely 403 from non logged in user so ignore

    // TODO renable when we support other locales
    // const request = await this.remoteMethods.requestNoHandle('api/current-user-locale/').catch(() => null)
    // const storedLocale = request?.locale
    // const applicationLocale = storedLocale || this.getBrowserLocaleOrDefault(SUPPORTED_LOCALES)

    const applicationLocale = this.getBrowserLocaleOrDefault(SUPPORTED_LOCALES)
    this.intl.setLocale(applicationLocale)

    if (config.ENV_VARS && config.ENV_VARS.GOOGLE_ANALYTICS_TRACKING_ID) {
      this.metrics.activateAdapters([
        {
          name: 'GoogleAnalytics',
          environments: ['all'],
          config: {
            id: config.ENV_VARS.GOOGLE_ANALYTICS_TRACKING_ID
          }
        }
      ])
    }
  }

  getBrowserLocaleOrDefault (locales) {
    const browserLocale = navigator.language.toUpperCase().substring(0, 2)
    const supportedLocales = locales.map(locale => locale.code)
    return supportedLocales.includes(browserLocale) ? browserLocale : 'en-us'
  }
}
