import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { REPORTS_ASSESSMENT_COLORS, ORDERED_CHART_COLORS, MILKING_OBSERVED_YES } from 'client/constants/values'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'

export default class AssessmentsCompletedDuringMilkingSeason extends Component {
  @inject router

  @tracked targetData
  @tracked targetCount
  @tracked targetWidth
  @tracked targetColor
  @tracked completedData
  @tracked completedCount
  @tracked completedWidth
  @tracked completedColor
  @tracked supplierCount
  @tracked programmeId
  @tracked seasonStartDate
  @tracked seasonEndDate
  @tracked completedSubgroups
  @tracked targetSubgroups = ['target']
  @tracked isToolTipEnabled = true
  @tracked disableLink = false

  constructor () {
    super(...arguments)
    this.completedData = [this.args.completed]
    this.targetData = [this.args.target]
    this.completedCount = this.completedAssessmentWidthCount
    this.targetColor = ORDERED_CHART_COLORS.slice(1)
    this.completedColors = REPORTS_ASSESSMENT_COLORS
    this.legendTranslations = this.legendTranslationKeys
    this.targetCount = this.targetData[0].target

    this.supplierCount = this.args.supplierCount
    this.seasonStartDate = this.args.seasonDates[0]
    this.seasonEndDate = this.args.seasonDates[1]
    this.programmeId = this.args.programmeId
    this.targetWidth = this.calculateWidth(this.targetCount)
    this.completedWidth = this.calculateWidth(this.completedCount)

    this.completedSubgroups = Object.keys(this.args.completed)
  }

  /**
   * Translations for the legend keys of the graph
   */
  get legendTranslationKeys () {
    return {
      Revisit: 'reports.assessments_completed_during_milking.revisit',
      Approval: 'reports.assessments_completed_during_milking.approval',
      'Non Notified': 'reports.assessments_completed_during_milking.non_notified',
      'Routine Annual Assessment': 'reports.assessments_completed_during_milking.routine_annual',
      'Colostrum Assessment': 'reports.assessments_completed_during_milking.colostrum'
    }
  }

  /**
   * Gets the total number of assessments completed from the data
   */
  get completedAssessmentWidthCount () {
    let width = 0
    for (var key in this.args.completed) {
      width += this.args.completed[key]
    }
    return width
  }

  get fivePercentSupplierCount () {
    return Math.ceil(5 / 100 * this.supplierCount)
  }

  @action
  calculateWidth (count) {
    const maxWidth = 400
    const maxCount = Math.max(this.completedCount, this.targetCount)
    if (maxCount === 0) {
      return 0
    }
    return count === maxCount ? maxWidth : count / maxCount * maxWidth
  }

  /**
   * Gets the visit type names for each visit type mapping associated with the given client
   */
  get visitTypeNames () {
    const visitTypeMappings = this.args?.visitTypeMapping
    const visitTypeNames = []
    Object.values(visitTypeMappings).forEach(visitTypeMapping => {
      visitTypeMapping.forEach(visitTypeName => visitTypeNames.push(visitTypeName[1]))
    }
    )
    // remove any duplicate names so that we don't have duplicate filters
    return [...new Set(visitTypeNames)]
  }

  @action
  viewAssessmentsCompletedForSeason () {
    this.disableLink = true
    const internalUserFilters = {
      programme: [this.programmeId.toString()],
      visitType: this.visitTypeNames,
      dateCompleted: [this.seasonStartDate, this.seasonEndDate],
      milkingObserved: [MILKING_OBSERVED_YES]
    }

    this.router.transitionTo('base.home.search.audit', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(internalUserFilters)
      }
    })
  }
}
