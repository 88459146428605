import Component from '@ember/component'
import { set, computed } from '@ember/object'

export default Component.extend({
  tagName: '',
  searchOnNextChange: false,

  textValue: computed('filter', function () {
    return this.filter.filterValue
  }),

  actions: {
    setText (text) {
      if (text && text.length > 0) {
        this.setValue({
          'filterKey': this.template.filterKey,
          'filterOperator': this.template.filterOperator || 'ilike',
          'filterValue': `${text}`
        })
      } else {
        this.setValue(undefined)
      }
      if (this.searchOnNextChange === true) {
        set(this, 'searchOnNextChange', false)
        this.performSearch()
      }
    },
    keyDown (e) {
      // Perform search when the enter key is pressed
      if (e.keyCode === 13) {
        set(this, 'searchOnNextChange', true)
      }
    }
  }
})
