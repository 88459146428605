import Mixin from '@ember/object/mixin'
import { get, set } from '@ember/object'
import { inject } from '@ember/service'

import { Promise } from 'rsvp'
import {
  findNextFormEdit,
  findNextFormView,
  isNonNavigable,
  getChildInstanceId
} from 'client/libs/dynamic-forms-methods-lib'
import { LAST, MODE } from 'client/constants/workflow'

/**
 * Provides actions for navigating within a workflow (eg. Continue button)
 */

// Find the path for the next workflow
const findNextWorkflowPath = (model, mode) => {
  try {
    let previousWorkflowWasActive = false
    const nextWorkflow = model.workflows.find((workflow) => {
      if (previousWorkflowWasActive === true) {
        return true
      } else {
        previousWorkflowWasActive = workflow.active === true
        return false
      }
    })
    if (nextWorkflow !== undefined) {
      return nextWorkflow.config.path || LAST
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('findNextWorkflowPath failed', { model, mode, error })
  }
  return LAST
}

// Find next path within the current workflow
const findNextFormPath = (model, mode) => {
  // in edit mode we jump to the next peer first, in view we drill down into the child forms
  const findNextForm = mode === 'edit'
    ? findNextFormEdit
    : findNextFormView

  const forms = model.activeWorkflow.menu.forms
  if (Array.isArray(forms) === true) { // workflows that haven't been started don't have forms
    const nextInstanceId = findNextForm(model.id, forms)
    if (nextInstanceId !== LAST) {
      return `${model.formRootPath}/${nextInstanceId}`
    }
  }

  return LAST
}

// Find next path within the current workflow, or the next workflow
const findNextPath = (model, mode, hideContinueToNextWorkflow = false) => {
  // first try to get the next form
  let path = findNextFormPath(model, mode)

  // if this is the last form, try to get the next workflow
  if (path === LAST && hideContinueToNextWorkflow !== true) {
    path = findNextWorkflowPath(model, mode)
  }

  return path
}

/**
 * Allows a route to process the form that's been retrieved in the model hook, and if is non-navigable form,
 * then redirects them to the correct child
 */
// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  dynamicFormsMethods: inject(),
  router: inject(),

  afterModel (model) {
    const mode = this.mode

    // Return early if we're in print view as we may want to view a full
    // workflow (which will be isNonNavigable).
    if (mode === MODE.PRINT_VIEW) {
      return
    }

    if (isNonNavigable(model)) {
      // The user should not be able to land on this form, so navigate to the first child
      const formRootRoute = get(model, 'formRootRoute')
      const formRootPath = get(model, 'formRootPath')
      const childInstanceId = getChildInstanceId(model)
      const path = `${formRootPath}/${childInstanceId}`
      this.router.transitionTo(formRootRoute, mode, path)
    }

    const hideContinueToNextWorkflowFunc = get(model, 'activeWorkflow.config.hideContinueToNextWorkflow') || (() => Promise.resolve(false))

    return Promise.resolve(hideContinueToNextWorkflowFunc.call(this, model))
      .then((hideContinueToNextWorkflowBoolean) => {
        set(model, 'hasNext', findNextPath(model, mode, hideContinueToNextWorkflowBoolean) !== LAST)
        return model
      })
  },

  actions: {
    // Move to the next form or workflow
    continue ({ skipNavigationCheck } = {}) {
      const model = this.controller.model
      const path = findNextPath(model, model.mode)
      if (path === LAST) {
        throw new Error('Tried to navigate beyond the last form')
      }
      this.dynamicFormsMethods.transitionToForm({ formRootPath: path, skipNavigationCheck })
    },

    saveAndContinue () {
      const state = get(this, 'controller.model.form.state')
      this.save.perform(state, false, false, () => {
        this.send('continue', { skipNavigationCheck: true })
      })
    },

    transitionToViewMode () {
      this.router.transitionTo(this.routeName, get(this, 'controller.model.parentId'), 'view', get(this, 'controller.model.path'))
    },

    // Save then transition to view mode - currently only used on the last page of the application form
    saveAndReturnToView (state) {
      this.save.perform(state, false, false)
        .then(() => this.dynamicFormsMethods.transitionToForm({ mode: 'view', skipNavigationCheck: true }))
    }
  }
})
