import Component from '@glimmer/component'

export default class ProgrammeSearchPopup extends Component {
  calculateStatus (mapData) {
    const statuses = {
      notCertified: { cardState: 'error-major', iconName: 'cross-circle', iconColor: 'red' },
      certified: { cardState: 'success', iconName: 'tick-circle', iconColor: 'green' }
    }

    return mapData.certified === 'Yes' ? statuses.certified : statuses.notCertified
  }

  get formattedPopupData () {
    const data = this.args.popupData
    data.status = this.calculateStatus(data)
    return data
  }
}
