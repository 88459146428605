import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Input\n  @type=\"checkbox\"\n  checked={{@tableActions.isChecked}}\n  {{on \"input\" this.selectAll}}\n/>\n", {"contents":"<Input\n  @type=\"checkbox\"\n  checked={{@tableActions.isChecked}}\n  {{on \"input\" this.selectAll}}\n/>\n","moduleName":"client/components/select-all-cell/index.hbs","parseOptions":{"srcName":"client/components/select-all-cell/index.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class SelectAllCell extends Component {
  @action
  selectAll () {
    this.args.tableActions.selectAllRecords()
  }
}
