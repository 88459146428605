import Ember from 'ember'
import { BREADCRUMB_PREFIX } from '../../constants'
import { find } from 'lodash'

const { inject, get, computed } = Ember

export default Ember.Mixin.create({
  router: inject.service(),

  /**
   * default values for page information
   */
  pageInfo: {
    applicationType: 'Application',
    consentTypes: []
  },

  /**
   * The crumb for the currently selected tab.
   */
  currentTabCrumb: computed('router.currentRouteName', 'mode', 'tabConfig', function () {
    const currentRouteName = get(this, 'router.currentRouteName')
    const mode = get(this, 'mode')

    const tabConfig = get(this, 'tabConfig')
    if (tabConfig === undefined) {
      throw new Error('tabConfig property not found on workflow-section component.')
    }

    // find the config for this tab (defined in constants.js)
    const key = currentRouteName.split('.').pop()
    const tab = find(tabConfig, { key })

    if (!tab) {
      return
    }

    // prepend "View" or "Edit" to the tab title
    const namePrefix = BREADCRUMB_PREFIX[mode]
    const name = namePrefix === undefined
      ? tab.title : namePrefix + (tab.title || '').toLowerCase()

    return {
      name,
      id: key
    }
  }),

  /**
   * Returns currently active route (used for styling active menu item).
   */
  currentRoute: computed('router.currentRouteName', function () {
    return get(this, 'router.currentRouteName')
  })
})
