import TabbedSearchController from 'client/mixins/controllers/tabbed-search-controller'
import { action, set } from '@ember/object'

export default class BaseMapSearchNonConformanceController extends TabbedSearchController {
  searchTypeKey = 'search-non-conformances'

  @action
  onSearchQueryChange () {
    set(this, 'q', null)
  }
}
