import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("  <div class=\"c-form-select-field--power-select c-dropdown-select\">\n    <DynamicForm::Edit::FieldLabel\n      @labelText={{@label}}\n      @class='c-form-select-field__label'\n      @inputElementId={{this.inputId}}\n   />\n\n  <PowerSelect\n      @triggerClass={{'c-form-select-field__input'}}\n      @triggerId={{this.inputId}}\n      @selected={{this.selected}}\n      @options={{@options}}\n      {{!-- template-lint-disable  no-action --}}\n      @onChange={{action \"setSelected\"}}\n      as |item|\n   >\n    {{#if item.label}}\n      {{item.label}}\n    {{else}}\n      {{item}}\n    {{/if}}\n  </PowerSelect>\n</div>", {"contents":"  <div class=\"c-form-select-field--power-select c-dropdown-select\">\n    <DynamicForm::Edit::FieldLabel\n      @labelText={{@label}}\n      @class='c-form-select-field__label'\n      @inputElementId={{this.inputId}}\n   />\n\n  <PowerSelect\n      @triggerClass={{'c-form-select-field__input'}}\n      @triggerId={{this.inputId}}\n      @selected={{this.selected}}\n      @options={{@options}}\n      {{!-- template-lint-disable  no-action --}}\n      @onChange={{action \"setSelected\"}}\n      as |item|\n   >\n    {{#if item.label}}\n      {{item.label}}\n    {{else}}\n      {{item}}\n    {{/if}}\n  </PowerSelect>\n</div>","moduleName":"client/components/dropdown/index.hbs","parseOptions":{"srcName":"client/components/dropdown/index.hbs"}});
import Component from '@glimmer/component'
import { inject } from '@ember/service'
import { action } from '@ember/object'
import { isObject } from 'lodash'

export default class Dropdown extends Component {
  /*
  * This component takes an argument called selected which defines the element that active in the dropdown.
  * The parent should handle the update of this variable via an onChangeAction that is passed through,
  * The onChangeAction should update the selected argument that is passed down to this component.
  *
  *  Args.options can be passed in as a string array, or an object with the type:
  * {
  *   value: [string|int]
  *   label: [string|int]
  *   disabled: boolean
  * }
  */
  @inject uniqueId

  get inputId () {
    return this.uniqueId.next()
  }

  get selected () {
    return this.args.options.find(option => {
      return isObject(option) ? option.value === this.args.selected : option === this.args.selected
    })
  }

  @action
  setSelected (selected) {
    if (this.args.onChangeAction) {
      this.args.onChangeAction(selected, this.args.valueKey)
    }
  }
}
