import CustomFormRoute from '../../../authenticated-custom-form-route'
import { inject } from '@ember/service'

export default class EditCustomFieldRecordsRoute extends CustomFormRoute {
  @inject customFieldsRemoteMethods

  async model ({ customFieldId }) {
    const customField = await this.customFieldsRemoteMethods.fetchCustomField(customFieldId)

    return { customField }
  }

  setupController (controller, model) {
    super.setupController(controller, model)

    controller.initialLoad()
  }
}
