import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  classNames: 'lt-sort-icon',
  classNameBindings: ['isAscending:lt-sort-icon--asc'],
  isAscending: computed('this.parentView.attrs.column.ascending', function () {
    return this.parentView.attrs.column.ascending
  })
})
