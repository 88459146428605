import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"c-nav-bar\" role=\"banner\">\n  <div class=\"c-nav-bar__container\">\n    <LinkTo @route=\"base\" class=\"c-nav-bar__logo-link\">\n      <span class=\"c-nav-bar__logo\"><AqLogo /></span>\n      <span class=\"c-nav-bar__site-title\">{{t 'assurance_hub'}}<span class=\"c-nav-bar__beta\">{{t 'beta'}}</span></span>\n    </LinkTo>\n    <div class=\"c-nav-bar__menu-container\">\n      {{#unless @suppressMenus}}\n        <Navbar::SearchMenu />\n        <Navbar::Menu\n          @isUserMenu={{true}}\n          @userFullName={{if @fullName @fullName this.session.fullName}}\n          @links={{this.userLinks}}\n        />\n        <Navbar::Menu\n          @links={{this.mainLinks}}\n        />\n      {{/unless}}\n    </div>\n  </div>\n</div>\n", {"contents":"<div class=\"c-nav-bar\" role=\"banner\">\n  <div class=\"c-nav-bar__container\">\n    <LinkTo @route=\"base\" class=\"c-nav-bar__logo-link\">\n      <span class=\"c-nav-bar__logo\"><AqLogo /></span>\n      <span class=\"c-nav-bar__site-title\">{{t 'assurance_hub'}}<span class=\"c-nav-bar__beta\">{{t 'beta'}}</span></span>\n    </LinkTo>\n    <div class=\"c-nav-bar__menu-container\">\n      {{#unless @suppressMenus}}\n        <Navbar::SearchMenu />\n        <Navbar::Menu\n          @isUserMenu={{true}}\n          @userFullName={{if @fullName @fullName this.session.fullName}}\n          @links={{this.userLinks}}\n        />\n        <Navbar::Menu\n          @links={{this.mainLinks}}\n        />\n      {{/unless}}\n    </div>\n  </div>\n</div>\n","moduleName":"client/components/navbar/index.hbs","parseOptions":{"srcName":"client/components/navbar/index.hbs"}});
import Component from '@glimmer/component'
import { USER_MENU_LINKS, MAIN_MENU_LINKS } from 'client/constants/links'
import { inject } from '@ember/service'

export default class NavbarComponent extends Component {
  @inject session
  @inject warden
  userLinks = USER_MENU_LINKS

  get mainLinks () {
    return this.warden.filterByAllowedActions(MAIN_MENU_LINKS, 'permissions')
  }
}
