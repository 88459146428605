/* global Raygun */
import { A } from '@ember/array'
import Controller from '@ember/controller'
import { action, set } from '@ember/object'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import GenericSearchController from 'client/mixins/controllers/generic-search'
import isEmpty from 'client/utils/is-empty'
import { task } from 'ember-concurrency-decorators'
import DS from 'ember-data'

// eslint-disable-next-line ember/no-new-mixins
export default class TabbedSearchController extends Controller.extend(GenericSearchController) {
  @action
  onSearchQueryChange () {
    set(this, 'q', null)
  }

  @inject('generic-search-remote-methods') remoteMethods
  @inject('generic-search') genericSearch
  @inject dynamicFormsRemoteMethods
  @inject gateKeeper
  @inject router
  @inject cookies
  @inject intl
  @inject toastMessages

  @tracked viewKey = null
  @tracked searchQuery = null
  @tracked searchResult = null
  @tracked searchTemplate = null
  @tracked reinflateQuery = null
  @tracked saveCurrentSearch = false
  @tracked displaySavedSearchList = false
  @tracked savedSearchList = null
  @tracked isTableExpanded = false

  customActions = {
    findOrCreateAuditFormInstanceId: this.findOrCreateAuditFormInstanceId,
    findOrCreateNonConformanceFormInstanceId: this.findOrCreateNonConformanceFormInstanceId
  }

  fetchSavedSearchList (searchTypeKey) {
    this.savedSearchList = DS.PromiseObject.create({
      promise: this.remoteMethods.fetchSavedQueryList(searchTypeKey).then(({ savedSearches }) => ({ rows: A(savedSearches.rows) }))
    })
  }

  @action async getSearchTemplate (searchTypeKey) {
    const { searchTemplate } = await this.remoteMethods.getSearchConfig(searchTypeKey)

    return searchTemplate
  }

  buildSearchQuery () {
    if (this.searchTemplate) {
      const viewKey = this.searchTemplate.views[0].viewKey

      this.searchQuery = {
        viewKey,
        searchTypeKey: this.searchTypeKey,
        requestedPage: this.genericSearch.getPreviouslyDisplayedPage(this.searchTypeKey, viewKey),
        requestedPageLength: 10,
        filterSet: this.genericSearch.getFilterSet(this.searchTypeKey)
      }

      if (!isEmpty(this.searchQuery.filterSet.filters)) {
        this.reinflateQuery = this.searchQuery
      }
    }
  }

  initialLoad = async () => {
    // searchTemplate: JSON definition of the search page structure
    // searchTypeKey: search endpoint to hit

    const searchTypeKey = this.searchTypeKey

    if (!this.searchTemplate) {
      this.searchTemplate = await this.getSearchTemplate(searchTypeKey)
    }
    if (this.searchTemplate.allowsQueryParamFiltering && this.queryParamSearchFilters) {
      this.genericSearch.saveFilterSet(this.searchTypeKey, JSON.parse(this.queryParamSearchFilters))
    }
    this.buildSearchQuery()

    this.searchTask.perform()
      .then(() => {
        // for performance reasons, load the list of saved searches after loading the search results
        if (this.gateKeeper.isAuthenticated) {
          this.fetchSavedSearchList(this.searchTypeKey)
        }
      })
  }

  init () {
    super.init(...arguments)
  }

  /**
   * ember-concurrency task
   * Gets search result based on current searchQuery
   * Sets class searchResult property
   */
  @task({ keepLatest: true })
  * searchTask () {
    yield this.remoteMethods.getSearchResult(this.searchQuery).then(({ searchResult }) => {
      this.searchResult = searchResult

      const searchResultIncludesCertified = Boolean(this.searchResult?.rows[0]?.certified)
      if (searchResultIncludesCertified) {
        this.insertUncertifiedIcon()
      }
    })
  }

  insertUncertifiedIcon () {
    // Creates icon in certified column / 'icon-with-text' component in generic search
    this.searchResult.rows.map(row => {
      const rowData = row.certified
      row.certified = {
        data: rowData
      }

      if (rowData === 'No') {
        row.certified.iconType = 'error-outline'
        row.certified.iconFill = '#FF595D' // $color--error
        row.certified.iconClass = 'generic-search-table__error-icon'
      }
    })
  }

  /**
   * Gets active sort column
   */
  get sortColumn () {
    if (this.searchQuery.ordering != null && this.searchQuery.ordering.length > 0) {
      return this.searchQuery.ordering[0]
    }
    return {}
  }

  /**
   * Returns current active view
   */
  get currentView () {
    if (!this.searchTemplate) return {}
    if (!this.searchQuery) return this.searchTemplate.views[0]
    return this.searchTemplate.views.find(view => view.viewKey === this.searchQuery.viewKey)
  }

  get rowsAlwaysExpandable () {
    if (!this.searchTemplate) return false
    return this.searchTemplate.rowsAlwaysExpandable
  }

  get expandRowOnClick () {
    if (!this.searchTemplate) return false
    return this.searchTemplate.expandRowOnClick
  }

  get expandAllColumns () {
    if (!this.searchTemplate) return false
    return this.searchTemplate.expandAllColumns
  }

  get csrfToken () {
    return this.cookies.read('csrftoken')
  }

  get exportQuery () {
    return JSON.stringify({ searchQuery: { ...this.searchQuery, requestedExport: true } })
  }

  get isTableExpanded () {
    return this.isTableExpanded
  }

  get isTableExpandable () {
    return this.searchTemplate.isTableExpandable
  }

  /**
   * Triggered on change event
   * Re-runs the search, and also triggers callback `onSearchQueryChange` if that has been passed in
   */
  onChange = () => {
    // If additional callback from parent context, call that
    this.onSearchQueryChange(this.searchQuery)
    // Perform search task
    this.searchTask.perform()
  }

  /**
   * Sets the sort column for the current search
   */
  setSortColumn = (orderColumn, orderDirection) => {
    this.searchQuery = {
      ...this.searchQuery,
      ordering: [
        {
          elementKey: orderColumn,
          direction: orderDirection
        }
      ]
    }

    this.onChange()
  }

  @action
  loadQuery (queryId) {
    this.loadingQuery = true
    this.remoteMethods.fetchSavedQuery(queryId)
      .then(({ searchQuery }) => {
        /*
        * The saved search query should be executed with the current viewKey
        * instead of the viewKey originally saved with the query
        */
        if ('viewKey' in searchQuery) delete searchQuery.viewKey
        this.reinflateQuery = searchQuery
        this.loadingQuery = false
        this.displaySavedSearchList = false
      })
  }

  /**
   * Update search query
   * (Shallow) Inherits values from the previously set query
   * @param {Object} searchQuery
   * @param {Boolean} resetToFirstPage
   */
  onSearch = (searchQuery, resetToFirstPage = false) => {
    if (this.searchTemplate.allowsQueryParamFiltering) {
      this.addSearchFiltersToQueryParams(searchQuery.filterSet)
    }
    if (resetToFirstPage) {
      // Reset search to first page
      this.genericSearch.resetPreviouslyDisplayedPage(searchQuery.searchTypeKey, searchQuery.viewKey)
      this.searchQuery = {
        ...this.searchQuery,
        requestedPage: 1
      }
    }
    // Set searchQuery to be new query, inheriting params from prev query (shallow)
    this.searchQuery = {
      ...this.searchQuery,
      ...searchQuery
    }
    this.genericSearch.saveFilterSet(this.searchQuery.searchTypeKey, this.searchQuery.filterSet)
    this.onChange()
  }

  /**
   * Sets the current page to the input page
   * @param {Number} page
   */
  setCurrentPage = (page) => {
    this.genericSearch.setPreviouslyDisplayedPage(this.searchTypeKey, this.searchQuery.viewkey, page)
    this.searchQuery = {
      ...this.searchQuery,
      requestedPage: page
    }
    this.onChange()
  }

  @action
  switchToMapView (searchQuery) {
    this.onSearch(searchQuery)
    this.router.transitionTo(this.searchTemplate.mapConfig.mapViewEmberRoute)
  }

  @action
  refreshSavedSearchList () {
    this.fetchSavedSearchList(this.searchTypeKey)
  }

  @action
  onSave () {
    this.saveCurrentSearch = false
    this.displaySavedSearchList = true
    this.actions.refreshSavedSearchList.call(this)
  }

  @action
  saveAction () {
    this.gateKeeper.isAuthenticated ? this.saveCurrentSearch = true : this.saveCurrentSearch = null
  }

  @action
  updateDisplaySavedSearches (bool) {
    this.displaySavedSearchList = bool
  }

  @action
  async findOrCreateAuditFormInstanceId (route, parent, mode, auditId, schemaAndTable, fixtureJson) {
    // Fetches a dynamic-forms formInstanceId related to auditId. If the formInstanceId does not exist yet, dynamic forms will inflate the form and return the formInstanceId.

    try {
      const formInstanceId = await this.dynamicFormsRemoteMethods.fetchFormInstanceId(parent, auditId, schemaAndTable, fixtureJson)
      this.router.transitionTo(route, parent, mode, `supplier/${formInstanceId.id}`)
    } catch (e) {
      this.toastMessages.danger(this.intl.t('search.failed_to_retrieve_audit'))
      Raygun.send(e)
    }
  }

  @action
  async findOrCreateNonConformanceFormInstanceId (route, parent, mode, nonConformanceId, schemaAndTable, fixtureJson) {
    // Fetches a dynamic-forms formInstanceId related to nonConformanceId. If the formInstanceId does not exist yet, dynamic forms will inflate the form and return the formInstanceId.

    try {
      const formInstanceId = await this.dynamicFormsRemoteMethods.fetchFormInstanceId(parent, nonConformanceId, schemaAndTable, fixtureJson)
      this.router.transitionTo(route, parent, mode, `supplier/${formInstanceId.id}`)
    } catch (e) {
      this.toastMessages.danger(this.intl.t('search.failed_to_retrieve_nonconformance'))
      Raygun.send(e)
    }
  }

  @action
  toggleIsTableExpanded () {
    if (this.isTableExpandable) {
      this.isTableExpanded = !this.isTableExpanded
    }
  }
}
