import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text, object, boolean } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './menu.notes.md'

export default {
  title: 'Menu',
  component: 'Menu',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const mainMenu = () => ({
  template: hbs`<Navbar::Menu />`
})

export const userMenu = () => ({
  template: hbs`<Navbar::Menu @isUserMenu={{true}} @userFullName="John Smith" />`
})

export const userMenuLongUsername = () => ({
  template: hbs`<Navbar::Menu @isUserMenu={{true}} @userFullName="John Smithensonlongnameson" />`
})

export const configurable = () => ({
  template: hbs`<Navbar::Menu @isUserMenu={{isUserMenu}} @userFullName={{username}} @links={{menuLinks}} />`,
  context: {
    menuLinks: object('Links', [
      { route: 'base', label: 'menu.home' },
      { route: 'base.settings', label: 'menu.settings' }
    ]),
    isUserMenu: boolean('Is user menu', true),
    username: text('User name', 'Some user name')
  }
})
