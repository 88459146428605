import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'

export default class UpcomingAuditsSnapshot extends Component {
  @inject router
  @inject warden

  todaysDate = new Date()
  todaysDateFormatted = this.todaysDate.toLocaleDateString('en-CA')
  endDate

  addOrSubtractDays (numberOfDays) {
    this.endDate = this.todaysDate
    this.endDate.setDate(this.endDate.getDate() + numberOfDays)
    this.endDate = this.endDate.toLocaleDateString('en-CA')
  }

  addOrSubtractMonths (numberOfMonths) {
    this.endDate = this.todaysDate
    this.endDate.setMonth(this.endDate.getMonth() + numberOfMonths)
    this.endDate = this.endDate.toLocaleDateString('en-CA')
  }

  @action viewAuditsInAuditSearch (auditStatus) {
    let genericSearchQueryParams
    if (auditStatus === 'overdue') {
      this.addOrSubtractMonths(-12)
      const auditOverdueQuery = {
        dateCompleted: ['is null'],
        nextAuditDueDate: [this.endDate, this.todaysDateFormatted]
      }

      genericSearchQueryParams = auditOverdueQuery
    } else if (auditStatus === 'not_booked_next_28_days') {
      this.addOrSubtractDays(28)
      const auditDueNotBookedQuery = {
        dateCompleted: ['is null'],
        nextAuditDueDate: [this.todaysDateFormatted, this.endDate],
        nextAuditBookedDate: ['is null']
      }

      genericSearchQueryParams = auditDueNotBookedQuery
    } else if (auditStatus === 'booked_next_28_days') {
      this.addOrSubtractDays(28)
      const AuditBookedNext28DaysQuery = {
        dateCompleted: ['is null'],
        nextAuditBookedDate: [this.todaysDateFormatted, this.endDate]
      }

      genericSearchQueryParams = AuditBookedNext28DaysQuery
    }

    this.router.transitionTo('base.home.search.audit', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(genericSearchQueryParams)
      }
    })
  }
}
