import AuthenticatedRoute from '../../authenticated-route'
import { inject } from '@ember/service'

export default AuthenticatedRoute.extend({
  router: inject(),

  model () {
    const model = this.modelFor('base.supplier-section')
    this.router.transitionTo('base.supplier-section.supplier', model)
  }
})
