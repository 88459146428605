import { get, computed } from '@ember/object'
import Row from 'ember-light-table/components/lt-row'

export default Row.extend({
  classNames: ['lt-row'],
  classNameBindings: ['isHighlighted'],
  isHighlighted: computed('row.highlight_row', function () {
    if (String(get(this, 'row.highlight_row')) === 'true') {
      return 'c-highlighted-row'
    }

    return ''
  })
})
