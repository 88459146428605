import Ember from 'ember'
import { copy } from 'ember-copy'
const { set, get, inject, run } = Ember

export default Ember.Component.extend({
  populateFunctions: inject.service(),

  didInsertElement () {
    this._super(...arguments)

    // This is used as a signal that the application has finished displaying on the page.
    // It is used by the PDF generation, see node-job-queue/handlers/generate-application-pdf.js
    document.RESC_APPLICATION_DONE = false
    run.scheduleOnce('afterRender', this, 'signalComplete')
  },

  updateDeletedHide (formElement, index, key, status = true) {
    // We take a copy here so that the state reference changes on set and forces observers throughout the app fire
    const state = copy(get(this, 'form.state'), true)
    state[formElement.name][index] = Object.assign({}, state[formElement.name][index], { [key]: status })
    set(this, 'form.state', state)
  },

  signalComplete () {
    // Signal that the page is done rendering.
    document.RESC_APPLICATION_DONE = true
  }
})
