import Mixin from '@ember/object/mixin'
import { get } from '@ember/object'
import { inject } from '@ember/service'

/**
 * A mixin to add to routes requiring them to have an authenticated user.
 */
export default Mixin.create({
  session: inject(),
  flashMessages: inject(),
  routing: inject('-routing'),
  beforeModel (transition) {
    return get(this, 'session.currentUser').then(() => {
      if (!get(this, 'session.isAuthenticated')) {
        // set the URL to return to after login
        let url = transition.intent.url
        if (url === undefined) {
          // attempt to generate the URL using the routing service
          const { name, contexts, queryParams } = transition.intent
          url = get(this, 'routing').generateURL(name, contexts, queryParams)
        }

        transition.abort()

        // We need to figure out if we're attempting to transition away from the home section,
        // there might be a better way to infer this, but I couldn't find it.
        const inHomeSection = get(transition, 'router.state.params.home-section') !== undefined
        if (inHomeSection) {
          // Display the login modal
          this.controllerFor('home-section').set('returnTo', url)
          this.controllerFor('home-section').set('loginModal', true)
        } else {
          // Redirect to the home section then display the login modal
          get(this, 'router').transitionTo('home-section', { queryParams: { loginModal: true, returnTo: url } })
        }
      } else {
        return this._super(...arguments)
      }
    })
  }
})
