import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import { deDupErrors } from '../../../../libs/validation-error-lib'

const { get, computed } = Ember

export default Ember.Component.extend(DynamicElement, {
  /**
   * Little bit of hacky code to remove duplicate errors from the same formElement.  Particularly useful where
   * child element errors have been promoted to their parent
   */
  processedErrors: computed('formElementState', 'state', 'index', 'formElement.name', function () {
    const errors = get(this, `state.${get(this, 'formElement.name')}.${get(this, 'index')}.errors`) || []
    return deDupErrors(errors)
  })
})
