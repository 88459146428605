
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { timeStampToString } from 'client/utils/chart-utils'
import { kebabCase } from 'lodash'
import { FDA_VISIT_TYPES } from '../../constants/values'

export default class RevisitAssessmentProgress extends Component {
  @inject router
  @inject warden

  revisitVisitType = FDA_VISIT_TYPES.REVISIT

  get barChartIdentifier () {
    return kebabCase('bar-chart-revisit-assessment-progress')
  }

  get barChartTitle () {
    return 'reports_tab_graph_titles.revisit_assessment_progress'
  }

  /**
   * Returns: the list of visit types for REVISIT e.g.  ['Revisit', 'Routine Annual Revisit']
   */
  get revisitVisitTypes () {
    const revisitVisitTypeMapping = this.args?.visitTypeMapping[this.revisitVisitType]
    const visitTypeNames = revisitVisitTypeMapping ? revisitVisitTypeMapping.map((mapping) => mapping[1]) : []
    // remove any duplicate names so that we don't have duplicate filters
    return [...new Set(visitTypeNames)]
  }

  auditCompletedSearchFilters = {
    programme: [this.args.programmeId.toString()],
    visitType: this.revisitVisitTypes,
    dateCompleted: [timeStampToString(this.args?.FDASeasonDates.start_date), timeStampToString(this.args?.FDASeasonDates.end_date)]
  }

  // to be 'booked' an audit have no completed date
  auditBookedSearchFilters = {
    programme: [this.args.programmeId.toString()],
    visitType: this.revisitVisitTypes,
    dateCompleted: ['is null'],
    assessmentDate: [timeStampToString(this.args?.FDASeasonDates.start_date), timeStampToString(this.args?.FDASeasonDates.end_date)]
  }
}
