import Component from '@ember/component'
import { computed } from '@ember/object'
import { getOwner } from '@ember/application'

import moment from 'moment'

export default Component.extend({
  tagName: '',

  // The format used to display the currently set date
  dateFormat: computed('container', function () {
    // Need to use this awkward syntax to get the application's environment settings, rather than the addon's
    return getOwner(this).resolveRegistration('config:environment').dateFormat
  }),

  displayValue: computed(function () {
    return this.value ? moment(this.value).format(this.dateFormat) : '-'
  })
})
