import RemoteMethodService from './remote-method'
import { enqueueTask } from 'ember-concurrency-decorators'

/**
 * A files service for managing files.
 *
 * Interacts with the server API to upload & download files and to retrieve
 * file metadata.
 */
export default class FileUploadService extends RemoteMethodService {
  /**
   * Upload a file to the server.
   *
   * Queues uploads and throttles them to only three files at any one time.
   *
   * @param {Object} file A file as returned from ember-file-upload.
   * @param {String} url The url that you want to upload the file to.
   * @returns {Promise<Object>} File metadata object as returned from the API,
   *                            e.g. {id: 22, filename: 'myfile.doc', size: 9475}
   */
  @enqueueTask({
    maxConcurrency: 3
  })
  * uploadTask (file, url) {
    // eslint-disable-next-line no-unused-vars
    const response = yield file.upload(url, {
      headers: {
        'X-CSRFToken': this.csrfToken,
        'Content-Disposition': `attachment; filename=${file.blob.name}`
      }
    })

    const {
      total_records: totalRows,
      invalid_records: invalidRows,
      error_summary: errorPreview,
      success_summary: successfulPreview,
      upload_id: uploadId,
      custom_field_name: customFieldName,
      csv_header_names: headerNames
    } = response.body

    return {
      totalRows,
      recordsImported: (+totalRows - +invalidRows),
      successfulPreview: successfulPreview.map(({ id, ...rest }) => ({ ...rest, row: id })),
      errorPreview: errorPreview.map(({ error, ...rest }) => ({ ...rest, value: error })),
      uploadId,
      customFieldName,
      headerNames: (headerNames == null ? '' : headerNames).split(',')
    }
  }

  /**
   * Upload a file, wrapper for the Ember concurrency task.
   * @param {Object} file A file as returned from ember-file-upload.
   * @param {String} type The related model type of this file, e.g. 'application'.
   * @param {String} ref The related model reference of this file, e.g. '12345'.
   * @returns {Promise<Object>} File metadata object as returned from the API,
   *                            e.g. {id: 22, filename: 'myfile.doc', size: 9475}
   */
  upload (file, url) {
    return this.uploadTask.perform(file, url)
  }
}
