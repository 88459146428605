import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'

class ValidationResponse {
  @tracked successfulPreview
  @tracked errorPreview
  @tracked customFieldName
  @tracked recordsImported
  @tracked totalRows
  @tracked uploadId
  @tracked headerNames
}

export default class CustomFieldEditComponent extends Component {
  @inject intl
  @inject router
  @inject customFieldsRemoteMethods
  @inject session
  @inject fileUpload
  @inject toastMessages

  @tracked showModal = false
  @tracked showSectionTwo = false
  @tracked showSectionThree = false
  @tracked customFields
  @tracked validationResponse
  @tracked customFieldError
  @tracked customFieldName
  @tracked validationErrors
  @tracked uploading
  @tracked fileName

  get organisationId () {
    return this.session?.organisation?.id
  }

  get customFieldId () {
    return this.currentCustomFieldId
  }

  constructor () {
    super(...arguments)
    this.customFieldsRemoteMethods.fetchCustomFields()
      .then(customFields => {
        this.customFields = customFields
      })
  }

  @action
  closeModal () {
    this.showModal = false
  }

  @action
  updateCustomField (event) {
    this.customFieldError = false
    this.currentCustomFieldId = event.target.value
    this.showSectionTwo = true
    if (this.currentCustomFieldId) {
      this.customFieldName = this.customFields.find(field => String(field.id) === String(this.currentCustomFieldId)).field_name
    }
  }

  @action
  uploadCSV (file) {
    if (!this.currentCustomFieldId) {
      this.customFieldError = this.intl.t('errors.MISSING')
      return
    }
    this.fileName = file.name
    this.uploading = true
    this.uploadError = false
    this.fileUpload.upload(file, `/api/organisation/${this.organisationId}/custom-field/${this.currentCustomFieldId}/validate`)
      .then(response => {
        this.validationResponse = new ValidationResponse()
        Object.assign(this.validationResponse, response)
        this.showSectionThree = true
        this.uploadId = response.uploadId

        if (response.recordsImported !== response.totalRows) {
          this.validationErrors = response.totalRows - response.recordsImported
        } else {
          this.validationErrors = false
        }
      })
      .catch(response => {
        this.uploadError = response.body.detail
        this.toastMessages.danger(this.uploadError)
        this.fileName = null
      })
      .finally(() => {
        this.uploading = false
      })
  }

  async saveField () {
    try {
      await this.customFieldsRemoteMethods.confirmUpload(this.currentCustomFieldId, this.validationResponse.uploadId)
      this.toastMessages.success(this.intl.t('messages.saved'))
      await this.router.transitionTo('base.settings.custom-fields')
    } catch (e) {
      this.toastMessages.danger(this.intl.t('messages.unable_to_save'))
    }
  }

  @action
  noop () {
    // Do nothing
  }

  @action
  confirmSave () {
    this.showModal = false
    this.saveField()
  }
}
