import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject } from '@ember/service'

export default class TermsAndConditionsController extends Controller {
  @inject remoteMethods
  @inject session
  @inject router
  @inject toastMessages
  @inject intl

  @action
  acceptTerms (termsId) {
    this.remoteMethods.post(`/policy/accept-terms/${termsId}`)
      .then(() => {
        this.session.hasAgreedToTerms = true
        // Transition user to route they were attempting to access before they had to accept-terms
        const previousTransition = this.previousTransition
        if (previousTransition) {
          previousTransition.retry()
          this.previousTransition = null
        } else {
          this.router.transitionTo('base.home.dashboard')
        }
      })
      .catch(() => {
        this.toastMessages.danger(this.intl.t('messages.terms_and_conditions_failed'))
      })
  }

  @action
  rejectTerms () {
    this.session.logout(false)
  }
}
