import Ember from 'ember'
import { MIN_REPEATS } from '../../constants'
const { computed, get, getWithDefault } = Ember

export default Ember.Mixin.create({
  showDeleteButton: computed('totalNumberDisplayed', 'formElement.{repeatable,minRepeats}', function () {
    if (!get(this, 'formElement.repeatable')) return false
    const minRepeats = getWithDefault(this, 'formElement.minRepeats', MIN_REPEATS)
    return get(this, 'totalNumberDisplayed') > minRepeats
  })
})
