import Controller from '@ember/controller'
import GenericSearchController from 'client/mixins/controllers/generic-search'
import { inject } from '@ember/service'
import { action } from '@ember/object'

export default class UserSearch extends Controller.extend(GenericSearchController) {
    @inject('generic-search-remote-methods') remoteMethods

    searchTypeKeyClientCompany = 'search-users'
    searchTypeKeyAdmin = 'search-users-aq'

    @action async getSearchTemplate (searchTypeKey) {
      const { searchTemplate } = await this.remoteMethods.getSearchConfig(searchTypeKey)
      return searchTemplate
    }
}
