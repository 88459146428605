import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'
import Table from 'ember-light-table'

export default class DashboardNewSuppliersTable extends Component {
  @inject customFieldsRemoteMethods
  @inject intl
  @inject router

  @tracked table

  get todaysDateFormatted () {
    const todaysDate = new Date()
    return todaysDate.toLocaleDateString('en-CA')
  }

  get ninetyDaysAgoFormatted () {
    const lastNinetyDays = new Date()
    lastNinetyDays.setDate(lastNinetyDays.getDate() - 90)
    return lastNinetyDays.toLocaleDateString('en-CA')
  }

  @action
  viewNewSuppliersInProgrammeSearch () {
    // Only care about showing results from the last 90 days
    const internalUserFilters = {
      dateAddedToProgramme: [this.ninetyDaysAgoFormatted, this.todaysDateFormatted]
    }

    this.router.transitionTo('base.home.search.programme', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(internalUserFilters)
      }
    })
  }

  get createTableAfterDOMInsert () {
    return this.createTable.bind(this)
  }

  columns = [
    {
      label: this.intl.t('dashboard_new_suppliers_table.trading_name'),
      valuePath: 'tradingName',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false,
      cellComponent: 'tooltip-cell'
    },
    {
      label: this.intl.t('dashboard_new_suppliers_table.client_company_supplier_id'),
      valuePath: 'clientCompanySupplierId',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false,
      width: '100px',
      breakpoints: ['smallDesktopUp'],
      cellComponent: 'tooltip-cell'
    },
    {
      label: this.intl.t('dashboard_new_suppliers_table.client_company'),
      valuePath: 'clientCompany',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false,
      breakpoints: ['smallDesktopUp'],
      cellComponent: 'tooltip-cell'
    },
    {
      label: this.intl.t('dashboard_new_suppliers_table.visit_type'),
      valuePath: 'visitType',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false,
      width: '100px',
      breakpoints: ['smallDesktopUp'],
      cellComponent: 'tooltip-cell'
    },
    {
      label: this.intl.t('dashboard_new_suppliers_table.programme'),
      valuePath: 'programme',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false,
      width: '200px',
      breakpoints: ['smallDesktopUp'],
      cellComponent: 'tooltip-cell'
    },
    {
      label: this.intl.t('dashboard_new_suppliers_table.date_supplier_added_to_programme'),
      valuePath: 'dateAddedToProgramme',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false,
      width: '100px',
      breakpoints: ['smallDesktopUp'],
      cellComponent: 'tooltip-cell'
    },
    {
      label: this.intl.t('dashboard_new_suppliers_table.actions'),
      valuePath: 'actions',
      cellComponent: 'view-supplier-profile',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      width: '100px'
    }
  ];

  @action
  navigateToSupplierProfile (enterpriseClientId) {
    this.router.transitionTo('base.supplier-section.supplier', enterpriseClientId, 'view', 'supplier/supplier')
  }

  createTable () {
    let rowState = []
    rowState = this.args.insights.rows
    this.table = Table.create({ columns: this.columns, rows: rowState, enableSync: true })
  }
}
