import Component from '@ember/component'
import { inject } from '@ember/service'
import { computed } from '@ember/object'

export default Component.extend({
  router: inject(),
  tagName: '',
  actionConfigs: computed.alias('column.config.actions'),
  disableButton: false,
  actions: {
    async onClick (actionConfig, event) {
      event.stopPropagation()
      this.toggleDisableButton()
      const args = actionConfig.args.map(arg => {
        if (arg.constant !== undefined) {
          return arg.constant
        }
        if (arg.elementKey !== undefined) {
          return this.row.content[arg.elementKey]
        }
      })
      const type = actionConfig.type
      if (this.actions[type]) {
        this.send(type, ...args)
      } else if (this.tableActions[type]) {
        await this.tableActions[type](...args)
        this.toggleDisableButton()
      } else {
        this.toggleDisableButton()
        const error = `attempted to run action.${actionConfig.type}(` + [...args].join(' ') + ')'
        throw new Error(error)
      }
    },

    transitionAction (...args) {
      this.router.transitionTo(...args)
    }
  },
  toggleDisableButton () {
    this.set('disableButton', !this.disableButton)
  }
})
