import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

export default class SeasonTargetsEditableTextCell extends Component {
  @tracked value
  maxLength = 7

  constructor () {
    super(...arguments)
    this.value = this.args?.row?.content?.target_value
  }

  @action
  setSelected (e) {
    this.value = e.target?.value
    this.args.tableActions.onBlur(this.args?.row?.content?.visit_type, this.args?.row?.content?.month, this.value)
  }

  @action
  onInput (e) {
    this.value = e.target?.value
    if (this.value.length > this.maxLength) {
      this.value = this.value.slice(0, this.maxLength)
    }
    if (this.value < 0) {
      this.value = null
    }
  }
}
