import Component from '@ember/component'

export default Component.extend({
  classNames: ['c-status-pill'],
  classNameBindings: [
    'isDraftPill:c-status-pill--draft',
    'isAlertPill:c-status-pill--alert',
    'isWarningPill:c-status-pill--warning',
    'isErrorPill:c-status-pill--error',
    'isSuccessPill:c-status-pill--success',
    'isInfoPill:c-status-pill--info',
    'isLargePill:c-status-pill--large'
  ]
})
