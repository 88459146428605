import Component from '@glimmer/component'
import { CARD_STATES } from '../../../constants/values'
import { inject } from '@ember/service'
export default class AuditeeActionCard extends Component {
  @inject router

  constructor () {
    super(...arguments)
    this.status = this.args.status
    this.buttonRoute = this.args.buttonRoute
  }

  get icon () {
    return CARD_STATES[this.status].iconType
  }

  get iconColor () {
    return CARD_STATES[this.status].iconColor
  }

  get cardColor () {
    return CARD_STATES[this.status].cardStatus
  }

  get hasIcon () {
    let hasIcon = false
    if (CARD_STATES[this.status].iconType && CARD_STATES[this.status].iconColor) {
      hasIcon = true
    }
    return hasIcon
  }
}
