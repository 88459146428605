import { inject } from '@ember/service'
import RSVP from 'rsvp'
import AuthenticatedRoute from '../authenticated-route'
export default class HomeRoute extends AuthenticatedRoute {
  @inject warden
  @inject insightsRemoteMethods
  @inject session
  @inject featureFlags
  @inject router

  get tabs () {
    return [
      {
        route: 'base.home.dashboard',
        translationKey: 'tabs.dashboard',
        permissions: ['view__dashboard', 'view__dashboard__internal']
      },
      {
        route: 'base.home.programme-insights',
        translationKey: 'tabs.programme_insights',
        permissions: ['view__programme_insights__basic_insights', 'view__programme_insights__advanced_insights', 'view__programme_insights__admin']
      },
      {
        route: 'base.home.non-conformance-insights',
        translationKey: 'tabs.non_conformance_insights',
        permissions: ['view__non_conformance_insights']
      },
      // Any new permissions added to reports tab must also check if the user is dairy, see roles/views.py
      {
        route: 'base.home.reports',
        translationKey: 'tabs.reports',
        permissions: ['view__reports_services_overview', 'view__FDA_reports'],
        featureFlag: 'FARM_DAIRY_REPORTS'
      },
      {
        route: 'base.home.search',
        translationKey: 'tabs.search',
        permissions: ['view__search__programme_search', 'view__search__programme_search__dairy_restricted', 'view__search__audit_search', 'view__search__non_conformance_search']
      },
      {
        route: 'base.home.programme-subscriber-search',
        translationKey: 'tabs.programme_subscriber_search',
        permissions: ['view__programme_subscriber_search']
      }
    ]
  }

  get auditeeTabs () {
    return [{
      route: 'base.home.non-conformances',
      translationKey: 'tabs.non_conformances',
      permissions: ['view__dashboard__auditee_summary']
    }]
  }

  async getTabs () {
    let tabsList = this.tabs
    if (this.warden.can('view__dashboard__auditee_summary')) {
      if (this.session?.entitiesIsAuditeeFor?.length) {
        tabsList = tabsList.concat(this.auditeeTabs)
      }
    }
    return this.warden.filterByAllowedActions(tabsList, 'permissions')
  }

  async model () {
    const tabs = await this.getTabs()
    return RSVP.hash({
      insights: this.warden.can('view__dashboard__programme_summary') ? this.insightsRemoteMethods.fetchProgrammeInsightsByNonConformance() : [],
      auditInsights: this.warden.can('view__dashboard__audit_snapshot') ? this.insightsRemoteMethods.fetchAuditInsights() : [],
      auditeeInsights: this.warden.can('view__dashboard__auditee_summary') ? this.insightsRemoteMethods.fetchAuditeeDashboardSummaryInsights() : [],
      newSuppliersTableInsights: this.warden.can('view__dashboard__new_suppliers_table') ? this.insightsRemoteMethods.fetchNewSuppliersTableInsights() : [],
      tabs
    })
  }

  async afterModel (model, transition) {
    const tabs = model.tabs
    super.beforeModel(...arguments).then(() => {
      if (tabs.length === 0) {
        transition.abort()
        this.router.transitionTo('base.not-found')
      } else {
        if (!tabs.any(v => transition.to.name.startsWith(v.route))) {
          transition.abort()
          this.router.transitionTo(tabs[0].route)
        }
      }
    })
  }
}
