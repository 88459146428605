/* global Raygun */
import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { cloneDeep } from 'lodash'
import { get3CharacterMonthNameFromInteger } from 'client/utils/date'

export default class EditSeasonTargets extends Component {
  @inject seasonTargetsRemoteMethods
  @inject toastMessages
  @inject intl
  @inject router
  @inject toastMessages

  @tracked initialRowState
  @tracked currentRowState
  @tracked tradingName
  @tracked clientCompanyOrgId
  @tracked showModal = false
  @tracked showModalUnsavedChanges = false
  @tracked tableInInitialState = true
  @tracked tableHasUnsavedChanges = true
  @tracked isLoading

  constructor () {
    super(...arguments)
    this.clientCompanyOrgId = this.args.model.clientCompanyOrgId
    this.seasonModifier = this.args.model.seasonModifier || 'current'
    this.fetchFdaSeasonTargets()
  }

  async fetchFdaSeasonTargets () {
    try {
      this.isLoading = true
      const response = await this.seasonTargetsRemoteMethods.fetchFdaSeasonTargets(this.clientCompanyOrgId, this.seasonModifier)
      this.initialRowState = response.data.rows
      this.tradingName = response.data.trading_name
      this.addMonthNames()
      this.currentRowState = this.initialRowState
    } catch (e) {
      Raygun.send(e)
      this.toastMessages.danger(this.intl.t('messages.unable_to_fetch_data'))
      this.router.transitionTo('base.settings.season-targets')
    }
    this.isLoading = false
  }

  addMonthNames () {
    Object.keys(this.initialRowState).map(visitType => {
      this.initialRowState[visitType].map(row => {
        row.monthName = get3CharacterMonthNameFromInteger(row.month)
      })
    })
  }

  @action closeModal () {
    this.showModal = false
  }

  @action
  onBlur (visitType, rowId, value) {
    if (this.hasChanged(visitType, rowId, value)) {
      this.currentRowState[visitType].find(row => row.month === rowId).target_value = value
      this.args.setFormDirty(true)
    }
  }

  hasChanged (visitType, rowId, newValue) {
    const initialValue = this.initialRowState[visitType].find(row => row.month === rowId)?.target_value
    this.tableInInitialState = false
    return newValue !== initialValue
  }

  @action
  onClickSave () {
    if (this.doAllRowValuesContainData()) {
      this.showModal = true
    } else {
      this.toastMessages.danger(this.intl.t('season_targets.toast.rows_must_contain_data'))
    }
  }

  doAllRowValuesContainData () {
    return Object.keys(this.currentRowState).map(visitType => {
      return this.currentRowState[visitType].filter(row => row.target_value).length === this.currentRowState[visitType].length
    }).every(value => value === true)
  }

  @action
  async updateTargetsConfirm () {
    try {
      const response = await this.seasonTargetsRemoteMethods.saveFdaSeasonTargets(this.currentRowState, this.clientCompanyOrgId)
      this.initialRowState = response.data.rows
      this.currentRowState = this.initialRowState
      this.tableInInitialState = true
      this.args.setFormDirty(false)
      cloneDeep(this.initialRowState)
      this.toastMessages.success(this.intl.t('season_targets.toast.update_successful'))
    } catch (e) {
      this.toastMessages.danger(this.intl.t('season_targets.toast.update_failed'))
    } finally {
      this.closeModal()
    }
  }
}
