
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import RouteAction from 'client/helpers/route-action'

export default class FarmMap extends Component {
  @tracked enterpriseClientId
  @tracked params = {}

  constructor () {
    super(...arguments)
    this.enterpriseClientId = RouteAction.create(this).compute(['getParentId'])()
    this.params = {
      formInstanceId: this.args.formInstanceId,
      parent: this.enterpriseClientId
    }
  }
}
