import Helper from '@ember/component/helper'

/*
* Helper to strip off html tags from string
* Source: https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
*/
export default Helper.extend({
  compute ([value, ...rest]) {
    if (typeof value === 'string') {
      value = value.replace(/(<([^>]+)>)/ig, '')
    }
    return value
  }
})
