/* global Raygun */

import RemoteMethodService from './remote-methods'
import { PDF_PRINTER_BASE_URL } from '../constants/values'
import FileSaverMixin from 'ember-cli-file-saver/mixins/file-saver'
import { inject } from '@ember/service'
import config from 'client/config/environment'

export default class PdfRemoteMethods extends RemoteMethodService.extend(FileSaverMixin) {
  @inject toastMessages
  PDF_PRINTER_BASE_URL = PDF_PRINTER_BASE_URL
  maxPdfRetries = config.ENV_VARS.PDF_RETRY_COUNT || 3

  async requestPdf (pdfEndpointUrl, pageToPrint, fileName, readyFlag) {
    const jobArgs = this.createPdfJobArgsObject(pageToPrint, fileName, readyFlag)
    try {
      this.toastMessages.info(this.intl.t('pdf_export.exporting_pdf'), { timeOut: 0, extendedTimeOut: 0, message: this.intl.t('pdf_export.exporting_pdf_message') })
      const data = await this.request(`${pdfEndpointUrl}`, {
        method: 'POST',
        data: jobArgs
      })
      return data.pdfExportId
    } catch (e) {
      Raygun.send(e)
      this.toastMessages.danger(this.intl.t('pdf_export.error_exporting_pdf'))
    }
  }

  async downloadPdf (pdfExportId) {
    // Check if file is ready to download. Retry every 3 seconds.
    try {
      let fileStatus = ''
      let data = null
      while (fileStatus !== 'ready') {
        await this.sleep(3000)
        data = await this.requestNoHandle(`${PDF_PRINTER_BASE_URL}/download-pdf/${pdfExportId}`)
        fileStatus = data.fileStatus

        if (fileStatus === 'error') {
          throw new Error('Error exporting PDF')
        }
      }
      this.toastMessages.clear()
      this.createLinkFromUrlAndDownload(data)
    } catch (e) {
      this.toastMessages.clear()
      // Bump up timeout to 15s when displaying async error
      this.toastMessages.danger(this.intl.t('pdf_export.error_exporting_pdf'), { timeOut: 15000 })
      Raygun.send(e)
    }
  }

  async sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  createPdfJobArgsObject (pageToPrint, fileName, readyFlag) {
    readyFlag = readyFlag || null
    return {
      printUrl: pageToPrint,
      fileName: fileName,
      readyFlag: readyFlag
    }
  }

  createLinkFromUrlAndDownload (data) {
    const a = document.createElement('a')
    a.href = data.url
    a.download = data.name
    a.click()
    a.href = ''
  }
}
