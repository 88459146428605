import Controller from '@ember/controller'
import GenericSearchController from 'client/mixins/controllers/generic-search'
import { action } from '@ember/object'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'

export default class BaseSettingsCustomFieldsEditCustomFieldRecordsController extends Controller.extend(GenericSearchController) {
  @inject('generic-search-remote-methods') remoteMethods
  @inject gateKeeper
  @inject router
  @inject cookies
  @inject customFieldsRemoteMethods
  @inject intl

  @tracked searchQuery = null
  @tracked searchTemplate = null
  @tracked customFieldRecords = []
  @tracked searchResults = []
  @tracked isLookupField = false
  @tracked isLoading = false

  // Pagination current page
  @tracked currentPage = 1

  searchTypeKey = 'custom-field-records-search-filters'

  acceptableFilterKeys = [
    { label: this.intl.t('settings.custom_fields.update_records.trading_name'), key: 'trading_name' },
    { label: this.intl.t('settings.custom_fields.update_records.address'), key: 'address' },
    { label: this.intl.t('settings.custom_fields.update_records.profile_id'), key: 'supplier_id' }
  ]

  initialLoad = async () => {
    this.isLoading = true
    this.searchTemplate = await this.getSearchTemplate(this.searchTypeKey)

    // -14 is the Lookup type & -13 is the text field type
    this.isLookupField = this.model.customField.field_type === '-14'

    // Only allows acceptable filters and filter for the currently viewed custom field
    if (this.searchTemplate) {
      this.searchTemplate.filters = this.searchTemplate.filters.filter(filter => {
        return this.acceptableFilterKeys.find(key => key.label === filter.label) || filter.label.toLowerCase() === this.model.customField.field_name.toLowerCase()
      })
    }
    const recordsResponse = await this.customFieldsRemoteMethods.fetchCustomFieldRecords(this.model.customField.id)
    this.customFieldRecords = recordsResponse.results
    this.initializeSearchResults()
    this.isLoading = false
  }

  init () {
    super.init(...arguments)
  }

  onSearch = async (searchQuery) => {
    this.isLoading = true
    let searchData
    if (searchQuery) {
      this.searchQuery = {
        ...this.searchQuery,
        ...searchQuery
      }
      searchData = this.getSearchQueryObject(this.searchQuery)
    }
    if (Object.keys(searchData).length) {
      this.filterResults(searchData)
    } else {
      this.initializeSearchResults()
    }
    this.isLoading = false
  }

  resetSearch = () => {
    this.initialLoad()
  }

  initializeSearchResults () {
    this.searchResults = this.customFieldRecords
    this.resetCurrentPage()
  }

  resetCurrentPage () {
    this.currentPage = 1
  }

  @action
  setPage (page) {
    this.currentPage = page
  }

  @action async getSearchTemplate (searchTypeKey) {
    const { searchTemplate } = await this.remoteMethods.getSearchConfig(searchTypeKey, false)

    return searchTemplate
  }

  /**
  * For each filterKey in SearchData
  * returns rows in customFieldRecords that include the searchQuery value
  */

  filterResults (searchData) {
    this.initializeSearchResults()
    if (searchData && Object.keys(searchData)?.length) {
      Object.keys(searchData).map(filterKey => {
        this.searchResults = this.searchResults.filter(row => {
          return searchData[filterKey].find(searchQuery => {
            // returns true if row[filterKey] includes the searchQuery
            return String(row[filterKey]).toLowerCase().includes(String(searchQuery).toLowerCase())
          })
        })
      })
    }
  }

  /**
   * Takes a the SearchQuery object that is returned from generic search filters
   * Transforms this into a simple object for the backend to process.
   * Example return value:
   * {
   *  tradingName:["beef","dairy"],
   *  address:["123 good street"]
   * }
   * @returns {Object}
   */
  getSearchQueryObject = (queryString) => {
    const reducerFunction = (acc, nextVal) => {
      const filters = nextVal.filterSet.filters
      const acceptableKeys = this.acceptableFilterKeys.map(key => key.key)
      let filterKey = filters[0].filterKey
      if (!acceptableKeys.find(key => key === filterKey)) {
        filterKey = 'custom_field_value'
      }
      return {
        ...acc,
        [filterKey]: filters.map(filter => filter.filterValue)
      }
    }
    return queryString.filterSet.filters.reduce(reducerFunction, {})
  }
}
