import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text, boolean } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './grid.notes.md'

export default {
  title: 'Grid',
  component: 'Grid',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const grid = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <Grid
        @cols={{cols}}
        @mobileCols={{mobileCols}}
        @tabletCols={{tabletCols}}
        @useWideGutters={{useWideGutters}}
        @useNarrowGutters={{useNarrowGutters}}
      >
        <div style="background: #000;height: 200px"></div>
        <div style="background: #000;height: 200px"></div>
        <div style="background: #000;height: 200px"></div>
        <div style="background: #000;height: 200px"></div>
        <div style="background: #000;height: 200px"></div>
        <div style="background: #000;height: 200px"></div>
        <div style="background: #000;height: 200px"></div>
        <div style="background: #000;height: 200px"></div>
      </Grid>
    </ContentContainer>
  `,
  context: {
    cols: text('cols', '3'),
    mobileCols: text('mobileCols', '1'),
    tabletCols: text('tabletCols', '2'),
    useWideGutters: boolean('useWideGutters', false),
    useNarrowGutters: boolean('useNarrowGutters', false)
  }
})
