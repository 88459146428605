/* global L */

export const LINZ_BASE_MAP_URL = 'https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.png'

export const MAP_CONFIG = Object.freeze({
  defaultCenter: [-40.5268616, 170.4194458],
  defaultZoom: 15,
  maxZoom: 18,
  minZoom: 13
})

export const SVG_PATH = '/assets/mapicons/'

export const ZOOM_CONTROL_CLASS = L.Control.extend({
  // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
  options: {
    position: 'topright'
  },
  onAdd: () => {
    const controlElementTag = 'div'
    const controlElementClass = 'c-full-sreen-map-zoom-controls'
    const container = L.DomUtil.create(controlElementTag, controlElementClass)
    container.id = 'leafletZoomControlId'
    return container
  },
  onRemove () {}
})
