import CacheMixin from '../mixins/cache'
import RemoteMethodService from '../services/remote-method'

export default RemoteMethodService.extend(CacheMixin, {
  /**
   * Makes a request to the server to fetch the configuration for a given search_type_key
   */
  getSearchConfig (searchTypeKey, cache = true) {
    if (cache) {
      const cacheKey = `getSearchConfig_${searchTypeKey}`
      const cachedResult = this.cache.fetch(cacheKey)
      if (cachedResult) return cachedResult

      const request = this.request(`generic-search/fetch-search-config/${searchTypeKey}`)
      this.cache.save(cacheKey, request)
      return request
    } else {
      // No-cache option used when search filters are variable, for example custom fields.
      return this.request(`generic-search/fetch-search-config/${searchTypeKey}`)
    }
  },

  /**
   * Sends the search query to the server and fetches the results
   */
  getSearchResult (searchQuery) {
    return this.post('generic-search/fetch-search-results', {
      data: { query: JSON.stringify({ searchQuery }) }
    })
  },

  /**
   * Sends the search query to the server and fetches the results
   */
  getLookupResult (lookupQuery) {
    return this.post('generic-search/fetch-lookup-results', {
      data: { query: JSON.stringify({ lookupQuery }) }
    })
  },

  /**
   * Sends the search query to the server and fetches the results
   */
  getLookupDisplayValue (lookupDisplayValueQuery) {
    return this.post('generic-search/fetch-lookup-display-value', {
      data: { query: JSON.stringify({ lookupDisplayValueQuery }) }
    })
  },

  /**
   * Saves a query with the given name
   */
  saveQuery (searchQuery, name) {
    return this.post('generic-search/add-saved-query', {
      data: { query: JSON.stringify({ searchQuery }), name }
    })
  },

  /**
   * Deletes a saved query
   */
  deleteSavedQuery (queryId) {
    return this.del(`generic-search/delete-saved-query/${queryId}`)
  },

  /**
   * Retreives a saved query
   */
  fetchSavedQuery (queryId) {
    return this.request(`generic-search/fetch-saved-query/${queryId}`)
  },

  /**
   * Retreives a list of the queries saved by the user
   */
  fetchSavedQueryList (searchTypeKey) {
    return this.request(`generic-search/fetch-saved-query-list/${searchTypeKey}`)
  },

  /**
   * Renames a saved query
   */
  renameQuery (queryId, name) {
    return this.put(`generic-search/update-saved-query/${queryId}`, {
      data: { name: name }
    })
  },

  fetchDetailedMapResult (mapDetailQuery) {
    return this.post('generic-search/fetch-detailed-map-result', {
      data: { query: JSON.stringify({ mapDetailQuery }) }
    })
  },

  updateSubscription (queryId) {
    return this.put(`generic-search/update-saved-query-subscription/${queryId}`)
  }
})
