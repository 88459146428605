import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<select {{on \"change\" this.onChangeLanguage}} class=\"c-language-select\">\n  {{#each this.supportedLanguages as |language|}}\n    <option selected={{eq this.selectedOption language.code}} value={{language.code}}>{{t language.translationKey}}</option>\n  {{/each}}\n</select>", {"contents":"<select {{on \"change\" this.onChangeLanguage}} class=\"c-language-select\">\n  {{#each this.supportedLanguages as |language|}}\n    <option selected={{eq this.selectedOption language.code}} value={{language.code}}>{{t language.translationKey}}</option>\n  {{/each}}\n</select>","moduleName":"client/components/language-select/index.hbs","parseOptions":{"srcName":"client/components/language-select/index.hbs"}});
import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { SUPPORTED_LOCALES } from '../../constants/locales'

export default class LanguageSelect extends Component {
    @inject intl
    @inject remoteMethods

    @tracked supportedLanguages = SUPPORTED_LOCALES
    @tracked selectedOption = this.intl.locale ? this.intl.locale[0].toUpperCase() : 'en-us'

    @action
    onChangeLanguage (e) {
      const selectedLocale = e.target.value
      this.selectedOption = selectedLocale
      this.intl.setLocale(selectedLocale)
      this.remoteMethods.put('api/set-current-user-locale/', { data: { locale: selectedLocale } })
    }
}
