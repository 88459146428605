import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

const { get, set, inject } = Ember

export default Ember.Component.extend(DynamicElement, {
  resourceConsentRemoteMethods: inject.service(),
  tagName: '',
  result: '',
  lastValue: null,
  didReceiveAttrs () {
    this._super(...arguments)

    // If the value hasn't changed, don't call the endpoint again.
    if (get(this, 'value') === get(this, 'lastValue')) {
      return
    }
    set(this, 'lastValue', get(this, 'value'))

    // Calls a method on resourceConsentRemoteMethods with the value and displays the response.
    const resourceConsentRemoteMethods = get(this, 'resourceConsentRemoteMethods')
    const methodName = get(this, 'formElement.extendedAttributes.methodName')
    if (methodName && typeof resourceConsentRemoteMethods[methodName] === 'function') {
      resourceConsentRemoteMethods[methodName](get(this, 'value')).then(v => set(this, 'result', v))
    }
  }
})
