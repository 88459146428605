import RemoteMethodService from './remote-methods'
export default class ReportsRemoteMethods extends RemoteMethodService {
  reportsUrl = '/api/audit/report'

  async fetchVisitTypeMappings () {
    const visitTypeMappings = await this.requestNoHandle('/api/audit/report/visit-type-mappings')
    return visitTypeMappings
  }

  async fetchRevisitProgress () {
    const revistVisitTypeData = await this.requestNoHandle('/api/audit/report/revisit-progress')
    return revistVisitTypeData
  }

  async fetchWaterStatusAssessmentProgress () {
    const waterStatusAssessment = await this.requestNoHandle('/api/audit/report/water-status-assessment')
    return waterStatusAssessment
  }

  async fetchFDASeasonDates () {
    const FDASeasonDatesData = await this.requestNoHandle('/api/audit/report/fda-season-dates')
    return FDASeasonDatesData
  }

  async fetchAssessmentsCompletedDuringMilkingSeason () {
    const assessmentsCompletedDuringMilkingSeason = await this.requestNoHandle('/api/audit/report/assessments-completed-during-milking-season')
    return assessmentsCompletedDuringMilkingSeason
  }

  async fetchNonNotifiedAssessments () {
    const nonNotifiedAssessments = await this.requestNoHandle('/api/audit/report/non-notified-assessments')
    return nonNotifiedAssessments
  }

  async fetchRoutineAnnualAssessmentProgress () {
    const response = await this.requestNoHandle(`${this.reportsUrl}/routine-annual-assessment`)
    return response?.data?.rows?.map(monthData => {
      return {
        month: monthData.month,
        completed: monthData.completed,
        endDate: monthData.end_date,
        startDate: monthData.start_date,
        visitType: monthData.visit_type,
        targetValue: monthData.target_value || 0,
        year: monthData.visit_type
      }
    }) || []
  }
}
