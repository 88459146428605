import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.display}}\n  <div class=\"c-form-select-field c-form-select-field--power-select\">\n    <DynamicForm::Edit::FieldLabel\n      @labelText={{@formElement.label}}\n      @toolTipText={{@formElement.help}}\n      @class='c-form-select-field__label'\n      @inputElementId={{this.inputId}}\n   />\n\n    <DynamicForm::Edit::ValidationMessage\n      @state={{@state}}\n      @formElement={{@formElement}}\n    />\n\n  <PowerSelect\n      @triggerClass={{'c-form-select-field__input'}}\n      @triggerId={{this.inputId}}\n      @searchEnabled={{true}}\n      @search={{perform this.searchTask}}\n      @selected={{this.selected}}\n      {{!-- template-lint-disable  no-action --}}\n      @onChange={{action \"setSelected\"}}\n      @allowClear={{true}}\n      as |item|\n   >\n      {{get item this.displayKey}}\n  </PowerSelect>\n  </div>\n{{/if}}", {"contents":"{{#if this.display}}\n  <div class=\"c-form-select-field c-form-select-field--power-select\">\n    <DynamicForm::Edit::FieldLabel\n      @labelText={{@formElement.label}}\n      @toolTipText={{@formElement.help}}\n      @class='c-form-select-field__label'\n      @inputElementId={{this.inputId}}\n   />\n\n    <DynamicForm::Edit::ValidationMessage\n      @state={{@state}}\n      @formElement={{@formElement}}\n    />\n\n  <PowerSelect\n      @triggerClass={{'c-form-select-field__input'}}\n      @triggerId={{this.inputId}}\n      @searchEnabled={{true}}\n      @search={{perform this.searchTask}}\n      @selected={{this.selected}}\n      {{!-- template-lint-disable  no-action --}}\n      @onChange={{action \"setSelected\"}}\n      @allowClear={{true}}\n      as |item|\n   >\n      {{get item this.displayKey}}\n  </PowerSelect>\n  </div>\n{{/if}}","moduleName":"client/components/dynamic-form/edit/type-ahead/index.hbs","parseOptions":{"srcName":"client/components/dynamic-form/edit/type-ahead/index.hbs"}});
import { alias } from '@ember/object/computed'
import { task, timeout } from 'ember-concurrency'
import DynamicElement from 'client/mixins/components/dynamic-form-element'
import { isObject } from 'lodash'
import { copy } from 'ember-copy'
import Component from '@ember/component'
import { get, computed, set } from '@ember/object'
import { getOwner } from '@ember/application'
import { inject } from '@ember/service'
import { TYPEAHEAD_COMPONENT } from '../../../../constants/workflow'
const { DEBOUNCE_MS, MIN_CHARS } = TYPEAHEAD_COMPONENT

export default Component.extend(DynamicElement, {
  uniqueId: inject(),
  inputId: computed(function () {
    return this.uniqueId.next()
  }),

  // Extended attributes, that specify where to fetch typeahead results from
  lookupServiceName: alias('formElement.extendedAttributes.lookupServiceName'),
  lookupMethodName: alias('formElement.extendedAttributes.lookupMethodName'),
  lookupKey: alias('formElement.extendedAttributes.lookupKey'),
  renderKey: alias('formElement.extendedAttributes.renderKey'),

  // Dynamically load the lookup service
  lookupService: computed('lookupServiceName', function () {
    const owner = getOwner(this)
    return owner.lookup(`service:${this.lookupServiceName}`)
  }),

  displayKey: computed('lookupKey', 'renderKey', function () {
    return this.renderKey || this.lookupKey
  }),

  didReceiveAttrs () {
    this._super(...arguments)

    const value = get(this, 'itemState.val')
    const displayText = get(this, 'itemState.displayText')

    // Only update the selected value if the dyn form element has a value and that value is used as display
    // text for the user.
    // note: (JH) If you use a renderKey, then in order to get the selected object on reloading the page, you'll
    // need to look the object up again from the server. This is definitely a bit of a problem and something
    // that will need solving when that use case is encountered
    if (value != null && this.renderKey == null) {
      const selected = {}
      selected[this.lookupKey] = value
      set(this, 'selected', selected)
    } else if (value != null && this.renderKey != null && displayText) {
      set(this, 'selected', {
        [this.lookupKey]: value,
        [this.renderKey]: displayText
      })
    }
  },

  /**
   * Search for existing numbers.
   *
   * Note: Must have at least MIN_CHARS before hitting back end.
   */
  searchTask: task(function * (query) {
    if (query.length < MIN_CHARS) return []
    yield timeout(DEBOUNCE_MS)
    return this.lookupService[this.lookupMethodName](query)
  }),

  /**
   * Set the value of the form element.
   * @param value
   * @param displayText
   */
  setValue (value, displayText) {
    let state = copy(this.state, true)
    const formElement = this.formElement

    // Cope with empty state issues
    if (!state) state = {}
    if (!state[formElement.name]) state[formElement.name] = []
    const valToSave = String(value).trim() ? String(value) : null

    const newElementState = { val: valToSave }
    if (displayText) newElementState.displayText = displayText

    state[formElement.name][0] = Object.assign({ id: 'new' }, state[formElement.name][0], newElementState)
    this.updateStateKey(get(this, 'formElement.name'), state[formElement.name], this.index)
  },

  actions: {
    setSelected (selected) {
      set(this, 'selected', selected)

      const newValue = isObject(selected) === true ? selected[this.lookupKey] : undefined
      const displayText = isObject(selected) === true ? selected[this.renderKey] : undefined

      if (newValue) {
        this.setValue(newValue, displayText)
      } else {
        this.clearValue()
      }
    }
  }
})
