import Ember from 'ember'
import { FORM_STATES } from '../../../../constants'
const { computed, get } = Ember

export default Ember.Component.extend({
  /**
   * Count of all the forms that are Not Started
   */
  // 12.11.2018 Added skipValidation to form template extendedAttributes. Leaving the `application` and
  //            `consent` form checks here for compatibility with existing forms.
  notStartedCount: computed('errors.forms', function () {
    const forms = get(this, 'errors.forms')
    if (!forms) return 0
    const filteredForms = forms.filter(form => ['application', 'consent'].indexOf(form.templateKey) === -1 &&
      !form.skipValidation && form.status === FORM_STATES.NOT_STARTED)
    return filteredForms.length
  })
})
