import RemoteMethodService from './remote-methods'
import { inject } from '@ember/service'

export default class CustomFieldsRemoteMethods extends RemoteMethodService {
  @inject session

  /**
   * Returns the custom field types ready to be consumed by a DF Select component
   * @returns {Promise<{value: string, label: string}>}
   */
  async fetchCustomFieldTypes () {
    const url = '/organisation/custom-field-types'
    const fieldTypes = await this.request(url)
    return fieldTypes.map(fieldType => ({ value: String(fieldType.id), label: fieldType.description }))
  }

  fetchCustomField (customFieldId) {
    const url = `/organisation/${this.session.organisation.id}/custom-field/${customFieldId}`
    return this.request(url)
  }

  saveCustomField (customFieldId, state) {
    const url = `/organisation/${this.session.organisation.id}/custom-field`
    if (customFieldId) {
      return this.put(`${url}/${customFieldId}`, { data: state })
    }
    return this.post(url, { data: state })
  }

  deleteCustomField (customFieldId) {
    const url = `/organisation/${this.session.organisation.id}/custom-field/${customFieldId}`
    return this.delete(url)
  }

  fetchTotalCustomFields () {
    const url = `/organisation/${this.session.organisation.id}/custom-field-count`
    return this.request(url)
  }

  fetchCustomFields () {
    const url = `/organisation/${this.session.organisation.id}/custom-fields`
    return this.request(url)
  }

  confirmUpload (customFieldId, uploadId) {
    const url = `/organisation/${this.session.organisation.id}/custom-field/${customFieldId}/confirm/${uploadId}`
    return this.post(url)
  }

  /**
   * Returns the custom field records to be used in the the custom field bulk edit table.
   */
  fetchCustomFieldRecords (customFieldId) {
    const url = `/organisation/${this.session.organisation.id}/custom-field-records/${customFieldId}`
    return this.request(url)
  }

  updateRecords (customFieldId, records) {
    const url = `/organisation/${this.session.organisation.id}/custom-field/update-records/${customFieldId}`
    return this.post(url, { data: records })
  }
}
