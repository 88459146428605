
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { AUDITEE_CARD_STATES } from '../../../constants/values'

export default class AuditeeNonConformances extends Component {
  @inject router
  @inject warden

  constructor () {
    super(...arguments)

    this.nextActionDate = this.args?.auditeeInsights?.auditeeAuditInsights?.nonConformanceNextActionDue
    this.nonConformancesDue = this.args?.auditeeInsights?.auditeeAuditInsights?.nonConformancesDue
    this.totalNonConformancesOpenAndOverdue = this.args?.auditeeInsights?.auditeeAuditInsights?.totalNonConformancesOpenAndOverdue
    this.openCriticalNonConformances = this.args?.auditeeInsights?.auditeeAuditInsights?.openCriticalNonConformances
    this.openMajorNonConformances = this.args?.auditeeInsights?.auditeeAuditInsights?.openMajorNonConformances
    this.openMinorNonConformances = this.args?.auditeeInsights?.auditeeAuditInsights?.openMinorNonConformances

    this.informationPanelParameters = this.getInformationPanelParameters()
  }

  nonConformanceTranslations = {
    nonConformancesToBeActioned: 'auditee_non_conformances_tab.non_conformances_to_be_actioned',
    minorNonConformancesToBeActioned: 'auditee_non_conformances_tab.minor_non_conformances_to_be_actioned',
    majorNonConformancesToBeActioned: 'auditee_non_conformances_tab.major_non_conformances_to_be_actioned',
    criticalNonConformancesToBeActioned: 'auditee_non_conformances_tab.critical_non_conformances_to_be_actioned',
    overdueNonConformancesToBeActioned: 'auditee_non_conformances_tab.overdue_non_conformances_to_be_actioned',
    nonConformancesDue: 'auditee_non_conformances_tab.non_conformances_due',
    actionNonConformance: 'auditee_non_conformances_tab.action_non_conformance',
    viewInCalendar: 'auditee_non_conformances_tab.view_in_calendar',
    nextDue: 'auditee_non_conformances_tab.next_due'
  }

  getInformationPanelParameters () {
    const parameters = [
      {
        count: this.highestPriorityNonConformanceCount,
        translation: this.nonConformanceCardState[1]
      },
      {
        count: this.nonConformancesDue,
        translation: this.nonConformanceTranslations.nonConformancesDue,
        dateTranslation: this.nonConformanceTranslations.nextDue,
        date: this.nextActionDate || '-'
      }
    ]
    return parameters
  }

  get status () {
    return this.nonConformanceCardState[0]
  }

  get nonConformanceCardState () {
    if (this.totalNonConformancesOpenAndOverdue) {
      return [AUDITEE_CARD_STATES.ERROR_CRITICAL, this.nonConformanceTranslations.overdueNonConformancesToBeActioned]
    } else if (this.openCriticalNonConformances) {
      return [AUDITEE_CARD_STATES.ERROR_CRITICAL, this.nonConformanceTranslations.criticalNonConformancesToBeActioned]
    } else if (this.openMajorNonConformances) {
      return [AUDITEE_CARD_STATES.ERROR_MAJOR, this.nonConformanceTranslations.majorNonConformancesToBeActioned]
    } else if (this.openMinorNonConformances) {
      return [AUDITEE_CARD_STATES.ERROR_MINOR, this.nonConformanceTranslations.minorNonConformancesToBeActioned]
    }
    return [AUDITEE_CARD_STATES.OK, this.nonConformanceTranslations.nonConformancesToBeActioned]
  }

  get highestPriorityNonConformanceCount () {
    return this.totalNonConformancesOpenAndOverdue ||
    this.openCriticalNonConformances ||
    this.openMajorNonConformances ||
    this.openMinorNonConformances ||
    0
  }
}
