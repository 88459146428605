import { EMAIL_CHECK } from '../constants/values'

export const validate = (intl) => {
  return {
    notEmpty: val => (val && val.trim() !== '' ? undefined : [{ type: 'MISSING', description: intl.t('errors.MISSING') }]),
    emailAddress: val => (val && val.match(EMAIL_CHECK) !== null
      ? undefined
      : [{
        type: 'INVALID',
        description: intl.t('errors.INVALID')
      }])
  }
}

/**
 * Converts a local internal State into the structure that can be used by DF
 * NOTE: this only supports one level of nesting, and section element is the locally defined section-one-element
 * @param {[string]} stateKeys
 * @param {{}} internalState
 * @param {{}} formElements
 * @param {{}} internalErrors
 * @returns {{}}
 */
export function getState (stateKeys, internalState, formElements, internalErrors) {
  return stateKeys.reduce((state, key) => {
    if (formElements[key].formElements) {
      // Note: this only works for single-component elements
      const eleName = formElements[key].formElements[0].name
      state[key] = {
        [key]: internalState[key].map((childVal, i) => {
          return { val: { [eleName]: [{ val: childVal == null ? '' : String(childVal), errors: internalErrors[key][i] }] } }
        })
      }
    } else {
      state[key] = { [key]: [{ val: internalState[key] == null ? '' : String(internalState[key]), errors: internalErrors[key] }] }
    }
    return state
  }, {})
}

/**
 *
 * @param {string} name - name of the element updated
 * @param {Object} returnedState - the new state of the named element
 * @param {Object} formElements - all the formElements in the page
 * @param {Object} internalState - MUTATED. The internal state of the page
 */
export function updateState (name, returnedState, formElements, internalState) {
  if (formElements[name].formElements) {
    internalState[name] = returnedState.reduce((retVal, childState) => {
      return childState.deleted
        ? retVal
        : [...retVal, ...Object.keys(childState.val).map((key) => childState.val[key][0].val)]
    }, [])
  } else {
    internalState[name] = returnedState[0].val
  }
}

/**
 * Reviews the internalState for any validation errors, using validation functions on the formElements
 * NOTE: this only supports one level of nesting, and section element is the locally defined section-one-element
 * @param {Object} stateKeys
 * @param {Object} formElements - all the formElements in the page
 * @param {Object} internalState - The internal state of the page in key:value format
 * @param {Object} internalErrors - MUTATED. Any errors associated with each field
 */
export function validateState (stateKeys, formElements, internalState, internalErrors) {
  stateKeys.forEach(key => {
    if (formElements[key].formElements) {
      internalErrors[key] = internalState[key].map(childVal => {
        // Note: this only works for single-component elements
        const validateFn = formElements[key].formElements[0].clientValidationFunc
        return validateFn && validateFn(childVal) // returns the error or undefined
      })
    }
    const validateFn = formElements[key].clientValidationFunc
    if (validateFn) {
      internalErrors[key] = validateFn(internalState[key])
    }
  })
}

/**
 * Indicates if the state has any current errors
 * @param {Object} stateKeys
 * @param {Object} internalErrors
 * @returns {boolean}
 */
export function hasErrors (stateKeys, internalErrors) {
  return !stateKeys.filter(key => {
    const keyError = internalErrors[key]
    return keyError && (!Array.isArray(keyError) || keyError.filter(v => !!v).length)
  }).length
}
