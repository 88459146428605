import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'
import { isObject } from 'lodash'

export default class ReportsServicesOverviewCard extends Component {
  @inject router
  @inject warden
  @inject servicesOverviewRemoteMethods
  @tracked isExpanded = false
  @tracked data = this.args?.data
  // Defaulting these to current dates as that is what the component does
  @tracked month = this.currentMonth
  @tracked year = this.currentYear
  @tracked isLoading = false
  @tracked disableViewAssessmentsInAuditSearchLink = false

  // + 1 to month as JS months start at 0
  currentMonth = new Date().getMonth() + 1
  currentYear = new Date().getFullYear()

  @tracked monthOptions = [
    { value: 1, label: 'January', disabled: false },
    { value: 2, label: 'February', disabled: false },
    { value: 3, label: 'March', disabled: false },
    { value: 4, label: 'April', disabled: false },
    { value: 5, label: 'May', disabled: false },
    { value: 6, label: 'June', disabled: false },
    { value: 7, label: 'July', disabled: false },
    { value: 8, label: 'August', disabled: false },
    { value: 9, label: 'September', disabled: false },
    { value: 10, label: 'October', disabled: false },
    { value: 11, label: 'November', disabled: false },
    { value: 12, label: 'December', disabled: false }
  ]

  constructor () {
    super(...arguments)
    this.args.getServiceOverviewMonthYearFilters(this.month, this.year)
    this.checkForQueryParams()
  }

  get isAdmin () {
    return this.warden.canAny(['view__reports_services_overview'])
  }

  get dialChartTranslations () {
    return {
      lineOne: 'services_overview.assessments'
    }
  }

  get startDate () {
    return this.data.startDate
  }

  get endDate () {
    return this.data.endDate
  }

  get updateServicesForMonth () {
    return this._updateServicesForMonth.bind(this)
  }

  checkForQueryParams () {
    if (!this.data.hasFilters) {
      // Check if data has filters for year / month
      return
    }

    if (this.args.selectedMonth) {
      this.isLoading = true
      this.month = parseInt(this.args.selectedMonth)
      this._updateServicesForMonth(
        { value: this.month },
        'month'
      )
    }
    if (this.args.data.searchableYears.includes(parseInt(this.args.selectedYear))) {
      this.isLoading = true
      this.year = parseInt(this.args.selectedYear)
      this._updateServicesForMonth(
        { value: this.year },
        'year'
      )
    }
  }

  async _updateServicesForMonth (selected, valueKey) {
    this.isLoading = true
    this[valueKey] = isObject(selected) ? selected.value : selected
    this.disableFutureMonths()
    this.setToActiveMonth()
    this.args.getServiceOverviewMonthYearFilters(this.month, this.year)
    const service = await this.servicesOverviewRemoteMethods.fetchServicesOverviewForMonth(this.month, this.year)
    service.hasFilters = this.args.data.hasFilters
    service.searchableYears = this.args.data.searchableYears
    this.data = service
    this.isLoading = false
  }

  disableFutureMonths () {
    // Disable any months after the current date
    const currentMonthIndex = this.monthOptions.findIndex(option => option.value === this.currentMonth)
    this.monthOptions = this.monthOptions.map((option, index) => {
      if (this.currentYear === this.year && index > currentMonthIndex) {
        option.disabled = true
      } else {
        option.disabled = false
      }
      return option
    })
  }

  setToActiveMonth () {
    // Don't allow setting a future month
    const setMonth = this.monthOptions.find(option => option.value === this.month)
    if (setMonth.disabled) {
      this.month = this.currentMonth
    }
  }

  @action
  setOffsetHeight () {
    /*
    * This manually sets the height of the container to its height after render so that
    * when the contents disappear for the spinner, it doesn't get resized
    */
    const overviewContainer = document.querySelectorAll('.c-services-overview-card__cols-container')[this.args.index]
    overviewContainer.style.minHeight = `${overviewContainer.offsetHeight}px`
  }

  @action
  viewAssessmentsInAuditSearch () {
    this.disableViewAssessmentsInAuditSearchLink = true
    const searchParams = {
      dateCompleted: [this.startDate, this.endDate]
    }

    this.router.transitionTo('base.home.search.audit', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(searchParams)
      }
    })
  }
}
