import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'
import { task } from 'ember-concurrency'
import { copy } from 'ember-copy'
const { get, getWithDefault, set, computed, inject } = Ember

export default Ember.Component.extend(DynamicElement, ComplexElement, {
  dynamicFormsMethods: inject.service(),
  router: inject.service(),
  flashMessages: inject.service(), // Need to add this here to fix an issue with section-test not init'ing the service
  showConfirmationModal: false,

  // In root sections, the state value is for all instances
  // Note - something feels a bit off here
  stateValue: computed.alias('formElementState'),
  classNames: ['h-clearfix', 'l-section-wrapper'],
  // Wrapper applies margin top/bottom only when element is displayed:
  classNameBindings: [
    'isHidden:l-section-wrapper--empty',
    'isRepeatable:l-repeatable-section-wrapper'
  ],
  isHidden: computed('display', function () {
    return !get(this, 'display')
  }),
  isRepeatable: computed('formElement.repeatable', function () {
    return get(this, 'formElement.repeatable')
  }),

  elementType: computed('formElement.deleteConfirmationLabel', function () {
    return get(this, 'formElement.extendedAttributes.deleteConfirmationLabel') || get(this, 'formElement.label') || 'section'
  }),

  updateDeletedHide (formElement, index, key, status = true) {
    const state = copy(get(this, 'state'), true)
    state[formElement.name][index] = Object.assign({}, state[formElement.name][index], { [key]: status })

    get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'))
  },

  /**
   * Function to add a new child form.  Only relevant if the section.isFormContainer === true
   * The createChildFormInstance method call is complicated because we need to provide enough information to the server
   * to be able to update the state at the right point in the hierarchy with the newly created instanceId.
   * There are constraints (see dynamic-forms/views.py) on the structure of the JSON such that if this ever has to deal
   * with other circumstances, it'll likely need a refactor.
   * BRITTLE
   */
  addNewChildForm: task(function * (childFormVersionId, childElementName, formName) {
    if (this.mapOrchestratorElementInstanceId) {
      // This is a map orchestrated element, so we treat it differently
      return get(this, 'dynamicFormsMethods')
        .createMapOrchestratorChildFormInstance(this.mapOrchestratorElementInstanceId, this.formInstanceId, this.formElement.name)
        .then((formInstanceId) => {
          this.flashMessages.success(`New ${formName} created`)
          this.transitionToForm({ id: formInstanceId, skipNavigationCheck: true })
        })
        .catch(() => {
          const label = getWithDefault(this, 'formElement.label', 'object').toLowerCase()
          get(this, 'flashMessages.danger')(`There was a problem adding a new ${label}`)
        })
    } else {
      // This is a normal repeating element
      const elementName = get(this, 'formElement.name')
      return get(this, 'dynamicFormsMethods')
        .createChildFormInstance(childFormVersionId, get(this, 'formInstanceId'), get(this, 'parentSectionId'), elementName, childElementName)
        .then((formInstanceId) => {
          this.flashMessages.success(`New ${formName} created`)
          this.transitionToForm({ id: formInstanceId, skipNavigationCheck: true })
        })
        .catch(() => {
          const label = getWithDefault(this, 'formElement.label', 'object').toLowerCase()
          get(this, 'flashMessages.danger')(`There was a problem adding a new ${label}`)
        })
    }
  }).drop(),

  /**
   * Instead of calling the function in the service directly, we use this function to call it
   * This lets us replace it with a different function which can transition to a different route,
   * eg. the map-orchestrator component transitions to a route with a full screen map.
   */
  transitionToForm ({ id, skipNavigationCheck }) {
    if (this.transitionToFormAction) {
      this.transitionToFormAction({ id, skipNavigationCheck })
    } else {
      this.dynamicFormsMethods.transitionToForm({ id, skipNavigationCheck })
    }
  },

  actions: {

    /**
     * Deletes the indicated repeated block of all elements within this section's `formElements` array
     */
    delete (formElement, index, postSaveCallback = null) {
      // If this section is wrapping a form-link type, we need to do a number of things differently:
      // 1. Show a confirmation dialogue to the user to ensure they really do want to delete all the consent
      // And on confirmation:
      // 2. Mark the state for the container iteration that holds the form as deleted
      // 3. Once that has finished flushing through the state tree (computeds, observers, etc) then save state to the server
      // 4. As part of this save, refresh the menu
      if (get(this, 'formElement.extendedAttributes.disableConfirmationModal') && !this.isFormContainer) {
        // We only need to do item (2) from above, mark the state as deleted
        this.updateDeletedHide(formElement, index, 'deleted')
      } else {
        set(this, 'confirmSuccessFunction', () => {
          this.updateDeletedHide(formElement, index, 'deleted')
          set(this, 'showConfirmationModal', false)
          if (get(this, 'isFormContainer')) {
            Ember.run.later(() => {
              const updateMenu = true
              const hideSuccessBanner = true
              return get(this, 'save')(updateMenu, hideSuccessBanner, postSaveCallback)
            }, 0)
          }
        })
        set(this, 'showConfirmationModal', true)
      }
    },

    /**
     * Add another block of all elements within this section's `formElements` array
     */
    add () {
      // If this section is wrapping a form-link type, we need to do something different
      if (get(this, 'isFormContainer')) {
        const childElements = get(this, 'formElement.formElements')
        const childVersionId = get(childElements, '0.childFormTemplateVersionId')
        get(this, 'addNewChildForm').perform(childVersionId, get(childElements, '0.name'), get(childElements, '0.label'))
        return
      }

      // This section does not wrap a form-link type, so we want to simply repeat the enclosing elements
      const elementName = get(this, 'formElement.name')
      const existingCount = get(this, `state.${elementName}.length`)
      get(this, 'populateFunctions').buildState({}, get(this, 'formElement.formElements'), existingCount + 1)
        .then(newState => {
          const existingState = copy(get(this, 'state'), true)
          existingState[elementName].push({ id: 'new', val: newState })
          this.updateStateKey(elementName, existingState[elementName])
        })
    }
  }
})
