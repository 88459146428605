import Ember from 'ember'
import RSVP from 'rsvp'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import DS from 'ember-data'
import { DELETED_ITEM_DEFAULT_SUFFIX } from '../../../../constants'
const { computed, get, getWithDefault, set, inject, observer } = Ember

// Add opt to optionArray, with de-duplication based on the value (usually an id number)
// Suffix is optional, for displaying " (inactive)" on deleted options
function addOption (optionArray = [], opt, suffix = '') {
  if (opt === null || opt === undefined || opt.value === null || opt.value === undefined) {
    return optionArray
  } else if (optionArray.some(o => o.value === opt.value)) {
    // option already exists in the array
    return optionArray
  } else {
    // the option doesn't exist in the array
    // therefore it has been deleted
    // add it to the array with an optional suffix
    const returnArray = Array.from(optionArray)
    returnArray.push({
      value: opt.value,
      label: opt.label + suffix
    })
    return returnArray
  }
}

export default Ember.Component.extend(DynamicElement, {
  dynamicFormsMethods: inject.service(),
  dependentKeyValue: null,

  init () {
    this._super(...arguments)

    // Set the initial value for the dependent key
    const filterKey = get(this, 'formElement.filterTriggerField')
    if (!filterKey) return
    const currentDependentKeyValue = get(this, `state.${filterKey}.0.val`)
    set(this, 'dependentKeyValue', currentDependentKeyValue)
  },

  initialSelectedOption: null,
  didReceiveAttrs () {
    this._super(...arguments)
    if (this.initialSelectedOption === null) {
      const initialSelectedOption = getWithDefault(this, `state.${this.formElement.name}.0.selectedOption`, null)
      if (initialSelectedOption !== null && get(this, 'formElement.filterTriggerField')) {
        // save the value of the form element we're dynamically filtering by so it can be reverted later if requested
        initialSelectedOption.filterKey = get(this, 'formElement.filterTriggerField')
        initialSelectedOption.filterState = get(this, `state.${initialSelectedOption.filterKey}`)
      }
      set(this, 'initialSelectedOption', initialSelectedOption)
    }
  },

  options: computed(
    'formElement.options',
    'formElement.selectedOption',
    'formElement.filterTriggerField',
    'formInstanceId',
    'state',
    function () {
      // This will trigger on any state change; that's unavoidable as we can't target listening to the bit of state
      // we need
      // We trust our cache to return the options very quickly when this computed property is called needlessly
      const filterKey = get(this, 'formElement.filterTriggerField')
      if (!filterKey) {
        return addOption(this.formElement.options, this.deletedOption, this.suffix)
      }

      const filterVal = get(this, `state.${filterKey}.0.val`)
      const formInstanceId = get(this, 'formInstanceId')
      let promise

      if (filterKey && filterVal != null) {
        promise = get(this, 'dynamicFormsMethods').fetchFilteredList(formInstanceId, get(this, 'formElement.name'), filterVal)
          .then((optionArray) => addOption(optionArray, this.deletedOption, this.suffix))
      } else {
        promise = RSVP.resolve([])
      }

      return DS.PromiseObject.create({ promise })
    }
  ),

  deletedOption: computed('state', function () {
    if (this.initialSelectedOption === null) {
      return null
    } else if (this.initialSelectedOption.filterKey === undefined) {
      return this.initialSelectedOption
    } else {
      // only return the selectedOption if the value of the form element we're dynamically filtering by hasn't changed
      if (this.state[this.initialSelectedOption.filterKey][0].val === this.initialSelectedOption.filterState[0].val) {
        return this.initialSelectedOption
      } else {
        return null
      }
    }
    // return getWithDefault(this, `state.${this.formElement.name}.0.selectedOption`, null)
  }),

  showRevertAction: computed('initialSelectedOption.value', 'stateValue', function () {
    // If the underlying value has been marked as deleted, and the user is changing it to something else, display a warning message with an undo button.
    return (getWithDefault(this, 'initialSelectedOption.isDeleted', false) === true) && (getWithDefault(this, 'initialSelectedOption.value', NaN) !== getWithDefault(this, 'stateValue', NaN))
  }),

  // Suffix for deleted options
  suffix: computed('formElement.extendedAttributes', function () {
    return getWithDefault(this, 'formElement.extendedAttributes.deletedSuffix', DELETED_ITEM_DEFAULT_SUFFIX)
  }),
  // Note: Acknowledgement that observers are not considered best practice, but since we're actively seeking side-effects
  // on the state object, this is currently considered the best way
  updateState: observer('formElement.filterTriggerField', 'state', function () {
    // This function monitors the state of the field on which the options are filtered upon.  If that value changes, we
    // need to clear whatever value has been stored in the state for this element
    // e.g.
    //  - Set consent_type to 'Subdivision', and subcategory_type to 'Allotment Creation'
    //  - Change the consent_type to 'Coastal'
    //  - We want the subcategory_type state value to clear
    const filterKey = get(this, 'formElement.filterTriggerField')
    if (!filterKey) return
    const currentDependentKeyValue = get(this, `state.${filterKey}.0.val`)
    const dependentKeyValue = get(this, 'dependentKeyValue')

    if (dependentKeyValue && currentDependentKeyValue && currentDependentKeyValue !== dependentKeyValue) {
      // We have to stick this change after the full render of this page, otherwise we're changing the state of the
      // page as it`s drawing, and Ember quite rightly throws up it's hands and asks us to think carefully about our
      // actions.  Therefore, we tell it modify the value after the render.
      // This results in an immediate re-render of the page, but it's quick, silent and painless
      Ember.run.scheduleOnce('afterRender', this, () => {
        set(this, 'dependentKeyValue', currentDependentKeyValue)
        this.clearValue()
      })
    }

    set(this, 'dependentKeyValue', currentDependentKeyValue)
  }),

  displayValue: computed('stateValue', 'options', function () {
    const stateValue = get(this, 'stateValue')
    if (!stateValue) return DS.PromiseObject.create({ promise: RSVP.resolve('-') })

    const optionsOrPromise = get(this, 'options')
    let promise
    if (optionsOrPromise.then) {
      promise = optionsOrPromise.then(options => {
        const option = options.find(option => get(option, 'value') === stateValue)
        return (option && option.label) || '-'
      })
    } else {
      const option = optionsOrPromise.find(option => get(option, 'value') === stateValue)
      promise = RSVP.resolve((option && option.label) || '-')
    }

    return DS.PromiseObject.create({ promise })
  }),

  actions: {
    revertToInitialOption () {
      // Is this form element dynamically filtered?
      if (this.initialSelectedOption.filterKey !== undefined) {
        // Revert the value of the form element we're filtering by
        this.updateStateKey(this.initialSelectedOption.filterKey, this.initialSelectedOption.filterState, this.index)

        // Revert the value of this form element
        // We need to schedule this twice so that it sets the value after the one in the 'updateState' observer, which will clear the value after the update above.
        Ember.run.scheduleOnce('afterRender', this, () => {
          Ember.run.scheduleOnce('afterRender', this, () => {
            this.send('setValue', this.initialSelectedOption.value)
          })
        })
      } else {
        // Revert the value of this form element
        this.send('setValue', this.initialSelectedOption.value)
      }
    }
  },

  // Hack to prevent bug in Firefox where the "Please select..." option gets erroniously selected
  didRender () {
    if (this.stateValue !== null) {
      const elem = document.getElementById('select-null-option-' + this.elementId)
      if (elem !== null) {
        elem.selected = false
      }
    }
  }
})
