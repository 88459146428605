import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { AUDITEE_CARD_STATES } from '../../../constants/values'

export default class AuditeeSummary extends Component {
  @inject session
  @tracked eapIdsUserIsKdmFor = []
  @tracked showMissingProgrammes = true

  constructor () {
    super(...arguments)
    this.eapIdsUserIsKdmFor = this.session.entitiesIsAuditeeFor ? this.session.entitiesIsAuditeeFor : []
    this.showMissingProgrammes = this.eapIdsUserIsKdmFor.length === 0
    // temporary console statement for development of auditee access then can be deleted
    // eslint-disable-next-line no-console
    console.log(`User is KDM for the following enterprise_audit_ids: ${this.eapIdsUserIsKdmFor}`)

    this.auditsBooked = this.args?.auditeeInsights?.auditeeAuditInsights.auditsBooked
    this.nextAuditBookedDate = this.args?.auditeeInsights?.auditeeAuditInsights.nextAuditBookedDate
    this.nonConformanceStatus = this.nonConformanceCardState()

    this.totalAuditProgrammes = this.args?.auditeeInsights?.auditeeAuditInsights.totalAuditProgrammes
    this.auditsApprovedOrCertified = this.args?.auditeeInsights?.auditeeAuditInsights.auditsApprovedOrCertified
  }

  get auditeeCards () {
    return [
      {
        titleString: this.bookedAuditsTranslations.title,
        numberOfAssessmentsTranslation: (this.auditsBooked === 1) ? this.bookedAuditsTranslations.countText : this.bookedAuditsTranslations.countTextPlural,
        dateTranslation: this.bookedAuditsTranslations.dateText,
        buttonTranslation: this.bookedAuditsTranslations.buttonText,
        numberOfAssessments: this.auditsBooked,
        date: this.nextAuditBookedDate || '-',
        state: (this.auditsBooked > 0) ? AUDITEE_CARD_STATES.INFO : AUDITEE_CARD_STATES.INFO_NO_ICON
      },
      {
        titleString: this.nonConformanceTranslations.title,
        numberOfAssessmentsTranslation: this.nonConformanceTranslations.countText,
        dateTranslation: this.nonConformanceTranslations.dateText,
        buttonTranslation: this.nonConformanceTranslations.buttonText,
        numberOfAssessments: this.args?.auditeeInsights?.auditeeAuditInsights.nonConformancesDue,
        date: this.nonConformanceDate || '-',
        state: this.nonConformanceStatus,
        buttonRoute: 'base.home.non-conformances'
      },
      {
        titleString: this.unbookedAuditsTranslations.title,
        numberOfAssessmentsTranslation: this.hasSubcategory ? this.unbookedAuditCountText[0] : this.unbookedAuditCountText,
        dateTranslation: this.unbookedAuditsTranslations.dateText,
        buttonTranslation: this.unbookedAuditsTranslations.buttonText,
        numberOfAssessments: this.hasSubcategory ? this.unbookedAuditsCount[0] : this.unbookedAuditsCount,
        date: this.unbookedAuditDate,
        state: this.unbookedAuditCardState,
        hasSubcategory: this.hasSubcategory,
        numberOfAssessmentsSubcategory: this.unbookedAuditsCount[1],
        numberOfAssessmentsTranslationSubcategory: this.unbookedAuditCountText[1]
      }
    ]
  }

  get enterpriseOperationsCard () {
    return {
      titleString: this.enterpriseOperationsTranslations.title,
      numberOfProgrammesTranslation: this.enterpriseOperationsTranslations.countText,
      outOfTranslation: this.enterpriseOperationsTranslations.outOfText,
      buttonTranslation: this.enterpriseOperationsTranslations.buttonText,
      tradingName: this.args?.auditeeInsights?.auditeeAuditInsights.tradingName,
      auditsApprovedOrCertified: this.auditsApprovedOrCertified,
      numberOfEnterprises: this.args?.auditeeInsights?.auditeeAuditInsights.numberOfEnterprises,
      totalAuditProgrammes: this.totalAuditProgrammes,
      state: this.enterpriseAuditCardState,
      tooltip: this.enterpriseOperationsTranslations.tooltip
    }
  }

  get unbookedAuditCardState () {
    if (this.args?.auditeeInsights?.auditeeAuditInsights.overdueUnbookedAudits) return AUDITEE_CARD_STATES.ERROR_CRITICAL
    else if (this.args?.auditeeInsights?.auditeeAuditInsights.dueUnbookedAudits) return AUDITEE_CARD_STATES.ERROR_MAJOR
    return AUDITEE_CARD_STATES.OK
  }

  get hasSubcategory () {
    return this.unbookedAuditCardState === AUDITEE_CARD_STATES.ERROR_CRITICAL
  }

  get unbookedAuditsCount () {
    if (this.unbookedAuditCardState === AUDITEE_CARD_STATES.ERROR_CRITICAL) {
      return [
        this.args?.auditeeInsights?.auditeeAuditInsights.overdueUnbookedAudits,
        this.args?.auditeeInsights?.auditeeAuditInsights.dueUnbookedAudits
      ]
    }
    return this.args?.auditeeInsights?.auditeeAuditInsights.dueUnbookedAudits
  }

  get unbookedAuditCountText () {
    if (this.unbookedAuditCardState === AUDITEE_CARD_STATES.ERROR_CRITICAL) {
      return [
        this.unbookedAuditsTranslations.countTextOverdue,
        this.unbookedAuditsTranslations.countTextDue
      ]
    } else if (this.unbookedAuditCardState === AUDITEE_CARD_STATES.ERROR_MAJOR) return this.unbookedAuditsTranslations.countTextDue
    return this.unbookedAuditsTranslations.countTextNotDue
  }

  get unbookedAuditDate () {
    if (this.unbookedAuditCardState === AUDITEE_CARD_STATES.OK || this.args?.auditeeInsights?.auditeeAuditInsights.nextAuditDueNotBookedNextThreeMonths == null) return '-'
    return this.args?.auditeeInsights?.auditeeAuditInsights.nextAuditDueNotBookedNextThreeMonths
  }

  nonConformanceCardState () {
    if (this.args?.auditeeInsights?.auditeeAuditInsights.totalNonConformancesOpenAndOverdue) return AUDITEE_CARD_STATES.ERROR_CRITICAL
    else if (this.args?.auditeeInsights?.auditeeAuditInsights.openCriticalNonConformances) return AUDITEE_CARD_STATES.ERROR_CRITICAL
    else if (this.args?.auditeeInsights?.auditeeAuditInsights.openMajorNonConformances) return AUDITEE_CARD_STATES.ERROR_MAJOR
    else if (this.args?.auditeeInsights?.auditeeAuditInsights.openMinorNonConformances) return AUDITEE_CARD_STATES.ERROR_MINOR
    return AUDITEE_CARD_STATES.OK
  }

  get nonConformanceDate () {
    if (this.nonConformanceStatus === 'OK') return '-'
    return this.args?.auditeeInsights?.auditeeAuditInsights.nonConformanceNextActionDue
  }

  get enterpriseAuditCardState () {
    if (this.totalAuditProgrammes === this.auditsApprovedOrCertified) return AUDITEE_CARD_STATES.OK
    else if (this.auditsApprovedOrCertified === 0) return AUDITEE_CARD_STATES.ERROR_CRITICAL
    return AUDITEE_CARD_STATES.ERROR_MAJOR
  }

  nonConformanceTranslations = {
    title: 'dashboard.non_conformances_card.non_conformances',
    countText: 'dashboard.non_conformances_card.non_conformances_due',
    dateText: 'dashboard.non_conformances_card.next_action_due',
    buttonText: 'dashboard.non_conformances_card.action_non_conformances'
  }

  bookedAuditsTranslations = {
    title: 'dashboard.booked_upcoming_audits_card.upcoming_audits',
    countText: 'dashboard.booked_upcoming_audits_card.num_audit_booked',
    countTextPlural: 'dashboard.booked_upcoming_audits_card.num_audits_booked',
    dateText: 'dashboard.booked_upcoming_audits_card.next_audit_booked',
    buttonText: 'dashboard.booked_upcoming_audits_card.complete_pre_audit_checklist'
  }

  unbookedAuditsTranslations = {
    title: 'dashboard.unbooked_audits_card.unbooked_audits',
    countTextDue: 'dashboard.unbooked_audits_card.unbooked_audits_due',
    countTextOverdue: 'dashboard.unbooked_audits_card.unbooked_audits_overdue',
    countTextNotDue: 'dashboard.unbooked_audits_card.unbooked_audits_not_due',
    dateText: 'dashboard.unbooked_audits_card.next_audit_due_not_booked',
    buttonText: 'dashboard.unbooked_audits_card.action_non_conformances'
  }

  enterpriseOperationsTranslations = {
    title: 'dashboard.enterprise_operations_card.enterprise_operations',
    countText: 'dashboard.enterprise_operations_card.programmes_certified_or_approved',
    outOfText: 'dashboard.enterprise_operations_card.out_of',
    buttonText: 'dashboard.enterprise_operations_card.view_my_farm_details',
    tooltip: 'dashboard.enterprise_operations_card.tooltip'
  }
}
