/* eslint ember/no-observers: off */

import { A } from '@ember/array'
import Component from '@ember/component'
import { computed, get, observer, set } from '@ember/object'
import { inject } from '@ember/service'
import { task } from 'ember-concurrency'
import DS from 'ember-data'

function isEmpty (value) {
  return value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
}

export default Component.extend({
  remoteMethods: inject('generic-search-remote-methods'),
  genericSearch: inject(),
  gateKeeper: inject(),
  router: inject(),
  cookies: inject(),
  viewKey: null,
  searchQuery: null,
  reinflateQuery: null,
  displaySavedSearchList: false,
  saveCurrentSearch: false,
  savedSearchList: null,
  csrfToken: null,
  rowsAlwaysExpandable: false,
  expandRowOnClick: false,
  isTableExpanded: false,

  get canExportToCSV () {
    if (this.gateKeeper.can('export__users_csv')) {
      return true
    }
  },

  fetchSavedSearchList (searchTypeKey) {
    set(this, 'savedSearchList', DS.PromiseObject.create({
      promise: this.remoteMethods.fetchSavedQueryList(searchTypeKey).then(({ savedSearches }) => ({ rows: A(savedSearches.rows) }))
    }))
  },

  loadQuery (queryId) {
    set(this, 'loadingQuery', true)
    this.remoteMethods.fetchSavedQuery(queryId)
      .then(({ searchQuery }) => {
        /*
        * The saved search query should be executed with the current viewKey
        * instead of the viewKey originally saved with the query
        */
        if ('viewKey' in searchQuery) delete searchQuery.viewKey
        set(this, 'reinflateQuery', searchQuery)
        set(this, 'loadingQuery', false)
      })
  },

  init () {
    this._super(...arguments)
    const requestedPageLength = parseInt(this.searchTemplate.requestedPageLength)
    set(this, 'csrfToken', get(this, 'cookies').read('csrftoken'))

    const searchFilters = []

    this.rowsAlwaysExpandable = this.searchTemplate.rowsAlwaysExpandable ? this.searchTemplate.rowsAlwaysExpandable : false
    this.expandRowOnClick = !!this.searchTemplate.expandRowOnClick
    this.isTableExpanded = !!this.searchTemplate.isTableExpanded
    this.searchTemplate.filters.forEach(({ filterKey, filterOperator, defaultValue }) => {
      if (defaultValue) {
        searchFilters.push({ 'filterSet': { 'booleanOperator': 'OR', 'filters': [{ 'filterKey': `${filterKey}`, 'filterOperator': `${filterOperator}`, 'filterValue': `${defaultValue}` }] } })
      }
    })

    if (searchFilters.length > 0) {
      set(this, 'defaultSearchFilters', { 'booleanOperator': 'AND', 'filters': searchFilters })
    }

    if (this.searchTemplate.allowsQueryParamFiltering && this.queryParamSearchFilters) {
      this.genericSearch.saveFilterSet(this.searchTypeKey, JSON.parse(this.queryParamSearchFilters))
    }

    set(this, 'searchQuery', {
      viewKey: this.searchTemplate.views[0].viewKey,
      searchTypeKey: this.searchTypeKey,
      requestedPage: this.genericSearch.getPreviouslyDisplayedPage(this.searchTypeKey, this.searchTemplate.views[0].viewKey),
      requestedPageLength: requestedPageLength || 10,
      filterSet: this.genericSearch.getFilterSet(this.searchTypeKey, this.defaultSearchFilters)
    })
    if (!isEmpty(get(this, 'searchQuery.filterSet.filters'))) {
      set(this, 'reinflateQuery', this.searchQuery)
    }
    this.searchTask.perform()
      .then(() => {
        // for performance reasons, load the list of saved searches after loading the search results
        if (this.gateKeeper.isAuthenticated) {
          this.fetchSavedSearchList(this.searchTypeKey)
        }
      })
  },

  onChange () {
    if (this.onSearchQueryChange) {
      this.onSearchQueryChange(this.searchQuery)
    }
    this.searchTask.perform()
  },

  currentView: computed('searchQuery.viewKey', function () {
    return this.searchTemplate.views.find(view => view.viewKey === this.searchQuery.viewKey)
  }),

  saveFilterSet: observer('searchQuery', 'searchQuery.filterSet', function () {
    this.genericSearch.saveFilterSet(this.searchQuery.searchTypeKey, this.searchQuery.filterSet)
  }),

  searchTask: task(function * () {
    yield this.remoteMethods.getSearchResult(this.searchQuery).then(({ searchResult }) => {
      set(this, 'searchResult', searchResult)
    })
  }).keepLatest(),

  sortColumn: computed('searchQuery.ordering', function () {
    if (this.searchQuery.ordering !== undefined && this.searchQuery.ordering.length > 0) {
      return this.searchQuery.ordering[0]
    } else {
      return {}
    }
  }),

  exportQuery: computed('searchQuery', function () {
    return JSON.stringify({
      searchQuery: Object.assign({ requestedExport: true }, this.searchQuery)
    })
  }),

  actions: {
    setSortColumn (orderColumn, orderDirection) {
      set(this, 'searchQuery.ordering', [
        {
          elementKey: orderColumn,
          direction: orderDirection
        }
      ])
      this.onChange(this.searchQuery)
    },
    onSearch (searchQuery, resetToFirstPage = false) {
      if (this.searchTemplate.allowsQueryParamFiltering) {
        this.addSearchFiltersToQueryParams(searchQuery.filterSet)
      }
      if (resetToFirstPage) {
        this.genericSearch.resetPreviouslyDisplayedPage(searchQuery.searchTypeKey, searchQuery.viewKey)
        set(this, 'searchQuery.requestedPage', 1)
      }
      set(this, 'searchQuery', Object.assign({}, this.searchQuery, searchQuery))
      this.onChange(this.searchQuery)
    },
    setViewKey (viewKey) {
      if (get(this, 'searchQuery.viewKey') !== viewKey) {
        set(this, 'searchQuery.viewKey', viewKey)
        this.genericSearch.resetPreviouslyDisplayedPage(this.searchTypeKey, viewKey)
        set(this, 'searchQuery.requestedPage', 1)
      }
      this.onChange(this.searchQuery)
    },
    setCurrentPage (page) {
      this.genericSearch.setPreviouslyDisplayedPage(this.searchTypeKey, get(this, 'searchQuery.viewKey'), page)
      set(this, 'searchQuery.requestedPage', page)
      this.onChange(this.searchQuery)
    },
    onSave () {
      set(this, 'saveCurrentSearch', false)
      set(this, 'displaySavedSearchList', true)
      this.actions.refreshSavedSearchList.call(this)
    },
    refreshSavedSearchList () {
      this.fetchSavedSearchList(this.searchTypeKey)
    },
    loadQuery (queryId) {
      set(this, 'displaySavedSearchList', false)
      this.loadQuery(queryId)
    },
    switchToMapView () {
      this.router.transitionTo(this.searchTemplate.mapConfig.mapViewEmberRoute)
    }
  }
})
