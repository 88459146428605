import Component from '@ember/component'
import { computed, get, set, observer } from '@ember/object'
import { inject } from '@ember/service'
import Table from 'ember-light-table'

export default Component.extend({
  router: inject(),
  addTitlesToHeadings: observer('table', () => {
    // This allows you to see the full name of the heading when hovering over of column header
    // Has to be done like this as it isn't supported by lighttable
    document.querySelectorAll('.c-table__cell--heading')
      .forEach(heading => {
        heading.title = heading.innerText
      })
  }),

  columnsTableExpanded: (columns, sortColumn) => {
    const expandedColumnWidth = (type, width, expandedWidth) => {
      if (type === 'actions-no-data') return '100px'
      // optional value for custom expanded columns width
      return expandedWidth || (width || '240px') // '240px' by default
    }

    columns = columns.filter(c => (c.type !== 'hidden'))
      .map(c => {
        const column = {
          label: c.columnHeading,
          sortable: !(c.type === 'actions-no-data'),
          cellComponent: `generic-search/table/cell/${c.type}`,
          valuePath: c.elementKey,
          classNames: 'c-table__cell c-table__cell--heading',
          cellClassNames: 'c-table__cell',
          config: c,
          sorted: c.elementKey === sortColumn.elementKey,
          ascending: c.elementKey === sortColumn.elementKey && sortColumn.direction === 'asc',
          width: expandedColumnWidth(c.type, c.width, c.expandedWidth),
          align: c.align != null ? c.align : 'left' // Left align by default
        }
        if (c.type === 'actions-no-data') {
          column.cellClassNames = column.cellClassNames + ' c-table__cell--fixed'
          column.classNames = column.classNames + ' c-table__cell--fixed'
        }
        return column
      })
    return columns
  },

  columnsTableStandard: (columns, sortColumn, rowsAlwaysExpandable) => {
    columns = columns.filter(c => (c.type !== 'hidden'))
      .map(c => ({
        label: c.columnHeading,
        sortable: c.sortable,
        cellComponent: `generic-search/table/cell/${c.type}`,
        valuePath: c.elementKey,
        classNames: 'c-table__cell c-table__cell--heading',
        cellClassNames: 'c-table__cell c-table__row--clickable',
        config: c,
        sorted: c.elementKey === sortColumn.elementKey,
        ascending: c.elementKey === sortColumn.elementKey && sortColumn.direction === 'asc',
        breakpoints: c.breakpoints,
        width: c.width,
        align: c.align != null ? c.align : 'left' // Left align by default
      }))

    // add column on the left with chevrons to control the expandable row
    if (!rowsAlwaysExpandable) {
      columns.unshift({
        sortable: false,
        width: '22px',
        cellComponent: 'dynamic-form/light-table-components/row-toggle',
        classNames: 'c-table__cell c-table__cell--heading',
        cellClassNames: 'c-table__cell c-table__row--clickable c-table__row--toggle-column',
        breakpoints: ['upToLargeDesktop']
      })
    } else {
      // always display at any breakpoint
      columns.unshift({
        sortable: false,
        width: '22px',
        cellComponent: 'dynamic-form/light-table-components/row-toggle',
        classNames: 'c-table__cell c-table__cell--heading',
        cellClassNames: 'c-table__cell c-table__row--clickable c-table__row--toggle-column'
      })
    }
    return columns
  },

  table: computed('rows', 'columns', 'isLoading', function () {
    return Table.create({ columns: get(this, 'columns'), rows: this.isLoading ? [] : this.rows })
  }),

  columns: computed('currentView.table.columns.[]', 'sortColumn', 'isTableExpanded', function () {
    const sortColumn = get(this, 'sortColumn')
    const isTableExpanded = get(this, 'isTableExpanded')
    const rowsAlwaysExpandable = get(this, 'rowsAlwaysExpandable')
    const columnsTableExpanded = get(this, 'columnsTableExpanded')
    const columnsTableStandard = get(this, 'columnsTableStandard')
    let columns = get(this, 'currentView.table.columns')

    columns = (isTableExpanded) ? columnsTableExpanded(columns, sortColumn) : columnsTableStandard(columns, sortColumn, rowsAlwaysExpandable)
    return columns
  }),

  actions: {
    onColumnHeaderClick (column) {
      if (column.sortable) {
        this.setSortColumn(column.get('valuePath'), column.ascending ? 'asc' : 'desc')
      }
    },
    onAfterResponsiveChange (matches) {
      // Unless rowsAlwaysExpandable is set to true,
      // when the screen is a large desktop or bigger, the expand/contract column gets hidden,
      // so we need to automatically expand all the rows.
      if (matches.indexOf('upToLargeDesktop') === -1) {
        this.get('table.expandedRows').setEach('expanded', false)
      }
    },

    onRowClick (row) {
      const actionConfig = get(this, 'currentView.table.onRowClick')
      const expandRowOnClick = get(this, 'expandRowOnClick')
      const isTableExpanded = get(this, 'isTableExpanded')

      if (actionConfig && expandRowOnClick) {
        const error = new Error('Conflict, JSON configuration cannot contain an onRowClick attribute and expandRowOnClick attribute')
        throw error
      }

      if ((actionConfig === undefined || actionConfig === null) && !isTableExpanded) {
        // no action configured
        if (expandRowOnClick) {
          set(row, 'expanded', !row.expanded)
        }
        return
      }
      const args = actionConfig.args.map(arg => {
        if (arg.constant !== undefined) {
          return arg.constant
        }
        if (arg.elementKey !== undefined) {
          return row.content[arg.elementKey]
        }
      })
      try {
        this.send(actionConfig.type, ...args)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`attempted to run action.${actionConfig.type}(`, ...args, ')')
        throw (e)
      }
    },
    transitionAction (...args) {
      this.router.transitionTo(...args)
    }
  }

})
