import GateKeeper from 'client/services/gate-keeper'
import { inject } from '@ember/service'

/**
 * Override MSAF gatekeeper to add additional methods
 */
export default class WardenService extends GateKeeper {
  @inject featureFlags
  /**
   * Return true if the user can perform any of the given actions,
   * or the actions given is zero length
   * @param {Array} actions Array of permission names
   * @return {boolean}
   */
  canAny (actions) {
    if (!Array.isArray(actions) || actions == null) {
      throw new Error('`actions` must be an array')
    }

    if (actions.length === 0) return true
    return actions.some(action => this.can(action))
  }

  /**
   * Filters an array of objects by the permissions associated with them.
   * If there are no permissions associated with an item, or the user has any
   * of the specified permissions, return true for that item.
   * Else return false for that item
   * Only items for which the permissions check returns true are returned
   *
   * @param {Array} items
   * @param {String} permissionsKey
   *
   * @return {Array} Array of items filtered by permission
   */
  filterByAllowedActions (items, permissionsKey = 'permissions', featureFlagKey = 'featureFlag') {
    if (!Array.isArray(items) || items == null) {
      throw new Error('`items` must be an array')
    }

    if (typeof permissionsKey !== 'string') {
      throw new Error('`permissionsKey` must be a string')
    }

    return items.filter(item => {
      const permissions = item[permissionsKey]
      if (item[featureFlagKey] && this.featureFlags.flagUnset(item[featureFlagKey])) {
        return false
      }
      return permissions == null || (Array.isArray(permissions) ? this.canAny(permissions) : this.can(permissions))
    })
  }

  /**
   * Flattens out all the permissions on a tab or menu structure based upon an optional route that they navigate to
   * @param {Array} items - the tab or menu structure
   * @param {string|null} routeMatch - the optional route match to filter the items
   * @param permissionsKey - the permissions key, defaults to `permissions`
   * @return {Array} array of permission strings
   */
  flattenPermissions (items, routeMatch = null, permissionsKey = 'permissions') {
    return items
      .filter(item => !routeMatch || item.route === routeMatch)
      .reduce((perms, item) => ([...perms, ...item[permissionsKey]]), [])
  }
}
