/* global Raygun */

import { inject } from '@ember/service'
import CSRFToken from 'client/mixins/csrf-token'
import { isForbiddenError, isNotFoundError } from 'ember-ajax/errors'
import AjaxService from 'ember-ajax/services/ajax'
import { DRF_LOGIN_NEEDED_ERROR } from '../constants/values'

/**
 * Note:
 * This class is also created within the msaf-core-ember module, however, we are
 * overriding this here to allow for:
 * * Specifying custom error strings
 * * Specifying custom redirect locations
 * * Update redirect query params
 */

/**
 * Extends the Ajax service, with settings common for calls to our API.
 *
 * Adds a CSRF Token to the headers property using the CSRFToken mixin, and
 * sets the base path. All calls to our API outside of Ember Data should use
 * this Service.
 */
export default class RemoteMethodService extends AjaxService.extend(CSRFToken) {
  @inject session
  @inject toastMessages
  @inject router
  @inject intl

  namespace = '/api'

  /**
   * Builds a properly escaped query string.
   * @param {Object} query Key/Value object.
   * @return {string}
   */
  buildQueryString (query) {
    return Object.keys(query === undefined ? {} : query)
      .map((key) => ({ key, value: query[key] }))
      .filter(({ value }) => value !== undefined)
      .map(({ key, value }) => (`${key}=${encodeURIComponent(value)}`))
      .join('&')
  }

  /**
   * Handle any errors coming from a request
   * @param {Object} error - the error returned from the server
   * @returns {RSVP.Promise|null} return null if error handled
   */
  handleError = (error) => {
    // Detect a user not logged in error and redirect them to the login page
    if (isForbiddenError(error) && error?.errors[0]?.detail?.detail === DRF_LOGIN_NEEDED_ERROR) {
      this.toastMessages.warning(this.intl.t('messages.unauthenticated'))
      return this.router.transitionTo('login', { queryParams: { returnTo: window.location.pathname } })
        .then(() => null)
    } else if (isForbiddenError(error) || isNotFoundError(error)) {
      // Wait for the userCache to update before we try to transition to avoid race conditions
      return this.session.updateUserCache().then(() => {
        return this.router.transitionTo('base.not-found')
          .then(() => null)
      })
    }
    Raygun.send(error)
    throw error
  }

  request () {
    return super.request(...arguments).catch(this.handleError)
  }

  requestNoHandle () {
    return super.request(...arguments)
  }

  post (url, options) {
    // Want to send POST messages with the json content type so Django can understand the request body
    return super.post(url, { ...options, headers: { 'Content-Type': 'application/json;charset=utf-8' } })
      .catch(this.handleError)
  }

  put (url, options) {
    // Want to send PUT messages with the json content type so Django can understand the request body
    return super.put(url, { ...options, headers: { 'Content-Type': 'application/json;charset=utf-8' } })
      .catch(this.handleError)
  }

  // Overwrites default isInvalidError behaviour to return the status, headers and payload of an invalid error
  isInvalidError () {
    return super.init(...arguments)
  }
}
