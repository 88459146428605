import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'

export default class PaginationControls extends Component {
  @tracked pageSize = 10
  @tracked maxPageLinksShown = 5

  get pageRange () {
    // Taken from the pagination controls in the msaf generic search
    const pages = []
    const curPage = this.args.curPage
    const numPages = this.args.maxPage

    const maxPageLinks = numPages > this.maxPageLinksShown ? this.maxPageLinksShown : numPages
    const numLinksEachSideOfActive = Math.ceil((maxPageLinks - 1) / 2)

    // Default range
    let i = 1
    let lastPageLink = maxPageLinks

    // Alter range so that current page link in in center once possible:
    if (curPage > numLinksEachSideOfActive) {
      // If at end of list, current page link can't be in center:
      if (curPage + numLinksEachSideOfActive > numPages) {
        i = numPages - (maxPageLinks - 1)
        lastPageLink = numPages
      // Otherwise, set range to 2 links either side of current page:
      } else {
        i = curPage - numLinksEachSideOfActive
        lastPageLink = curPage + numLinksEachSideOfActive
      }
    }

    for (i; i <= lastPageLink; i++) {
      pages.push(i)
    }

    return pages
  }
}
