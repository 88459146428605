import { inject } from '@ember/service'
import RSVP from 'rsvp'
import RemoteMethodService from './remote-methods'

export default class ServicesOverviewRemoteMethods extends RemoteMethodService {
  @inject intl
  @inject session

  servicesOverviewUrl = '/api/audit/services-overview'

  async fetchServicesOverviewForSeason () {
    const response = await this.requestNoHandle(`${this.servicesOverviewUrl}/season`)
    const data = this.insertTranslationsForSeason(response.data)

    return RSVP.hash({
      heading: data.heading,
      tradingName: data.trading_name,
      subHeading: data.subHeading,
      assessmentsCount: data.completed_audits,
      supplierCount: data.supplier_count,
      startDate: data.start_date,
      endDate: data.end_date,
      clientCompany: data.client_company,
      rows: data.rows
    })
  }

  async fetchServicesOverviewForMonth (month, year) {
    const response = await this.requestNoHandle(`${this.servicesOverviewUrl}/calendar-month`, {
      method: 'POST',
      data: {
        month,
        year
      }
    })
    const data = this.insertTranslationsForLastCalendarMonth(response.data)
    return RSVP.hash({
      heading: data.heading,
      tradingName: data.trading_name,
      subHeading: data.subHeading,
      assessmentsCount: data.completed_audits,
      startDate: data.start_date,
      endDate: data.end_date,
      clientCompany: data.client_company,
      rows: data.rows
    })
  }

  async fetchRoutineAnnualNonConformances () {
    const response = await this.requestNoHandle(`${this.servicesOverviewUrl}/routine-annual-non-conformances`)
    const ncData = this.insertTranslationsForNonConformanceType(response.data.data)
    return RSVP.hash({
      seasonDates: response.data.season_dates,
      auditProgrammeId: response.data.audit_programme_id,
      overallNcCount: response.data.overall_nc_count,
      criticalNcCount: response.data.critical_nc_count,
      data: ncData,
      visitTypeMappings: response.data.visit_type_mappings
    })
  }

  async fetchSearchableYears () {
    const response = await this.requestNoHandle(`${this.servicesOverviewUrl}/years`)
    return response.data
  }

  insertTranslationsForSeason (data) {
    data.heading = this.intl.t('services_overview.season.services_for_season', { monthRange: data.month_range })
    data.subHeading = this.intl.t('services_overview.season.summary_of_services', { yearRange: data.year_range })
    return data
  }

  insertTranslationsForLastCalendarMonth (data) {
    const year = data.start_date.slice(0, 4)
    data.heading = this.intl.t('services_overview.last_calendar_month.services_for_month', { month: data.start_month, year })
    data.subHeading = this.intl.t('services_overview.last_calendar_month.summary_of_services')
    return data
  }

  insertTranslationsForNonConformanceType (data) {
    // Replaces keys for NC Types with translations
    Object.keys(data).map(ncSector => {
      Object.keys(data[ncSector]).map(ncType => {
        const translatedKey = this.intl.t(`services_overview.${ncType}`)
        data[ncSector][translatedKey] = data[ncSector][ncType]
        delete data[ncSector][ncType]
      })
    })
    return data
  }
}
