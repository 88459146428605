import Ember from 'ember'
import { copy } from 'ember-copy'
const { set, get } = Ember

export default Ember.Component.extend({
  showConfirmationModal: false,
  updateDeletedHide (formElement, index, key, status = true) {
    // We take a copy here so that the state reference changes on set and forces observers throughout the app fire
    const state = copy(get(this, 'form.state'), true)
    state[formElement.name][index] = Object.assign({}, state[formElement.name][index], { [key]: status })
    set(this, 'form.state', state)
  },

  didRender () {
    // Capture a snapshot after a render
    // We need this, because the  render adds in hidden state details that become very difficult to strip
    // out later when we want to compare if anything has changed on the page
    if (get(this, 'takeSnapshot')) {
      Ember.run.later(() => {
        // Need the if condition, as tests don't include this function and will fail
        if (get(this, 'setCheckpointState')) {
          get(this, 'setCheckpointState')(get(this, 'form.state'))
        }
      })
    }
  },

  actions: {
    updateStateKey (key, childState) {
      const state = copy(get(this, 'form.state'), true)
      state[key] = childState
      set(this, 'form.state', state)
    },

    delete (formElement, index) {
      this.updateDeletedHide(formElement, index, 'deleted')
    },

    /**
     * Wrap the updateRootState closure function so that if the route's template does not pass this function, it doesn't
     * cause a crash
     */
    updateRootState (state, updateMenu = false) {
      state = copy(state, true)
      if (get(this, 'updateRootState')) {
        get(this, 'updateRootState')(state, updateMenu)
      } else {
        Ember.Logger.info('Attempted to call "updateRootState" when no closure function was passed to the dynamic forms')
      }
    },

    /**
     * Wrap the save closure function so that if the route's template does not pass this function, it doesn't
     * cause a crash
     */
    save (updateMenu = false, hideSuccessBanner = true, postSaveCallback = null) {
      if (get(this, 'save')) {
        get(this, 'save')(updateMenu, hideSuccessBanner, postSaveCallback)
      } else {
        Ember.Logger.info('Attempted to call "save" when no closure function was passed to the dynamic forms')
      }
    },

    /**
     * Wrap the refreshModel closure function so that if the route's template does not pass this function, it doesn't
     * cause a crash
     */
    refreshModel () {
      if (get(this, 'refreshModel')) {
        get(this, 'refreshModel')()
      } else {
        Ember.Logger.info('Attempted to call "refreshModel" when no closure function was passed to the dynamic forms')
      }
    }
  }
})
