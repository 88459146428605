import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'

const { get, set, copy, computed } = Ember

export default Ember.Component.extend(DynamicElement, ComplexElement, {
  showConfirmationModal: false,

  /**
   * Keeps track of the numbering.
   *
   * @return {integer|null[]} An array of numbers, matches the index of the
   *                          orderable text fields, null means no number.
   *                          e.g. [null, 1, 2, null, 1, 2, 3]
   */
  numbers: computed('values', 'types', function () {
    const values = get(this, 'values')

    let counter = 0
    const numbers = values.map(value => {
      const orderableTextFieldIsHeadingVal = get(value, 'val.orderableTextFieldIsHeading.0.val')
      const isHeading = orderableTextFieldIsHeadingVal && orderableTextFieldIsHeadingVal.toString() === 'true'

      // Deleted items and sub-headings don't get numbered.
      if (value.deleted || isHeading) return null

      counter += 1
      return counter
    })

    return numbers
  }),

  /**
   * Marks a form element as deleted.
   * @param formElement
   * @param index
   */
  markDeleted (formElement, index) {
    const state = copy(get(this, 'state'), true)
    state[formElement.name][index] = Object.assign({}, state[formElement.name][index], { deleted: true })
    get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'))
  },

  /**
   * For moving up and down we need to only work with visible (non-deleted)
   * items in the list. This filters deleted items out and returns an array
   * of their original indexes e.g. [0, 3, 4, 6] would mean that indexes of
   * 1, 2, or 5 in the list are deleted.
   *
   * @return {integer[]}
   */
  visibleIndexes: computed('values', function () {
    const values = get(this, 'values')
    const nonDeletedItems = []
    values.forEach((value, index) => {
      // Is it deleted?
      if (!value.deleted) nonDeletedItems.push(index)
    })
    return nonDeletedItems
  }),

  actions: {
    delete (index, formElement) {
      set(this, 'confirmSuccessFunction', () => {
        this.markDeleted(formElement, index)
        set(this, 'showConfirmationModal', false)
      })
      set(this, 'showConfirmationModal', true)
    },

    add (isHeading) {
      const elementName = get(this, 'formElement.name')
      const existingCount = get(this, `state.${elementName}.length`)
      get(this, 'populateFunctions').buildState({}, get(this, 'formElement.formElements'), existingCount + 1)
        .then(newState => {
          newState.orderableTextFieldIsHeading[0].val = isHeading ? 'true' : 'false'
          const existingState = copy(get(this, 'state'), true)
          existingState[elementName].push({ id: 'new', val: newState })
          this.updateStateKey(elementName, existingState[elementName])
        })
    },

    moveUp (index) {
      const elementName = get(this, 'formElement.name')
      const visibleIndexes = get(this, 'visibleIndexes')
      const visiblePosition = visibleIndexes.findIndex(i => i === index)

      if (visiblePosition < 1) {
        // Nothing to do, as item is not found or already first
        return
      }

      const indexOfItemToSwitchWith = visibleIndexes[visiblePosition - 1]
      const existingState = copy(get(this, 'state'), true)
      const items = existingState[elementName]

      const item = items.splice(index, 1)[0] // Remove item
      items.splice(indexOfItemToSwitchWith, 0, item) // Insert into new position
      this.updateStateKey(elementName, items)
      // As this is a re-ordering change, and not detected by the
      // dynamic-form-element mixin, force a reset of the "values" variable.
      this.forceSetValues()
    },

    moveDown (index) {
      const elementName = get(this, 'formElement.name')
      const visibleIndexes = get(this, 'visibleIndexes')
      const visiblePosition = visibleIndexes.findIndex(i => i === index)

      if (visiblePosition === -1 || visiblePosition === (visibleIndexes.length - 1)) {
        // Nothing to do, as item is not found or this is the last item
        return
      }

      const indexOfItemToSwitchWith = visibleIndexes[visiblePosition + 1]
      const existingState = copy(get(this, 'state'), true)
      const items = existingState[elementName]

      const item = items.splice(index, 1)[0] // Remove item
      items.splice(indexOfItemToSwitchWith, 0, item) // Insert into new position
      this.updateStateKey(elementName, items)
      // As this is a re-ordering change, and not detected by the
      // dynamic-form-element mixin, force a reset of the "values" variable.
      this.forceSetValues()
    }
  }
})
