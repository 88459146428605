import Ember from 'ember'
import { task, timeout } from 'ember-concurrency'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import { TYPEAHEAD_COMPONENT } from '../../../../constants'

const { get, computed, set, getOwner } = Ember
const { DEBOUNCE_MS, MIN_CHARS } = TYPEAHEAD_COMPONENT

export default Ember.Component.extend(DynamicElement, {
  classNames: ['c-orderable-field__field-container'],

  // Extended attributes, that configure the predefined value typeahead
  showOptionalField: false,
  addPredefinedValue: computed.alias('formElement.extendedAttributes.addPredefinedValue'),
  addPredefinedValueButtonText: computed.alias('formElement.extendedAttributes.addPredefinedValueButtonText'),
  predefinedValueLabel: computed.alias('formElement.extendedAttributes.predefinedValueLabel'),
  lookupType: computed.alias('formElement.extendedAttributes.predefinedLookupType'),

  editor: null,

  /**
   * Search for existing lib lookup items with lookup type.
   *
   * Note: Must have at least MIN_CHARS before hitting back end.
   */
  searchTask: task(function * (query) {
    if (query.length < MIN_CHARS) return []
    yield timeout(DEBOUNCE_MS)
    return getOwner(this).lookup('service:lookups').getLibLookupItems(query, get(this, 'lookupType'))
  }),

  actions: {
    toggleOptionalField () {
      this.toggleProperty('showOptionalField')
    },
    setSelected (selected) {
      set(this, 'selected', selected)
    },
    didInsertEditor (editor) {
      set(this, 'editor', editor)
    },
    changeTextField () {
      // Gets called when the "Add" button next to the predefined text dropdown is clicked.
      if (this.editor !== null) {
        this.editor.setData(this.selected.description)
        this.toggleProperty('showOptionalField')
      }
    }
  }
})
