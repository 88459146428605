import Service, { inject } from '@ember/service'
import { get, computed } from '@ember/object'

export default Service.extend({
  session: inject(),

  loaded: computed.alias('session.loaded'),

  isAuthenticated: computed.alias('session.isAuthenticated'),

  /**
   * Usage: Inject the 'gateKeeper' service into your route or component
   * `get(this, 'gateKeeper').can('global.test_permission_1')`
   *
   * @param {string} action Permission name.
   * @return {boolean}
   */
  can (action) {
    const allowedActions = get(this, 'session.currentUser.allowedActions')
    return (Array.isArray(allowedActions) && Array.from(allowedActions).includes(action))
  },

  /**
   * Similar to "can" but ensures that the session is loaded before
   * performing the test.
   *
   * @param {string} action Permission name.
   * @return {Promise<boolean>}
   */
  canEnsureLoaded (action) {
    return get(this, 'session.loaded').then(() => this.can(action))
  },

  /**
   * Checks that the session has been loaded

   * @return {Promise<boolean>}
   */
  isSessionLoaded () {
    return get(this, 'session.loaded')
  }
})
