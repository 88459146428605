
const SEARCH_FILTER_OPERATORS = {
  programme: '=',
  clientCompany: '=',
  visitType: '=',
  isOpen: '=',
  category: 'ilike',
  initialCategory: 'ilike',
  isOverdue: '=',
  nextAuditDueDate: 'DATE_RANGE_OPERATOR',
  nextAuditBookedDate: 'DATE_RANGE_OPERATOR',
  dateCompleted: 'DATE_RANGE_OPERATOR',
  assessmentDate: 'DATE_RANGE_OPERATOR',
  dateAddedToProgramme: 'DATE_RANGE_OPERATOR',
  issueDate: 'DATE_RANGE_OPERATOR',
  type: '=',
  milkingObserved: '='
}

/**
   * Builds the entire query parameter object.
   *
   * @param {Object} searchCriteria An object describing the desired Generic Search filters and filter values.
   * The key is the filterKey of the Generic Search Filter, as defined in the search JSON file.
   * The value is an array of one or more values for the particular filter
   * {
        visitType: ['Routine', 'Routine Annual', 'Routine assessment'],
        programme: ['1'],
        nextAuditDueDate: [this.startDate, this.endDate],
        nextAuditBookedDate: ['is null']
      }
    This config has the meaning:
    visitType IS 'Routine' OR 'Routine Annual' OR 'Routine assessment'
    AND programme IS 1
    AND nextAuditDueDate > this.startDate AND nextAuditDueDate < this.endDate
    AND nextAuditBookedDate IS NULL

   * @returns {Json} Query parameter object in the format required by Generic Search.
   * This is the same format that can be viewed in the formData of the fetch-search-results request in the network tab
   * after a search query is performed.
   *
   */
export function buildQueryParamsForFilters (searchCriteria) {
  const queryParamsForFilters = {
    booleanOperator: 'AND',
    filters: Object.keys(searchCriteria)
      // if a filter was provided with no values we remove the filter completely.
      .filter(filterKey => Array.isArray(searchCriteria[filterKey]) && searchCriteria[filterKey].length > 0)
      .map(filterKey => {
        return {
          filterSet: {
            booleanOperator: 'OR',
            filters: _buildSingleFilter(searchCriteria[filterKey], filterKey)
          }
        }
      })
  }
  return JSON.stringify(queryParamsForFilters)
}

function _buildSingleFilter (items, filterKey) {
  if (!(filterKey in SEARCH_FILTER_OPERATORS)) {
    // eslint-disable-next-line no-console
    console.error(`${filterKey} operator not configured. Please add its operator to list of search filters`)
    return []
  }
  if (SEARCH_FILTER_OPERATORS[filterKey] === 'DATE_RANGE_OPERATOR') {
    return _buildDateRange(items, filterKey)
  }
  return items.map((item) => {
    return {
      filterKey: filterKey,
      filterOperator: SEARCH_FILTER_OPERATORS[filterKey],
      filterValue: item
    }
  })
}

function _buildDateRange (items, filterKey) {
  // Builds the query for generic search date filter where date is null.
  if (items.length === 1 && items[0] === 'is null') {
    return [{
      templateFilterOperator: 'None',
      filterSet: {
        booleanOperator: 'OR',
        filters: [
          {
            filterKey: filterKey,
            filterOperator: 'is null'
          }
        ]
      }
    }]
  } else {
    //  Builds the query for generic search date filter with a custom date range.
    if (items.length === 2) {
      return [{
        templateFilterOperator: 'Custom',
        filterSet: {
          booleanOperator: 'AND',
          filters: [
            {
              filterKey: filterKey,
              filterOperator: '>=',
              filterValue: items[0]
            },
            {
              filterKey: filterKey,
              filterOperator: '<=',
              filterValue: items[1]
            }
          ]
        }
      }]
    } else {
      // future improvement if ever needed: Support more than one filter for a single generic search date filter.
      // rather than supporting just a range or is null, we could support many ranges:
      // {dateFilter: [[startDate, endDate], [earlyDate, lateDate], ['is null']]}
      // eslint-disable-next-line no-console
      console.error(`Unimplemented error: Range filters currently only supports either a date range, or 'is null'. However recieved: ${items}`)
      return []
    }
  }
}
