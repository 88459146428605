import { get, set, getWithDefault, computed } from '@ember/object'
import Component from '@ember/component'

export default Component.extend({
  displaySavedSearchList: false,
  saveCurrentSearch: false,
  showGotoControl: true,
  showSearchControl: true,

  /*
   * Which controls panel is showing.
   * Shows the search pane by default and
   * false if none are showing.
  */
  showPanel: 'search',

  /*
   * Controls whether panel is expanded on
   * mobile (always visible on desktop)
   */
  isPanelVisible: true,

  panelToggleButtonIcon: computed('isPanelVisible', function () {
    return get(this, 'isPanelVisible') ? 'chevron-left' : 'panel-menu'
  }),

  // Used to pre-select the address lookup with the last entered value.
  lastSelectedAddress: null,

  showLayersControl: computed('layers', function () {
    return getWithDefault(this, 'layers', []).length > 0
  }),

  init() {
    this._super(...arguments)
    this.hasStickyToolbar =  this.searchTemplate.mapConfig.stickyToolbar || false
  },

  didReceiveAttrs () {
    this._super(...arguments)
    const checked = {}
    const layers = getWithDefault(this, 'layers', [])
    layers.forEach(l => {
      checked[l.id] = l.defaultOn || false
    })
    set(this, 'checked', checked)
  },

  actions: {
    noop () {},
    closePanelOnMobile () {
      if (window.matchMedia('(max-width: 599px)').matches) {
        set(this, 'isPanelVisible', false)
      }
    },
    togglePanel (panelName) {
      if (!this.isPanelVisible && this.showPanel !== panelName || panelName === this.showPanel || !panelName) {
        set(this, 'isPanelVisible', !this.isPanelVisible)
      }
      if (!this.isPanelVisible) {
        // Add sleep if tool-pane is closing so the content doesn't disappear before it's closed
        setTimeout(() => {
          set(this, 'showPanel', get(this, 'isPanelVisible') ? panelName : '')
        }, 250)
      } else {
        set(this, 'showPanel', get(this, 'isPanelVisible') ? panelName : '')
      }
    },
    clickLayer (layerId, e) {
      const isChecked = e.target.checked
      if (isChecked) {
        this.showLayer(layerId)
      } else {
        this.hideLayer(layerId)
      }
      set(this, `checked.${layerId}`, isChecked)
    },
    queryModified () {
      if (get(this, 'queryModified')) {
        this.queryModified(...arguments)
      }
    },
    onSearch () {
      if (get(this, 'onSearch')) {
        this.onSearch(...arguments)
      }
    },
    onAddressChange (addressItem) {
      set(this, 'lastSelectedAddress', addressItem)
    },
    loadQuery () {
      set(this, 'filtersCurrentlyCollapsed', true)
      set(this, 'displaySavedSearchList', false)
      this.loadQuery(...arguments)
      set(this, 'showPanel', 'search')
    },
    saveSearch () {
      set(this, 'saveCurrentSearch', false)
      // TODO: fix the linting error below
      this.sendAction('fetchSavedSearchList') // eslint-disable-line ember/closure-actions
      set(this, 'displaySavedSearchList', true)
    }
  }
})
