import AuthenticatedRoute from '../authenticated-route'
import { inject } from '@ember/service'
import { hash } from 'rsvp'

export default class TermsAndConditionsRoute extends AuthenticatedRoute {
  @inject remoteMethods
  @inject session
  @inject router

  beforeModel (transition) {
    return super.beforeModel(transition).then(() => {
      // If you've already agreed to the T&Cs,then you shouldn't be able to navigate here
      if (this.session.hasAgreedToTerms) {
        transition.abort()
        return this.router.transitionTo('base.home.dashboard')
      }
    })
  }

  model () {
    return hash({
      termsAndConditions: this.remoteMethods.request('/policy/terms-and-conditions')
    })
  }
}
