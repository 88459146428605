import Component from '@ember/component'
import { computed, get, getProperties } from '@ember/object'
import { FORM_STATES, SIDE_MENU_PREFIX, SIDE_MENU_PREFIX_A } from '../../../../constants'
import { inject } from '@ember/service'


export default Component.extend({
  tagName: 'li',
  classNameBindings: [
    'isCompactView:c-task-menu__compact-task:c-task-menu__task',
    'isActive:c-task-menu__task--active',
    'form.nonNavigable:c-task-menu__sub-menu',
    'isSubMenuOpen:c-task-menu__sub-menu--open'
  ],

  dynamicFormsMethods: inject(),

  isActive: computed.alias('form.active'),

  taskNumber: computed('index', function () {
    return get(this, 'index') + 1
  }),

  formLabel: computed('form.{childFormAlias,aliasFormLocation,aliasForParent,label,hideInMenuIfInactive}', function () {
    let label = this.dynamicFormsMethods.getFormLabel(this.form)

    // We only show the "Viewing"/"Editing" prefix if we're hiding
    // non-active sibling items from the menu.
    if (!this.form.hideInMenuIfInactive) return label

    let prefix = ''
    if (['a', 'e', 'i', 'o', 'u'].includes(label.charAt(0))) {
      // If the label begins with an 'a', we need the prefix "Viewing an" or "Editing an"
      // this isn't an ideal way to handle this, but a proper fix would require dynamic forms engine changes.
      prefix = SIDE_MENU_PREFIX_A[this.mode] || ''
    } else {
      prefix = SIDE_MENU_PREFIX[this.mode] || ''
    }

    return prefix + `<span class="c-task-menu__current-action-formLabel">${label}</span>`
  }),

  taskLabel: computed('taskNumber', 'form.label', function () {
    const labelSegments = []
    if (!get(this, 'suppressTaskNumbers'))  {
      labelSegments.push('<span class="c-task-menu__task-number">' + get(this, 'taskNumber') + '. </span>')
    }

    labelSegments.push('<span class="c-task-menu__task-label" data-test-task-menu-label>' + get(this, 'formLabel') + '</span>')
    return labelSegments.join('')
  }),

  /**
   * Sub menu items that should be shown in the sidebar. Removes non-active
   * ones, if that is flagged in the config by the extendedAttribute
   * "hideInMenuIfInactive".
   */
  menuSubItems: computed('form.forms', 'activeSubItems', 'showSubItems', function () {
    const subItems = get(this, 'form.forms')

    // If menu item has sub items, check if any of them are active
    if (!subItems) return []

    return subItems
      .filter(si => si.active || si.activeChild || !si.hideInMenuIfInactive)
  }),

  /**
   * Open if any of this menu item's sub-items are active.
   */
  isSubMenuOpen: computed.alias('form.activeChild'),

  /**
   * Note: this function will always return `true` for snapshots because the formInstanceId !== errorForm.formInstanceId
   * This is okay as we don't want to display errors on the `submission-view`, and this behaviour gives that result
   * We can't actually get summary error info for the application from the snapshot due to how the summary error function
   * works, and the fact that the snapshots are just json blobs
   */
  isComplete: computed('form.complete', 'form', 'errors', function () {
    // component-based workflows manually set complete:true/false
    if (get(this, 'form.complete') !== undefined) {
      return get(this, 'form.complete')
    }

    const { form, errors } = getProperties(this, ['form', 'errors'])

    // If no errors object returned at all, assume form completed
    // (More likely is in test run)
    if (!errors) return true

    return errors.forms.some(errorForm => {
      // will purposefully never match on submission-view
      return String(form.formInstanceId) === String(errorForm.formInstanceId) &&
        errorForm.status === FORM_STATES.COMPLETE
    })
  }),

  actions: {
    toggleSubMenu () {
      // TODO: Julia will remove this hack/make this work properly :D
      this.$().find('.c-task-menu__task-link').eq(0).trigger('click')
    }
  },

  click () {
    get(this, 'closeMenu')()
  }
})
