import Ember from 'ember'

const { computed, get } = Ember

export default Ember.Component.extend({
  tagName: 'li',
  classNames: ['c-compact-menu__task'],
  isActive: computed('form.active', 'form.formInstanceId', 'currentFormId', function () {
    // component-based workflows manually set active:true/false
    if (get(this, 'form.active') !== undefined) {
      return get(this, 'form.active')
    }
    // with form-based workflows it gets set automatically
    return String(get(this, 'form.formInstanceId')) === String(get(this, 'currentFormId'))
  })
})
