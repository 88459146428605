
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'
import { action } from '@ember/object'
import config from '../../../config/environment'
import { LINZ_BASE_MAP_URL } from 'client/constants/mapping'

export default class mapView extends Component {
  @inject mappingRemoteMethods
  @inject router
  @inject farmMaps
  @tracked enterpriseClientId
  @tracked divId = 'farm-map'
  @tracked showMap = true
  @tracked isLoading = false

  tilePath = `${LINZ_BASE_MAP_URL}?api=${config.ENV_VARS.LINZ_KEY}`

  constructor () {
    super(...arguments)
    this.enterpriseClientId = this.args.params.parent
    this.farmMaps.zoomControlElementInserted = false
  }

  get createMapAfterDOMInsert () {
    return this.initMap.bind(this)
  }

  async initMap () {
    try {
      this.isLoading = true
      await this.farmMaps.fetchMapData(this.enterpriseClientId)
      this.farmMaps.createMap(this.divId, this.tilePath)
      this.farmMaps.addFeatures()
    } catch (e) {
      this.showMap = false
    } finally {
      this.isLoading = false
    }
  }

  @action transitionToFullScreenMap (params) {
    this.router.transitionTo('base.supplier-farm-map', params.parent, params.formInstanceId)
  }
}
