import Component from '@ember/component'
import { inject } from '@ember/service'
import CommonMapMixin from '../../../mixins/components/common-map-mixin'

/**
 * Displays a simple map
 * Primarilly intended for testing msaf-core-maps
 */
export default Component.extend(CommonMapMixin, {
  mapLib: inject(),

  didInsertElement () {
    this._super(...arguments)

    this.createMapAndBaseLayers('.js-map-container')
  }
})
