import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'
const { get, computed } = Ember

export default Ember.Component.extend(DynamicElement, ComplexElement, {
  classNames: ['c-form-location-field'],
  honorificSelect: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'honorificSelect')
  }),
  firstName: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'firstName')
  }),
  surname: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'surname')
  }),
  fullName: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'fullName')
  }),
  hasFullName: computed.equal('formElement.extendedAttributes.hasFullName', true),
  hasHonorific: computed.equal('formElement.extendedAttributes.hasHonorific', true)
})
