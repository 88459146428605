/* eslint ember/no-observers: off */

import { set, observer } from '@ember/object'
import Mixin from '@ember/object/mixin'
import { inject } from '@ember/service'

export default Mixin.create({
  remoteMethods: inject('generic-search-remote-methods'),
  genericSearch: inject('generic-search'),
  searchTemplate: null,
  q: null,
  queryParamEnabled: false,
  queryParamSearchFilters: null,

  queryParams: ['queryParamSearchFilters', {
    q: {
      as: 'q',
      scope: 'router',
      replace: true
    }
  }],

  refinflateQuery: null,

  preventReinflation: false,
  reinflateQueryObserver: observer('q', function () {
    if (!this.preventReinflation && this.q !== null) {
      const searchQuery = {
        filterSet: {
          booleanOperator: 'AND',
          filters: this.q ? [
            {
              filterSet: {
                booleanOperator: 'OR',
                filters: [
                  {
                    filterKey: 'omniSearch',
                    filterOperator: '@@',
                    filterValue: this.q
                  }
                ]
              }
            }
          ] : []
        }
      }
      this.genericSearch.saveFilterSet(this.searchTypeKey) // clear any existing filter set saved to the generic search service (RMA-244)
      set(this, 'reinflateQuery', searchQuery)
    }
    set(this, 'preventReinflation', false)
  }),

  onSearchQueryChange () {
    set(this, 'q', null)
  },

  addSearchFiltersToQueryParams (searchQuery) {
    const newSearchFilters = JSON.stringify(searchQuery)
    if (this.queryParamSearchFilters !== newSearchFilters) {
      this.router.transitionTo({ queryParams: { queryParamSearchFilters: newSearchFilters } })
    }
  }
})
