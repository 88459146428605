import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './featured-section.notes.md'

export default {
  title: 'Featured Section',
  component: 'FeaturedSection',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const featuredSection = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <FeaturedSection>{{content}}</FeaturedSection>
    </ContentContainer>`,
  context: {
    content: text('Section content', 'Content goes here. Can be plain text or other components (e.g. cards)')
  }
})

export const withHeading = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <FeaturedSection @headingText={{headingText}}>{{content}}</FeaturedSection>
    </ContentContainer>`,
  context: {
    headingText: text('Heading text', 'Programmes'),
    content: text('Section content', 'Content goes here. Can be plain text or other components (e.g. cards)')
  }
})
