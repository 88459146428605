import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { ORDERED_CHART_COLORS } from 'client/constants/values'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'

export default class NonNotifiedAssessment extends Component {
  @inject router

  @tracked targetData
  @tracked targetCount
  @tracked targetWidth
  @tracked targetColor
  @tracked completedData
  @tracked completedCount
  @tracked completedWidth
  @tracked completedColor
  @tracked supplierCount
  @tracked programmeId
  @tracked seasonStartDate
  @tracked seasonEndDate
  @tracked completedSubgroups = ['NON_NOTIFIED']
  @tracked targetSubgroups = ['target']
  @tracked isToolTipEnabled = false
  @tracked disableViewNonNotifiedAuditsForSeasonLink = false

  constructor () {
    super(...arguments)

    this.completedData = [{ NON_NOTIFIED: this.args.completed['Non Notified'] }]
    this.targetData = [this.args.target]
    this.completedCount = this.args.completed['Non Notified']
    this.targetColor = ORDERED_CHART_COLORS.slice(1)
    this.completedColor = ORDERED_CHART_COLORS
    this.targetCount = this.targetData[0].target

    this.supplierCount = this.args.supplierCount
    this.seasonStartDate = this.args.seasonDates[0]
    this.seasonEndDate = this.args.seasonDates[1]
    this.programmeId = this.args.programmeId

    this.targetWidth = this.calculateWidth(this.targetCount)
    this.completedWidth = this.calculateWidth(this.completedCount)
  }

  get fivePercentSupplierCount () {
    return Math.ceil(5 / 100 * this.supplierCount)
  }

  @action
  calculateWidth (count) {
    const maxWidth = 400
    const maxCount = Math.max(this.completedCount, this.targetCount)
    if (maxCount === 0) {
      return 0
    }
    return count === maxCount ? maxWidth : count / maxCount * maxWidth
  }

  get nonNotifiedVisitTypes () {
    const nonNotifiedVisitTypeMapping = this.args?.visitTypeMapping.NON_NOTIFIED
    const visitTypeNames = nonNotifiedVisitTypeMapping ? nonNotifiedVisitTypeMapping.map((mapping) => mapping[1]) : []
    // remove any duplicate names so that we don't have duplicate filters
    return [...new Set(visitTypeNames)]
  }

  @action
  viewNonNotifiedAuditsForSeason () {
    this.disableViewNonNotifiedAuditsForSeasonLink = true
    const internalUserFilters = {
      programme: [this.programmeId.toString()],
      visitType: this.nonNotifiedVisitTypes,
      dateCompleted: [this.seasonStartDate, this.seasonEndDate]
    }

    this.router.transitionTo('base.home.search.audit', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(internalUserFilters)
      }
    })
  }
}
