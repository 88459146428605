import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

const { get, computed } = Ember

export default Ember.Component.extend(DynamicElement, {
  attr: computed.alias('formElement.extendedAttributes'),

  prefix: computed('attr.prefix', function () {
    return get(this, 'attr.prefix') || ''
  }),

  // add prefix and convert to fixed decimal places (if required)
  stringValue: computed('value', 'decimalPlaces', function () {
    let value = get(this, 'stateValue')

    // don't prefix empty values
    if (value === undefined || value === null) {
      return value
    }

    const decimalPlaces = get(this, 'attr.decimalPlaces')
    if (decimalPlaces !== undefined) {
      value = Number(value).toFixed(decimalPlaces)
    }

    return get(this, 'prefix') + value
  })
})
