import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'

export default class addCustomFieldButtonComponent extends Component {
  @inject session
  @inject customFieldsRemoteMethods

  @tracked numberCustomFields

  constructor () {
    super(...arguments)
    this.customFieldsRemoteMethods.fetchTotalCustomFields()
      .then(fieldCount => {
        this.numberCustomFields = fieldCount
      })
  }

  get isButtonDisabled () {
    return this.numberCustomFields == null || this.numberCustomFields >= this.session?.organisation?.maxCustomFields
  }
}
