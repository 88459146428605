import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'

export default class ProgrammeInformationDocumentsTranslatedCell extends Component {
  // If the value string exits, pass the string to INTL for translation
  // * Only works if the value is the same as the translation path

  @inject intl
  @tracked translationString = this.args.value ? this.intl.t(`supplier_summary.${this.args.value.toLowerCase()}`) : ''
}
