import EmberRouter from '@ember/routing/router'
import config from './config/environment'

export default class Router extends EmberRouter {
  location = config.locationType
  rootURL = config.rootURL

  constructor () {
    super(...arguments)
    this.on('routeDidChange', () => {
      // Return page to top on route change:
      window.scrollTo(0, 0)

      // Adds the page route into Raygun's breadcrumbs of user actions
      if (config.ENV_VARS && config.ENV_VARS.RAYGUN_API_KEY) {
        window.Raygun.trackEvent('pageView', { path: window.location.pathname })
      }
    })
  }
}

Router.map(function () {
  this.route('login')
  this.route('logout')

  this.route('base', { path: '/' }, function () {
    this.route('home', { path: '/' }, function () {
      this.route('dashboard', { path: '/' })
      this.route('search', function () {
        this.route('programme')
        this.route('audit')
        this.route('non-conformance')
      })
      this.route('programme-insights')
      this.route('non-conformance-insights')
      this.route('programme-subscriber-search')
      this.route('reports', function () {
        this.route('visit-type')
        this.route('service-overview')
      })
      this.route('non-conformances')
    })

    this.route('supplier-section', { path: 'supplier/:parent/:mode' }, function () {
      this.route('index', { path: '/' })
      this.route('supplier', { path: 'supplier/*path' })
      this.route('supplier-default', { path: 'supplier/' })
    })

    this.route('settings', function () {
      this.route('users', function () {
        this.route('edit', { path: ':user_id/edit' })
        this.route('switch-user', { path: ':user_id/switch-user' })
        this.route('invite')
      })
      this.route('custom-fields', function () {
        // eslint-disable-next-line ember/routes-segments-snake-case
        this.route('edit-custom-field', { path: '/edit-custom-field/:customFieldId' })
        this.route('add-custom-field')
        this.route('import-supplier-data')
        // eslint-disable-next-line ember/routes-segments-snake-case
        this.route('edit-custom-field-records', { path: '/edit-custom-field-records/:customFieldId' })
      })
      this.route('season-targets', function () {
        // eslint-disable-next-line ember/routes-segments-snake-case
        this.route('edit', { path: 'edit/:clientCompanyOrgId' }, function () {
          this.route('this-season')
          this.route('next-season')
        })
      })
    })

    this.route('pdf-print', function () {
      this.route('reports', function () {
        this.route('service-overview-print')
        this.route('visit-type-print')
      })
    })

    // Not found route
    this.route('not-found')
    this.route('page-not-found', { path: '/*' })

    this.route('map-search', function () {
      this.route('programme')
      this.route('audit')
      this.route('non-conformance')
    })

    // eslint-disable-next-line ember/routes-segments-snake-case
    this.route('supplier-farm-map', { path: 'farm-map/view/:parent/:formInstanceId' })

    // eslint-disable-next-line ember/routes-segments-snake-case
    this.route('supplier-farm-map-print', { path: 'farm-map/view/:parent/:formInstanceId/print' })

    this.route('terms-and-conditions')
  })
})
