import Ember from 'ember'

const { get } = Ember

// Takes two arguments, an element and a list and returns a new list with the element inserted at the first place.
const cons = (elem, list = []) => {
  const newList = list.slice(0) // copy the list
  newList.unshift(elem)
  return newList
}

// Traverses the menu structure, finds the active item and marks all of its parents with `activeChild: true`
const traverseMenuMarkActiveParents = (currentFormId, childForms = [], parentFormStack = []) => {
  childForms.forEach((form) => {
    // If this menu item is active
    // For component-based workflows this is set manually, for form-based ones we check the instance id
    if (currentFormId === form.formInstanceId) {
      // mark it as active
      form.active = true
    }

    if (form.active === true) {
      // and mark its parents as having an active child
      parentFormStack.forEach((form) => { form.activeChild = true })
    }

    traverseMenuMarkActiveParents(currentFormId, form.forms, cons(form, parentFormStack))
  })
}

export default Ember.Component.extend({
  classNames: ['c-task-menu', 'h-hide-for-print'],
  ariaRole: 'navigation',

  init (...args) {
    this._super(...args)

    const currentFormId = Number(get(this, 'currentFormId'))

    traverseMenuMarkActiveParents(currentFormId, get(this, 'menu.forms'))
  }
})
