import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './secondary-heading.notes.md'

export default {
  title: 'Secondary Heading',
  component: 'SecondaryHeading',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const heading = () => ({
  template: hbs`<SecondaryHeading>{{heading}}</SecondaryHeading>`,
  context: {
    heading: text('Heading text', 'Secondary level heading')
  }
})

export const noMarginTop = () => ({
  template: hbs`<SecondaryHeading @noMarginTop={{true}}>{{heading}}</SecondaryHeading>`,
  context: {
    heading: text('Heading text', 'Secondary level heading')
  }
})

export const noMarginBottom = () => ({
  template: hbs`<SecondaryHeading @noMarginBottom={{true}}>{{heading}}</SecondaryHeading>`,
  context: {
    heading: text('Heading text', 'Secondary level heading')
  }
})
