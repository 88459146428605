/* eslint ember/no-observers: off */

import Component from '@ember/component'
import { set, get, getWithDefault, observer } from '@ember/object'
import { A } from '@ember/array'
import { inject } from '@ember/service'

function findFilterKey (filter = {}) {
  if (filter.filterSet) {
    return filter.filterSet.filters.map(findFilterKey).filter(x => x !== undefined).pop()
  } else if (filter.filterKey) {
    return filter.filterKey
  }
}

export default Component.extend({
  tagName: '',
  query: null,
  viewKey: null,
  isCollapsed: false,
  genericSearchLibs: inject(),

  init () {
    this._super(...arguments)
    set(this, 'query', {})
    get(this, 'reinflateQuery') // get this to ensure the observer works correctly
  },

  didReceiveAttrs () {
    set(this, 'viewKey', this.searchTemplate.views[0].viewKey)
    this.searchTemplate.filters.forEach(({ filterKey }) => {
      set(this.query, filterKey, A())
    })
    this.queryChanged(false)
    this.reinflateQueryObserver()
  },

  // the `reinflateQuery` parameter gets set in order to 'reinflate' a query from JSON
  // we use an observer here to allow it to be changed without completely re-initialising
  // the component
  reinflateQueryObserver: observer('reinflateQuery', function () {
    if (!this.reinflateQuery) return

    // make sure the existing query is cleared
    Object.keys(this.query).forEach(filterKey => {
      this.query[filterKey].clear()
    })

    // reinflate the query
    this.reinflateQuery.filterSet.filters.forEach((filter) => {
      const filterKey = findFilterKey(filter)
      this.query[filterKey].pushObjects(filter.filterSet.filters)
    })
    this.queryChanged()
    if (getWithDefault(this, 'collapseOnReinflate', true) === true) {
      this.collapseFilters()
    }

    // pass the event to the higher-level component to initiate the search
    if (getWithDefault(this, 'runSearchOnReinflate', true) === true && this.onSearch) {
      this.onSearch(this.searchQuery, false)
    }
  }),

  collapseFilters () {
    // set isCollapsed to true if there are no filters applied, else set it to false
    set(this, 'isCollapsed', this.genericSearchLibs.removeRedundantFilters(this.searchQuery, true, true) !== undefined)
  },

  noFilters: false,

  onCollapseToggleObserver: observer('isCollapsed', function () {
    if (this.onCollapseToggle) {
      this.onCollapseToggle(get(this, 'isCollapsed'))
    }
  }),

  // this gets called after changing the internal representation of the query, in order to construct
  // 1: the searchQuery JSON
  // 2: the filter values that get passed to the child components
  queryChanged (bubbleEvent = true) {
    set(this, 'searchQuery', this.genericSearchLibs.removeRedundantFilters({
      'filterSet': {
        'booleanOperator': 'AND',
        'filters': Object.keys(this.query).map(key => ({
          'filterSet': {
            'booleanOperator': 'OR',
            'filters': this.query[key]
          }
        }))
      }
    }, true, false))

    // special case when no filters are applied
    if (this.searchQuery === undefined) {
      set(this, 'searchQuery', {
        'filterSet': {
          'booleanOperator': 'AND',
          'filters': []
        }
      })
    }

    if (Object.keys(this.query).filter(key => this.query[key].length > 0).length > 0) {
      set(this, 'noFilters', false)
    } else {
      set(this, 'noFilters', true)
    }

    // optionally pass the event to the higher-level component
    if (bubbleEvent && this.onChange) {
      this.onChange(this.searchQuery)
    }
  },

  actions: {
    addValue (filterKey) {
      if (this.query[filterKey]) {
        this.query[filterKey].pushObject({})
      }
      this.queryChanged()
    },
    setValue (filterKey, index, value) {
      set(this, `query.${filterKey}.${index}`, value)
      this.queryChanged()
    },
    removeValue (filterKey, index) {
      if (this.query[filterKey].length === 1) {
        // if there is only one value, call removeAllValues to remove the entire filter
        this.actions.removeAllValues.call(this, filterKey, index)
      } else {
        // else remove the value at the specified index
        this.query[filterKey].removeAt(index)
      }
      this.queryChanged()
    },
    removeAllValues (filterKey) {
      this.query[filterKey].clear()
      this.queryChanged()
    },
    resetSearch () {
      Object.keys(this.query).forEach(filterKey => {
        this.query[filterKey].clear()
      })
      this.queryChanged()
      this.send('performSearch')
      // eslint-disable-next-line no-undef
      if (event && event.target) {
        // eslint-disable-next-line no-undef
        event.target.blur()
      }
    },
    customSearchAction () {
      if (this.customSearchAction) {
        this.customSearchAction()
      } else {
        console.log('customSearchAction not implemented')
      }
    },
    performSearch () {
      this.set('disableSearchButton', true)
      this.onSearch(this.searchQuery, true)
      this.collapseFilters()
      this.set('disableSearchButton', false)
    },
    performAction (actionName, desiredArgs = []) {
      // Check if this action is defined on the component
      const isCompAction = get(this, `actions.${actionName}`) != null

      if (isCompAction) {
        // If the action passed is specified on this component
        // Trigger the action using `send`
        this.send(actionName)
      } else {
        // Check if action exists as a passed prop
        // If it does, trigger it
        if (get(this, actionName) != null) {
          const args = (Array.isArray(desiredArgs) ? desiredArgs : []).map(argName => {
            return get(this, argName)
          })
          get(this, actionName)(...args)
        }
      }
    }
  }
})
