// ============================================================
// Links:
//
// Links defined here are imported as required into different
// routes for rendering purposes.
// ============================================================
export const SETTINGS_TABS = [
  {
    route: 'base.settings.users',
    translationKey: 'menu.users',
    permissions: ['view__settings__users__admin', 'view__settings__users']
  },
  {
    route: 'base.settings.custom-fields',
    translationKey: 'menu.custom_fields',
    permissions: ['view__settings__custom_fields']
  },
  {
    route: 'base.settings.season-targets',
    translationKey: 'menu.season_targets',
    permissions: ['view__settings__season_targets'],
    featureFlag: 'FARM_DAIRY_REPORTS'
  }
]

export const DASHBOARD_REPORTS_TABS = [
  {
    route: 'base.home.reports.service-overview',
    translationKey: 'reports.service_overview',
    permissions: ['view__reports_services_overview'],
    key: 'reports-service-overview',
    tabEnabled: true
  },
  {
    route: 'base.home.reports.visit-type',
    translationKey: 'reports.visit_type',
    permissions: ['view__FDA_reports'],
    key: 'reports-visit-type',
    tabEnabled: true
  }
]

export const EDIT_SEASON_TARGETS_TABS = [
  {
    route: 'base.settings.season-targets.edit.this-season',
    translationKey: 'season_targets.tabs.this_season',
    permissions: ['edit__settings__season_targets'],
    key: 'season-targets-this-season',
    tabEnabled: true
  },
  {
    route: 'base.settings.season-targets.edit.next-season',
    translationKey: 'season_targets.tabs.next_season',
    permissions: ['edit__settings__season_targets'],
    key: 'season-targets-next-season',
    tabEnabled: true
  }
]

export const MAIN_MENU_LINKS = Object.freeze([
  {
    route: 'base',
    translationKey: 'menu.home',
    seleniumTag: 'main-menu-home'
  }, {
    route: 'base.settings.users.index',
    translationKey: 'menu.settings',
    permissions: SETTINGS_TABS.reduce((perms, tab) => {
      return [...perms, ...tab.permissions]
    }, []),
    seleniumTag: 'main-menu-settings'
  }
])

export const USER_MENU_LINKS = Object.freeze([
  { url: '/api/auth/change-password', translationKey: 'menu.change_password' },
  { route: 'logout', translationKey: 'menu.log_out' }
])

export const DASHBOARD_SEARCH_VIEWS = Object.freeze([
  {
    enabledForSearch: false,
    route: 'base.home.search.general',
    permissions: ['view__search__general_search'],
    key: 'search-general',
    translationKey: 'search.general'
  },
  {
    enabledForSearch: true,
    route: 'base.home.search.programme',
    permissions: ['view__search__programme_search'],
    key: 'search-programmes',
    translationKey: 'search.programme'
  },
  {
    enabledForSearch: true,
    route: 'base.home.search.programme',
    permissions: ['view__search__programme_search__dairy_restricted'],
    key: 'search-programmes-dairy-restricted',
    translationKey: 'search.programme'
  },
  {
    enabledForSearch: true,
    route: 'base.home.search.audit',
    permissions: ['view__search__audit_search'],
    key: 'search-audits',
    translationKey: 'search.audit'
  },
  {
    enabledForSearch: true,
    route: 'base.home.search.non-conformance',
    permissions: ['view__search__non_conformance_search'],
    key: 'search-non-conformances',
    translationKey: 'search.non_conformance'
  }
])

export const NAVBAR_EXCLUDED_ROUTES = Object.freeze([
  'base.map-search.programme',
  'base.map-search.audit',
  'base.map-search.non-conformance',
  'base.supplier-farm-map',
  'base.supplier-farm-map-print',
  'base.pdf-print.reports.service-overview-print',
  'base.pdf-print.reports.visit-type-print'
])
