import Service, { inject } from '@ember/service'

/**
 *  Service that primarily determines if the formElement should be displayed based upon provided
 *  the provided displayConditions.
 *  Right now can only look for conditions on the current level of the state hierarchy; cannot traverse up or down
 *  Method is present in a service so that displayFunctions - functions that determine whether an element should show
 *  rather than simply inspecting the state (e.g. show based on permissions) - can be called.
 **/
export default Service.extend({
  displayFunctions: inject(),
  customDisplayFunctionsServices: [],

  /**
   * Register an additional service to use for finding display functions.
   *
   * This is used to register application specific display functions, general
   * purpose ones can be added to display-function.js in this dir.
   *
   * @param {Service} service An Ember service to search through for custom
   *                          display functions.
   */
  registerCustomDisplayFunctionsService (service) {
    this.customDisplayFunctionsServices.push(service)
  },

  shouldDisplay (state, displayConditions, elementName) {
    if (!state) return true

    const conditionKeys = displayConditions ? Object.keys(displayConditions) : []
    if (!displayConditions || conditionKeys.length === 0) return true
    if (conditionKeys.length > 1) {
      // No support for this, throw an error
      throw new Error(`Multiple root level keys for the displayConditions for ${elementName} - only one allowed`)
    }

    // Need to evaluate the displayConditions - should only be one key
    let display
    switch (conditionKeys[0]) {
      case 'and':
        display = displayConditions.and.every(condition => {
          return this.evaluateCondition(state, condition, elementName)
        })
        break

      case 'or':
        display = displayConditions.or.some(condition => {
          return this.evaluateCondition(state, condition, elementName)
        })
        break

      case 'not':
        display = !displayConditions.not.some(condition => {
          return this.evaluateCondition(state, condition, elementName)
        })
        break

      case 'func':
        if (!('name' in displayConditions.func)) {
          throw new Error(`All "func" objects in displayConditions must have a "name" key for ${elementName}`)
        }
        display = this.evaluateFunction(state, displayConditions)
        break

      default:
        throw new Error(`Root key for conditional element must be "and", "or", "not", or "func" for ${elementName}`)
    }

    return display
  },

  evaluateCondition (state, condition, elementName) {
    // Test if this is condition is further nested, we assume it is if it has
    // a single key rather than a name/value pair.
    const nestedCondition = Object.keys(condition).length === 1

    if (nestedCondition) {
      return this.shouldDisplay(state, condition, elementName)
    }

    const keys = Object.keys(state)
    const target = keys.find(key => key === condition.name)
    // The target condition is not yet in the state.  Because of the progress way the state is built, this is
    // legitimate, but we don't want an error thrown
    if (!target) return false

    // Purposeful non-strict compare so we don't fall foul of "string or number" type issues
    // Also, note that we only use the first formElement.  Not sure yet how we would handle a condition with repeated formElements
    return (state[target][0].val == condition.value) // eslint-disable-line eqeqeq
  },

  evaluateFunction (state, displayConditions) {
    const functionName = displayConditions.func.name
    const includeState = displayConditions.func.includeState === true
    let functionArgs = displayConditions.func.arguments || []
    // If includeState flag is passed, we pass the state as the first argument.
    if (includeState) {
      functionArgs = [state, ...functionArgs]
    }

    // The list of display function services to search through.
    const displayFunctionsServices = [
      ...this.customDisplayFunctionsServices,
      this.displayFunctions
    ]

    // Look for a matching display function in all of the display function services.
    const displayFunctionsService = displayFunctionsServices.find(dfs => {
      return typeof dfs[functionName] === 'function'
    })

    if (!displayFunctionsService) {
      throw new Error(`Display function "${functionName}" not found in display function services`)
    }

    return displayFunctionsService[functionName](...functionArgs)
  }
})
