import Component from '@ember/component'
import { get, computed } from '@ember/object'
import { htmlSafe } from '@ember/template'

export default Component.extend({
  tagName: 'span',
  classNames: ['c-icon'],
  height: '16px',
  width: '16px',
  fill: 'currentColor',
  styleAttr: computed('width', 'height', 'fill', function () {
    // eslint-disable-next-line new-cap
    return new htmlSafe(
      'height:' + get(this, 'height') + '; ' +
      'width: ' + get(this, 'width') + '; ' +
      'fill: ' + get(this, 'fill')
    )
  })
})
