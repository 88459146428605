import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

const { get, set, inject } = Ember

export default Ember.Component.extend(DynamicElement, {
  resourceConsentRemoteMethods: inject.service(),
  tagName: '',
  result: '',

  didReceiveAttrs () {
    this._super(...arguments)

    // Calls a method on resourceConsentRemoteMethods with the value and displays the response.
    const resourceConsentRemoteMethods = get(this, 'resourceConsentRemoteMethods')
    const methodName = get(this, 'formElement.extendedAttributes.methodName')
    if (methodName && typeof resourceConsentRemoteMethods[methodName] === 'function') {
      resourceConsentRemoteMethods[methodName](get(this, 'stateValue')).then(result => set(this, 'result', result))
    }
  },

  actions: {
    noop () {
      // pass
    }
  }
})
