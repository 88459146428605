import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

export default Ember.Component.extend(DynamicElement, {
  tagName: '',
  uniqueId: Ember.inject.service(),
  inputId: Ember.computed(function () {
    return this.uniqueId.next()
  })
})
