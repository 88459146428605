/**
 * A wrapper service for embr-toastr

 * Docs for ember-toastr (wrapper) and toastr
 * https://github.com/knownasilya/ember-toastr
 * https://github.com/CodeSeven/toastr

 * Example usage:
  * import { inject } from '@ember/service'
  * @inject intl (used for translations in Ember)
  * @inject toastMessages

  * this.toastMessages.success(this.intl.t('<your-message>'))
  * this.toastMessages.info(this.intl.t('<your-message>'))
  * this.toastMessages.warning(this.intl.t('<your-message>'))
  * this.toastMessages.danger(this.intl.t('<your-message>'))

 * To clear toast messages call this.toastMessages.clear
*/

import Service, { inject } from '@ember/service'
import { htmlSafe } from '@ember/template'

export default class ToastMessagesService extends Service {
  @inject toast // ember-toastr

  toastrOptions = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-bottom-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '6000',
    extendedTimeOut: '6000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  }

  clear () {
    this.toast.clear()
  }

  remove () {
    this.toast.remove()
  }

  submitToast (title, state, icon, options) {
    const iconHtml = htmlSafe(`<svg class="toast-svg toast-svg--${state}";"><use xlink:href="/assets/icons/icons.svg#${icon}"></use></svg>`)
    const body = htmlSafe(`
    <div class="c-toast__body">
      <span class="c-toast__title">${title}</span>
      ${options.message != null ? `<span class="c-toast__message">${options.message || ''}</span>` : ''}
    </div>`)
    this.toast[state](iconHtml, body, { ...this.toastrOptions, ...options })
  }

  success (title, options = {}) {
    this.submitToast(title, 'success', 'icon-tick-circle', options)
  }

  info (title, options = {}) {
    this.submitToast(title, 'info', 'icon-info', options)
  }

  warning (title, options = {}) {
    this.submitToast(title, 'warning', 'icon-exclamation-triangle', options)
  }

  danger (title, options = {}) {
    this.submitToast(title, 'error', 'icon-error', options)
  }
}
