import { inject } from '@ember/service'
import { hash } from 'rsvp'
import AuthenticatedRoute from '../../../authenticated-route'

export default class EditCustomFieldRoute extends AuthenticatedRoute {
  @inject customFieldsRemoteMethods

  routePermissions = [
    'edit__custom_fields'
  ]

  model ({ customFieldId }) {
    return hash({
      fieldTypes: this.customFieldsRemoteMethods.fetchCustomFieldTypes(),
      customField: this.customFieldsRemoteMethods.fetchCustomField(customFieldId)
    })
  }
}
