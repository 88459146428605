import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { axisBottom, axisLeft } from 'd3-axis'
import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale'
import { format } from 'd3-format'
import { select } from 'd3-selection'
import { stack } from 'd3-shape'
import { capitalize } from 'lodash'

/*
  Hi there!

  This bar char accepts the following arguments:
  * @barChartIdentifier (Text)
  * @data (Object)
  * @translations (Object)
  * @chartColors (Object)
  * @dynamicResize (Boolean) - Allows SVG to scale with window width (CSS required)
  * @enableToolTip (Boolean)
  * @showLegendTitle (Boolean)
  * @alignLegendRight (Boolean)
  * Optional: @translateChartHorizontal(Integer) / @translateChartVertical - Used to fine tune placement of chart, defaults to the value of this.marginLeft / this.marginBottom
  * Optional: @width / @height (Integer - default '100%')

  It accepts data in the following format and will adjust in size automatically

  @data = {
    'X axis key #1': {
      'Y axis key 1': integer,
      'Y axis key 2': integer,
      'Y axis key 3': integer,
    },
    'X axis key #2': {
      'Y axis key 1': integer,
      'Y axis key 2': integer,
      'Y axis key 3': integer,
    }
  }

  this.args.translations = {
    legendTitle: 'translation.string'
  }

  this.args.chartColors = {
    'Y axis key 1': '#HEXCODE',
    'Y axis key 2': '#HEXCODE',
    'Y axis key 3': '#HEXCODE',
  }

  this.args.height = integer
  this.args.width = integer
*/

export default class BarChartVerticalSegmented extends Component {
  @inject intl

  @tracked data = this.args?.data
  @tracked chartColors = this.args?.chartColors
  @tracked translations = this.args?.translations
  @tracked barChartIdentifier = this.args?.barChartIdentifier
  @tracked dynamicResize = this.args?.dynamicResize
  @tracked alignLegendRight = this.args?.alignLegendRight
  @tracked showLegendTitle = this.args?.showLegendTitle

  // To display numbers over 10,000 setMarginLeft to 45
  @tracked marginLeft = 35
  @tracked height
  @tracked width
  @tracked mobileWidth = 450
  @tracked marginTop = 50
  @tracked marginBottom = 50
  @tracked translateChartVertical = this.args?.translateChartVertical || this.marginBottom
  @tracked translateChartHorizontal = this.args?.translateChartHorizontal || this.marginLeft
  @tracked colourFn = () => {}
  @tracked isMobile = false
  @tracked tetherTarget
  @tracked disableHover = false
  @tracked selectionCount
  @tracked withToolTip
  @tracked showLegendTitle = false

  barChartId = 0
  resizeFunc = this.setDimensions.bind(this)
  legendTextCharacterPixelWidth = 5 // Approximate width of legend item character in pixels

  constructor () {
    super(...arguments)
    this.setDimensions()
    window.addEventListener('resize', this.resizeFunc)
  }

  get legendTitle () {
    return this.intl.t(this.translations.legendTitle)
  }

  get legendTitlePixelWidth () {
    // Approximate pixel width of 6.5px per character
    return this.legendTitle.length * 6.5
  }

  get totalWidth () {
    return this.width + this.marginLeft
  }

  get totalHeight () {
    return this.height + this.marginBottom + this.marginTop
  }

  get svgColumnWidth () {
    return (this.width / this.legendDataSubGroups.length) / 2
  }

  get svgChartWidth () {
    return this.dynamicResize ? '100%' : this.totalWidth
  }

  get svgChartHeight () {
    return this.dynamicResize ? '100%' : this.totalHeight
  }

  get svgChartViewBox () {
    return this.dynamicResize ? `0 0 ${this.totalWidth} ${this.totalHeight}` : null
  }

  get legendDataSubGroups () {
    // Returns an array of Y axis keys
    return Object.keys(this.data)
  }

  get legendItemSubGroups () {
    // Returns an array of X axis keys
    return Object.keys(Object.values(this.data)[0])
  }

  get dataWithKeys () {
    // Returns an array of objects with the X axis key as a 'key' property
    return Object.keys(this.data).map(key => {
      const tempObject = { ...this.data[key] }
      tempObject.key = key
      return tempObject
    })
  }

  get chartColorsOrderedByDataKey () {
    // Returns an array of hex codes ordered by the Y axis keys
    return this.legendItemSubGroups.map(legendItem => {
      return this.chartColors ? this.chartColors[legendItem] : '#000000'
    })
  }

  get legendItems () {
    // Creates legend items and assigns a color to them
    const paddingRight = 15 // px
    const circleWidth = 5 // px
    const numberOfLegendItems = this.legendItemSubGroups.length
    let totalCharacterLength = 0
    this.legendItemSubGroups.map(legendItem => { totalCharacterLength += legendItem.length })

    let dxValue = 18
    let cxValue = '122'

    if (this.alignLegendRight) {
      dxValue = 25
      cxValue = '10'
    }

    const totalLegendItemWidth = (totalCharacterLength * this.legendTextCharacterPixelWidth) + (circleWidth * numberOfLegendItems) + (paddingRight * (numberOfLegendItems - 1))

    let translateValue = this.totalWidth - (this.legendTitlePixelWidth + totalLegendItemWidth)

    return this.legendItemSubGroups.map(title => {
      const legendColor = this.chartColors ? this.chartColors[title] : '#000000'
      const legendItemObject = {
        title: capitalize(title),
        color: legendColor,
        translateString: `translate(${translateValue},0)`,
        dxValue: `${dxValue}px`,
        cxValue: cxValue,
        circleWidth: circleWidth
      }
      translateValue += (dxValue + paddingRight + circleWidth + (title.length * this.legendTextCharacterPixelWidth))
      return legendItemObject
    })
  }

  setTetherTarget (rectClass, disableHover = false) {
    if (rectClass) {
      const selectorString = `#${this.args.barChartIdentifier} .${rectClass}`
      this.tetherTarget = select(document.querySelector(selectorString)).node()
    } else {
      this.tetherTarget = null
    }

    setTimeout(() => {
      this.disableHover = disableHover
    }, 50)
  }

  @action
  close () {
    if (this.disableHover) {
      this.setTetherTarget(null, false)
    }
  }

  willDestroy () {
    window.removeEventListener('resize', this.resizeFunc)
  }

  setDimensions () {
    const windowWidth = window.innerWidth
    if (windowWidth < this.mobileWidth) {
      this.marginBottom = 75
      this.height = 250 - this.marginBottom - this.marginTop
      this.width = (this.args.width || 300) - this.marginLeft
      this.isMobile = true
    } else {
      this.marginTop = 50
      this.height = (this.args.height || 250) - this.marginBottom - this.marginTop
      this.width = (this.args.width || 300) - this.marginLeft
      this.isMobile = false
    }
  }

  incrementClass () {
    const classString = `rect_${this.barChartId += 1}`
    return classString
  }

  @action
  drawChart (element) {
    const svg = select(element)
      .append('g')
      .attr('transform', `translate(${this.translateChartHorizontal}, ${this.translateChartVertical})`)

    const stackedData = stack()
      .keys(this.legendItemSubGroups)(Object.values(this.dataWithKeys)).map(data => {
        data.map(d => {
          d.rectClass = this.incrementClass()
          return d
        })
        return data
      })

    const maxYVal = stackedData.reduce((max, row) => {
      const colMax = Math.max(...row.map(col => col[1]))
      max = colMax > max ? colMax : max
      return max
    }, 0)

    const y = scaleLinear()
      .domain([0, maxYVal])
      .range([this.height, 0])

    // Define the ticks we want to use on the y-access. They should be integers only
    const yAxisTicks = y.ticks()
      .filter(tick => Number.isInteger(tick))

    svg.append('g')
      .classed('timeline-graph__yaxis', true)
      .call(axisLeft(y).tickValues(yAxisTicks).tickSizeInner(-this.width - 3).tickPadding(6).tickSizeOuter(0).tickFormat(format('d')))

    const x = scaleBand()
      .domain(this.dataWithKeys.map(d => d.key))
      .range([0, this.width])
      .padding(0.4)

    svg.append('g')
      .classed('timeline-graph__xaxis', true)
      .attr('transform', `translate(0, ${this.height})`)
      .call(axisBottom(x).tickSizeOuter(0).tickFormat(format('d')).tickValues(x.domain().filter((d, i) => {
        if (this.legendDataSubGroups > 6 && this.isMobile) {
          return !(i % 2)
        }
        return true
      })))
      .selectAll('text')
      // Break tick label onto multiple lines:
      .html((d) => {
        const stringParts = []
        d.split(' ').map((word, index) => {
        // Join any word that is 4 characters or less to the previous word, unless the previous word is over 11 characters long
          if (index > 0 && word?.length <= 5 && d.split(' ')[index - 1].length < 10) {
            const previousWord = d.split(' ')[index - 1]
            const combinedWords = `${previousWord} ${word}`
            stringParts.splice(stringParts.indexOf(previousWord), 1)
            stringParts.push(combinedWords)
          } else {
            stringParts.push(word)
          }
        })
        // Return HTML for each string part
        return stringParts.map(stringPart => {
          return `<tspan x="0" dy="1.2em">${stringPart}</tspan>`
        }).join('')
      })
      .style('text-anchor', 'middle')

    this.colourFn = scaleOrdinal()
      .domain(this.legendItemSubGroups)
      .range(this.chartColorsOrderedByDataKey)

    svg.append('g')
      .selectAll('g')
      .data(stackedData)
      .enter().append('g')
      .attr('fill', d => this.colourFn(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('x', d => x(d.data.key))
      .attr('y', d => y(d[1]))
      .attr('height', d => y(d[0]) - y(d[1]))
      .attr('width', this.svgColumnWidth)
      .attr('class', d => d.rectClass)
      // Align bar to middle of axis label:
      .style('transform', 'translate(5px, 0)')
      .on('click', d => {
        if (!this.disableHover) {
          this.setTetherTarget(d.rectClass, true)
        }
      })
      .on('mouseenter', d => {
        if (!this.disableHover) {
          this.setTetherTarget(d.rectClass)
          this.selectionCount = d[1] - d[0]
        }
      })
      .on('mouseout', () => {
        if (!this.disableHover) {
          this.setTetherTarget()
        }
      })
  }
}
