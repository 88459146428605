import AuthenticatedRoute from '../authenticated-route'
import { inject } from '@ember/service'

export default class PageNotFoundRoute extends AuthenticatedRoute {
  @inject router

  beforeModel (transition) {
    super.beforeModel(...arguments)

    transition.abort()
    this.router.transitionTo('base.not-found')
  }
}
