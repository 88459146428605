import Ember from 'ember'

const { computed, get } = Ember

export default Ember.Component.extend({
  tagName: 'ul',
  classNames: ['c-workflow-menu'],
  ariaRole: 'navigation',
  activeMenuItem: computed('menuItems', 'activeRoute', function () {
    return get(this, 'menuItems').find((item) => {
      return get(this, 'activeRoute').indexOf(item.path) === 0
    })
  })
})
