import AuthenticatedRoute from '../../authenticated-route'
import { inject } from '@ember/service'
import { SETTINGS_TABS } from '../../../constants/links'
import { action, set } from '@ember/object'

export default class CustomFieldsSettingsRoute extends AuthenticatedRoute {
  @inject warden

  routePermissions = this.warden.flattenPermissions(SETTINGS_TABS, 'base.settings.custom-fields')

  @action
  onSearchQueryChange () {
    set(this, 'q', null)
  }
}
