import Controller from '@ember/controller'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class BaseHomeReportsServiceOverviewController extends Controller {
  queryParams = ['selectedMonth', 'selectedYear']
  @tracked selectedMonth
  @tracked selectedYear

  setReportsController (reportsController) {
    this.reportsController = reportsController
  }

  @action getServiceOverviewMonthYearFilters (selectedMonth, selectedYear) {
    this.reportsController.selectedMonth = selectedMonth
    this.reportsController.selectedYear = selectedYear
  }
}
