import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'
import { kebabCase } from 'lodash'

export default class AddonSummary extends Component {
  @inject router
  @inject warden
  @tracked isExpanded = false

  get dialChartIdentifier () {
    return kebabCase(`dial-chart-${this.args?.insight?.programmeName}-${this.args?.insight?.clientName}`)
  }

  get isAdmin () {
    return this.warden.canAny(['view__programme_insights__admin'])
  }

  get dialChartTranslations () {
    return {
      lineOne: 'programme_insights.dial_chart_line_1',
      lineTwo: 'programme_insights.approved',
      hover: 'programme_insights.supplier_or_suppliers',
      passed: 'programme_insights.approved',
      failed: 'programme_insights.not_approved'
    }
  }

  get dialChartIcon () {
    return {
      passed: { dialIcon: 'tick', iconColor: 'green' },
      failed: { dialIcon: 'error-outline', iconColor: 'red' }
    }
  }

  @action
  toggleDetails () {
    this.isExpanded = !this.isExpanded
  }

  @action
  viewSuppliersInProgrammeSearch (clientCompany, programmeId) {
    const internalUserFilters = {
      programme: [programmeId.toString()]
    }

    const adminUserFilter = {
      clientCompany: [clientCompany.toString()],
      programme: [programmeId.toString()]
    }

    this.router.transitionTo('base.home.search.programme', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.isAdmin ? adminUserFilter : internalUserFilters)
      }
    })
  }
}
