import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { ORDERED_CHART_COLORS } from 'client/constants/values'
import { axisBottom, axisLeft } from 'd3-axis'
import { format } from 'd3-format'
import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale'
import { select } from 'd3-selection'
import { stack } from 'd3-shape'

export default class ChartsBarComponent extends Component {
  @tracked height
  @tracked width
  @tracked marginBottom = 50
  @tracked marginLeft = 25
  @tracked marginTop = 50
  @tracked colourFn = () => {}
  @tracked mobileWidth = 430
  @tracked isMobile = false
  @tracked barChartIdentifier
  @tracked tetherTargetTranslations
  @tracked tetherTarget
  @tracked disableHover = false
  @tracked sectionAuditCount
  @tracked sectionAuditDates
  @tracked showAuditDue = false
  @tracked withToolTip
  @tracked showLegendTitle = false
  @tracked selectedKey

  barChartId = 0

  @inject intl

  resizeFunc = this.setDimensions.bind(this)

  constructor () {
    super(...arguments)
    this.setDimensions()
    this.legendTitle = this.args?.translations?.legendTitle
    this.legendItemOneLabel = this.args?.translations?.legendItemOneLabel
    this.legendItemTwoLabel = this.args?.translations?.legendItemTwoLabel
    this.tetherTargetTranslations = this.args?.tetherTargetTranslations
    window.addEventListener('resize', this.resizeFunc)
  }

  setTetherTarget (rectClass, disableHover = false) {
    if (rectClass) {
      const selectorString = `#${this.args.barChartIdentifier} .${rectClass}`
      this.tetherTarget = select(document.querySelector(selectorString)).node()
    } else {
      this.tetherTarget = null
    }

    setTimeout(() => {
      this.disableHover = disableHover
    }, 50)
  }

  @action
  close () {
    if (this.disableHover) {
      this.setTetherTarget(null, false)
    }
  }

  willDestroy () {
    window.removeEventListener('resize', this.resizeFunc)
  }

  get totalHeight () {
    return this.height + this.marginBottom + this.marginTop
  }

  get totalWidth () {
    return this.width + this.marginLeft
  }

  get legendOneColour () {
    return this.colourFn(this.args?.legendDataSubgroups[0])
  }

  get legendTwoColour () {
    return this.colourFn(this.args?.legendDataSubgroups[1])
  }

  yearMonthToString (yearmonth) {
    const year = yearmonth.slice(0, 4)
    const month = yearmonth.slice(4)
    const date = new Date(year, month - 1, 1)
    return this.intl.formatDate(date, { month: 'short', year: '2-digit' })
  }

  setDimensions () {
    if (window.innerWidth < this.mobileWidth) {
      this.isMobile = true
      this.marginLeft = 15
      this.marginBottom = 75
      this.height = 300 - this.marginBottom - this.marginTop
      this.width = 180 - this.marginLeft
    } else {
      this.isMobile = false
      this.marginLeft = 25
      this.marginTop = 50
      this.height = (this.args.height || 250) - this.marginBottom - this.marginTop
      this.width = (this.args.width || 300) - this.marginLeft
    }
  }

  incrementClass () {
    const classString = `rect_${this.barChartId += 1}`
    return classString
  }

  @action
  drawChart (element) {
    const svg = select(element)
      .append('g')
      .attr('transform', `translate(${this.marginLeft}, ${this.marginBottom})`)

    const stackedData = stack()
      .keys(this.args.legendDataSubgroups)(this.args.data).map((data, index) => {
        data.map(d => {
          d.rectClass = this.incrementClass()
          d.key = this.args.legendDataSubgroups[index]
          return d
        })
        return data
      })

    const maxYVal = stackedData.reduce((max, row) => {
      const colMax = Math.max(...row.map(col => col[1]))
      max = colMax > max ? colMax : max
      return max
    }, 0)

    const y = scaleLinear()
      .domain([0, maxYVal])
      .range([this.height, 0])

    // Define the ticks we want to use on the y-access. They should be integers only
    const yAxisTicks = y.ticks()
      .filter(tick => Number.isInteger(tick))

    svg.append('g')
      .classed('timeline-graph__yaxis', true)
      .call(axisLeft(y).tickValues(yAxisTicks).tickSizeInner(-this.width - 3).tickPadding(6).tickSizeOuter(0).tickFormat(format('d')))

    const x = scaleBand()
      .domain(this.args.data.map(d => d.year_month))
      .range([0, this.width])
      .padding(0.4)

    svg.append('g')
      .classed('timeline-graph__xaxis', true)
      .attr('transform', `translate(0, ${this.height})`)
      .call(axisBottom(x).tickSizeOuter(0).tickFormat(this.yearMonthToString.bind(this)).tickValues(x.domain().filter((d, i) => {
        if (this.args.data.length > 6 && this.isMobile) {
          return !(i % 2)
        }
        return true
      })))
      .selectAll('text')
      // Break tick label onto two lines:
      .html((d) => {
        const dateStringParts = this.yearMonthToString(d).split(' ')
        if (dateStringParts.length === 2) {
          return `<tspan x="0" dy="1.2em">${dateStringParts[0]}</tspan><tspan x="0" dy="1.1em">${dateStringParts[1]}</tspan>`
        } else {
          return this.yearMonthToString(d)
        }
      })
      .style('text-anchor', 'middle')

    this.colourFn = scaleOrdinal()
      .domain(this.args.legendDataSubgroups)
      // Currently will map up to these three colours to the corresponding subgroup.
      // I.e. If there are 2 subgroups/legends, it will map to the first two colours, and so on.
      // If you want more legends, add more colours to this list.
      .range(ORDERED_CHART_COLORS)

    svg.append('g')
      .selectAll('g')
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .enter().append('g')
      .attr('fill', d => this.colourFn(d.key))
      .selectAll('rect')
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(d => d)
      .enter().append('rect')
      .attr('x', d => x(d.data.year_month))
      .attr('y', d => y(d[1]))
      .attr('height', d => y(d[0]) - y(d[1]))
      .attr('width', 10)
      .attr('class', d => d.rectClass)
      // Align bar to middle of axis label:
      .style('transform', 'translate(5px, 0)')
      .on('click', d => {
        if (!this.disableHover) {
          this.setTetherTarget(d.rectClass, true)
        }
      })
      .on('mouseover', d => {
        if (!this.disableHover) {
          this.setTetherTarget(d.rectClass)
          this.sectionAuditCount = d.data[d.key]
          this.sectionAuditDates = this.yearMonthToString(d.data.year_month)
          this.selectedKey = d.key
        }
      })
      .on('mouseleave', () => {
        if (!this.disableHover) {
          this.setTetherTarget()
        }
      })
  }
}
