import Helper from '@ember/component/helper'
import { inject } from '@ember/service'

/**
 * Usage example:
 * {{#if (feature-flag-set "FARM_DAIRY_REPORTS")}}
 *   CODE TO TOGGLE HERE
 * {{/if}}
 */
export default Helper.extend({
  featureFlags: inject(),

  compute ([name]) {
    return this.featureFlags.flagSet(name)
  }
})
