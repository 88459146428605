import Component from '@ember/component'
import { set } from '@ember/object'
import { inject } from '@ember/service'

export default Component.extend({
  tagName: '',

  remoteMethods: inject('generic-search-remote-methods'),

  newQueryName: null,

  init () {
    this._super(...arguments)
    set(this, 'newQueryName', '')
  },

  didRender () {
    const elem = document.getElementById('saveSearchNameInput')
    if (elem !== null) {
      elem.focus()
    }
  },

  actions: {
    saveQuery () {
      // Save a new query
      const saveQuery = Object.assign({}, this.currentSearchQuery)
      this.remoteMethods.saveQuery(saveQuery, this.newQueryName)
        .then((queryId) => {
          if (this.onSave) {
            this.onSave({
              queryId,
              query: this.currentSearchQuery,
              name: this.newQueryName
            })
          }
        })
    },
    keyDown (e) {
      // Save when the enter key is pressed
      if (e.keyCode === 13 && this.newQueryName.length) {
        this.send('saveQuery')
      }
    }
  }
})
