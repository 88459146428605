/* global Raygun */

import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { CUSTOM_FIELD_LIST_TYPE_ID } from '../../constants/values'
import { getState, hasErrors, updateState, validate, validateState } from '../../libs/local-dynamic-forms-utils'

class State {
  // Variable names match what is required by our back-end
  // eslint-disable-next-line camelcase
  @tracked field_type
  // eslint-disable-next-line camelcase
  @tracked field_name
  // eslint-disable-next-line camelcase
  @tracked include_in_search
  // eslint-disable-next-line camelcase
  @tracked allowable_values = [null] // Must have a least one element so the minimum number of repeats shown
}

const CUSTOM_FIELD_LIST_TYPE_STRING = String(CUSTOM_FIELD_LIST_TYPE_ID)

export default class CustomFieldEditComponent extends Component {
  @inject customFieldsRemoteMethods
  @inject intl
  @inject toastMessages
  @inject router

  @tracked showModal = false

  formElements = {
    field_type: { name: 'field_type', label: this.intl.t('settings.custom_fields.custom_field_form.type'), clientValidationFunc: validate(this.intl).notEmpty },
    field_name: { name: 'field_name', label: this.intl.t('settings.custom_fields.custom_field_form.name'), clientValidationFunc: validate(this.intl).notEmpty },
    include_in_search: { name: 'include_in_search', label: this.intl.t('settings.custom_fields.custom_field_form.include_in_search') },
    fieldValuesHeading: { markup: this.intl.t('settings.custom_fields.custom_field_form.values_heading') },
    allowable_values: {
      name: 'allowable_values',
      label: 'Lookup options',
      minRepeats: 1,
      repeatable: true,
      repeatButtonText: '', // doesn't get rendered
      formElements: [{ name: 'allowableValue', label: 'Option value', type: 'text', clientValidationFunc: validate(this.intl).notEmpty }]
    }
  }

  initialState = new State()
  internalState = new State()
  internalErrors = new State()

  constructor () {
    super(...arguments)
    this.formElements.field_type.options = this.args.fieldTypes
    if (this.args.customField) {
      this.stateKeys.forEach(key => {
        if (this.args.customField[key] != null) {
          // set both the working internal state and the initial state
          this.internalState[key] = this.args.customField[key]
          this.initialState[key] = this.args.customField[key]
        }
      })

      // Can't change the type on an edit
      this.formElements.field_type.readOnly = true
    } else {
      // Default include_in_search to true
      this.initialState.include_in_search = true
      this.internalState.include_in_search = true
    }
  }

  get stateKeys () {
    return Object.keys(Object.getPrototypeOf(this.internalState))
  }

  /**
   * Calculates the state on request
   * Includes values from `this.internalState` and errors from `this.internalErrors`
   * @returns {Object}
   */
  get state () {
    const dfState = getState(this.stateKeys, this.internalState, this.formElements, this.internalErrors)
    if (this.internalState.field_type != null) {
      // Need to add the 'selectedOption' to the values
      // REFACTOR: Note, I think this double `field_type` is messy and could be removed. It's some work though, and this is
      // working in a few places, so :shrug: - would be a nice tidy-up refactor.
      dfState.field_type.field_type[0].selectedOption = {
        label: this.args.fieldTypes.find(ft => String(ft.value) === String(this.internalState.field_type)).label
      }
    }
    return dfState
  }

  get isListTypeChosen () {
    return this.internalState.field_type === CUSTOM_FIELD_LIST_TYPE_STRING
  }

  get addedValues () {
    if (!this.args.customField) return []
    return this.internalState.allowable_values.filter(oVal => !this.initialState.allowable_values.includes(oVal))
  }

  get deletedValues () {
    if (!this.args.customField) return []
    return this.initialState.allowable_values.filter(oVal => !this.internalState.allowable_values.includes(oVal))
  }

  validate () {
    // Warning: mutates this.internalErrors
    validateState(this.stateKeys, this.formElements, this.internalState, this.internalErrors)

    // Special cases
    // 1. Remove errors for non-completed allowable_values if Lookup List not selected
    if (this.internalState.field_type !== CUSTOM_FIELD_LIST_TYPE_STRING) {
      this.internalErrors.allowable_values = []
    }

    // 2. Allowable fields can't have duplicate values
    if (this.internalState.field_type === CUSTOM_FIELD_LIST_TYPE_STRING) {
      const valueSet = {}
      this.internalState.allowable_values.forEach((value, i) => {
        const error = {
          type: 'DUPLICATE',
          description: this.intl.t('errors.DUPLICATE')
        }
        if (valueSet[value]) {
          if (Array.isArray(this.internalErrors.allowable_values[i])) {
            this.internalErrors.allowable_values[i].push(error)
          } else {
            this.internalErrors.allowable_values[i] = [error]
          }
        } else {
          valueSet[value] = true
        }
      })
    }

    return hasErrors(this.stateKeys, this.internalErrors)
  }

  saveField () {
    // Because `this.internalState` doesn't have regular properties (because of @tracked), need a little shimmy to
    // construct a POJO for sending
    const stateObj = this.stateKeys.reduce((state, key) => ({ ...state, [key]: this.internalState[key] }), {})
    if (stateObj.field_type !== CUSTOM_FIELD_LIST_TYPE_STRING) {
      // Only lookup lists can have allowable_values
      delete stateObj.allowable_values
    }

    this.customFieldsRemoteMethods.saveCustomField(this.args.customField?.id, stateObj)
      .then(() => {
        this.toastMessages.success(this.intl.t('settings.custom_fields.custom_field_save'))
        this.router.transitionTo('base.settings.custom-fields')
      })
      .catch((e) => {
        this.toastMessages.danger(this.intl.t('settings.custom_fields.custom_field_save_failed'))
        Raygun.send(e)
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }

  @action
  updateLocalState (name, returnedState) {
    // Warning: `this.internalState` is mutated in this function
    updateState(name, returnedState, this.formElements, this.internalState)
  }

  @action
  saveCustomField () {
    if (!this.validate()) return

    // Values only added or deleted on edit
    if (this.addedValues.length || this.deletedValues.length) {
      this.showModal = true
    } else {
      this.saveField()
    }
  }

  @action
  closeModal () {
    this.showModal = false
  }

  @action
  confirmSave () {
    this.showModal = false
    this.saveField()
  }

  @action
  noop () {
    // Action not required for this implementation
  }
}
