import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'

const { get, computed } = Ember

export default Ember.Component.extend(DynamicElement, ComplexElement, {
  // Children element values
  assignNewNumberVal: computed.alias('formElementState.0.val.assignNewNumber.0.val'),
  assignedNumberVal: computed.alias('formElementState.0.val.assignedNumber.0.val'),

  // Shorthand properties for children form elements
  assignNewNumber: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'assignNewNumber')
  }),
  assignedNumber: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'assignedNumber')
  })
})
