import hbs from 'htmlbars-inline-precompile'
import { withKnobs } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './programme-snapshot.notes.md'

export default {
  title: 'Programme ',
  component: 'ProgrammeSnapshot',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const programmeSnapshot = () => ({
  template: hbs`
    <ContentContainer>
      <ProgrammeSnapshot></ProgrammeSnapshot>
    </ContentContainer>
  `
})
