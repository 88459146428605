import { inject } from '@ember/service'
import { camelCase } from 'lodash'
import FileSaverMixin from 'ember-cli-file-saver/mixins/file-saver'
import RemoteMethodService from './remote-methods'
export default class AuditDocumentRemoteMethods extends RemoteMethodService.extend(FileSaverMixin) {
  @inject intl
  @inject toastMessages

  auditProgrammeDocumentUrl = '/api/aqdocs/audit-programme-document'

  fetchAuditDocuments (externalAuditId, documentSource) {
    return this.request(`/api/aqdocs/get-master-job-report/${externalAuditId}/${documentSource}`)
      .then(data => {
        return data
      })
  }

  fetchAuditDocument (url, fileName) {
    return this.request((url), {
      method: 'GET',
      dataType: 'arraybuffer', // or 'blob'
      processData: false
    })
      .then(data => {
        this.saveFileAs(`${fileName}`, data)
        return data
      })
      .catch((e) => {
        this.toastMessages.danger(this.intl.t('supplier_summary.error_retrieving_documents'))
        return e
      })
  }

  async fetchListOfAuditProgrammeDocuments (casdbEnterpriseAuditId) {
    const documents = await this.requestNoHandle(`/api/aqdocs/audit-programme-documents/${casdbEnterpriseAuditId}`)
    // Python snake_case to camelCase
    documents.forEach((file, index) => {
      const metadata = documents[index].metadata

      // Make a new object using reduce to copy over the camel cased keys and values
      documents[index].metadata = Object.entries(metadata).reduce((acc, [key, value]) => {
        acc[camelCase(key)] = value || '-'
        return acc
      }, {})
    })
    return documents
  }

  fetchAuditProgrammeDocument (casdbEnterpriseAuditId, fileId, fileName) {
    // Written in old ember syntax because the filesaver mixin requires it

    return this.requestNoHandle((`${this.auditProgrammeDocumentUrl}/${casdbEnterpriseAuditId}/${fileId}`), {
      method: 'GET',
      dataType: 'arraybuffer', // or 'blob'
      processData: false
    })
      .then(data => {
        this.saveFileAs(`${fileName}`, data)
        return data
      })
  }

  async uploadAuditProgrammeDocument (casdbEnterpriseAuditId, fileName, file, metadata) {
    const url = `${this.auditProgrammeDocumentUrl}/${casdbEnterpriseAuditId}/${fileName}`
    const headers = {
      headers: {
        'X-CSRFToken': this.csrfToken,
        'Content-Disposition': `attachment; filename=${file.blob.name}`,
        Metadata: JSON.stringify(metadata)
      }
    }
    const response = await file.upload(url, headers)
    return response
  }

  async patchAuditProgrammeDocument (fileId, casdbEnterpriseAuditId, fileName, metadata) {
    const url = `${this.auditProgrammeDocumentUrl}/${casdbEnterpriseAuditId}/${fileName}`
    const headers = {
      headers: {
        'X-CSRFToken': this.csrfToken,
        Metadata: JSON.stringify(metadata),
        fileId: fileId
      }
    }
    const response = await this.patch(url, headers)
    return response
  }

  async deleteAuditProgrammeDocument (casdbEnterpriseAuditId, fileId) {
    const response = await this.delete(`${this.auditProgrammeDocumentUrl}/${casdbEnterpriseAuditId}/${fileId}`)
    return response
  }
}
