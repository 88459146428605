import Mixin from '@ember/object/mixin'
import { get, set } from '@ember/object'
import { inject } from '@ember/service'

import { task } from 'ember-concurrency'
import { Promise } from 'rsvp'

import { cloneDeep } from 'lodash'
import { DeepDiff } from 'deep-diff'

import { MODE } from 'client/constants/workflow'

/**
 * Provides actions for editing forms (eg. Save button, unsaved changes dialog)
 */
// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  dynamicFormsMethods: inject(),
  populateFunctions: inject(),
  intl: inject(),

  setupController (controller, model) {
    // Call _super for default behavior
    this._super(controller, model)
    set(controller, 'takeSnapshot', true)
  },

  updateMenu () {
    return this.dynamicFormsMethods.fetchMenu(this.controller.model.menu.formInstanceId)
      .then(menuObj => {
        set(this, 'controller.model.menu', menuObj.menu)
      })
  },

  // eslint-disable-next-line require-yield
  save: task(function * (state, updateMenu, hideSuccessBanner, successAction = () => {}) {
    const formInstance = get(this, 'controller.model.form.formInstance')
    const returnObject = {
      formInstance,
      state
    }
    return this.dynamicFormsMethods.save(returnObject, get(this, 'controller.model.form.formElements'))
      .then((state) => {
        if (!hideSuccessBanner) get(this, 'toastMessages.success')(this.intl.t('messages.saved'))
        // If a post save function has been defined in the route, call it
        const postFormSavePromise = Promise.resolve(this.postFormSaveFunction !== undefined ? this.postFormSaveFunction() : undefined)

        return postFormSavePromise.then(() => {
          if (updateMenu) {
            return this.updateMenu().then(() => successAction(state))
          } else {
            successAction(state)
          }
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error while trying to save', error)
        get(this, 'toastMessages.danger')(this.intl.t('messages.problem_saving'))
      })
  }).drop(),

  actions: {
    save (state, updateMenu = true, hideSuccessBanner = false, postSaveCallback = null) {
      if (!state) {
        if (typeof postSaveCallback === 'function') {
          postSaveCallback()
        }
      } else {
        this.save.perform(state, updateMenu, hideSuccessBanner, (newState) => {
          set(this, 'controller.takeSnapshot', true)
          set(this, 'controller.model.form.state', newState)
          if (typeof postSaveCallback === 'function') {
            postSaveCallback(newState)
          }
        })
      }
    },

    next () {
      const menu = get(this, 'controller.model.menu')
      const instanceId = get(this, 'controller.model.id')
      // eslint-disable-next-line no-console
      console.log('FIXME: navigate to next form', menu, instanceId, this.mode)
    },

    /**
     * Called by the components when a child form has been created and the side menu needs updating
     */
    updateRootState (state, updateMenu = false) {
      set(this, 'controller.model.form.state', state)
      if (updateMenu) {
        this.updateMenu()
      }
    },

    hasUnsavedChanges () {
      // This behaviour should only be triggered when transitioning away from form-based workflows in edit mode
      // Other workflows don't set the checkpointState (and probably won't have a navigation dialog)
      // so the whole application will appear broken.
      if (this.currentModel.mode !== MODE.EDIT || !(this.currentModel.templateName === 'workflow/form' || this.currentModel.templateName === 'workflow/application-form')) {
        return false
      }

      if (!get(this, 'controller.skipNavigationCheck')) {
        const isDirty = DeepDiff(get(this, 'controller.model.form.state'), this.checkpointState) !== undefined
        if (isDirty) {
          return true
        }
      }
      return false
    },

    showNavDialogue () {
      set(this, 'controller.showNavDialogue', true)
    },

    // Prevent the transition, and display the "There are unsaved changes" dialog when needed
    willTransition (transition) {
      // Hack to allow use of this hook from routes where this has been mixed in
      // (Ember doesn't call the willTransition hook in the route when one has been defined in a mixin)
      if (this.willTransitionEditFormActions !== undefined) {
        this.willTransitionEditFormActions(transition)
      }

      // This behaviour should only be triggered when transitioning away from form-based workflows in edit mode
      // Other workflows don't set the checkpointState (and probably won't have a navigation dialog)
      // so the whole application will appear broken.
      if (this.currentModel.mode !== MODE.EDIT || !(this.currentModel.templateName === 'workflow/form' || this.currentModel.templateName === 'workflow/application-form')) {
        return true
      }

      if (!get(this, 'controller.skipNavigationCheck')) {
        const isDirty = DeepDiff(get(this, 'controller.model.form.state'), this.checkpointState) !== undefined
        if (isDirty) {
          set(this, 'controller.showNavDialogue', true)
          set(this, 'transition', transition)
          // eslint-disable-next-line no-console
          console.info('Attempted to transition, but there are unsaved changes to the state.', this)
          transition.abort()
        }
      }
      // Reset the skipNavigationCheck
      if (get(this, 'controller.skipNavigationCheck') === true) {
        set(this, 'controller.skipNavigationCheck', false)
      }
    },

    setCheckpointState (checkpointState) {
      set(this, 'checkpointState', cloneDeep(checkpointState))
      set(this, 'controller.takeSnapshot', false)
    },

    cancelNavigation () {
      set(this, 'controller.showNavDialogue', false)
      set(this, 'transition', null)
    },

    // Called from the "Unsaved changes" dialog
    // Discards changes to the form state, then continues the transition which was attempted
    loseChangesAndContinue () {
      set(this, 'controller.model.form.state', this.checkpointState)
      set(this, 'controller.showNavDialogue', false)
      set(this, 'controller.skipNavigationCheck', true) // Setting this flag prevents the transition from being cancelled again
      this.transition.retry()
      set(this, 'transition', null)
    },

    // Called from the "Unsaved changes" dialog
    // Saves changes to the form state, then continues the transition which was attempted
    saveAndContinueTransition () {
      set(this, 'controller.showNavDialogue', false)
      const state = get(this, 'controller.model.form.state')
      this.save.perform(state, false, false, () => {
        set(this, 'controller.skipNavigationCheck', true) // Setting this flag prevents the transition from being cancelled again
        this.transition.retry()
        set(this, 'transition', null)
      })
    }
  }
})
