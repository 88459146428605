import Service from '@ember/service'
import config from 'client/config/environment'

export default class FeatureFlags extends Service {
  /**
   *  Is the feature flag of the given name set. Yeah or nah?
   *
   * @param name {string} Feature Flag name.
   * @returns {boolean}
   */
  flagSet (name) {
    return config.ENV_VARS.FEATURE_FLAGS.filter(f => f === name).length > 0
  }

  /**
   *  Is the feature flag of the given name unset. Yeah or nah?
   *
   * @param name {string} Feature Flag name.
   * @returns {boolean}
   */
  flagUnset (name) {
    return config.ENV_VARS.FEATURE_FLAGS.filter(f => f === name).length === 0
  }
}
