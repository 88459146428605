import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

import { get } from '@ember/object'

export default Ember.Component.extend(DynamicElement, {
  tagName: '',

  contactEmail: Ember.computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'contactEmail')
  }),

  contactConfirmEmail: Ember.computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'contactConfirmEmail')
  }),

  contactEmailValue: Ember.computed('stateValue.contactEmail.0.val', function () {
    return get(this, 'stateValue.contactEmail.0.val')
  }),

  contactConfirmEmailValue: Ember.computed('stateValue.contactConfirmEmail.0.val', function () {
    return get(this, 'stateValue.contactConfirmEmail.0.val')
  })
})
