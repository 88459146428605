import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { select } from 'd3-selection'
import { arc, pie } from 'd3-shape'
import { REPORTS_ASSESSMENT_COLORS } from 'client/constants/values'

export default class DialChartSegmentedComponent extends Component {
  @inject intl
  @inject uniqueId

  @tracked diameter
  @tracked svgContainer
  @tracked dialChartIdentifier
  @tracked tetherTarget

  @tracked showTooltip = !!this.args?.showTooltip
  @tracked tooltipIdentifier = `${this.dialChartIdentifier}-tooltip`
  @tracked tooltipColor
  @tracked tooltipTitle
  @tracked tooltipCount

  constructor () {
    super(...arguments)
    this.dialChartIdentifier = this.args.dialChartIdentifier || this.uniqueId.next()
    this.diameter = this.args.diameter || 200
    this.smallText = this.diameter < 160
    this.lineOneTranslationKey = this.args?.translations?.lineOne
    this.lineTwoTranslationKey = this.args?.translations?.lineTwo
    this.donutWidth = this.args?.donutWidth || 15
  }

  get assessmentsCount () {
    return this.args.assessmentsCount
  }

  get rows () {
    return this.args.rows
  }

  get onlyOneRowHasData () {
    // Returns true if only one row has data
    // We use this to move tooltip location to the top of chart
    return this.rows.filter(d => d.completed_audits !== 0).length === 1
  }

  get radius () {
    return this.diameter / 2
  }

  get svgBBox () {
    // Gives us the height and width of the SVG element in the DOM
    // Used to scale the tooltip x/y coordinates with the SVG
    return document.getElementById(this.dialChartIdentifier).getBBox()
  }

  @action
  drawChart (element) {
    const chartArc = arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius - this.donutWidth)

    this.svgContainer = select(element)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${this.diameter} ${this.diameter}`)
      .attr('preserveAspectRatio', 'xMinYMin')
      .select('g')
      .attr('transform', `translate(${this.diameter / 2}, ${this.diameter / 2})`)

    if (this.assessmentsCount) { // If data exists, draw pie chart
      const pieChart = pie()
        .sort(null) // disable default sorting
        .value((d) => d.completed_audits)(this.rows)

      const g = this.svgContainer.selectAll('arc')
        .data(pieChart)
        .enter().append('g')
        .attr('class', this.dialChartIdentifier + '-arc')

      g.append('path')
        .attr('d', chartArc)
        .style('fill', (d, i) => REPORTS_ASSESSMENT_COLORS[i])
        .each((d, index) => {
          const centroid = chartArc.centroid(d)
          this.rows[index].x = centroid[0] + this.radius
          this.rows[index].y = centroid[1] + this.radius
        })
      if (this.showTooltip) {
        g.on('focus', (d, index) => {
          this.createToolTip(index)
        })
          .on('click', (d, index) => {
            this.createToolTip(index)
          })
          .on('blur', () => {
            this.hideToolTip()
          })
          .on('mouseenter', (d, index) => {
            this.createToolTip(index)
          })
          .on('mouseout', () => {
            this.hideToolTip()
          })
      }
    } else { // Create an empty transparent grey circle if no data is present
      const path = this.svgContainer
        .append('path')
        .attr('tabindex', '0')
      const angle = (Math.PI * 2)
      const arcFunction = chartArc
        .startAngle(0)
        .endAngle(angle)
      path.style('fill', '#444444')
      path.style('opacity', 0.5)
      path.attr('d', arcFunction())
    }
  }

  createToolTip (index) {
    const data = this.rows[index]
    this.tooltipColor = data.color
    this.tooltipTitle = data.description
    this.tooltipCount = data.completed_audits
    const tooltip = document.getElementById(this.tooltipIdentifier)
    tooltip.style.display = 'block'
    if (this.onlyOneRowHasData) { // If there is no second row of data, move tooltip to top of circle
      tooltip.style.top = 10 + 'px'
      tooltip.style.left = data.x + 'px'
    } else {
      tooltip.style.top = (data.y * (this.svgBBox.height / this.diameter)) + 'px'
      tooltip.style.left = (data.x * (this.svgBBox.width / this.diameter)) + 'px'
    }
  }

  hideToolTip () {
    const tooltip = document.getElementById(this.tooltipIdentifier)
    tooltip.style.display = 'none'
  }
}
