import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'

export default Ember.Component.extend(DynamicElement, ComplexElement, {
  classNameBindings: ['formElement.repeatable:c-view-section', 'formElementName'],

  // Insert the name of this formElement as a css class for hacky styling
  formElementName: Ember.computed('formElement.name', function () {
    return 'c-view-section--' + Ember.String.dasherize(Ember.get(this, 'formElement.name'))
  })
})
