import Ember from 'ember'
const { get } = Ember

export function getSubstate ([state, formElement, index]) {
  if (!state || !Object.keys(state).length) return

  const wholeComponentState = get(state, formElement.name)
  if (wholeComponentState == null) return

  return wholeComponentState[index].val
}

export default Ember.Helper.helper(getSubstate)
