import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'

export default class BaseSupplierFarmMapPrintController extends Controller {
   queryParams = ['selectedFeatures', 'boundingBox']
   @tracked selectedFeatures
   @tracked boundingBox

   get selectedFeaturesDecoded () {
     // Turn query params back into arrays
     return this.selectedFeatures ? this.selectedFeatures.split(',') : []
   }

   get boundingBoxDecoded () {
     // Turns bounding box string into nested arrays with 2 entries each for Leaflet.
     const coordinates = this.boundingBox ? this.boundingBox.split(',') : []
     const rawBounds = []
     const size = 2
     while (coordinates.length > 0) {
       rawBounds.push(coordinates.splice(0, size))
     }
     return rawBounds
   }
}
