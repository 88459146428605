/* global L */

/**
 * Loads in this plugin to prevent Leaflet maps from immediately capturing the cursor.
 *
 * This fixes a problem when the user is scrolling down a screen and when the cursor
 * enters the map it switches into zooming mode. It will use a small grace period
 * before capturing the cursor.
 *
 * Because of the way this plugin is initialised it's difficult to import it using NPM,
 * so it has been copied into this initializer. Defaults (see ~line 72) have been
 * tweaked to not show a message or make the map slightly transparent when not focused.
 *
 * These settings can be configured when you initalise the Leaflet Map object.
 *
 * See: https://github.com/CliffCloud/Leaflet.Sleep
 */

export function initialize () {
  L.Control.SleepMapControl = L.Control.extend({

    initialize: function (opts) {
      L.setOptions(this, opts)
    },

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    options: {
      position: 'topright',
      prompt: 'disable map',
      styles: {
        backgroundColor: 'white',
        padding: '5px',
        border: '2px solid gray'
      }
    },

    buildContainer: function () {
      var self = this
      var container = L.DomUtil.create('p', 'sleep-button')
      var boundEvent = this._nonBoundEvent.bind(this)
      container.appendChild(document.createTextNode(this.options.prompt))
      L.DomEvent.addListener(container, 'click', boundEvent)
      L.DomEvent.addListener(container, 'touchstart', boundEvent)

      Object.keys(this.options.styles).map(function (key) {
        container.style[key] = self.options.styles[key]
      })

      return (this._container = container)
    },

    onAdd: function () {
      return this._container || this.buildContainer()
    },

    _nonBoundEvent: function (e) {
      L.DomEvent.stop(e)
      if (this._map) this._map.sleep._sleepMap()
      return false
    }

  })

  L.Control.sleepMapControl = function () {
    return new L.Control.SleepMapControl()
  }

  /*
   * The Sleep Handler
   */

  // Defaults.
  L.Map.mergeOptions({
    sleep: true,
    sleepTime: 750,
    wakeTime: 750,
    wakeMessageTouch: 'Touch to Wake',
    sleepNote: false,
    hoverToWake: true,
    sleepOpacity: 1,
    sleepButton: L.Control.sleepMapControl
  })

  L.Map.Sleep = L.Handler.extend({

    addHooks: function () {
      this.sleepNote = L.DomUtil.create('p', 'sleep-note', this._map._container)
      this._enterTimeout = null
      this._exitTimeout = null

      /*
       * If the device has only a touchscreen we will never get
       * a mouseout event, so we add an extra button to put the map
       * back to sleep manually.
       *
       * a custom control/button can be provided by the user
       * with the map's `sleepButton` option
       */
      this._sleepButton = this._map.options.sleepButton()

      if (this._map.tap) {
        this._map.addControl(this._sleepButton)
      }

      var mapStyle = this._map._container.style
      mapStyle.WebkitTransition += 'opacity .5s'
      mapStyle.MozTransition += 'opacity .5s'

      this._setSleepNoteStyle()
      this._sleepMap()
    },

    removeHooks: function () {
      if (!this._map.scrollWheelZoom.enabled()) {
        this._map.scrollWheelZoom.enable()
      }
      L.DomUtil.setOpacity(this._map._container, 1)
      L.DomUtil.setOpacity(this.sleepNote, 0)
      this._removeSleepingListeners()
      this._removeAwakeListeners()
    },

    _setSleepNoteStyle: function () {
      var noteString = ''
      var style = this.sleepNote.style

      if (this._map.tap) {
        noteString = this._map.options.wakeMessageTouch
      } else if (this._map.options.wakeMessage) {
        noteString = this._map.options.wakeMessage
      } else if (this._map.options.hoverToWake) {
        noteString = 'click or hover to wake'
      } else {
        noteString = 'click to wake'
      }

      if (this._map.options.sleepNote) {
        this.sleepNote.appendChild(document.createTextNode(noteString))
        style.pointerEvents = 'none'
        style.maxWidth = '150px'
        style.transitionDuration = '.2s'
        style.zIndex = 5000
        style.opacity = '.6'
        style.margin = 'auto'
        style.textAlign = 'center'
        style.borderRadius = '4px'
        style.top = '50%'
        style.position = 'relative'
        style.padding = '5px'
        style.border = 'solid 2px black'
        style.background = 'white'

        if (this._map.options.sleepNoteStyle) {
          var noteStyleOverrides = this._map.options.sleepNoteStyle
          Object.keys(noteStyleOverrides).map(function (key) {
            style[key] = noteStyleOverrides[key]
          })
        }
      }
    },

    _wakeMap: function (e) {
      this._stopWaiting()
      this._map.scrollWheelZoom.enable()
      if (this._map.tap) {
        this._map.touchZoom.enable()
        this._map.dragging.enable()
        this._map.tap.enable()
        this._map.addControl(this._sleepButton)
      }
      L.DomUtil.setOpacity(this._map._container, 1)
      this.sleepNote.style.opacity = 0
      this._addAwakeListeners()
    },

    _sleepMap: function () {
      this._stopWaiting()
      this._map.scrollWheelZoom.disable()

      if (this._map.tap) {
        this._map.touchZoom.disable()
        this._map.dragging.disable()
        this._map.tap.disable()
        this._map.removeControl(this._sleepButton)
      }

      L.DomUtil.setOpacity(this._map._container, this._map.options.sleepOpacity)
      this.sleepNote.style.opacity = 0.4
      this._addSleepingListeners()
    },

    _wakePending: function () {
      this._map.once('mousedown', this._wakeMap, this)
      if (this._map.options.hoverToWake) {
        var self = this
        this._map.once('mouseout', this._sleepMap, this)
        self._enterTimeout = setTimeout(function () {
          self._map.off('mouseout', self._sleepMap, self)
          self._wakeMap()
        }, self._map.options.wakeTime)
      }
    },

    _sleepPending: function () {
      var self = this
      self._map.once('mouseover', self._wakeMap, self)
      self._exitTimeout = setTimeout(function () {
        self._map.off('mouseover', self._wakeMap, self)
        self._sleepMap()
      }, self._map.options.sleepTime)
    },

    _addSleepingListeners: function () {
      this._map.once('mouseover', this._wakePending, this)
      this._map.tap &&
        this._map.once('click', this._wakeMap, this)
    },

    _addAwakeListeners: function () {
      this._map.once('mouseout', this._sleepPending, this)
    },

    _removeSleepingListeners: function () {
      this._map.options.hoverToWake &&
        this._map.off('mouseover', this._wakePending, this)
      this._map.off('mousedown', this._wakeMap, this)
      this._map.tap &&
        this._map.off('click', this._wakeMap, this)
    },

    _removeAwakeListeners: function () {
      this._map.off('mouseout', this._sleepPending, this)
    },

    _stopWaiting: function () {
      this._removeSleepingListeners()
      this._removeAwakeListeners()
      var self = this
      if (this._enterTimeout) clearTimeout(self._enterTimeout)
      if (this._exitTimeout) clearTimeout(self._exitTimeout)
      this._enterTimeout = null
      this._exitTimeout = null
    }
  })

  L.Map.addInitHook('addHandler', 'sleep', L.Map.Sleep)
}

export default {
  name: 'leaflet-sleep-initializer',
  initialize: initialize
}
