import AuthenticatedRoute from '../../../authenticated-route'

export default class HomeReportsRoute extends AuthenticatedRoute {
  beforeModel (transition) {
    // This only gets called when we're already on the base.home.reports route, and hence beforeModel hook in
    // base/home/reports.js does not get called. That beforeModel hook will redirect you to the correct child page
    // This can only get called when someone clicks on the search tab again. Abort that transition and leave them
    // where they are.
    transition.abort()
  }
}
