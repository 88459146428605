/* global Raygun */

import { tracked } from '@glimmer/tracking'
import FullScreenMap from '..'

export default class Print extends FullScreenMap {
  @tracked specifiedZoomLevel
  @tracked selectedFeatures
  constructor () {
    super(...arguments)

    this.specifiedBoundingBox = this.args.boundingBox
    this.selectedFeatures = this.args.selectedFeatures
  }

  updateBoundsFromQueryParams () {
    if (this.specifiedBoundingBox) {
      this.farmMaps.map.fitBounds(this.specifiedBoundingBox)
    }
  }

  get displayLegend () {
    return this.farmMaps.farmHasFeatures
  }

  async initMap () {
    // MAP_PRINT_PAGE_LOADED flag is used for the PDF generator.
    // Screenshot of page will not occur until the flag becomes true.
    document.MAP_PRINT_PAGE_LOADED = false

    if (!this.farmMaps.farmData || !this.farmMaps.config) {
      Raygun.send('Attempted to generate a map PDF, but there was no map data')
    }

    try {
      await this.farmMaps.fetchMapData(this.enterpriseClientId)
      this.farmMaps.filterFeaturesFromQueryParams(this.selectedFeatures)
      this.farmMaps.createMap(this.divId, this.tilePath)
      this.farmMaps.addFeatures()
      this.updateBoundsFromQueryParams()

      this.farmMaps.map.whenReady(() => {
        document.MAP_PRINT_PAGE_LOADED = true
      })
    } catch (e) {
      Raygun.send(e)
    }
  }
}
