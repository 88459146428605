import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import RSVP from 'rsvp'
import { VIRUS_DETECTED_MESSAGE, VIRUS_SCAN_CLIENT_REFRESH_RATE } from '../../../../constants'

const { get, inject, set } = Ember

export default Ember.Component.extend(DynamicElement, {
  classNames: ['c-view-text'],
  filesService: inject.service('files'),

  init () {
    this._super(...arguments)

    // Init values
    set(this, 'file', false) // File metadata
    set(this, 'loading', false) // Loading file metadata
    set(this, 'error', false) // An error retrieving file metadata
    set(this, 'avScanStatus', null) // Current antivirus scan status

    // Load file metadata if set
    const fileId = get(this, 'stateValue')
    if (fileId) {
      set(this, 'loading', true)
      this.loadFileMetadata()
    }
  },

  scanning: Ember.computed('file.status', 'loading', 'uploading', function () {
    return !this.loading && !this.uploading && this.file && !this.file.status
  }),

  // Load file metadata
  loadFileMetadata () {
    if (this.isDestroyed === true) {
      return
    }
    const fileId = get(this, 'stateValue')
    return get(this, 'filesService').getInfo(fileId)
      .then((file) => {
        this.set('file', file)
        set(this, 'loading', false)

        // keep re-fetching the metadata until the file has been scanned
        if (!file.status) {
          return new RSVP.Promise(resolve => window.setTimeout(resolve, VIRUS_SCAN_CLIENT_REFRESH_RATE))
            .then(() => this.loadFileMetadata())
        }

        if (file.status === 'INFECTED') {
          set(this, `state.${this.formElement.name}.0.errors`, [{
            description: VIRUS_DETECTED_MESSAGE,
            type: 'INFECTED'
          }])
          // Hack to get the validation message to update after setting the above value, which is deep down in the state tree
          set(this, 'state', Ember.copy(this.state))
        }
      })
      .catch(err => {
        console.error(err)
        set(this, 'error', 'Failed to load file information')
        set(this, 'loading', false)
      })
  },

  actions: {
    /**
     * Download the file
     * @param {Number} id
     */
    download (id) {
      get(this, 'filesService').download(id)
    }
  }
})
