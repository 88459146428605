import { get } from '@ember/object'
import CacheMixin from '../mixins/cache'
import RemoteMethod from '../services/remote-method'

export default RemoteMethod.extend(CacheMixin, {

  search (query) {
    const qs = this.buildQueryString({ q: query, includePlaces: true })

    const cache = get(this, 'cache')
    const cacheKey = ['search', qs].join('_')
    const cachedResult = cache.fetch(cacheKey)
    if (cachedResult) return cachedResult

    const request = this.request(`esri-address/search-physical/?${qs}`)
    cache.save(cacheKey, request)
    return request
      .then(response => {
        if (response.error !== false) {
          cache.flush(cacheKey)
        }
        return response.data
      })
  },

  getCoordinates (text, key) {
    const qs = this.buildQueryString({ text, key })

    const cache = get(this, 'cache')
    const cacheKey = ['getCoordinates', qs].join('_')
    const cachedResult = cache.fetch(cacheKey)
    if (cachedResult) return cachedResult

    const request = this.request(`esri-address/get-coordinates/?${qs}`)
    cache.save(cacheKey, request)
    return request
      .then(response => {
        if (response.error !== false) {
          cache.flush(cacheKey)
        }
        return response.data
      })
  }
})
