import Component from '@glimmer/component'
import Table from 'ember-light-table'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { action, setProperties } from '@ember/object'
import RouteAction from 'client/helpers/route-action'
import { sortBy } from 'lodash'

export default class SupplierSummary extends Component {
  @inject nonConformanceRemoteMethods
  @inject router
  @inject intl
  @inject dynamicFormsMethods
  @inject warden

  @tracked columns = []
  @tracked table
  @tracked nonConformances = [];
  @tracked enterpriseClientId
  @tracked sortDirection = 'asc'
  @tracked columnKey = 'programme'
  @tracked currentPage = 1
  @tracked maxPage = 1
  @tracked pageLength = 10
  @tracked activePage = 'all'

  get sortedRows () {
    let sortedRows = []
    if (this.sortDirection === 'desc') {
      sortedRows = sortBy(this.nonConformances, item => item[this.columnKey])
    } else {
      sortedRows = sortBy(this.nonConformances, item => item[this.columnKey]).reverse()
    }
    return sortedRows
  }

  setRows () {
    let pageCount = 0
    const allPages = [...this.sortedRows].reduce((pages, row, rowIndex) => {
      if (rowIndex % this.pageLength === 0) {
        // sets pageCount based on pageLength - defined in controller
        pageCount = rowIndex / this.pageLength
      }
      if (!pages[pageCount]) {
        // creates empty array if pageCount index does not exist
        pages.push([])
      }
      // pushes row to page
      pages[pageCount].push(row)
      return pages
    }, [])
    this.setMaxPage(this.nonConformances)
    this.table.setRows(allPages[this.currentPage - 1])
  }

  fullColumns = [
    {
      label: this.intl.t('non_conformance_table.programme'),
      valuePath: 'programme',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      width: '120px',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.type'),
      valuePath: 'type',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.section'),
      valuePath: 'section',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.due_date'),
      valuePath: 'due_date',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.closed_date'),
      valuePath: 'closed_date',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      valuePath: 'value',
      cellComponent: 'view-non-conformance',
      width: '50px',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      sortable: false
    }
  ]

  restrictedColumns = [
    {
      label: this.intl.t('non_conformance_table.programme'),
      valuePath: 'programme',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      width: '120px',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.category'),
      valuePath: 'category',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.question'),
      valuePath: 'question_text',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.closed_date'),
      valuePath: 'closed_date',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      label: this.intl.t('non_conformance_table.comments'),
      valuePath: 'comments',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      cellComponent: 'tooltip-cell',
      ascending: false
    },
    {
      valuePath: 'value',
      cellComponent: 'view-non-conformance',
      width: '50px',
      classNames: 'c-table__cell c-table__cell--heading',
      cellClassNames: 'c-table__cell c-table__row--clickable',
      sortable: false
    }
  ]

  constructor () {
    super(...arguments)
    if (this.warden.can('view__supplier_profile__non_conformances_restricted')) {
      this.columns = this.restrictedColumns
    } else {
      this.columns = this.fullColumns
    }

    this.table = Table.create({ columns: this.columns, rows: [], enableSync: true })
    this.enterpriseClientId = RouteAction.create(this).compute(['getParentId'])()
    this.nonConformanceRemoteMethods.fetchNonConformances(this.enterpriseClientId).then((nonConformances) => {
      this.nonConformances = nonConformances
      this.setRows()
    })
  }

  @action
  filterNonConformances (isOpen) {
    let targetPage
    if (isOpen != null) {
      targetPage = isOpen === true ? 'open' : 'closed'
    } else {
      targetPage = 'all'
    }

    if (targetPage === this.activePage) {
      return
    }
    this.currentPage = 1
    return this.nonConformanceRemoteMethods.fetchNonConformances(this.enterpriseClientId, isOpen).then((nonConformances) => {
      this.nonConformances = nonConformances
      this.setRows()
      this.activePage = targetPage
    })
  }

  @action
  navigateToNonConformance (ncFormId) {
    const transitionData = { id: ncFormId }
    this.dynamicFormsMethods.transitionToForm(transitionData)
  }

  @action
  setPage (page) {
    this.currentPage = page
    this.setRows()
  }

  onColumnClick = (column) => {
    if (column.sorted) {
      setProperties(this, {
        columnKey: column.valuePath,
        sortDirection: column.ascending ? 'asc' : 'desc'
      })
      this.setRows()
    }
  }

  setMaxPage (rows) {
    const totalPages = rows?.length / this.pageLength
    this.maxPage = Math.ceil(totalPages)
  }
}
