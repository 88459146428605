import { action, set } from '@ember/object'
import TabbedSearchController from 'client/mixins/controllers/tabbed-search-controller'
import { inject } from '@ember/service'

export default class BaseMapSearchProgrammeController extends TabbedSearchController {
  @inject warden

  get searchTypeKey () {
    const isDairyRestricted = this.warden.canAny(['view__search__programme_search__dairy_restricted'])
    return isDairyRestricted ? 'search-programmes-dairy-restricted' : 'search-programmes'
  }

  @action
  onSearchQueryChange () {
    set(this, 'q', null)
  }
}
