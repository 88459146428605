import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text, object } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './breadcrumbs.notes.md'

export default {
  title: 'Breadcrumbs',
  component: 'Breadcrumbs',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const singleCrumb = () => ({
  template: hbs`<Breadcrumbs @crumbs={{crumbs}} />`,
  context: {
    crumbs: [{ label: 'Crumb 1' }]
  }
})

export const multiCrumb = () => ({
  template: hbs`<Breadcrumbs @crumbs={{crumbs}} />`,
  context: {
    crumbs: [
      {
        label: text('Crumb 1 name', 'Crumb 1'),
        route: text('Route path', 'base')
      },
      {
        label: text('Crumb 2 name', 'Crumb 2')
      }
    ]
  }
})

export const multiCrumbObjectView = () => ({
  template: hbs`<Breadcrumbs @crumbs={{crumbs}} />`,
  context: {
    crumbs: object('Crumbs definition', [
      {
        label: 'Crumb 1',
        route: 'some.path'
      },
      {
        label: 'Crumb 2'
      }
    ])
  }
})
