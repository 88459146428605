import CustomFormRoute from 'client/routes/authenticated-custom-form-route'
import { inject } from '@ember/service'

export default class BaseSettingsSeasonTargetsEditNextSeasonRoute extends CustomFormRoute {
  @inject seasonTargetsRemoteMethods
  @inject intl
  @inject router
  @inject toastMessages

  routePermissions = ['edit__settings__season_targets', 'view__settings__season_targets']

  async model () {
    const clientCompanyOrgId = this.modelFor('base.settings.season-targets.edit').clientCompanyOrgId
    const seasonModifier = 'next'
    return { clientCompanyOrgId, seasonModifier }
  }

  setupController (controller, model) {
    super.setupController(controller, model)
  }
}
