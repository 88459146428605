import Ember from 'ember'
const { get, inject } = Ember

/**
 *  All the functions that can be used to determine whether a formElement should be display need to housed within this
 *  service.  Functions can be a proxy of a function located elsewhere if required.
 *  All functions must be *synchronous* and return a boolean value
 *
 *
 *  Usage with json config:
 *  ```
 *  {
 *    type: "text",
 *    ...
 *    displayConditions: {
 *       func: {
 *         name: 'funcName'
 *         arguments: ['value1']
*         }
 *    }
 *  }
 *  ```
 */
export default Ember.Service.extend({
  gateKeeper: inject.service(),

  /**
   * Proxies the gatekeeper `can` method
   * @param {string} permission
   * @returns boolean
   */
  can (permission) {
    return get(this, 'gateKeeper').can(permission)
  }
})
