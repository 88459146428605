import RemoteMethodService from './remote-methods'
import CacheMixin from 'client/mixins/cache'

export default class LookupsService extends RemoteMethodService.extend(CacheMixin) {
  getOrganisation (query) {
    if (!this.cache.fetch(query)) {
      this.cache.save(query, this.request(`/organisation/organisation-lookup/${query}`))
    }
    return this.cache.fetch(query)
  }
}
