/* global Raygun */
import Controller from '@ember/controller'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'
export default class BaseSettingsCustomFieldsController extends Controller {
  @inject customFieldsRemoteMethods
  @inject intl
  @inject toastMessages
  @inject router

  @tracked displayModal = false
  elementKey = ''

  customActions = {
    onDeleteClick: this.onDeleteClick
  }

  @action
  onDeleteClick (elementKey) {
    this.displayModal = true
    this.elementKey = elementKey
  }

  @action
  confirmDelete () {
    this.customFieldsRemoteMethods.deleteCustomField(this.elementKey)
      .then(() => {
        this.toastMessages.success(this.intl.t('settings.custom_fields.custom_field_delete'))
        this.router.transitionTo('base')
      })
      .catch((e) => {
        this.toastMessages.danger(this.intl.t('settings.custom_fields.custom_field_delete_failed'))
        Raygun.send(e)
        // eslint-disable-next-line no-console
        console.error(e)
      })
    this.displayModal = false
  }

  @action
  closeModal () {
    this.displayModal = false
  }
}
