import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import { deDupErrors } from '../../../../libs/validation-error-lib'

const { computed, get } = Ember

export default Ember.Component.extend(DynamicElement, {
  fullName: computed('value.firstName.0.val', 'value.surname.0.val', 'value.fullName.0.val', function () {
    const honorificSelect = get(this, 'value.honorificSelect.0.val')
    const otherHonorific = honorificSelect === 'Other' || !honorificSelect
    const honorific = otherHonorific ? '' : honorificSelect
    const firstName = get(this, 'value.firstName.0.val') || ''
    const surname = get(this, 'value.surname.0.val') || ''
    const fullName = get(this, 'value.fullName.0.val') || ''
    return `${honorific} ${firstName} ${surname} ${fullName}`.trim()
  }),

  errors: computed('value.firstName.0.errors', 'value.surname.0.errors', function () {
    // merges any errors from the parent, or firstName/surname child elements, into one array
    const firstNameErrors = get(this, 'value.firstName.0.errors') || []
    const surnameErrors = get(this, 'value.surname.0.errors') || []
    const parentErrors = get(this, 'itemState.errors') || []
    const errors = firstNameErrors.concat(surnameErrors).concat(parentErrors)

    return deDupErrors(errors)
  })
})
