import Controller from '@ember/controller'
import { inject } from '@ember/service'
import { NAVBAR_EXCLUDED_ROUTES } from '../constants/links'

export default class BaseController extends Controller {
  @inject router

  get hideNavbar () {
    return NAVBAR_EXCLUDED_ROUTES.includes(this.router.currentRouteName)
  }
}
