import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import config from 'client/config/environment'

export default class LoginController extends Controller {
  queryParams = ['sessionExpired', 'returnTo', 'authError']
  @tracked sessionExpired = null
  @tracked returnTo = null
  @tracked authError = null
  @tracked loginUrl = '/api/auth/login'

  get idpUrl () {
    return config.ENV_VARS.IDP_URL
  }

  get loginUrlWithRedirect () {
    if (this.returnTo) {
      return `${this.loginUrl}?returnTo=${this.returnTo}`
    }
    return this.loginUrl
  }
}
