import { action } from '@ember/object'
import { inject } from '@ember/service'
import { formatDateTime } from 'client/utils/date'
import RSVP from 'rsvp'
import CustomFormRoute from '../../../authenticated-custom-form-route'

export default class SettingsUsersEditRoute extends CustomFormRoute {
  @inject remoteMethods

  routePermissions = ['edit__settings__users__admin', 'edit__settings__users']

  async model (params) {
    const { user, roles } = await RSVP.hash(
      {
        user: this.remoteMethods.request(`/roles/get-user/${params.user_id}`),
        roles: this.remoteMethods.request('/roles/get-roles')
      }
    )
    user.dateFirstRegistered = formatDateTime(user.dateFirstRegistered)
    user.dateLastLogin = formatDateTime(user.dateLastLogin)
    user.roles.map(role => { role.date = formatDateTime(role.date) })
    user.archivedRoles.map(role => { role.date = formatDateTime(role.date) })

    user.roles = this.removeDuplicateRoles(user.roles)
    user.archivedRoles = this.removeDuplicateRoles(user.archivedRoles)

    // Make the IDs into strings, as that's what dynamic forms will need
    return { userDetails: user, roles }
  }

  @action
  refreshRoute () {
    this.refresh()
  }

  removeDuplicateRoles (arrayOfRoles) {
    // Removes duplicate roles, filters by role_name
    return arrayOfRoles.filter((role, index, array) => array.findIndex(findRole => (findRole.role_name === role.role_name)) === index)
  }
}
