import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'

export default class SearchMenuComponent extends Component {
  @inject router
  @tracked downKey = null
  @tracked onSelect = null
  @tracked row

  linkToRouteName = 'base.supplier-section.supplier-default'

  @action
  clicked (linkToId) {
    this.router.transitionTo(this.linkToRouteName, linkToId, 'edit')
    // tell the higher-level component that something has been selected
    // (used to close the menu)
    if (this.onSelect != null) {
      this.onSelect(this)
    }
  }

  @action
  down (downKey, e) {
    // enter
    if (e.keyCode === 13) {
      this.clicked()
    }

    // tell the higher-level component that a key has been pressed
    // (used to tab to the next/previous option)
    if (downKey !== null) {
      downKey(e)
    }
  }
}
