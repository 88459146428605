import RemoteMethodService from 'client/services/remote-methods'
import CacheMixin from 'client/mixins/cache'

/**
 * Actions that are defined in workflow configurations. They appear in the 'Actions' menu.
 *
 * eg. in routes/processing-section/checklist.js:
 * {
 *   key: 'assignee',
 *   path: 'assignee',
 *   title: 'Assignee',
 *   actions: [
 *     {
 *       name: 'Send notification to processing officer',
 *       icon: 'bell',
 *       method: 'sendAssignedProcessingOfficerNotification',
 *       permissionRequired: 'global.send_assigned_processing_officer_notification'
 *     }
 *   ]
 * }
 */
export default RemoteMethodService.extend(CacheMixin, {
})
