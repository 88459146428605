import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n    @triggerClass={{'c-editable-typeahead-cell'}}\n    @triggerId={{this.inputId}}\n    @searchEnabled={{true}}\n    @selected={{this.selected}}\n    @allowClear={{true}}\n    @options={{this.options}}\n    @onChange={{fn this.setSelected}}\n    as |item|\n  >\n  {{item}}\n</PowerSelect>\n", {"contents":"<PowerSelect\n    @triggerClass={{'c-editable-typeahead-cell'}}\n    @triggerId={{this.inputId}}\n    @searchEnabled={{true}}\n    @selected={{this.selected}}\n    @allowClear={{true}}\n    @options={{this.options}}\n    @onChange={{fn this.setSelected}}\n    as |item|\n  >\n  {{item}}\n</PowerSelect>\n","moduleName":"client/components/editable-typeahead-cell/index.hbs","parseOptions":{"srcName":"client/components/editable-typeahead-cell/index.hbs"}});
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'
import { action } from '@ember/object'

export default class EditableTypeaheadCell extends Component {
  @inject uniqueId

  @tracked selected
  @tracked options

  constructor () {
    super(...arguments)
    this.options = this.args.value.options
    this.selected = this.args.value.custom_field_value
  }

  get inputId () {
    return this.uniqueId.next()
  }

  @action
  setSelected (value) {
    this.selected = value
    this.args.value.custom_field_value = value
    this.args.tableActions.onBlur(this.args.row.content.value.supplier_id, value)
  }
}
