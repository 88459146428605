import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  tagName: '',
  displayValue: computed(function () {
    if (this.value !== null && this.value !== undefined) {
      const valueType = typeof (this.value)
      let displayValue = this.value
      if (valueType === 'string') {
        displayValue = this.value.length > 0 ? this.value : '-'
      }
      return displayValue
    }
    return '-'
  })
})
