import Service from '@ember/service'
import { copy } from 'ember-copy'

const filterSets = {}
const previouslyDisplayedPage = {}
const defaultFilterSet = {
  booleanOperator: 'AND',
  filters: []
}

export default Service.extend({
  saveFilterSet (type, filterSet) {
    filterSets[type] = copy(filterSet, true)
  },

  getFilterSet (type, defaultSearchFilters) {
    return filterSets[type] || defaultSearchFilters || defaultFilterSet
  },

  // Set the Previously displayed page for the search type
  // and view, so that when the search is navigated back to,
  // it can be returned to the original state. If the viewKey
  // is not specified, then all views for that searchType are'
  // set.
  setPreviouslyDisplayedPage (searchTypeKey, viewKey = null, page = 1) {
    if (previouslyDisplayedPage[searchTypeKey] === undefined) {
      previouslyDisplayedPage[searchTypeKey] = {}
    }
    if (viewKey == null) {
      Object.keys(previouslyDisplayedPage[searchTypeKey]).forEach((view) => {
        previouslyDisplayedPage[searchTypeKey][view] = 1
      })
    } else {
      previouslyDisplayedPage[searchTypeKey][viewKey] = page
    }
  },

  getPreviouslyDisplayedPage (searchTypeKey, viewKey) {
    if ((previouslyDisplayedPage[searchTypeKey] === undefined) || (previouslyDisplayedPage[searchTypeKey][viewKey] === undefined)) {
      this.resetPreviouslyDisplayedPage(searchTypeKey, viewKey)
    }
    return previouslyDisplayedPage[searchTypeKey][viewKey]
  },

  resetPreviouslyDisplayedPage (searchTypeKey, viewKey = null) {
    this.setPreviouslyDisplayedPage(searchTypeKey, viewKey, 1)
  }
})
