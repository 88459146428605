import { inject } from '@ember/service'
import RSVP from 'rsvp'
import AuthenticatedRoute from '../authenticated-route'

export default class SupplierFarmMapRoute extends AuthenticatedRoute {
  @inject mappingRemoteMethods
  @inject router
  @inject featureFlags

  beforeModel (transition) {
    super.beforeModel(...arguments).then(() => {
      if (this.featureFlags.flagUnset('CLIENT_COMPANY_MAPPING')) {
        transition.abort()
        this.router.transitionTo('not-found')
      }
    })
  }

  async model (params) {
    // Parent = Enterprise Client Id
    const parent = params.parent
    const formInstanceId = params.formInstanceId

    try {
      return RSVP.hash(
        {
          parent,
          formInstanceId,
          farmData: await this.mappingRemoteMethods.fetchFarmMapBoundaries(parent),
          config: await this.mappingRemoteMethods.fetchFarmMapFeaturesConfig(parent),
          features: await this.mappingRemoteMethods.fetchFarmMapFeatures(parent)
        }
      )
    } catch (e) {
      this.router.transitionTo('base.not-found')
    }
  }
}
