import config from 'client/config/environment'
import moment from 'moment'

export function formatDateTime (date) {
  return date ? moment(date).format(config.datetimeFormat) : '-'
}

export function formatDate (date) {
  return date ? moment(date).format(config.dateFormat) : '-'
}

// Utility function to format strings that have come from the database in an already formatted structure.
// Takes: date string in the format "YYYY-MM-DD"
// Returns: A date formatted in the style set out in our configuration
export function formatDateFromShortString (databaseDateString) {
  return databaseDateString ? formatDate(moment(databaseDateString, 'YYYY-MM-DD')) : '-'
}

export function get3CharacterMonthNameFromInteger (integer) {
  return moment.months((integer - 1), 'MM').slice(0, 3)
}

export function formatDateToOrdinalString (date) {
  /**
   *   Helper function to convert a date from the format yyyy-mm-dd to date (ordinal) month (short) year
   *   e.g. 2022-03-02 will be converted to 2nd Feb 2022
  */
  if (date) {
    return moment(date).format('Do MMM YYYY')
  }
}
