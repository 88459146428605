import Mixin from '@ember/object/mixin'
import { get, getWithDefault, set } from '@ember/object'
import { inject } from '@ember/service'

/**
 * CSRF Token Protection
 *
 * Should be mixed in to Objects that use the 'headers' property to add HTTP
 * headers, for example AjaxService or DS.JSONAPIAdapter.
 *
 * The csrfToken property can be used to retrieve the token if needed by
 * objects mixing this in.
 */
export default Mixin.create({
  cookies: inject(),
  csrfToken: undefined,
  init () {
    this._super(...arguments)

    // Read the CSRF Token out from the Cookie.
    const csrfToken = get(this, 'cookies').read('csrftoken')
    if (!csrfToken) {
      throw new Error('No CSRF Token defined, "csrftoken" cookie probably not getting set')
    }
    set(this, 'csrfToken', csrfToken)

    // Set the 'headers' property, will merge with an parents headers if defined
    // Django normalises this to HTTP_X_CSRFTOKEN as requested by the docs. If we try and use that directly as the
    // header name, it breaks.  https://docs.djangoproject.com/en/1.11/ref/csrf/#setting-the-token-on-the-ajax-request
    set(this, 'headers', Object.assign({}, getWithDefault(this, 'headers', {}), {
      'X-CSRFToken': csrfToken
    }))
  }
})
