import Component from '@ember/component'
import { set } from '@ember/object'
import { inject } from '@ember/service'

import config from '../../../config/environment'

export default Component.extend({
  remoteMethods: inject('generic-search-remote-methods'),
  focusNextRender: null,
  isSavedSearchNotificationEnabled: config.ENV_VARS.ENABLE_SAVED_SEARCH_NOTIFICATION,

  actions: {
    deleteQuery (row) {
      set(row, 'deleteLoading', true)
      this.remoteMethods.deleteSavedQuery(row.queryId)
        .then(() => {
          this.savedSearchList.content.rows.removeObject(row)
        })
    },
    enableRenameQuery (row) {
      set(row, 'newQueryName', row.queryName)
      this.savedSearchList.content.rows.setEach('renameEnabled', false)
      set(this, 'focusNextRender', `generic-search-rename-query-${row.queryId}`)
      set(row, 'renameEnabled', true)
    },
    updateSubscription (row) {
      this.remoteMethods.updateSubscription(row.queryId)
        .then(response => {
          set(row, 'isSubscribed', response.isSubscribed)
        })
    },
    cancelRenameQuery (row) {
      set(row, 'renameEnabled', false)
      set(row, 'newQueryName', row.queryName)
    },
    renameQuery (row) {
      set(row, 'renameLoading', true)
      this.remoteMethods.renameQuery(row.queryId, row.newQueryName)
        .then(() => {
          set(row, 'queryName', row.newQueryName)
          set(row, 'renameEnabled', false)
          set(row, 'renameLoading', false)
        })
    },
    renameQueryKeyDown (row, e) {
      // Save when the enter key is pressed
      if (e.keyCode === 13 && row.newQueryName.length) {
        this.actions.renameQuery.call(this, row)
      }
    }
  },

  didRender () {
    if (this.focusNextRender !== null) {
      document.getElementById(this.focusNextRender).focus()
    }
    set(this, 'focusNextRender', null)
  }

})
