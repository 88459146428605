import Ember from 'ember'
import { sortBy } from 'lodash'
import moment from 'moment'
import config from '../../config/environment'

const { computed, get, setProperties } = Ember

export default Ember.Mixin.create({

  sortedRows: computed('tableData.data', 'tableData.metadata', 'dir', 'sort', function () {
    const data = get(this, 'tableData.data')
    const metadata = get(this, 'tableData.metadata')
    const columnKey = get(this, 'sort')
    const dir = get(this, 'dir')

    if (!columnKey || !dir) return data

    const columnData = metadata.find(item => item.elementKey === columnKey)
    let sortedRows
    if (columnData.type === 'date') {
      const sortFormat = columnData.dateSortingFormat || config.dateFormat
      // Convert to unix time for sorting, for reliable parsing specify the date format.
      sortedRows = sortBy(data, item => moment(item[columnKey], sortFormat).unix())
    } else {
      // Default to plain string sort.
      sortedRows = sortBy(data, item => item[columnKey])
    }

    if (dir === 'desc') sortedRows.reverse()
    return sortedRows
  }).readOnly(),

  sortDisabled: computed.alias('formElement.extendedAttributes.disableSorting'),

  actions: {
    onColumnClick (column) {
      if (column.sorted) {
        setProperties(this, {
          dir: column.ascending ? 'asc' : 'desc',
          sort: column.get('valuePath')
        })

        get(this, 'setRows').perform()
      }
    }
  }
})
