/* global Raygun */

import { inject } from '@ember/service'
import RSVP from 'rsvp'
import AuthenticatedRoute from '../../../authenticated-route'

export default class HomeReportsVisitTypeRoute extends AuthenticatedRoute {
  @inject router
  @inject reportsRemoteMethods
  @inject toastMessages
  @inject session
  @inject intl

  beforeModel () {
    // Indicate to PDF service that the page is loading
    document.REPORTS_PRINT_PAGE_LOADED = false
  }

  async model () {
    try {
      const revisitProgress = await this.reportsRemoteMethods.fetchRevisitProgress()
      const visitTypeMapping = await this.reportsRemoteMethods.fetchVisitTypeMappings()
      const targets = await this.reportsRemoteMethods.fetchRoutineAnnualAssessmentProgress()
      const programmeName = revisitProgress[0]?.programme
      const programmeId = revisitProgress[0]?.programme_id
      const waterStatusAssessment = await this.reportsRemoteMethods.fetchWaterStatusAssessmentProgress()
      const FDASeasonDatesData = await this.reportsRemoteMethods.fetchFDASeasonDates()
      const assessmentsCompletedDuringMilkingSeasonTotals = await this.reportsRemoteMethods.fetchAssessmentsCompletedDuringMilkingSeason()
      const nonNotifiedAssessments = await this.reportsRemoteMethods.fetchNonNotifiedAssessments()

      // Hide the card when no data is returned
      const showWaterStatusAssessmentCard = waterStatusAssessment.data.length !== 0

      // Time zone is required to be set here as this is going to be using the servers timezone which is utc by default
      // A future enhancement would be required in future to handle dynamic timezones
      const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Pacific/Auckland' }
      const dateString = new Date().toLocaleDateString('en-GB', options)
      const printHeaderString = this.intl.t(
        'reports.visit_type_pdf_header',
        { tradingName: assessmentsCompletedDuringMilkingSeasonTotals.trading_name, date: dateString }
      )

      return RSVP.hash({
        printHeader: printHeaderString,
        revisitProgress: revisitProgress,
        routineAnnualProgress: targets,
        programmeName: programmeName,
        programmeId: programmeId,
        visitTypeMapping: visitTypeMapping,
        waterStatusAssessment: waterStatusAssessment,
        nonNotifiedAssessments,
        FDASeasonDatesData: FDASeasonDatesData,
        showWaterStatusAssessmentCard: showWaterStatusAssessmentCard,
        assessmentsCompletedDuringMilkingSeasonTotals: assessmentsCompletedDuringMilkingSeasonTotals
      })
    } catch (e) {
      Raygun.send(e)
      this.toastMessages.danger(this.intl.t('messages.unable_to_fetch_data'))
      this.router.transitionTo('base.home.dashboard')
    }
  }

  async afterModel (model, transition) {
    await super.afterModel(model, transition)
    // Indicate to PDF service that the page has finished loading
    document.REPORTS_PRINT_PAGE_LOADED = true
  }
}
