import RemoteMethodService from './remote-methods'

export default class NonConformanceRemoteMethods extends RemoteMethodService {
  fetchNonConformances (enterpriseClientId, isOpen) {
    let url = `/api/audit/non-conformance/${enterpriseClientId}`
    if (isOpen != null) {
      url += `?isOpen=${isOpen}`
    }
    return this.request(url)
  }
}
