import Table from 'ember-light-table'
import Component from '@glimmer/component'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { REPORTS_ASSESSMENT_COLORS } from 'client/constants/values'

export default class ReportsServicesOverviewAssessmentsTable extends Component {
  @inject intl

  @tracked table

  constructor () {
    super(...arguments)
    this.createTable()
  }

  get rows () {
    return this.args?.rows
  }

  columns = [
    {
      label: this.intl.t('services_overview.assessment'),
      valuePath: 'color',
      cellComponent: 'reports/services-overview-card/assessments-table/color-icon-cell',
      classNames: 'c-table__cell c-services-overview-card__table-cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-services-overview-card__table-cell ember-view',
      ascending: false
    },
    {
      label: this.intl.t('services_overview.completed'),
      valuePath: 'completed_audits',
      classNames: 'c-table__cell c-services-overview-card__table-cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-services-overview-card__table-cell ember-view',
      ascending: false,
      width: '100px'
    }
  ]

  createTable () {
    this.addColorsToRows()
    this.table = Table.create({ columns: this.columns, rows: this.rows, enableSync: true })
  }

  addColorsToRows () {
    this.rows.map((row, index) => {
      row.color = REPORTS_ASSESSMENT_COLORS[index]
    })
  }
}
