import hbs from 'htmlbars-inline-precompile'
import { withKnobs } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './addon-summary.notes.md'

export default {
  title: 'Addon Summary',
  component: 'AddonSummary',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

const insight = {
  programmeName: 'Animal Welfare Assurance',
  supplierCount: 1,
  clientName: 'ANZCO',
  approvedCount: 4
}

export const addonSummary = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <AddonSummary @insight={{insight}}></AddonSummary>
    </ContentContainer>
  `,
  context: {
    insight: insight
  }
})
