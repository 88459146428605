import Helper from '@ember/component/helper'
import { FORM_STATES_PILL_STATUS } from '../constants'

export default Helper.extend({
  compute ([status]) {
    if (!status) return ''

    return FORM_STATES_PILL_STATUS[status]
  }
})
