import { inject } from '@ember/service'
import { SETTINGS_TABS } from '../../constants/links'
import AuthenticatedRoute from '../authenticated-route'

export default class BaseSettingsRoute extends AuthenticatedRoute {
  @inject router
  @inject warden
  routePermissions = SETTINGS_TABS.reduce((perms, tab) => ([...perms, ...tab.permissions]), [])

  beforeModel (transition) {
    return super.beforeModel(transition).then(() => {
      // Should always have allowedTabs in order to get here, but check anyway
      if (transition.targetName === 'base.settings.index' && this.allowedTabs.length) {
        // redirect the user to the first tab they can see
        this.router.transitionTo(this.allowedTabs[0].route)
      } else if (!this.allowedTabs.length) {
        // This is an error condition, should not be able to get here, but handle anyway
        this.router.transitionTo('not-found')
      }
    })
  }

  model () {
    return {
      crumbs: [
        {
          label: 'menu.home',
          route: 'base.home'
        },
        {
          label: 'menu.settings'
        }
      ],
      tabs: this.allowedTabs,
      organisationName: this.session?.organisation?.name
    }
  }

  get allowedTabs () {
    return this.warden.filterByAllowedActions(SETTINGS_TABS, 'permissions')
  }
}
