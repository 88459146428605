import Ember from 'ember'
import { copy } from 'ember-copy'
const { computed, observer, get, getWithDefault, set, inject, run } = Ember

export default Ember.Mixin.create({
  // Some useful values
  values: [],
  index: 0,
  calculateDisplay: inject.service(),
  formElementState: computed('state', 'formElement', function () {
    const formElementName = get(this, 'formElement.name')
    return get(this, `state.${formElementName}`)
  }),
  stateToDisplay: computed.filter('formElementState', (item) => !get(item, 'deleted') && !get(item, 'hidden')),
  totalNumberDisplayed: computed.alias('stateToDisplay.length'),

  itemState: computed('formElementState', 'index', function () {
    return Array.isArray(this.formElementState) && this.formElementState.length
      ? this.formElementState[this.index]
      : null
  }),

  /**
   * stateValue represents the 'val' of this element.  Only really relevant where the type contains other formElements
   * that need to have a new root state passed down
   */
  stateValue: computed('itemState', function () {
    const state = get(this, 'itemState')
    return state ? get(state, 'val') : null
  }),

  isDeletedOrHidden: computed('formElementState', 'index', function () {
    const index = get(this, 'index')
    if (index == null) return false
    if (!Array.isArray(get(this, 'formElementState'))) return false

    const state = this.formElementState[this.index]
    return state && (state.hidden || state.deleted)
  }),

  display: computed('state', 'formElement.{name,displayConditions}', function () {
    const displayConditions = get(this, 'formElement.displayConditions')
    const formElementName = get(this, 'formElement.name')
    const state = get(this, 'state')

    // Calculate whether this particular element passes the displayConditions to display
    const display = get(this, 'calculateDisplay').shouldDisplay(state, displayConditions, formElementName)

    if (Array.isArray(get(this, 'formElementState'))) {
      // Set each element of the array to be hidden (normally only one item unless a section)
      get(this, 'formElementState').forEach(element => { set(element, 'hidden', !display) })
    }
    return display
  }),

  // Text formElements need the value to be updated as you type in order to update
  valuesObserver: observer('state', 'formElement.name', function () {
    this.conditionalSetValues()
  }),

  init () {
    this._super(...arguments)
    this.conditionalSetValues()
  },

  conditionalSetValues () {
    if (get(this, 'formElementState.length') !== get(this, 'values.length')) {
      set(this, 'values', get(this, 'formElementState'))
    }
  },

  forceSetValues () {
    run.next(this, function () {
      set(this, 'values', get(this, 'formElementState'))
    })
  },

  value: computed.alias('stateValue'),

  /**
   * Set the value of the form element.
   * @param value
   */
  setValue (value) {
    let state = copy(get(this, 'state'), true)
    const formElement = get(this, 'formElement')

    // Cope with empty state issues
    if (!state) state = {}
    if (!state[formElement.name]) state[formElement.name] = []
    const valToSave = String(value).trim() ? String(value) : null

    state[formElement.name][0] = Object.assign({ id: 'new' }, state[formElement.name][0], { val: valToSave })
    get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'))
  },

  /**
   * Clear the current value from the state.
   */
  clearValue () {
    // let state = copy(get(this, 'state'), true)
    // const formElement = get(this, 'formElement')

    // Cope with empty state issues
    // if (!state) state = {}
    // if (!state[formElement.name]) state[formElement.name] = []

    // state[formElement.name][0] = Object.assign({}, state[formElement.name][0], { val: undefined, selectedOption: undefined })
    // get(this, 'updateStateKey')(get(this, 'formElement.name'), state[formElement.name], get(this, 'index'))

    const { name } = this.formElement
    const oldState = copy(getWithDefault(this, `state.${name}.0`, {}), true)
    const newState = Object.assign(oldState, { val: undefined, selectedOption: undefined })
    this.updateStateKey(name, [newState], this.index)
  },

  actions: {
    /**
     * Set the value of the form element.
     * @param value
     */
    setValue (value) {
      this.setValue(value)
    },

    /**
     * Clear the current value from the state.
     */
    clearValue () {
      this.clearValue()
    }
  }
})
