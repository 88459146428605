import Ember from 'ember'
const { set } = Ember

export default Ember.Component.extend({
  classNames: ['c-form-toggle-group__toggle-button'],
  classNameBindings: [
    'isChecked:c-form-toggle-group__toggle-button--active',
    'isFocused:c-form-toggle-group__toggle-button--focused'
  ],
  actions: {
    toggleFocus (isFocused) {
      set(this, 'isFocused', isFocused)
    }
  }
})
