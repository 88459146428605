/* global L */

import Service from '@ember/service'

import proj4 from 'proj4'
import { cloneDeep, isArray, isEmpty, isNumber } from 'lodash'

proj4.defs('NZTM', '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 ' +
  '+y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs')

/**
 * Reverse points in an arbitrarily deep multi-dimensional array of co-ordinates.
 *
 * @param {array} value Input array.
 * @return {array} Copy of the input array with points reversed.
 */
const convertPoints = (value) => {
  // Take a copy to prevent modifiying the given array.
  const list = cloneDeep(value)

  if (!isArray(list) || isEmpty(list)) return list

  // Determine if this is an array of coordinates.
  if (list.length === 2 && isNumber(list[0]) && isNumber(list[1])) {
    // Reverse the co-ordinate positions.
    return list.reverse()
  }

  return list.map(item => convertPoints(item))
}

export default Service.extend({
  convertToLatLng (crs, coordinates) {
    switch (crs) {
      case 'EPSG3857': // WGS84
        return coordinates
      case 'EPSG2193': // NZTM
        return this.nztmToLatLng(coordinates)
      default:
        throw Error('Invalid coordinate system supplied')
    }
  },

  // Takes co-ordinates in the format [easting, northing] and returns [lat, lng] (WGS84).
  nztmToLatLng (coordinates) {
    return proj4('NZTM', 'WGS84', coordinates).reverse()
  },

  latLngToNztm (latlng) {
    return proj4('WGS84', 'NZTM', [latlng.lng, latlng.lat])
  },

  convertLayerToGeoJson (layer) {
    const geoJson = layer.toGeoJSON()
    return geoJson.geometry
  },

  validate (crs, v1, v2) {
    switch (crs) {
      case 'EPSG3857': // WGS84
        return this.validateWGS84(v1, v2)
      case 'EPSG2193': // NZTM
        return this.validateNztm(v1, v2)
      default:
        return false
    }
  },

  validateWGS84 (lat, lng) {
    return !(isNaN(parseFloat(lat)) || isNaN(parseFloat(lng)))
  },

  validateNztm (easting, northing) {
    if (isNaN(parseFloat(easting)) || isNaN(parseFloat(northing))) return false

    return (
      easting >= 1000000 &&
      easting <= 2000000 &&
      northing >= 5000000 &&
      northing <= 6000000
    )
  },

  /**
   * Handler callback for an ESRI query.
   *
   * @param {Object|undefined} error See ESRI leaflet.
   * @param {Object[]} featureCollection See ESRI leaflet.
   * @param {Function} resolve
   * @param {Function} reject
   * @param {Object} L.FeatureGroup drawnItems
   * @return {undefined}
   */
  esriQueryHandler (error, featureCollection, resolve, reject, drawnItems) {
    if (error) return reject(error)
    if (!featureCollection.features.length) return resolve(false)

    // We're only concerned with a single feature.
    const feature = featureCollection.features[0]

    // The points we get back from ESRI need to be reversed from lng/lat
    // to lat/lng.
    const polygons = convertPoints(feature.geometry.coordinates)
    // Cannot deal with multi-polygons, so add them separately.
    polygons.forEach(p => drawnItems.addLayer(L.polygon(p)))

    resolve(true)
  }
})
