import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"c-tabs-container\">\n  <nav class=\"c-tabs-container__tabs\" aria-label={{t 'aria_labels.section_tabs'}}>\n    <ul class=\"c-tab-group\">\n      {{#each @tabs as |tab|}}\n        <li class=\"c-tab-group__item\">\n          <button\n            type=\"button\"\n            class=\"\n              c-tab-group__link\n              {{if (eq tab this.activeTab) \"c-tab-group__link--active\"}}\n            \"\n            {{on \"click\" (fn this.changeTab tab)}}\n          >\n            {{tab}}\n          </button>\n        </li>\n      {{/each}}\n    </ul>\n  </nav>\n  <div class=\"c-tabs-container__content\">\n    {{yield (hash activeTab=this.activeTab)}}\n  </div>\n</div>", {"contents":"<div class=\"c-tabs-container\">\n  <nav class=\"c-tabs-container__tabs\" aria-label={{t 'aria_labels.section_tabs'}}>\n    <ul class=\"c-tab-group\">\n      {{#each @tabs as |tab|}}\n        <li class=\"c-tab-group__item\">\n          <button\n            type=\"button\"\n            class=\"\n              c-tab-group__link\n              {{if (eq tab this.activeTab) \"c-tab-group__link--active\"}}\n            \"\n            {{on \"click\" (fn this.changeTab tab)}}\n          >\n            {{tab}}\n          </button>\n        </li>\n      {{/each}}\n    </ul>\n  </nav>\n  <div class=\"c-tabs-container__content\">\n    {{yield (hash activeTab=this.activeTab)}}\n  </div>\n</div>","moduleName":"client/components/tabs-container/index.hbs","parseOptions":{"srcName":"client/components/tabs-container/index.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class TabsContainer extends Component {
  @tracked activeTab = this.args.tabs[0] || ''

  @action
  changeTab (newTab) {
    this.activeTab = newTab
  }
}
