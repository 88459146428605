import { get, set } from '@ember/object'
import Component from '@ember/component'

export default Component.extend({
  tagName: 'form',
  classNames: ['c-pagination__goto-page-form'],
  pageNumber: null,
  submit (e) {
    e.preventDefault()
    const numToGoTo = parseInt(get(this, 'pageNumber'))
    const totalPages = parseInt(get(this, 'numberOfPages'))
    if (numToGoTo > 0 && numToGoTo <= totalPages) {
      get(this, 'setPage')(numToGoTo)
    } else {
      window.alert('Page number out of range. Please enter a number between 0 and ' + totalPages)
    }
    set(this, 'pageNumber', null)
  },
  actions: {
    keyDown (e) {
      // Save when the enter key is pressed
      if (e.keyCode === 13 && this.newQueryName.length) {
        this.send('saveQuery')
      }
    }

  }
})
