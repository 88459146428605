import Table from 'ember-light-table'
import Component from '@glimmer/component'
import { action } from '@ember/object'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'

export default class SeasonTargetsTable extends Component {
  @inject intl
  @inject seasonTargetsRemoteMethods
  @inject toastMessages

  @tracked table
  @tracked tableIsLocked = false
  @tracked initialRowState = this.args?.target

  clientCompanyOrgId = this.args?.clientCompanyOrgId

  constructor () {
    super(...arguments)
    this.tableIsLocked = this.doRowsContainTargeValues
    this.createTable()
  }

  get visitType () {
    return this.args?.target?.visitType
  }

  get lockIcon () {
    return this.tableIsLocked ? 'lock' : 'unlocked'
  }

  get doRowsContainTargeValues () {
    return this.initialRowState.some(target => target.target_value)
  }

  columns = [
    {
      label: this.intl.t('season_targets.table.month'),
      valuePath: 'monthName',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false
    },
    {
      label: this.intl.t('season_targets.table.year'),
      valuePath: 'year',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false
    },
    {
      label: this.intl.t('season_targets.table.completed'),
      valuePath: 'completed',
      classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
      ascending: false
    }
  ]

  textCell = {
    label: this.intl.t('season_targets.table.target'),
    valuePath: 'target_value',
    classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
    cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view c-non-editable-text-cell',
    ascending: false
  }

  editableTextCell = {
    label: this.intl.t('season_targets.table.target'),
    valuePath: 'target_value',
    cellComponent: 'edit-season-targets/season-targets-table/editable-text-cell',
    classNames: 'c-table__cell c-table__cell--heading c-table__cell--view-heading is-sorted',
    cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view c-editable-text-cell__font-size',
    ascending: false
  }

  createTable () {
    const columns = this.tableIsLocked ? [...this.columns, this.textCell] : [...this.columns, this.editableTextCell]
    this.table = Table.create({ columns: columns, rows: this.initialRowState, enableSync: true })
  }

  @action
  onClickLock () {
    this.tableIsLocked = !this.tableIsLocked
    this.createTable()
  }
}
