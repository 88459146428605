import AuthenticatedRoute from './authenticated-route'
import { inject } from '@ember/service'

export default class LogoutRoute extends AuthenticatedRoute {
  @inject session
  @inject toastMessages
  @inject intl

  beforeModel (transition) {
    // Need to actually log the user out, which will also redirect them
    return this.session.logout()
      .catch(() => {
        this.toastMessages.warning(this.intl.t('messages.log_out_failed'))
        return transition.abort()
      })
  }
}
