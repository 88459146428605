import {
  EXTENDED_NON_CONFORMANCE_CATEGORIES,
  NON_CONFORMANCE_CATEGORIES
} from '../constants/values'
import RemoteMethodService from './remote-methods'
import { formatDateToOrdinalString } from 'client/utils/date'

export default class InsightsRemoteMethods extends RemoteMethodService {
  fetchProgrammeInsights () {
    return this.request('/api/audit/programme/insight')
      .then(insights => {
        return insights.map((insight) => {
          return {
            programmeName: insight.description,
            supplierCount: insight.supplier_count,
            nonConformanceCount: insight.open_non_conformance_count,
            approvedCount: insight.approved_supplier_count,
            validCertificationCount: insight.valid_certification_count,
            clientName: insight.audit_programme_client,
            notCertifiedCount: insight.supplier_count - insight.valid_certification_count,
            auditData: insight.audit_data || [],
            auditProgramme: insight.audit_programme,
            isAddon: !insight.has_certification,
            clientCompany: insight.client,
            testPassCount: insight.suppliers_whose_most_recent_test_in_the_season_passed,
            hasCertification: insight.has_certification
          }
        })
      })
  }

  fetchProgrammeInsightsByNonConformance () {
    return this.fetchProgrammeInsights().then(insights => {
      return insights.sort((a, b) => (a.notCertifiedCount < b.notCertifiedCount) ? 1 : -1)
    })
  }

  fetchNonConformanceInsights () {
    return this.request('/api/audit/non-conformance/insight')
      .then(insights => {
        let categoryTranslations
        if (insights.find(o => o.category === 'return_major')) {
          categoryTranslations = EXTENDED_NON_CONFORMANCE_CATEGORIES
        } else {
          categoryTranslations = NON_CONFORMANCE_CATEGORIES
        }
        return Object.keys(categoryTranslations).map((category) => {
          const insight = insights.find(insight => insight.category === category.toLowerCase())
          if (!insight) {
            // if there is no insight for the category, this means the values are all 0
            // as the backend only returns responses if a nc exists.
            return {
              nonConformanceCategory: category.toUpperCase(),
              openNonConformanceCount: 0,
              overdueOpenNonConformanceCount: 0,
              notOverdueOpenNonConformanceCount: 0
            }
          } else {
            return {
              nonConformanceCategory: insight.category.toUpperCase(),
              openNonConformanceCount: insight.open_nc_total,
              overdueOpenNonConformanceCount: insight.overdue_and_open_nc_total,
              notOverdueOpenNonConformanceCount: insight.not_overdue_and_open_nc_total
            }
          }
        })
      })
  }

  fetchSupplierSummary (auditProgrammeEnterpriseId) {
    return this.request(`/api/audit/programme/supplier-summary/${auditProgrammeEnterpriseId}`).then((supplierSummary) => {
      return {
        supplierName: supplierSummary.supplierName,
        productType: supplierSummary.productType || '-',
        region: supplierSummary.region || '-',
        programmes: supplierSummary.programmes.map((programme) => {
          return {
            programmeName: programme.description,
            lastCertifiedDate: programme.last_certified_date,
            certificateExpiryDate: programme.certificate_expiry_date,
            isCertified: programme.is_certified,
            latestAuditFormInstanceId: programme.latest_audit_form_instance_id
          }
        })
      }
    })
  }

  async fetchAuditInsights () {
    const auditInsights = await this.request('/api/audit/audit-insights/')
    return [
      {
        translation: 'dashboard.overdue_audits',
        auditNumber: auditInsights.audits_overdue,
        auditStatus: 'overdue',
        state: 'error-major',
        icon: 'error-outline'
      },
      {
        translation: 'dashboard.audits_not_booked_next_28_days',
        auditNumber: auditInsights.audits_due,
        auditStatus: 'not_booked_next_28_days',
        state: 'warning',
        icon: 'error-outline'
      },
      {
        translation: 'dashboard.audits_booked_next_28_days',
        auditNumber: auditInsights.audits_booked,
        auditStatus: 'booked_next_28_days',
        state: 'success-alt',
        icon: 'tick'
      }
    ]
  }

  fetchNewSuppliersTableInsights () {
    return this.request('/api/audit/suppliers/insight')
      .then(insights => {
        const newSuppliers = insights.rows.map((insight) => {
          return {
            tradingName: insight.trading_name,
            clientCompanySupplierId: insight.client_company_supplier_id,
            enterpriseClientId: insight.enterprise_client_id,
            clientCompany: insight.client_company,
            visitType: insight.visit_type,
            programme: insight.programme,
            dateAddedToProgramme: insight.date_added_to_programme
          }
        })
        return {
          totalNumberOfRecords: insights.total_num_rows,
          numRecordsToDisplay: insights.num_records_to_display,
          rows: newSuppliers
        }
      })
  }

  async fetchAuditeeDashboardSummaryInsights () {
    const auditeeInsightsObject = await this.request('/api/audit/auditee-dashboard-summary-insights')

    return {
      auditeeAuditInsights: {
        auditsBooked: auditeeInsightsObject.auditeeInsights.audits_booked,
        nextAuditBookedDate: formatDateToOrdinalString(auditeeInsightsObject.auditeeInsights.next_audit_booked_date),
        nonConformancesDue: auditeeInsightsObject.auditeeInsights.non_conformances_due,
        nonConformanceNextActionDue: formatDateToOrdinalString(auditeeInsightsObject.auditeeInsights.non_conformance_next_action_due),
        openMinorNonConformances: auditeeInsightsObject.auditeeInsights.open_minor_non_conformances,
        openMajorNonConformances: auditeeInsightsObject.auditeeInsights.open_major_non_conformances,
        openCriticalNonConformances: auditeeInsightsObject.auditeeInsights.open_critical_non_conformances,
        totalNonConformancesOpenAndOverdue: auditeeInsightsObject.auditeeInsights.total_non_conformances_open_and_overdue,
        nextAuditDueNotBookedNextThreeMonths: formatDateToOrdinalString(auditeeInsightsObject.auditeeInsights.next_audit_due_not_booked_within_three_months),
        overdueUnbookedAudits: auditeeInsightsObject.auditeeInsights.overdue_unbooked_audits,
        dueUnbookedAudits: auditeeInsightsObject.auditeeInsights.due_unbooked_audits,
        tradingName: auditeeInsightsObject.auditeeInsights.trading_name,
        auditsApprovedOrCertified: auditeeInsightsObject.auditeeInsights.audits_approved_or_certified,
        numberOfEnterprises: auditeeInsightsObject.auditeeInsights.number_of_enterprises,
        totalAuditProgrammes: auditeeInsightsObject.auditeeInsights.total_audit_programmes
      },
      enterpriseOperations:
        {
        },
      EAPIds: auditeeInsightsObject.EAPIds
    }
  }
}
