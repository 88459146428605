import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { CARD_STATES } from '../../../constants/values'

export default class EnterpriseDetailsActionCard extends Component {
  constructor () {
    super(...arguments)
    this.status = this.args.state
    this.colors = ['#47A23F', '#C5B9AB']
    this.chartWidth = 100
    this.hasTooltip = false
    this.targetSubgroups = this.chartSubgroups
    this.data = [this.chartData]
  }

  @tracked isVisible = false

  @action toggleTooltip () {
    this.isVisible = !this.isVisible
  }

  @action setIsVisibleDefault () {
    this.isVisible && (this.isVisible = false)
  }

  get auditsNotApprovedOrCertified () {
    return this.args.totalAuditProgrammes - this.args.auditsApprovedOrCertified
  }

  get chartData () {
    return {
      auditsApprovedOrCertified: this.args.auditsApprovedOrCertified,
      auditsNotApprovedOrCertified: this.auditsNotApprovedOrCertified
    }
  }

  get chartSubgroups () {
    return Object.keys(this.chartData)
  }

  get cardColor () {
    return CARD_STATES[this.status].cardStatus
  }
}
