import Component from '@glimmer/component'

export default class ProgrammeSnapshot extends Component {
  get dialChartTranslations () {
    return {
      lineOne: 'programme_insights.dial_chart_line_1',
      lineTwo: 'programme_insights.dial_chart_line_2',
      hover: 'programme_insights.supplier_or_suppliers'
    }
  }
}
