import AuthenticatedRoute from '../../authenticated-route'
import { inject } from '@ember/service'

export default class HomeProgrammeInsightsRoute extends AuthenticatedRoute {
  @inject insightsRemoteMethods
  routePermissions = [
    'view__programme_insights__basic_insights',
    'view__programme_insights__advanced_insights',
    'view__programme_insights__admin'
  ]

  async model () {
    const insights = await this.insightsRemoteMethods.fetchProgrammeInsights()
    return {
      addonInsights: insights.filter(item => !!item.isAddon),
      programmeInsights: insights.filter(item => !item.isAddon)
    }
  }
}
