import Component from '@ember/component'
import { set, computed } from '@ember/object'
import { inject } from '@ember/service'

import RSVP from 'rsvp'

export default Component.extend({
  tagName: '',
  remoteMethods: inject('generic-search-remote-methods'),
  filterValue: computed.alias('filter.filterValue'),

  options: null,
  init () {
    this._super(...arguments)
    set(this, 'options', [])
  },

  // initialise the list of options - either from the search template json, or by requesting from the server
  // returns a promise that resolves when the options have been loaded
  initOptions () {
    if (this.template.options === undefined && this.template.optionsKey !== undefined) {
      return this.remoteMethods.getLookupResult({
        searchTypeKey: this.searchTypeKey,
        optionsKey: this.template.optionsKey,
        filter: ''
      }).then(({ lookupResult }) => {
        set(this, 'options', lookupResult.rows.map(row => ({
          value: '' + row.id,
          label: row.display_value
        })))
      })
    } else {
      set(this, 'options', this.template.options)
      return RSVP.Promise.resolve()
    }
  },

  didReceiveAttrs () {
    this.initOptions()
  },

  actions: {
    setFilterValue (filterValue) {
      if (filterValue === '') {
        this.setValue({})
      } else {
        this.setValue({
          'filterKey': this.template.filterKey,
          'filterOperator': this.template.filterOperator || '=',
          'filterValue': `${filterValue}`
        })
      }
    }
  }
})
