import Mixin from '@ember/object/mixin'
import { Promise, hash } from 'rsvp'
import { get, set, computed } from '@ember/object'
import { inject } from '@ember/service'

import { isForbiddenError } from 'ember-ajax/errors'

import EditFormActions from 'client/mixins/routes/edit-form-actions'
import HandleWorkflowNavigation from 'client/mixins/routes/handle-workflow-navigation'
import { MODE } from 'client/constants/workflow'
import { workflowTypes } from 'client/libs/workflows'

/**
 * Workflow mixin.
 * Handles the model (including the menu and the currently selected form) and provides workflow actions (including start workflow).
 *
 * Notes:
 *  - Workflow types (form or component) are implemented in libs/workflows.js.
 *  - This file is named `generic-workflows.js` in resc
 */
// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create(EditFormActions, HandleWorkflowNavigation, {
  serialize ({ path }) {
    return {
      path: path
    }
  },

  dynamicFormsMethods: inject(),
  workflowActions: inject(),
  intl: inject(),
  router: inject(),

  sectionRoute: computed('routeName', function () {
    const routeNameParts = this.routeName.split('.')
    routeNameParts.pop()
    return routeNameParts.join('.')
  }),

  // Instead of setting the templateName directly, we override the renderTemplate
  // method to allow us to render different templates
  dynamicTemplateName: null,
  renderTemplate () {
    let template = this.dynamicTemplateName

    // If the mode is print-view, use a different template (append "-print" to the template).
    if (this.mode === MODE.PRINT_VIEW) {
      template += '-print'
    }
    this.render(template)
  },

  mode: null, // View or Edit - set in the model
  workflowType: 'form',

  /**
   * This builds a list of all the workflows, needed to drive the sidebar menu
   * for multiple workflows.
   * @param workflows The workflows configuration object from the route.
   * @param path The tidied path from the URL.
   * @param parentId
   */
  getWorkflowMenus (workflows, path, parentId, sectionRouteModel) {
    // Loop over all the workflows and build an array of objects like:
    // {
    //   active: true,
    //   type: 'form',
    //   parentFormInstanceId: 12345, (null for component workflows),
    //   menu: <menuObject>,
    //   title: 'Application details',
    //   config: <workflowConfigObject> (from the route)
    // }
    return Promise.all(workflows
      .filter(wf => wf.visible !== false) // Don't attempt to get the menu for workflows that aren't visible
      .map((wf) => {
        // Compare against the first segment of the path.
        const active = path.split('/')[0] === wf.path

        // the default workflowType is form
        const workflowType = workflowTypes[wf.type] || workflowTypes.form

        let parentFormInstance, menu

        const getHideWorkflow = () => {
          if (wf.visible !== undefined) {
            return Promise.resolve(!wf.visible)
          }
          if (wf.hideWorkflow !== undefined) {
            return Promise.resolve(wf.hideWorkflow.call(this, { wf, parentId, sectionRouteModel }))
          } else {
            return Promise.resolve(false)
          }
        }

        let permissionDenied = false
        let notFound = false

        return Promise.resolve()
          .then(() => {
            return workflowType.getFormInstance.call(this, { wf, parentId })
              .then(x => {
                // store the result in the `parentFormInstance` variable
                parentFormInstance = x

                if (parentFormInstance.status === 'NOT_FOUND') {
                  notFound = true
                }

                // if the workflow hasn't been started and the user can't start it, flag it as permission denied
                if (parentFormInstance.status === 'PERMISSION_DENIED') {
                  // console.log(`The ${wf.title} workflow (${wf.key}) is being hidden because you do not have permission to start it`)
                  permissionDenied = true
                }
              })
          })
          .then(() => {
            // if the workflow has been flagged as permission denied, we know that getMenu() will result in an error
            if (permissionDenied !== true) {
              return workflowType.getMenu.call(this, { wf, parentFormInstance, path, active })
                .then(x => { menu = x })
                .catch(e => {
                  // if getMenu() results in an error anyway, flag it as permission denied
                  if (isForbiddenError(e)) {
                    // console.log(`The ${wf.title} workflow (${wf.key}) is being hidden because you do not have permission to view it`)
                    permissionDenied = true
                  }
                })
            }
          })
          .then(() => {
            return hash({
              parentFormInstance,
              parentFormInstanceId: parentFormInstance.instanceId,
              menu,
              active,
              workflowType,
              title: wf.title,
              config: wf,
              hideWorkflow: getHideWorkflow(),
              permissionDenied,
              notFound
            })
          })
      }))
      .then(workflows => {
        // hide workflows if permission was denied, or if they're explicitly hidden
        return workflows
          .filter(({ hideWorkflow, permissionDenied, notFound }) => hideWorkflow !== true && permissionDenied !== true && notFound !== true)
      })
  },

  model ({ path }, transition) {
    this._super(...arguments)
    const sectionRoute = this.sectionRoute
    const sectionRouteModel = this.modelFor(sectionRoute)
    const { parentId, mode, pageInfo, tabConfig } = sectionRouteModel

    // Tidy up the path
    // e.g. trailing slashes or double slashes will be trimmed.
    path = path.split('/').filter(v => !!v).join('/')

    const currentTabKey = this.routeName.split('.').pop()
    const currentTabConfig = tabConfig.find(x => x.key === currentTabKey)

    // Combine workflows sent from the server (using the get-tabs/ endpoint), with workflows defined in the route
    //  (these are typically defined in app/routes/[processing|consents]-section/*.js)
    // Workflows that haven't been defined on the server are ignored
    // Properties defined in the route override ones from the server
    //
    // Should be an array of objects with the following attributes:
    // key = the form template key
    // path = the URL path to match against
    // title = text to show in the page
    const workflows = currentTabConfig.workflows.map((wf) => (Object.assign({}, wf, this.workflows.find(w => w.path === wf.path) || {})))

    if (!workflows) {
      get(this, 'toastMessages.danger')(this.intl.t('messages.unable_to_load_workflow'))
      // eslint-disable-next-line no-console
      console.error('Workflows not defined')
      this.router.transitionTo(sectionRoute, parentId)
    }

    const validModes = [MODE.VIEW, MODE.EDIT, MODE.PRINT_VIEW]
    if (validModes.indexOf(mode) < 0) {
      throw new Error(`Unrecognised mode, valid modes are "${validModes.join('", "')}"`)
    }

    // Edit form actions mixin uses this
    set(this, 'mode', mode)

    const formRootRoute = get(transition, 'targetName')

    let workflowMenus, activeWorkflow, workflowModel

    return Promise.resolve()
      .then(() => {
        // Get all the workflow menu information first.
        return this.getWorkflowMenus(workflows, path, parentId, sectionRouteModel)
          .then(x => { workflowMenus = x })
      })
      .then(() => {
        // First check that there is a workflow visible somewhere in the menu
        if (workflowMenus.length <= 0) {
          get(this, 'toastMessages.danger')(this.intl.t('messages.unable_to_load_tab'))
          // eslint-disable-next-line no-console
          console.error(`Could not find any visible workflows on the ${sectionRoute} ${path} tab`)
          this.router.transitionTo(sectionRoute)
        }

        // Find the active workflow
        activeWorkflow = workflowMenus.find(wfm => wfm.active)

        // If there is no active workflow, transition to the first visible one
        if (!activeWorkflow) {
          this.router.transitionTo(this.routeName, parentId, 'view', workflowMenus[0].config.path)
          return
        }

        // There is an active workflow, so return its model
        return Promise.resolve()
          .then(() => {
            // Then get the workflow-specific model
            const getWorkflowModel = activeWorkflow.workflowType.getModel
            if (getWorkflowModel === undefined) {
              get(this, 'toastMessages.danger')(this.intl.t('messages.unable_to_load_workflow'))
              // eslint-disable-next-line no-console
              console.error('Invalid workflow model')
              this.router.transitionTo(sectionRoute, parentId)
              return
            }

            return getWorkflowModel.call(this, { activeWorkflow, parentId, path, transition })
              .then((x) => { workflowModel = x })
          })
          .then(() => {
            // the templateName is set by the model, set dynamicTemplateName on the model
            // so that renderTemplate() can determine the template to render
            set(this, 'dynamicTemplateName', workflowModel.templateName)

            // extend the workflowModel with a some extra properties then pass it to the template
            return Object.assign(workflowModel, {
              mode,
              path,
              workflows: workflowMenus,
              parentId,
              formRootRoute,
              sectionRoute,
              activeWorkflow,
              sectionRouteModel,
              pageInfo,
              tabConfig
            })
          })
      })
  },

  afterModel (model, transition) {
    this._super(model, transition)

    // call the afterModel hook defined in the workflow type above
    const workflowAfterModel = get(model, 'activeWorkflow.workflowType.afterModel')
    if (workflowAfterModel) {
      workflowAfterModel.call(this, model, transition)
    }
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    startWorkflow ({ parentId, activeWorkflow, formRootRoute, formRootPath }) {
      const dynamicFormsMethods = this.dynamicFormsMethods
      return dynamicFormsMethods.retrieveFormInstance({
        parentId,
        formKey: activeWorkflow.config.key,
        startWorkflow: true
      })
        .then(formInstance => {
          // after starting a workflow, go straight into editing the newly created form
          this.dynamicFormsMethods.transitionToForm({ id: formInstance.formInstanceId, mode: 'edit' })
        })
    },

    /**
     * Runs a method in the workflow-actions.js service.
     *
     * Used for the actions in the actions menu.
     */
    workflowAction (method, model) {
      const workflowActions = this.workflowActions
      if (workflowActions[method] === undefined) {
        // eslint-disable-next-line no-console
        console.error(`The workflowActions Ember service has no method ${method}`)
        return
      }
      workflowActions[method](model)
    },

    /**
     * Rerun the model hook.
     *
     * Useful in cases were you want to force a refresh. An example and the
     * only current use is to reload the menu after an item is deleted from
     * a form-link table (like Consents).
     */
    refreshModel () {
      this.refresh()
    }
  }
})
