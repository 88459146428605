import Ember from 'ember'
const { get, set } = Ember

export default Ember.Component.extend({
  actions: {
    confirm () {
      set(this, 'displayModal', false)
      get(this, 'confirmSuccessFunction')()
    }
  }
})
