/* global L */

import { inject } from '@ember/service'
import { get, set } from '@ember/object'
import Mixin from '@ember/object/mixin'

export default Mixin.create({
  mapLib: inject(),

  /**
   * Creates the map property on the component and sets it up with the correct
   * base layers, CRS, etc.
   *
   * @param {String} selector If this is a string, it is treated as the CSS Selector for the map element. Alternatively, a HTML DOM element can be passed.
   */
  createMapAndBaseLayers (selector) {
    const mapElement = typeof selector === 'string' ? this.$(selector).get(0) : selector
    set(this, 'mapElement', mapElement)
    set(this, 'currentLocation', this.mapLib.defaultCenter)
    const map = L.map(mapElement, {
      center: get(this, 'currentLocation'),
      zoom: this.mapLib.mapDefaultZoom,
      crs: this.mapLib.crs,
      maxZoom: this.mapLib.mapMaxZoom,
      minZoom: this.mapLib.mapMinZoom,
      sleep: this.mapLib.sleep,
    })
    map.zoomControl.setPosition('topright')

    // add map layers
    this.mapLib.getBaseLayers().addTo(map)

    // Initialise the FeatureGroup to store editable layers
    const drawnItems = this.drawnItems || new L.FeatureGroup()
    map.addLayer(drawnItems)

    // Note: this generates a deprecation warning.  There's not a huge amount we can do at this stage as wherever
    // you do this in the didInsertElement/didRender lifecycle you get this message.
    // https://github.com/emberjs/ember.js/issues/11493 suggests using Ember.run.scheduleOnce to set the values
    // once rendering is complete, but I couldn't get that to work
    set(this, 'map', map)
    set(this, 'drawnItems', drawnItems)
  },

  addInitialLayersAndMarkers () {
    this.mapLib.addInitialLayersAndMarkers(this.map)
  },

  gotoNZTM (easting, northing) {
    const latLng = this.mapLib.nztmToLatLng([easting, northing])
    this.setMapView(latLng)
  },

  gotoLatLng (lat, lng, extents = false) {
    if (extents) {
      const bounds = [
        [extents.latMin, extents.lngMin],
        [extents.latMax, extents.lngMax]
      ]
      this.setMapView([lat, lng], { bounds, zoomLevel: this.mapLib.maxFitBoundsZoom || undefined })
    } else {
      this.setMapView([lat, lng], { zoomLevel: this.mapLib.maxFitBoundsZoom || this.mapLib.mapMaxZoom })
    }
  },

  resetToDefaultView () {
    this.setMapView(this.mapLib.defaultCenter, {
      zoomLevel: this.mapLib.mapDefaultZoom
    })
  },

  getLayers () {
    if (typeof this.mapLib.getLayers !== 'function') {
      return []
    }
    return this.mapLib.getLayers()
  },

  showLayer (layerId) {
    return this.mapLib.showLayer(layerId, get(this, 'map'))
  },

  hideLayer (layerId) {
    return this.mapLib.hideLayer(layerId, get(this, 'map'))
  },

  setMapView (latLng, options) {
    const map = get(this, 'map')

    if (options && options.zoomLevel) {
      map.setView(latLng, options.zoomLevel)
    } else {
      map.setView(latLng)
    }

    if (options && options.bounds) {
      map.fitBounds(options.bounds)
    }
  }
})
