/* global Raygun */
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { inject } from '@ember/service'
import { cloneDeep } from 'lodash'
import { getState, updateState } from '../../../../libs/local-dynamic-forms-utils'
import Table from 'ember-light-table'

class State {
  @tracked documentType = null
  @tracked startDate = null
  @tracked endDate = null
  @tracked summary = null
  @tracked visibilityFlagAssessor = false
  @tracked visibilityFlagClientCompany = false
}

export default class AuditDocuments extends Component {
  @tracked internalState = new State()
  @tracked internalErrors = new State()

  @inject intl
  @inject auditDocumentRemoteMethods
  @inject router
  @inject session
  @inject fileUpload
  @inject toastMessages
  @inject warden

  // Document Download / Delete
  @tracked activeFileIndex
  @tracked activeFiles = []
  @tracked isLoading = false
  @tracked isDeleting = false
  @tracked hasError = false
  @tracked casdbEnterpriseAuditId

  // Document Upload
  @tracked selectedFile
  @tracked uploading = false
  @tracked fileName
  @tracked showDeleteConfirmationModal = false

  // Table
  @tracked table
  @tracked isTableExpanded = false

  // Modal options
  @tracked showAdditionalInfoModal = false
  @tracked modalHeadingTranslation
  @tracked modalClickAction
  @tracked modalClickActionIsDisabled = false

  setModalOptionsForUpload () {
    this.resetState()
    this.modalHeadingTranslation = this.intl.t('supplier_summary.programme_document_upload')
    this.modalClickAction = this.uploadFile
    this.showAdditionalInfoModal = true
  }

  setModalOptionsForEdit () {
    this.modalHeadingTranslation = this.intl.t('supplier_summary.document_edit')
    this.modalClickAction = this.confirmEditDocument
    this.showAdditionalInfoModal = true
  }

  resetState () {
    this.internalState = new State()
  }

  actions = {
    confirmDelete: this.confirmDelete,
    editDocument: this.editDocument
  }

  // Default values for Metadata sent to AQ API
  defaultMetaDataValues = {
    isActive: true,
    modifiedByEmail: this.session.username,
    modifiedDate: new Date().toISOString().replace('T', ', ').split(',')[0]
  }

  // Value = Key for lib_lookup table
  documentTypeOptions = [
    { value: 'APPLICATION', label: this.intl.t('supplier_summary.application') },
    { value: 'CERTIFICATE', label: this.intl.t('supplier_summary.certificate') },
    { value: 'DAIRY_DISPENSATION_DOCUMENT', label: this.intl.t('supplier_summary.dairy_dispensation_document') },
    { value: 'EMAIL', label: this.intl.t('supplier_summary.email') },
    { value: 'IMAGE', label: this.intl.t('supplier_summary.image') },
    { value: 'LETTER', label: this.intl.t('supplier_summary.letter') },
    { value: 'OTHER', label: this.intl.t('supplier_summary.other') },
    { value: 'REPORT', label: this.intl.t('supplier_summary.report') },
    { value: 'WATER_USE_PLAN', label: this.intl.t('supplier_summary.water_use_plan') }
  ]

  get chevron () {
    if (!this.isTableExpanded) {
      return {
        width: '30px',
        cellComponent: 'dynamic-form/light-table-components/row-toggle',
        classNames: 'c-table__cell c-table__cell--heading',
        cellClassNames: 'c-table__cell c-table__row--clickable c-table__row--toggle-column c-table__row--toggle-column'
      }
    }
    return {}
  }

  // We want the summary column to show in the table expanded view
  get summaryTableExpanded () {
    if (!this.isTableExpanded) {
      return {
        label: this.intl.t('supplier_summary.table.summary'),
        valuePath: 'metadata.summary',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        breakpoints: ['collapsedByDefault'],
        cellComponent: 'tooltip-cell'
      }
    } else {
      return {
        label: this.intl.t('supplier_summary.table.summary'),
        valuePath: 'metadata.summary',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        cellComponent: 'tooltip-cell'
      }
    }
  }

  // Getters re-compute column widths when called
  get columns () {
    return this.isUserClientCompany ? this.columnsInternal : this.columnsAdmin
  }

  get columnsAdmin () {
    return [
      {
        label: this.intl.t('supplier_summary.table.document_type'),
        valuePath: 'metadata.documentType',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        width: this.columnWidth,
        ascending: false,
        breakpoints: ['smallDesktopUp'],
        cellComponent: 'dynamic-form/view/programme-information-documents/translated-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.status'),
        valuePath: 'metadata.isActive',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        breakpoints: ['smallDesktopUp'],
        cellComponent: 'tooltip-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.name'),
        valuePath: 'displayName',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        cellComponent: 'tooltip-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.source'),
        valuePath: 'metadata.modifiedByEmail',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        breakpoints: ['smallDesktopUp'],
        cellComponent: 'tooltip-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.modified_date'),
        valuePath: 'metadata.modifiedDate',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        breakpoints: ['smallDesktopUp'],
        cellComponent: 'tooltip-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.file_link'),
        valuePath: 'actions',
        classNames: 'c-table__cell c-table__cell--heading is-sorted c-audit_programme__table__row_sticky',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view c-audit_programme__table__row_sticky',
        ascending: false,
        width: '100px',
        cellComponent: 'dynamic-form/view/programme-information-documents/table-file-link'
      }
    ]
  }

  get columnsInternal () {
    return [
      this.chevron,
      {
        label: this.intl.t('supplier_summary.table.document_type'),
        valuePath: 'metadata.documentType',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        width: this.columnWidth,
        ascending: false,
        breakpoints: ['smallDesktopUp'],
        cellComponent: 'dynamic-form/view/programme-information-documents/translated-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.name'),
        valuePath: 'displayName',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        cellComponent: 'tooltip-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.start_date'),
        valuePath: 'metadata.startDate',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        breakpoints: ['smallDesktopUp'],
        cellComponent: 'tooltip-cell'
      },
      {
        label: this.intl.t('supplier_summary.table.end_date'),
        valuePath: 'metadata.endDate',
        classNames: 'c-table__cell c-table__cell--heading is-sorted',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view',
        ascending: false,
        width: this.columnWidth,
        breakpoints: ['smallDesktopUp'],
        cellComponent: 'tooltip-cell'
      },
      this.summaryTableExpanded,
      {
        label: this.intl.t('supplier_summary.table.file_link'),
        valuePath: 'actions',
        classNames: 'c-table__cell c-table__cell--heading is-sorted c-audit_programme__table__row_sticky',
        cellClassNames: 'lt-cell align-left c-table__cell c-table__row--clickable ember-view c-audit_programme__table__row_sticky',
        ascending: false,
        width: this.fileLinkColumnWidth,
        cellComponent: 'dynamic-form/view/programme-information-documents/table-file-link'
      }
    ]
  }

  formElements = {
    documentType: {
      name: 'documentType',
      label: this.intl.t('supplier_summary.document_type'),
      type: 'select',
      options: this.documentTypeOptions
    },
    startDate: {
      name: 'startDate',
      label: this.intl.t('supplier_summary.document_start_date'),
      type: 'date'
    },
    endDate: {
      name: 'endDate',
      label: this.intl.t('supplier_summary.document_end_date'),
      type: 'date'
    },
    summary: {
      name: 'summary',
      label: this.intl.t('supplier_summary.document_summary'),
      type: 'textArea'
    },
    visibilityFlagAssessor: {
      name: 'visibilityFlagAssessor',
      label: this.intl.t('supplier_summary.document_visibility_flag_assessor'),
      type: 'checkBox'
    },
    visibilityFlagClientCompany: {
      name: 'visibilityFlagClientCompany',
      label: this.intl.t('supplier_summary.document_visibility_flag_client_company'),
      type: 'checkBox'
    }
  }

  constructor () {
    super(...arguments)
    const componentState = this.args.state['programme-information-documents']
    // casdbEnterpriseAuditId is passed through from dynamic forms
    this.casdbEnterpriseAuditId = componentState?.[0]?.val?.casdbEnterpriseAuditId[0]?.val
    this.getAuditDocuments()
  }

  get isUserClientCompany () {
    return this.warden.canAny(['view__audit_programme_documents__client_company_internal'])
  }

  get columnWidth () {
    return this.isTableExpanded ? '250px' : '100px'
  }

  get fileLinkColumnWidth () {
    return this.isTableExpanded ? '65px' : '49px'
  }

  get stateKeys () {
    return Object.keys(Object.getPrototypeOf(this.internalState))
  }

  get state () {
    return getState(this.stateKeys, this.internalState, this.formElements, this.internalErrors)
  }

  get activeFile () {
    return this.activeFiles[this.activeFileIndex]
  }

  get activeFileMetadata () {
    return this.activeFiles[this.activeFileIndex].metadata
  }

  get cloneDeepActiveFiles () {
    return cloneDeep(this.activeFiles)
  }

  get restructuredStateObject () {
    // Restructures the state object to be sent to the server
    // Keys are defined in the formElements object

    const stateObject = {}
    const localState = { ...this.state }
    this.stateKeys.map(key => {
      const value = localState[key][key][0].val
      stateObject[key] = value === '' ? null : value
    })
    return stateObject
  }

  insertAdditionalData () {
    if (this.isUserClientCompany) {
      this.activeFiles = this.activeFiles.filter(file => file.metadata.visibilityFlagClientCompany)
    }
    Object.keys(this.activeFiles).forEach(key => {
      this.activeFiles[key].metadata.isActive = this.activeFiles[key].metadata.isActive ? this.intl.t('supplier_summary.active') : this.intl.t('supplier_summary.deactivated')
      this.activeFiles[key].actions = this.actions
      this.activeFiles[key].casdbEnterpriseAuditId = this.casdbEnterpriseAuditId
    })
  }

  updateActiveFileMetadata () {
    Object.keys(this.restructuredStateObject).forEach(key => {
      this.activeFiles[this.activeFileIndex].metadata[key] = this.restructuredStateObject[key]
    })
  }

  removeActiveFileFromActiveFilesArray () {
    this.activeFiles.splice(this.activeFileIndex, 1)
    this.activeFileIndex = null
  }

  createTable () {
    this.insertAdditionalData()
    this.table = Table.create({ columns: this.columns, rows: this.cloneDeepActiveFiles, enableSync: true })
  }

  setActiveFileIndexById (id) {
    this.activeFileIndex = this.activeFiles.findIndex(file => file.id === id)
  }

  @action
  updateLocalState (name, returnedState) {
    // Warning: `this.internalState` is mutated in this function
    updateState(name, returnedState, this.formElements, this.internalState)
  }

  @action
  toggleIsTableExpanded () {
    this.isTableExpanded = !this.isTableExpanded
    this.createTable()
  }

  @action
  closeModal () {
    this.modalClickActionIsDisabled = false
    this.showDeleteConfirmationModal = false
    this.showAdditionalInfoModal = false
    this.fileName = null
    this.resetState()
  }

  async getAuditDocuments () {
    this.hasError = false
    this.isLoading = true
    try {
      this.activeFiles = await this.auditDocumentRemoteMethods.fetchListOfAuditProgrammeDocuments(this.casdbEnterpriseAuditId)
    } catch (e) {
      this.hasError = true
      Raygun.send(e)
    } finally {
      this.createTable()
      this.isLoading = false
    }
  }

  @action
  editDocument (selectedRow) {
    this.resetState()
    this.setActiveFileIndexById(selectedRow.content.id)
    Object.keys(this.state).forEach(stateKey => {
      if (this.activeFileMetadata[stateKey]) {
        this.updateLocalState(stateKey, [{ val: this.activeFileMetadata[stateKey] }])
      }
    })
    this.setModalOptionsForEdit()
  }

  @action
  async confirmEditDocument () {
    this.isLoading = true
    if (!this.restructuredStateObject.documentType) {
      this.isLoading = false
      this.toastMessages.danger(this.intl.t('supplier_summary.document_type_required'))
      return
    }
    try {
      this.modalClickActionIsDisabled = true
      await this.auditDocumentRemoteMethods.patchAuditProgrammeDocument(this.activeFile.id, this.casdbEnterpriseAuditId, this.activeFile.name, this.restructuredStateObject)
      this.updateActiveFileMetadata()
      this.toastMessages.success(this.intl.t('supplier_summary.successfully_edited_document'))
    } catch (e) {
      this.toastMessages.danger(this.intl.t('supplier_summary.error_editing_document'))
    } finally {
      this.createTable()
      this.modalClickActionIsDisabled = false
      this.isLoading = false
      this.closeModal()
    }
  }

  @action
  upload (selectedFile) {
    this.selectedFile = selectedFile
    this.setModalOptionsForUpload()
  }

  @action
  async uploadFile () {
    this.fileName = this.selectedFile.name
    this.isLoading = true
    if (!this.restructuredStateObject.documentType) {
      this.isLoading = false
      this.toastMessages.danger(this.intl.t('supplier_summary.document_type_required'))
      return
    }
    const uploadPlaceholder = {
      name: this.fileName,
      displayName: this.fileName,
      id: 'temporary-id',
      isUploading: true,
      metadata: { ...this.restructuredStateObject, ...this.defaultMetaDataValues }
    }
    try {
      this.activeFiles.push(uploadPlaceholder)
      this.modalClickActionIsDisabled = true
      const response = await this.auditDocumentRemoteMethods.uploadAuditProgrammeDocument(this.casdbEnterpriseAuditId, this.fileName, this.selectedFile, this.restructuredStateObject)
      this.setActiveFileIndexById(uploadPlaceholder.id)
      this.activeFiles[this.activeFileIndex].id = response.body.id
      this.activeFiles[this.activeFileIndex].name = response.body.external_file_name
      this.toastMessages.success(this.intl.t('supplier_summary.successfully_uploaded_document'))
      this.activeFiles[this.activeFileIndex].isUploading = false
    } catch (e) {
      this.toastMessages.danger(this.intl.t('supplier_summary.error_uploading_documents'))
      this.removeActiveFileFromActiveFilesArray()
      Raygun.send(e)
    } finally {
      this.createTable()
      this.isLoading = false
      this.closeModal()
    }
  }

  @action
  confirmDelete (selectedRow) {
    this.setActiveFileIndexById(selectedRow.content.id)
    this.showDeleteConfirmationModal = true
  }

  @action
  async deleteAuditDocument () {
    this.isDeleting = true
    this.modalClickActionIsDisabled = true
    try {
      await this.auditDocumentRemoteMethods.deleteAuditProgrammeDocument(this.casdbEnterpriseAuditId, this.activeFile.id)
      this.removeActiveFileFromActiveFilesArray()
    } catch (e) {
      this.toastMessages.danger(this.intl.t('supplier_summary.error_deleting_documents'))
      Raygun.send(e)
    } finally {
      this.createTable()
      this.isDeleting = false
      this.closeModal()
    }
  }
}
