import Ember from 'ember'
import DS from 'ember-data'
import RSVP from 'rsvp'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import { computed } from '@ember/object'
import { inject } from '@ember/service'

export default Ember.Component.extend(DynamicElement, {
  dynamicFormsMethods: inject(),

  displayValue: computed('stateValue',  function () {
    const selectedOptionFromServer = this?.values?.[0]?.selectedOption?.label
    const selectedOptionFromState = this.formElement.options.find((option) => option.value === this?.itemState?.val)?.label
    const selected = selectedOptionFromServer || selectedOptionFromState || '-'
    return DS.PromiseObject.create({ promise: RSVP.resolve(selected) })
  }),

  statusPill: computed.alias('formElement.extendedAttributes.statusPill'),
  statusPillType: computed('statusPill', 'displayValue', function () {
    // Type of the status pill - error, success, info etc.
    // Configured using the extendedAttributes object in the form JSON
    // Can be set conditionally based on the stateValue, eg:
    // "extendedAttributes": {
    //   "statusPill": {
    //     "type": "info",  // used if none of the values below match
    //     "conditionalTypes": [
    //       { "val": 10766, "#": "Granted", "type": "success" },
    //       { "val": 10769, "#": "Refused", "type": "error" }
    //     ]
    //   }
    // }
    if (this.statusPill === undefined || !this.stateValue) {
      // Note: this returns undefined if the stateValue null, to avoid displaying "-" in a pill.
      return
    }

    if (Array.isArray(this.statusPill.conditionalTypes)) {
      const conditionalType = this.statusPill.conditionalTypes.find(({ val }) => (val === this.stateValue))
      if (conditionalType !== undefined) {
        return conditionalType.type
      }
    }
    return this.statusPill.type
  })
})
