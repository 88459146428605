import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { timeStampToString } from 'client/utils/chart-utils'
import { kebabCase } from 'lodash'

export default class WaterStatusAssessmentProgress extends Component {
  @inject router
  @inject warden

  get barChartIdentifier () {
    return kebabCase('bar-chart-water-status-assessment-progress')
  }

  get barChartTitle () {
    return 'reports_tab_graph_titles.water_status_assessment_progress'
  }

  get auditTotalsByMonth () {
    return this.args?.auditTotalsByMonth?.data
  }

  /**
 * Gets the id of the Water Status Assessment Programme from the returned data
 * This programme will/should always be water status assessment
 */
  get waterStatusAssessmentProgrammeId () {
    return this.args?.auditTotalsByMonth?.audit_programme_id
  }

  auditCompletedSearchFilters = {
    programme: [this.waterStatusAssessmentProgrammeId.toString()],
    dateCompleted: [timeStampToString(this.args?.FDASeasonDates.start_date), timeStampToString(this.args?.FDASeasonDates.end_date)]
  }

  // to be 'booked' an audit have no completed date
   auditBookedSearchFilters = {
     programme: [this.waterStatusAssessmentProgrammeId.toString()],
     dateCompleted: ['is null'],
     assessmentDate: [timeStampToString(this.args?.FDASeasonDates.start_date), timeStampToString(this.args?.FDASeasonDates.end_date)]
   }
}
