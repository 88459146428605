import CustomFormRoute from '../../../authenticated-custom-form-route'
import { inject } from '@ember/service'

export default class InviteUsersRoute extends CustomFormRoute {
  @inject remoteMethods
  routePermissions = ['invite__users', 'invite__users__admin', 'invite__users_programme_subscriber']

  model () {
    return this.remoteMethods.request('/roles/get-roles').then(roles => {
      // Make the IDs into strings, as that's what dynamic forms will need
      return { roles }
    })
  }
}
