import AuthenticatedRoute from '../../authenticated-route'
import { inject as service } from '@ember/service'
import { action, set } from '@ember/object'

export default class BaseHomeProgrammeSubscriberSearchRoute extends AuthenticatedRoute {
  @service warden

  routePermissions = ['view__programme_subscriber_search']

  @action
  onSearchQueryChange () {
    set(this, 'q', null)
  }
}
