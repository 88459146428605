import CustomFormRoute from 'client/routes/authenticated-custom-form-route'
import { inject } from '@ember/service'
import { EDIT_SEASON_TARGETS_TABS } from 'client/constants/links'

export default class BaseSettingsSeasonTargetsEditRoute extends CustomFormRoute {
  @inject router
  @inject warden
  @inject featureFlags

  clientCompanyOrgId

  routePermissions = ['edit__settings__season_targets', 'view__settings__season_targets']

  get tabs () {
    return this.warden.filterByAllowedActions(EDIT_SEASON_TARGETS_TABS, 'permissions')
  }

  async beforeModel (transition) {
    await super.beforeModel(...arguments)
    if (this.featureFlags.flagUnset('FARM_DAIRY_REPORTS')) {
      transition.abort()
      this.router.transitionTo('base.not-found')
    }
    if (this.tabs.length === 0) {
      transition.abort()
      this.router.transitionTo('base.not-found')
    } else {
      // Logic for immediately opening the first route in the list
      if (!this.tabs.map(v => v.route).includes(transition.to.name)) {
        transition.abort()
        this.router.transitionTo(this.tabs[0].route)
      }
    }
  }

  model ({ clientCompanyOrgId }) {
    this.clientCompanyOrgId = clientCompanyOrgId
    return { clientCompanyOrgId, tabs: this.tabs }
  }

  setupController (controller, model) {
    super.setupController(controller, model)
  }
}
