import Component from '@ember/component'
import { computed } from '@ember/object'
import { inject } from '@ember/service'
import { getOwner } from '@ember/application'
import RSVP from 'rsvp'
import DS from 'ember-data'

import moment from 'moment'

export default Component.extend({
  tagName: '',
  genericSearchLibs: inject(),
  remoteMethods: inject('generic-search-remote-methods'),

  // The format used to display the currently set date
  dateFormat: computed('container', function () {
    // Need to use this awkward syntax to get the application's environment settings, rather than the addon's
    return getOwner(this).resolveRegistration('config:environment').dateFormat
  }),

  isNoneQuery: computed(function () {
    if (this.query[0].templateFilterOperator) {
      return Boolean(this.query[0].templateFilterOperator === 'None')
    }
  }),

  isDateRange: computed(function () {
    if (this.template.type) {
      return Boolean(this.template.type === 'date-range')
    }
  }),

  // Converts a single value to a string. Can optionally return a promise.
  valueToString (value) {
    if (this.isNoneQuery) {
      return value
    }
    if (this.isDateRange || this.template.type === 'date-specific') {
      return moment(value).format(this.dateFormat)
    }
    if (this.template.type === 'typeahead-selector' || this.template.type === 'select') {
      return this.remoteMethods.getLookupDisplayValue({
        searchTypeKey: this.searchTypeKey,
        optionsKey: this.template.optionsKey,
        internalValue: value
      }).then(({ lookupDisplayValueResult }) => lookupDisplayValueResult.displayValue)
        .catch(() => value)
    }
    return value
  },

  filterToString (filter = {}) {
    if (filter.filterSet) {
      let operator = ({
        OR: 'or',
        AND: 'and'
      })[filter.filterSet.booleanOperator] || filter.filterSet.booleanOperator

      // special case for date ranges
      if (this.isDateRange) {
        operator = ''
      }

      return RSVP.Promise.all(filter.filterSet.filters.map((filter) => this.filterToString(filter)))
        .then(s => {
          return s.reduce((prev, curr) => {
            if (prev.length > 0) {
              prev.push({ operator })
            }
            return prev.concat(...curr)
          }, [])
        })
    } else if (filter.filterKey) {
      let operator = ({
        '>': 'greater than',
        '<': 'less than',
        '>=': 'greater than or equal to',
        '<=': 'less than or equal to',
        '=': 'is',
        '@@': 'for',
        'ilike': 'like',
        'ilike-bar': 'is',
        'is null': 'is none',
        'in': 'is'
      })[filter.filterOperator]
      if (operator === undefined) {
        operator = filter.filterOperator
      }

      // special case for date ranges
      if (this.isDateRange && !this.isNoneQuery) {
        // custom range
        operator = ({
          '>=': 'from',
          '<=': 'to',
          '>': 'after',
          '<': 'before'
        })[filter.filterOperator] || operator
      }

      return RSVP.Promise.resolve(this.valueToString(filter.filterValue))
        .then(value => [
          { operator },
          { value }
        ])
    }
  },

  pills: computed('query', function () {
    const promise = RSVP.Promise.all((this.query || []).map(q => this.filterToString(this.genericSearchLibs.removeRedundantFilters(q, true, true))))
      .then(pills => pills.filter(q => q !== undefined && q.length > 0))
    return DS.PromiseArray.create({ promise })
  })
})
