import Component from '@ember/component'
import { set, computed } from '@ember/object'
import { inject } from '@ember/service'
import { getOwner } from '@ember/application'
import { dateRangeFilterOperatorList } from '../../../../../helpers/format-date-filter'

import moment from 'moment'

export default Component.extend({
  tagName: '',
  genericSearchLibs: inject(),
  genericSearch: inject(),

  dateValueLower: null,
  dateValueUpper: null,

  filterOperator: '',

  filterOperatorList: computed('template.optionValues', function () {
    return dateRangeFilterOperatorList(this.template.optionValues)
  }),

  isNoneQuery: computed(function () {
    if (this.filter.templateFilterOperator) {
      return Boolean(this.filter.templateFilterOperator === 'None')
    }
  }),

  disableDateFilter: computed(function () {
    if (this.filter.templateFilterOperator) {
      return Boolean(this.filter.templateFilterOperator === '')
    }
  }),

  didReceiveAttrs () {
    set(this, 'templateFilterOperator', this.filter.templateFilterOperator || '')
    set(this, 'dateValueLower', this.getFilterValue('>=') || null)
    set(this, 'dateValueUpper', this.getFilterValue('<=') || null)
  },

  // The format used to display the currently set date
  dateFormat: null,
  init () {
    this._super(...arguments)

    // Need to use this awkward syntax to get the application's environment settings, rather than the addon's
    this.dateFormat = getOwner(this).resolveRegistration('config:environment').dateFormat

    // Set filterOperator when date-range component is initialized
    this.filterOperator = this.filter.templateFilterOperator ? this.filter.templateFilterOperator : ''
  },

  // Use a custom function to parse dates entered by the user as text
  parseDate (dateString, format) {
    const dateFormats = [
      format, // The same format as used for display
      'D/M/YYYY', // Standard NZ date formatting
      'YYYY-M-D' // ISO standard date formatting
    ]
    return moment(dateString, dateFormats).toDate()
  },

  filters: computed.alias('filter.filterSet.filters'),

  getFilterValue (filterOperator) {
    if (this.isNoneQuery) {
      return undefined
    }
    const filter = (this.filters || []).find(f => f.filterOperator === filterOperator)
    return filter ? filter.filterValue : undefined
  },

  // Javascript date objects for passing to the date picker component's min/max date properties
  dateLower: computed('dateValueLower', function () {
    return moment(this.dateValueLower).toDate()
  }),
  dateUpper: computed('dateValueUpper', function () {
    return moment(this.dateValueUpper).toDate()
  }),

  dateInputClasses: computed('isMapMode', function () {
    const classes = ['c-form-date-field__input']
    if (this.isMapMode) {
      classes.push('c-form-date-field__input--small')
    }
    return classes.join(' ')
  }),

  actions: {
    setFilterOperator (filterOperator) {
      set(this, 'filterOperator', filterOperator)
      this.actions.setDate.call(this)
    },
    setDate (bound, date) {
      if (this.filterOperator === '') { // custom date range
        const dateValue = date ? moment(date).format('YYYY-MM-DD') : null
        if (bound === 'lower') {
          set(this, 'dateValueLower', dateValue)
        } else if (bound === 'upper') {
          set(this, 'dateValueUpper', dateValue)
        }
      } else if (this.filterOperator === 'None') {
        set(this, 'dateValueLower', null)
        set(this, 'dateValueUpper', null)
      } else {
        // set local values
        const { getMomentLower, getMomentUpper } = this.filterOperatorList.find(f => f.value === this.filterOperator)
        set(this, 'dateValueLower', getMomentLower ? getMomentLower().format('YYYY-MM-DD') : null)
        set(this, 'dateValueUpper', getMomentUpper ? getMomentUpper().format('YYYY-MM-DD') : null)
      }

      if (this.filterOperator === 'None') { // Return records with NULL values
        this.setValue({
          templateFilterOperator: this.filterOperator,
          filterSet: {
            booleanOperator: 'OR',
            filters: [
              {
                filterKey: this.template.filterKey,
                filterOperator: 'is null'
              }
            ]
          }
        })
      } else {
        this.setValue({
          templateFilterOperator: this.filterOperator,
          filterSet: {
            booleanOperator: 'AND',
            filters: [
              {
                filterKey: this.template.filterKey,
                filterOperator: '>=',
                filterValue: this.dateValueLower
              },
              {
                filterKey: this.template.filterKey,
                filterOperator: '<=',
                filterValue: this.dateValueUpper
              }
            ].filter(({ filterValue }) => filterValue)
          }
        })
      }
    }
  }
})
