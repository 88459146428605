import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import { AUTH_EXCEPTIONS } from '../constants/values'

export default class LoginRoute extends Route {
  @inject toastMessages
  @inject intl

  model ({ authError }) {
    if (authError && AUTH_EXCEPTIONS[authError]) {
      this.toastMessages.warning(this.intl.t(AUTH_EXCEPTIONS[authError]))
    }
  }
}
