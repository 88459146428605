import AuthenticatedRoute from './authenticated-route'
import { action } from '@ember/object'

export default class CustomFormRoute extends AuthenticatedRoute {
  // Used to conditionally prevent transition of a form
  // Inherits from AuthenticatedRoute
  // See base/settings/users/edit for example

  // Can override this in the route to allow transition if form is dirty
  preventTransitionIfDirty = true
  isFormDirty = false
  transition = null

  @action
  setFormDirty (dirtyState) {
    this.isFormDirty = !!dirtyState
  }

  @action
  retryTransition () {
    if (this.transition) {
      this.controller.set('showNavDialogue', false)
      this.transition.retry()
    }
  }

  @action
  willTransition (transition) {
    if (this.isFormDirty && this.preventTransitionIfDirty && this.controller) {
      this.controller.set('showNavDialogue', true)
      this.transition = transition
      this.transition.abort()
    }
  }

  @action
  loseChangesAndContinue () {
    this.controller.set('showNavDialogue', false)
    this.isFormDirty = false
    this.transition.retry()
  }

  @action
  suppressModal () {
    this.controller.set('showNavDialogue', false)
  }

  @action
  cancelNavigation () {
    if (this.controller) {
      this.controller.set('showNavDialogue', false)
    }
  }
}
