import { inject } from '@ember/service'
import { get, set } from '@ember/object'
import { getOwner } from '@ember/application'

import { errors } from 'ember-ajax'
import { Promise } from 'rsvp'

import RemoteMethodService from './remote-method'
import { tidyData } from '../libs/dynamic-forms-methods-lib'
import CacheMixin from '../mixins/cache'

export default RemoteMethodService.extend(CacheMixin, {
  contentType: 'application/json; charset=utf-8',
  populateFunctions: inject(),
  flashMessages: inject(),
  router: inject(),

  fetchInstance (instanceId, includeSubForms = false) {
    return this.request(`/forms/fetch-instance/${instanceId}/${String(includeSubForms)}`)
      .then(form => Promise.all([get(this, 'populateFunctions').buildState(form.state, form.formElements), form]))
      .then(([state, form]) => {
        set(form, 'state', state)
        return form
      })
      .catch(error => {
        // Handle the user accessing a form instance they don't have access to.
        // This block would possibly be more appropriate within the routes
        // calling this method, but has been put here instead as it can be
        // defined in a single location and there's not currently any case
        // where we don't want to redirect in this scenario.
        if (errors.isForbiddenError(error)) {
          const message = 'Either the application does not exist or you do not have permission to view it'
          get(this, 'flashMessages.danger')(message)
          get(this, 'router').transitionTo('home-section')
          return
        }
        throw error
      })
  },

  save (data, formElements) {
    data.state = tidyData(data.state)
    return this.put('/forms/save-instance', { data: JSON.stringify(data) })
      .then(form => {
        if (!formElements) return form.state
        return get(this, 'populateFunctions').buildState(form.state, formElements)
      })
  },

  getFormDetails (formKey) {
    return this.request(`/forms/get-forms/${formKey}`)
  },

  /**
   * Retrieve the table row data for the children form-links of this form instance.
   * @param {String|Number} formInstanceId
   * @return {Promise<Object[]>}
   */
  getFormLinkTableData (formInstanceId, formElementName) {
    return this.request(`forms/form-link-table/${formInstanceId}/${formElementName}`)
  },

  /**
   * This fetches the instance_id of a sub-form attached to dynamic for table
   * If the subform has not been inflated, then it will be reinflated first
   */
  getTableChildFormInstanceId (formElementName, formInstanceId, tableRowId) {
    return this.request(`/forms/form-link-inflate-instance/${formInstanceId}/${formElementName}/${tableRowId}`)
      .then(result => {
        if (result.error) {
          throw new Error('Unable to get table child form instance')
        }
        return result.new_instance_id
      })
  },

  /**
   * Deletes a row from a dynamic form table. If the row has not been
   * inflated, then it will be reinflated and then deleted (in order
   * to keep the data consistent)
   */
  deleteTableRow (formElementName, formInstanceId, childFormInstanceId, tableRowId) {
    if (childFormInstanceId == null) {
      childFormInstanceId = ''
    }
    return this.post(`/forms/form-link-table-delete-instance/${formInstanceId}/${childFormInstanceId}/${formElementName}/${tableRowId}`)
      .then(result => {
        if (result.error) {
          throw new Error('Unable to delete row')
        }
        const message = 'Record deleted'
        get(this, 'flashMessages.success')(message)
        return true
      })
  },

  retrieveFormInstance ({ formKey, parentId, startWorkflow }) {
    return this.request(`/forms/retrieve-form-instance-id/${formKey}/${parentId}/${startWorkflow === true ? 'true' : 'false'}`)
  },

  /**
   * This fetches a new form instance for a given versionId
   * If the `createChildInstances = true` arg is passed, then the server will generate instances for each immediate
   * child form.  E.g. create an application instance, and it also generates Applicant Details, Property Details, etc.
   * instances.
   * This is required, because the menu json only returns child instances.  If we just created the Application with no
   * children, there could be no menu.
   * @param {number} versionId
   * @param {boolean} createChildInstances
   * @returns {*|Promise|{title}}
   */
  createNewFormInstance (versionId, createChildInstances = false) {
    return this.post(`/forms/create-new-instance/${versionId}/${String(createChildInstances)}`)
  },

  /**
   * Creates a sub-form and binds it to the form-link. This function has limitations that are described in
   * the python API implementation. This API is a candidate for re-implementation, as it has more arguments
   * than it needs. Ideally it would have the id of the parentElement, and everything else would be determined
   * server-side TODO.
   * @param {number} childFormVersionId - The version Id of the child form
   * @param {number} parentFormInstanceId - The instance id of the parent form
   * @param {number} parentElementName - The container_key of the container that holds the containerElementName
   * @param {string} containerElementName - The container_key of the container that holds the form_link (i.e. is its direct parent)
   * @param {string} formLinkElementName - The field_key of the form-link
   * @returns {integer} - The Id of the newly created child form instance
   */
  createChildFormInstance (childFormVersionId, parentFormInstanceId, parentElementName, containerElementName, formLinkElementName) {
    parentElementName = parentElementName || 'root'
    return this.post(`/forms/create-child-instance/${parentFormInstanceId}/${childFormVersionId}/${parentElementName}/${containerElementName}/${formLinkElementName}`)
      .then(result => result.new_id)
  },

  /**
   * Creates a sub-form for a map orchestrator element and binds it to the orchestrator's designated form-link
   * @param {number} mapOrchestratorElementInstanceId - the form_instance_container.id of the orchestrator. If this
   *                                                    element_id is not the instance_id of an orchestrator, then
   *                                                    the underlying API will walk up the tree until it finds the
   *                                                    orchestrator.
   * @param {string} containerElementName - The container_key of the orchestrator or one of its direct children
   *                                        (only required if the mapOrchestratorElementInstanceId is null)
   * @param {number} parentFormInstanceId - The instance id of the parent form (only required if the
   *                                        mapOrchestratorElementInstanceId is null)
   * @returns {integer} - Promise returning the id of the newly created child form instance
   */
  createMapOrchestratorChildFormInstance (mapOrchestratorElementInstanceId, parentFormInstanceId, containerElementName) {
    mapOrchestratorElementInstanceId = (mapOrchestratorElementInstanceId == null ? '' : mapOrchestratorElementInstanceId)
    parentFormInstanceId = (parentFormInstanceId == null ? '' : parentFormInstanceId)
    containerElementName = (containerElementName == null ? '' : containerElementName)
    return this.post(`/forms/create-child-instance-for-map-orchestrator/${mapOrchestratorElementInstanceId}/${parentFormInstanceId}/${containerElementName}`)
      .then(result => result.new_id)
  },

  deleteInstance (id) {
    return this.delete(`/forms/delete-instance/${id}`)
  },

  fetchMenu (instanceId) {
    return this.request(`/forms/fetch-menu/${instanceId}`)
  },

  /**
   * Requests filtered data for components that pull their options back from the server
   * Caches the values locally so changes to state don't cause a whole bunch of requests
   * Cache gets cleared when the page is refreshed or after 15 mins.
   * If you open another application, the cache isn't cleared but it will generate a different cache key
   */
  fetchFilteredList (instanceId, filterKeyName, filterStateValue) {
    const cache = get(this, 'cache')
    const cacheKey = 'fetchFilteredList_' + [...arguments].join('_')
    const cachedResult = cache.fetch(cacheKey)
    if (cachedResult) return cachedResult

    const request = this.request(`/forms/fetch-filtered-list/${instanceId}/${filterKeyName}/${filterStateValue}`)
    cache.save(cacheKey, request)
    return request
  },

  /**
   * Requests summarised error details for a form and any children
   * todo: not currently used, but left in case we need it
   * @deprecated
   */
  fetchErrorSummary (instanceId) {
    const cache = get(this, 'cache')
    const cacheKey = 'fetchErrorSummary_' + instanceId
    const cachedResult = cache.fetch(cacheKey)
    if (cachedResult) return cachedResult

    const request = this.request(`/forms/fetch-error-summary/${instanceId}`)
    cache.save(cacheKey, request)
    return request
  },

  /**
   * Transitions to a form.
   * If any of the following are not supplied, looks up the values for the current route.
   * @param {integer} id - A form instance id
   * @param {string} route - Ember route name, eg. 'consents-section.summary'
   * @param {integer} parentId - The parent id for the workflow - eg. an application_decision id
   * @param {string} mode - 'view', 'edit' etc.
   * @param {string} formRootPath - the workflow name, ie. the `path` variable defined in the router
   * @param {string} skipNavigationCheck - Set to true to skip the check for unsaved changes to the form state
   */
  transitionToForm ({ id, route, parentId, mode, formRootPath, skipNavigationCheck } = {}) {
    const currentRoute = getOwner(this).lookup('controller:application').currentPath
    if (route === undefined) {
      route = currentRoute
    }
    const controllerName = `controller:${currentRoute}`
    const controller = getOwner(this).lookup(controllerName)
    const model = controller.model
    const router = this.router

    if (parentId === undefined) {
      parentId = model.parentId
    }
    if (mode === undefined) {
      mode = model.mode
    }
    if (formRootPath === undefined) {
      formRootPath = model.formRootPath
    }
    if (skipNavigationCheck === true) {
      set(controller, 'skipNavigationCheck', true)
    }

    router.transitionTo(route, parentId, mode, [formRootPath, id].filter(x => x !== undefined).join('/'))
  },

  /**
   * Makes a request to the server to get a list of history records
   */
  getHistoryTable (parentId, parentIdType, targetIdType, sortField = 'created_timestamp', sortDirection = 'DESC') {
    const query = {
      parent_row_id: parentId,
      parent_row_type: parentIdType,
      target_row_type: targetIdType,
      sort_field: sortField,
      sort_direction: sortDirection
    }
    return this.request('forms/history-table', query)
  },

  /**
   * Gets the text to display in the menu for a given form.  Handles the situation when an alias is set, both in the
   * current form or in a child form
   */
  getFormLabel (form) {
    const aliasFormLocation = get(form, 'aliasFormLocation')
    if (aliasFormLocation) {
      // Need to get the alias from a child form
      const childForm = get(form, 'forms').find(childForm => get(childForm, 'templateKey') === aliasFormLocation)

      // ============================================ WARNING =========================================================
      // Warning: There's no reason label == childFormAlias (default form `name`) other than convention
      // This is BRITTLE as hell, but the actual form `name` is not available in the form json and this is the only way
      // to determine if we do want to grab the childFormAlias from the child form or use the current form default
      // ==============================================================================================================

      if (childForm && get(childForm, 'childFormAlias').trim() !== '' && get(childForm, 'childFormAlias') !== get(childForm, 'label')) return get(childForm, 'childFormAlias')
    }

    if (get(form, 'aliasForParent')) return get(form, 'label')

    return get(form, 'childFormAlias')
  },

  fetchMapData (mapFormElementInstanceId) {
    return this.request(`forms/fetch-map-data/${mapFormElementInstanceId}`)
      .then(({ mapDataQueryResult }) => mapDataQueryResult)
  },

  fetchMapBoundaryGeometry (mapFormElementInstanceId) {
    return this.request(`forms/fetch-map-boundary-geometry/${mapFormElementInstanceId}`)
      .then(({ mapBoundaryGeometryResult: { geometry } }) => geometry)
  }
})
