import Controller from '@ember/controller'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'

export default class BaseHomeReportsController extends Controller {
  @inject pdfPrinter
  @inject router
  @tracked selectedMonth
  @tracked selectedYear
  @tracked disableExportPdfButton = false

  get reportType () {
    const parts = this.router.currentRouteName.split('.')
    return parts[parts.length - 1]
  }

  @action async exportServiceOverviewPdf () {
    try {
      this.disableExportPdfButton = true
      await this.pdfPrinter.generateReportsPdf(this.reportType, this.selectedMonth, this.selectedYear)
    } finally {
      this.disableExportPdfButton = false
    }
  }
}
