import Component from '@ember/component'
import { computed } from '@ember/object'
import { getOwner } from '@ember/application'

import moment from 'moment'

export default Component.extend({
  // The format used to display the currently set date
  dateFormat: computed('container', function () {
    // Need to use this awkward syntax to get the application's environment settings, rather than the addon's
    return getOwner(this).resolveRegistration('config:environment').dateFormat
  }),

  // Use a custom function to parse dates entered by the user as text
  parseDate (dateString, format) {
    const dateFormats = [
      format, // The same format as used for display
      'D/M/YYYY', // Standard NZ date formatting
      'YYYY-M-D' // ISO standard date formatting
    ]
    return moment(dateString, dateFormats).toDate()
  },

  dateValue: computed.alias('filter.filterValue'),

  actions: {
    setDate (date) {
      if (!date) {
        this.setValue({})
      } else {
        this.setValue({
          'filterKey': this.template.filterKey,
          'filterOperator': this.template.filterOperator || '=',
          'filterValue': moment(date).format('YYYY-MM-DD') // pikaday returns "any valid JavaScript date including Date object."
        })
      }
    }
  }

})
