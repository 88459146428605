/* global L */

// Need to import this to get access to L on the global name space.
import proj4leaflet from 'proj4leaflet'
import esri from 'esri-leaflet'

import {} from 'leaflet.markercluster'

export function initialize (/* container, application */) {
  L.esri = esri
  L.Proj = proj4leaflet
  // Fix the browser touch flag so that we can get consistent point sizes.
  L.Browser.touch = false

  // Overriding the Style for leaflet to resolve the issue for leaflet.js 1.5.1
  // sometimes shape.options.editing===undefine , override L.Path.prototype.setStyle just check empty;
  // Reference: https://github.com/Leaflet/Leaflet.draw/issues/943

  // eslint-disable-next-line indent, no-prototype-builtins, semi
  L.Path.prototype.setStyle = function (style) { L.setOptions(this, style); if (this._renderer) { this._renderer._updateStyle(this); if (this.options.stroke && typeof style === 'object' && style.hasOwnProperty('weight')) { this._updateBounds(); } } return this; }; L.Circle.prototype.setStyle = function (style) { L.setOptions(this, style); if (this._renderer) { this._renderer._updateStyle(this); if (this.options.stroke && typeof style === 'object' && style.hasOwnProperty('weight')) { this._updateBounds(); } } return this; };
  // ---------------------------------------------------------------------------------------
}

export default {
  name: 'leaflet-initializer',
  initialize: initialize
}
