import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

const { computed, get, set } = Ember

/**
 * This element is responsible for wrapping the orderable text fields and
 * displaying the common elements (e.g. delete/up/down buttons) before calling
 * the correct specific component depending on whether it's a section or a
 * sub-heading.
 */
export default Ember.Component.extend(DynamicElement, {
  classNames: ['c-orderable-field'],
  classNameBindings: ['number:c-orderable-field--has-number'],

  didReceiveAttrs () {
    this._super(...arguments)
    set(this, 'stateValue.orderableTextFieldNumber.0.val', get(this, 'number'))
  },

  /**
   * Get the child text form element.
   */
  textFormElement: computed('formElement.formElements', function () {
    const formElements = get(this, 'formElement.formElements')
    return formElements.find(item => item.name === 'orderableTextFieldValue')
  }),

  /**
   * Determine which component should be used.
   */
  textFieldComponentName: computed('stateValue.orderableTextFieldIsHeading.0.val', function () {
    const orderableTextFieldIsHeadingVal = get(this, 'stateValue.orderableTextFieldIsHeading.0.val')
    const isHeading = orderableTextFieldIsHeadingVal && orderableTextFieldIsHeadingVal.toString() === 'true'
    return isHeading ? 'sub-heading' : 'section'
  }),

  /**
   * Is this the top item?
   * @return {boolean}
   */
  topItem: computed('visibleIndexes', 'index', function () {
    return get(this, 'visibleIndexes.0') === get(this, 'index')
  }),

  /**
   * Is this the bottom item?
   * @return {boolean}
   */
  bottomItem: computed('visibleIndexes', 'index', function () {
    const visibleIndexes = get(this, 'visibleIndexes')
    return visibleIndexes[visibleIndexes.length - 1] === get(this, 'index')
  })
})
