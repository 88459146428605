import AuthenticatedRoute from '../../authenticated-route'
import { set } from '@ember/object'

import WorkflowTab from '../../../mixins/routes/workflow-tab'

export default AuthenticatedRoute.extend(WorkflowTab, {
  workflows: null,

  init () {
    this._super(...arguments)
    // workflows for this tab should be configured in the supplier-section route
    set(this, 'workflows', [])
  },
  actions: {
    getParentId () {
      return this.controller.model.parentId
    }
  }
})
