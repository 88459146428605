import Helper from '@ember/component/helper'

export default Helper.helper(([b]) => {
  // based on https://stackoverflow.com/a/41402498
  let u = 0
  const s = 1024
  while (b >= s || -b >= s) {
    b /= s
    u++
  }
  return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'iB'
})
