import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
const { computed, get } = Ember

export default Ember.Component.extend(DynamicElement, {
  tagName: '',
  displayValue: computed('stateValue', 'formElement.options.@each.label', function () {
    const stateValue = get(this, 'stateValue')
    if (!stateValue) return null

    const option = get(this, 'formElement.options').find(option => get(option, 'value') === stateValue)
    return (option && option.label) || '-'
  })
})
