import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'
import { timeParse } from 'd3-time-format'
import Table from 'ember-light-table'
import { kebabCase } from 'lodash'
import { FDA_VISIT_TYPES } from '../../constants/values'

export default class RoutineAnnualProgress extends Component {
  @inject router
  @inject intl

  @tracked table
  d3TimeParse = timeParse('%Y-%m-%d');
  @tracked disableViewRoutineProgressAuditSearchLink = false

  routineAnnualAssessment = FDA_VISIT_TYPES.ROUTINE_ANNUAL_ASSESSMENT

  get lines () {
    return [this.createLineData('targetValue'), this.createLineData('completed')]
  }

  createLineData (resultsKey) {
    let count = 0
    return this.args.routineAnnualProgress.map(monthData => {
      count += +monthData[resultsKey]
      return {
        count,
        date: this.d3TimeParse(monthData.startDate)
      }
    })
  }

  get createTableAfterDOMInsert () {
    return this.createTable.bind(this)
  }

  get lineChartIdentifier () {
    return kebabCase('line-chart-revisit-assessment-progress')
  }

  /**
   * Returns: the list of visit types for REVISIT e.g.  ['Revisit', 'Routine Annual Revisit']
  */
  get routineAnnualAssessments () {
    let routineAnnualAssessmentMapping = this.args?.visitTypeMapping[this.routineAnnualAssessment]
    routineAnnualAssessmentMapping = routineAnnualAssessmentMapping ? routineAnnualAssessmentMapping.map((mapping) => mapping[1]) : []
    // remove any duplicate names so that we don't have duplicate filters
    return [...new Set(routineAnnualAssessmentMapping)]
  }

  /**
   * Returns an array of 2 dates:
   * the first date is the first of the month for the first entry of data
   * the second date is the last day of the month for the last entry of data
  */
  get chartDateRange () {
    const auditData = this.lines[0]
    const startDateForChart = new Date(auditData[0]?.date)
    const endDateForChart = new Date(auditData[auditData.length - 1]?.date)
    const lastDayOfMonth = new Date(endDateForChart.getFullYear(), endDateForChart.getMonth() + 1, 0)
    return [startDateForChart.toLocaleDateString('en-CA'), lastDayOfMonth.toLocaleDateString('en-CA')]
  }

  formatDate (startDate) {
    const date = new Date(startDate)
    return date.toLocaleString('en-UK', {
      year: '2-digit',
      month: 'short'
    })
  }

  @action
  viewRoutineProgressAuditSearch () {
    this.disableViewRoutineProgressAuditSearchLink = true
    const internalUserFilters = {
      programme: [this.args.programmeId.toString()],
      visitType: this.routineAnnualAssessments,
      dateCompleted: [this.chartDateRange[0], this.chartDateRange[1]]
    }

    this.router.transitionTo('base.home.search.audit', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(internalUserFilters)
      }
    })
  }

  columns = [
    {
      label: this.intl.t('reports.routine.month'),
      valuePath: 'startDate',
      format: this.formatDate,
      classNames: 'c-table__cell-small c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell-small ember-view',
      ascending: false
    },
    {
      label: this.intl.t('reports.routine.fdas_completed'),
      valuePath: 'completed',
      classNames: 'c-table__cell-small c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell-small ember-view',
      ascending: false
    },
    {
      label: this.intl.t('reports.routine.fdas_target'),
      valuePath: 'targetValue',
      classNames: 'c-table__cell-small c-table__cell--heading c-table__cell--view-heading is-sorted',
      cellClassNames: 'lt-cell align-left c-table__cell-small ember-view',
      ascending: false
    }
  ]

  createTable () {
    let rowState = []
    rowState = this.args.routineAnnualProgress
    this.table = Table.create({ columns: this.columns, rows: rowState, enableSync: true })
  }
}
