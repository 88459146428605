import hbs from 'htmlbars-inline-precompile'
import { withKnobs } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './navbar.notes.md'

export default {
  title: 'Navbar',
  component: 'Navbar',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const basicUsage = () => ({
  template: hbs`<Navbar @fullName="Bill Smith" />`,
  context: {
  }
})
