import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { select } from 'd3-selection'
import { arc, pie } from 'd3-shape'

export default class DialChartComponent extends Component {
  @inject intl
  @inject uniqueId

  @tracked diameter
  @tracked svgContainer
  @tracked dialChartIdentifier
  @tracked tooltipIdentifier = `${this.dialChartIdentifier}-tooltip`
  @tracked showTooltip
  @tracked finished = false
  @tracked tetherTarget
  @tracked disableHover = false

  @tracked hoverSectionCount
  @tracked sectionString
  @tracked iconType
  @tracked iconColor

  @tracked chartData = [
    {
      key: 'passed',
      class: 'dial-chart--passed',
      iconType: this.args?.dialIcon?.passed?.dialIcon,
      iconColor: this.args?.dialIcon?.passed?.iconColor,
      count: this.args.successCount
    },
    {
      key: 'failed',
      class: 'dial-chart--failed',
      iconType: this.args?.dialIcon?.failed?.dialIcon,
      iconColor: this.args?.dialIcon?.failed?.iconColor,
      count: this.args.totalCount - this.args.successCount
    }
  ]

  constructor () {
    super(...arguments)
    this.diameter = this.args.diameter || 200
    this.smallText = this.diameter < 160
    this.lineOneTranslationKey = this.args?.translations?.lineOne
    this.lineTwoTranslationKey = this.args?.translations?.lineTwo
    this.hoverTranslationKey = this.args?.translations?.hover
    this.donutWidth = this.args?.donutWidth || 15
    this.dialChartIdentifier = this.args.dialChartIdentifier || this.uniqueId.next()
    this.showTooltip = this.args?.showTooltip || false
  }

  get percentagePassed () {
    return Math.floor((this.args.successCount / this.args.totalCount) * 100) + '%'
  }

  get radius () {
    return this.diameter / 2
  }

  get svgBBox () {
    return document.getElementById(this.dialChartIdentifier).getBBox()
  }

  get chartDataHasZeroCount () {
    return this.chartData[0]?.count === 0 || this.chartData[1]?.count === 0
  }

  @action
  drawChart (element) {
    const chartArc = arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius - this.donutWidth)

    this.svgContainer = select(element)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${this.diameter} ${this.diameter}`)
      .attr('preserveAspectRatio', 'xMinYMin')
      .select('g')
      .attr('transform', `translate(${this.diameter / 2}, ${this.diameter / 2})`)

    const pieChart = pie()
      .sort(null) // disable default sorting
      .value((d) => d.count)(this.chartData)

    const g = this.svgContainer.selectAll('arc')
      .data(pieChart)
      .enter().append('g')
      .attr('class', this.dialChartIdentifier + '-arc')

    g.append('path')
      .attr('d', chartArc)
      .attr('class', (d, i) =>
        this.chartData[i].class
      )
      .each((d, i) => {
        const centroid = chartArc.centroid(d)
        this.chartData[i].x = centroid[0] + this.radius
        this.chartData[i].y = centroid[1] + this.radius
      })

    if (this.showTooltip) {
      g.on('focus', (d, index) => {
        this.createToolTip(index)
      })
        .on('click', (d, index) => {
          this.createToolTip(index)
        })
        .on('blur', () => {
          this.hideToolTip()
        })
        .on('mouseenter', (d, index) => {
          this.createToolTip(index)
        })
        .on('mouseout', () => {
          this.hideToolTip()
        })
    }
  }

  createToolTip (index) {
    const data = this.chartData[index]
    this.iconType = data.iconType
    this.iconColor = data.iconColor
    this.hoverSectionCount = data.count
    this.sectionString = this.intl.t(this.args.translations[data.key])
    const tooltip = document.getElementById(this.tooltipIdentifier)

    tooltip.style.display = 'block'
    if (this.chartDataHasZeroCount) { // If there is a count of 0 in failed or passed data, move tooltip to top of chart
      tooltip.style.top = 10 + 'px'
      tooltip.style.left = data.x + 'px'
    } else {
      tooltip.style.top = (data.y * (this.svgBBox.height / this.diameter)) + 'px'
      tooltip.style.left = (data.x * (this.svgBBox.width / this.diameter)) + 'px'
      // this.svgBBox gives us the height and width of the SVG element in the DOM, so we can scale the tooltip x/y coordinates with the SVG
    }
  }

  hideToolTip () {
    const tooltip = document.getElementById(this.tooltipIdentifier)
    tooltip.style.display = 'none'
  }
}
