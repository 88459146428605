import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './tag.notes.md'

export default {
  title: 'Tag',
  component: 'Tag',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const info = () => ({
  template: hbs`<Tag>{{label}}</Tag>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const errorMinor = () => ({
  template: hbs`<Tag @state="error-minor">{{label}}</Tag>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const errorMajor = () => ({
  template: hbs`<Tag @state="error-major">{{label}}</Tag>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const warning = () => ({
  template: hbs`<Tag @state="warning">{{label}}</Tag>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const success = () => ({
  template: hbs`<Tag @state="success">{{label}}</Tag>`,
  context: {
    label: text('Label text', 'Label')
  }
})
