import hbs from 'htmlbars-inline-precompile'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './tabs-container.notes.md'

export default {
  title: 'Tabs Container',
  component: 'TabsContainer',
  decorators: [
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const tabsContainer = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <TabsContainer @tabs={{array "Suppliers" "Non-compliant" "Audits due"}} as |tabsContainer|>
        <TabsContainer::TabContent @tab={{"Suppliers"}} @activeTab={{tabsContainer.activeTab}}>
          Suppliers
        </TabsContainer::TabContent>
        <TabsContainer::TabContent @tab={{"Non-compliant"}} @activeTab={{tabsContainer.activeTab}}>
          Non-compliant
        </TabsContainer::TabContent>
        <TabsContainer::TabContent @tab={{"Audits due"}} @activeTab={{tabsContainer.activeTab}}>
          Audits due
        </TabsContainer::TabContent>
      </TabsContainer>
    </ContentContainer>
  `
})
