import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

const { computed, get } = Ember

/**
 * This element is responsible for wrapping the orderable text fields and
 * displaying the common elements (e.g. delete/up/down buttons) before calling
 * the correct specific component based on the type e.g. sub-heading.
 */
export default Ember.Component.extend(DynamicElement, {
  number: computed('stateValue.orderableTextFieldNumber.0.val', function () {
    const val = get(this, 'stateValue.orderableTextFieldNumber.0.val')
    // Very occasionally migrated data has a trailing '.', strip it so there
    // aren't two when displaying.
    return val ? val.replace(/\.+$/, '') : ''
  }),

  /**
   * Get the child text form element.
   */
  textFormElement: computed('formElement.formElements', function () {
    const formElements = get(this, 'formElement.formElements')
    return formElements.find(item => item.name === 'orderableTextFieldValue')
  }),

  /**
   * Determine which component should be used.
   */
  textFieldComponentName: computed('stateValue.orderableTextFieldIsHeading.0.val', function () {
    const orderableTextFieldIsHeadingVal = get(this, 'stateValue.orderableTextFieldIsHeading.0.val')
    const isHeading = orderableTextFieldIsHeadingVal && orderableTextFieldIsHeadingVal.toString() === 'true'
    return 'dynamic-form/view/' + (isHeading ? 'text-to-sub-heading' : 'restricted-rich-text')
  })
})
