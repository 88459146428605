import { inject } from '@ember/service'
import { get, set, computed } from '@ember/object'
import Component from '@ember/component'

import { task, timeout } from 'ember-concurrency'

import { TYPEAHEAD_COMPONENT } from 'client/constants'
const { DEBOUNCE_MS, MIN_CHARS } = TYPEAHEAD_COMPONENT

export default Component.extend({
  addressToLocation: inject('address-to-location'),

  eastingInputValue: '', // testing val: '1673081'
  northingInputValue: '', // testing val: '5406647'

  easting: computed('eastingInputValue', function () {
    return parseFloat(get(this, 'eastingInputValue'))
  }),

  northing: computed('northingInputValue', function () {
    return parseFloat(get(this, 'northingInputValue'))
  }),

  init () {
    this._super(...arguments)
    if (this.preselectedAddress) {
      set(this, 'selected', this.preselectedAddress)
    } else {
      set(this, 'selected', undefined)
    }
  },

  /**
   Search method for the address lookup.
   */
  searchAddress: task(function * (query) {
    if (query.length < MIN_CHARS) return []
    yield timeout(DEBOUNCE_MS)
    return get(this, 'addressToLocation').search(query)
  }),

  actions: {
    /**
     * Resolve the selected address to a set of coordinates and re-center the map.
     */
    setSelected (selected) {
      set(this, 'selected', selected)
      if (typeof this.onAddressChange === 'function') {
        this.onAddressChange(selected)
      }
      const { text, key } = selected
      return get(this, 'addressToLocation').getCoordinates(text, key)
        .then((result) => {
          if (result) {
            this.gotoLatLng(result.lat, result.lng, result.extents)
          }
        })
    },

    resetToDefaultView () {
      this.resetToDefaultView()
    }
  }
})
