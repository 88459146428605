import AjaxService from 'ember-ajax/services/ajax'
import CSRFToken from '../mixins/csrf-token'
import { isForbiddenError } from 'ember-ajax/errors'
import { get } from '@ember/object'
import { inject } from '@ember/service'

const DRF_LOGIN_NEEDED_ERROR = 'Authentication credentials were not provided.'

/**
 * Extends the Ajax service, with settings common for calls to our API.
 *
 * Adds a CSRF Token to the headers property using the CSRFToken mixin, and
 * sets the base path. All calls to our API outside of Ember Data should use
 * this Service.
 */
export default AjaxService.extend(CSRFToken, {
  session: inject(),
  flashMessages: inject(),
  router: inject(),

  namespace: '/api',

  /**
   * Builds a properly escaped query string.
   * @param {Object} query Key/Value object.
   * @return {string}
   */
  buildQueryString (query) {
    return Object.keys(query === undefined ? {} : query)
      .map((key) => ({ key, value: query[key] }))
      .filter(({ value }) => value !== undefined)
      .map(({ key, value }) => (`${key}=${encodeURIComponent(value)}`))
      .join('&')
  },

  request () {
    return this._super(...arguments)
      .catch(error => {
        // Detect a user not logged in error and redirect them to the login page
        if (isForbiddenError(error) && get(error, 'errors.0.detail.detail') === DRF_LOGIN_NEEDED_ERROR) {
          get(this, 'flashMessages.warning')('You must be logged in to access that')
          get(this, 'router').transitionTo('home-section', { queryParams: { loginModal: true, returnTo: window.location.pathname } })
          return
        } else if (isForbiddenError(error)) {
          console.log(error)
          this.session.updateCurrentUser()
          const message = 'Either the resource does not exist or you do not have permission to view it'
          get(this, 'flashMessages.danger')(message)
          get(this, 'router').transitionTo('home-section')
          return
        }
        throw error
      })
  }
})
