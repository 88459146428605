import { inject } from '@ember/service'
import AuthenticatedRoute from '../../authenticated-route'

export default class HomeNonConformanceInsightsRoute extends AuthenticatedRoute {
  @inject insightsRemoteMethods
  routePermission = [
    'view__non_conformance_insights'
  ]

  model () {
    return this.insightsRemoteMethods.fetchNonConformanceInsights().then(insights => {
      return { insights }
    })
  }
}
