/* global Raygun */
import AuthenticatedRoute from '../../../authenticated-route'
import { inject } from '@ember/service'

export default class SwitchUserRoute extends AuthenticatedRoute {
  @inject session
  @inject toastMessages
  @inject router
  @inject intl

  async beforeModel (transition) {
    const userId = transition.to.params.user_id
    const res = await this.session.switchUser(userId)

    if (!res.error) {
      window.location.href = '/'
      return
    }

    if (res.error === 'INVALID_USER') {
      this.toastMessages.danger(this.intl.t('settings.user.impersonate_error'))
      this.router.transitionTo('base.settings.users')
    } else {
      Raygun.send(res)
      this.toastMessages.danger(this.intl.t('settings.user.impersonate_error'))
      this.router.transitionTo('base.home.dashboard')
    }
  }

  async model () {
    return {}
  }
}
