import Component from '@ember/component'
import { get, set } from '@ember/object'

export default Component.extend({
  classNames: ['c-modal-content'],
  actions: {
    overlayClicked () {
      // clicking on the overlay should do nothing if the close button is hidden
      if (get(this, 'hideCloseButton') !== true) {
        set(this, 'displayModal', false)
      }
    }
  }
})
