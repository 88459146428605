import Route from '@ember/routing/route'
import { inject } from '@ember/service'
import RSVP from 'rsvp'

export default class SupplierFarmMapPrintRoute extends Route {
  @inject mappingRemoteMethods
  @inject router

  async model (params) {
    // Parent = Enterprise Client Id

    const parent = params.parent
    const formInstanceId = params.formInstanceId

    try {
      return RSVP.hash(
        {
          parent,
          formInstanceId,
          farmData: await this.mappingRemoteMethods.fetchFarmMapBoundaries(parent),
          config: await this.mappingRemoteMethods.fetchFarmMapFeaturesConfig(parent),
          features: await this.mappingRemoteMethods.fetchFarmMapFeatures(parent)
        }
      )
    } catch (e) {
      this.router.transitionTo('base.not-found')
    }
  }
}
