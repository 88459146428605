import Helper from '@ember/component/helper'
import moment from 'moment'

export function dateRangeFilterOperatorList (optionValues) {
  // if the optionValues variable is available in JSON config then start adding the dropdown options for date ranges
  if (optionValues) {
    // this will be default blank value to start of the dropdown
    const tempFilterOperatorList = [{
      value: '',
      label: 'Custom'
    }]

    optionValues.forEach(dateText => {
      // dateText will be option values in dropdown selection, and date value will be the unique key value required for date range values
      // example: dateText is "This calendar month" then date value will be "this-calendar-month"
      const dateValue = dateText.toLowerCase().split(' ').join('-')
      switch (dateText) {
        case 'Today':
          tempFilterOperatorList.push({
            value: dateValue,
            label: dateText,
            getMomentUpper: moment, // the current date
            getMomentLower: moment, // the current date
            getPillText: () => [{ operator: 'is' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'Tomorrow':
          tempFilterOperatorList.push({
            value: dateValue,
            label: dateText,
            getMomentUpper: () => moment().add(1, 'days'), // Tomorrow's date
            getMomentLower: () => moment().add(1, 'days'), // Tomorrow's date
            getPillText: () => [{ operator: 'is' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'Yesterday':
          tempFilterOperatorList.push({
            value: dateValue,
            label: dateText,
            getMomentUpper: () => moment().subtract(1, 'days'), // Yesterday's date
            getMomentLower: () => moment().subtract(1, 'days'), // Yesterday's date
            getPillText: () => [{ operator: 'is' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'This calendar month':
          tempFilterOperatorList.push({
            value: dateValue,
            label: dateText,
            getMomentUpper: () => moment().endOf('month'), // Considering todays date is 19th July, then the this will be 31st July
            getMomentLower: () => moment().startOf('month'), // Considering todays date is 19th July, then the this will be 1st July
            getPillText: () => [{ operator: 'is' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'Last calendar month':
          tempFilterOperatorList.push({
            value: dateValue,
            label: dateText,
            getMomentUpper: () => moment().subtract(1, 'months').endOf('month'), // Considering todays date is 19th July, then the this will be 30th June
            getMomentLower: () => moment().subtract(1, 'months').startOf('month'), // Considering todays date is 19th July, then the this will be 1st June
            getPillText: () => [{ operator: 'in the' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'Next calendar month':
          tempFilterOperatorList.push({
            value: dateValue,
            label: dateText,
            getMomentUpper: () => moment().add(1, 'months').endOf('month'), // Considering todays date is 19th July, then the this will be 30th August
            getMomentLower: () => moment().add(1, 'months').startOf('month'), // Considering todays date is 19th July, then the this will be 1st August
            getPillText: () => [{ operator: 'in the' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'None':
          tempFilterOperatorList.push({
            value: 'None',
            label: dateText,
            getMomentUpper: null,
            getMomentLower: null,
            getPillText: () => [{ operator: 'is' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'Last 18 months':
          tempFilterOperatorList.push({
            'value': dateValue,
            'label': dateText,
            'getMomentUpper': moment, // the current date
            'getMomentLower': () => moment().subtract(18, 'months'), // Considering todays date is 19th July 2021, then the this will be 19th January
            'getPillText': () => [{ operator: 'in the' }, { value: dateText.toLowerCase() }]
          })
          break
        case 'Last 3 years':
          tempFilterOperatorList.push({
            'value': dateValue,
            'label': dateText,
            'getMomentUpper': moment, // the current date
            'getMomentLower': () => moment().subtract(36, 'months'), // Considering todays date is 19th July 2021, then the this will be 19th July, 2018
            'getPillText': () => [{ operator: 'in the' }, { value: dateText.toLowerCase() }]
          })
          break
        default: {
          // Find the time period requested, its days or months or weeks or years from text.
          const timePeriod = dateText.trim().toLowerCase().split(' ').slice(-1)[0] // If the text is "Next 2 days", then timePeriod will be "days"

          // Find the past or future date ranges
          // this variable will help to set the upper limit and lower limit value of a date ranges
          const isPastDateRange = dateText.includes('Last') // If the text is "Next 2 days", then this will be false. If the text is "Last 2 days", then this will be true.

          // Find the number of timePeriod requested
          const number = parseInt(dateText.replace(/[^0-9]/g, '')) // // If the text is "Next 2 days", then this will return value "2".

          // Is it checking for calendar month
          const isCalendarRange = dateText.includes('calendar') // If the text is "Next 2 months", then this will be false. If the text is "Next 2 calendar months", then this will be true.

          if (timePeriod === 'days') {
            // Build the object
            tempFilterOperatorList.push({
              value: dateValue,
              label: dateText,
              getMomentUpper: () => (isPastDateRange) ? moment() : moment().add((number - 1), timePeriod),
              getMomentLower: () => (isPastDateRange) ? moment().subtract((number - 1), timePeriod) : moment(),
              getPillText: () => [{ operator: 'in the' }, { value: dateText.toLowerCase() }]
            })
          } else {
            // removing "s" from months and years for moment
            const currentDateRange = timePeriod.substring(0, timePeriod.length - 1)
            if (isCalendarRange) {
              tempFilterOperatorList.push({
                value: dateValue,
                label: dateText,
                getMomentUpper: () => (isPastDateRange) ? moment().subtract(1, timePeriod).endOf(currentDateRange) : moment().add(number, timePeriod).endOf(currentDateRange),
                getMomentLower: () => (isPastDateRange) ? moment().subtract(number, timePeriod).startOf(currentDateRange) : moment().add(1, timePeriod).startOf(currentDateRange),
                getPillText: () => [{ operator: 'in the' }, { value: dateText.toLowerCase() }]
              })
            } else {
              tempFilterOperatorList.push({
                value: dateValue,
                label: dateText,
                getMomentUpper: () => (isPastDateRange) ? moment() : moment().add(number, timePeriod),
                getMomentLower: () => (isPastDateRange) ? moment().subtract(number, timePeriod) : moment(),
                getPillText: () => [{ operator: 'in the' }, { value: dateText.toLowerCase() }]
              })
            }
          }
          break
        }
      }
    })
    return tempFilterOperatorList
  }
}

export default Helper.helper(dateRangeFilterOperatorList)
