import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'
import { copy } from 'ember-copy'
import { deDupErrors } from '../../../../libs/validation-error-lib'

import { get, set } from '@ember/object'

export default Ember.Component.extend(DynamicElement, ComplexElement, {
  uniqueId: Ember.inject.service(),
  currentlySelected: null,
  inputId1: Ember.computed(function () {
    return this.uniqueId.next()
  }),
  inputId2: Ember.computed(function () {
    return this.uniqueId.next()
  }),

  contactEmail: Ember.computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'contactEmail')
  }),

  contactConfirmEmail: Ember.computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'contactConfirmEmail')
  }),

  contactEmailValue: Ember.computed('stateValue.contactEmail.0.val', function () {
    return get(this, 'stateValue.contactEmail.0.val')
  }),

  contactConfirmEmailValue: Ember.computed('stateValue.contactConfirmEmail.0.val', function () {
    return get(this, 'stateValue.contactConfirmEmail.0.val')
  }),

  processedErrors: Ember.computed('state', 'formElementState', 'stateValue.contactEmail.0.val', 'stateValue.contactConfirmEmail.0.val', function () {
    const values = Object.keys(this.stateValue).map(key => {
      const currentValue = get(this, `stateValue.${key}.0`)
      currentValue.errors = currentValue.errors || []
      if (currentValue && currentValue.val) {
        if (!currentValue.val.match(/^[^@\s]+@[^@\s]+(\.[^@\s]+)+$/)) {
          currentValue.errors.push(
            { type: 'INVALID', description: 'Must be a valid email address' }
          )
        } else {
          currentValue.errors = currentValue.errors.filter(e => e.type !== 'INVALID')
        }
      }

      return { key, val: currentValue.val, errors: currentValue.errors }
    })

    const errors = {}
    values.forEach(v => {
      errors[v.key] = []
      if (v.errors) {
        errors[v.key] = deDupErrors(v.errors)
      }
    })

    if (values[0].val && values[1].val && values[0].val !== values[1].val) {
      values.forEach(value => {
        if (!value.errors.find(e => e.description === 'These fields must match')) {
          errors[value.key].push('These fields must match')
        }
      })
    }

    if (values[0].val === values[1].val) {
      Object.keys(errors).map(key => {
        errors[key] = errors[key].filter(e => e !== 'These fields must match')
      })
    }

    return errors
  }),

  actions: {
    setSelected (formElement) {
      this.currentlySelected = formElement
    },

    /**
     * Set the value of the form element.
     * @param value
     */
    setValue (value) {
      let state = copy(get(this, 'state'), true)
      const emailElement = this.currentlySelected
      const emailWrapperElement = this.formElement
      let elementState = state[emailWrapperElement.name][0].val[emailElement.name]
      // Cope with empty state issues
      if (!state) state = {}
      if (!elementState) elementState = []
      const valToSave = String(value).trim() ? String(value) : null

      elementState[0] = Object.assign({ id: 'new' }, elementState[0], { val: valToSave })
      set(this, `formElementState.0.val.${emailElement.name}.0.val`, value)

      this.updateStateKey(emailWrapperElement.name, this.formElementState, this.index)
    }
  }
})
