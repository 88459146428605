import Ember from 'ember'
import moment from 'moment'

const { computed, get } = Ember

export default Ember.Component.extend({
  cellValue: computed('row', 'column', function () {
    const cellVal = (get(get(this, 'row'), get(this, 'column.valuePath')))
    if (cellVal && typeof cellVal === 'object' && 'data' in cellVal) {
      return (cellVal.data)
    }
    if (get(this, 'column.format')) {
      return get(this, 'column.format')(cellVal)
    } else {
      if (get(this, 'column.config.type') === 'date') {
        return cellVal ? moment(cellVal).format('DD MMMM YYYY') : '-'
      }
      return cellVal
    }
  })
})
