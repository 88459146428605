import AuthenticatedRoute from '../authenticated-route'
import { inject } from '@ember/service'

export default class BaseNotFoundRoute extends AuthenticatedRoute {
  @inject intl

  model () {
    return {
      crumbs: [
        {
          route: 'base', label: 'tabs.dashboard'
        },
        {
          label: 'tabs.not_found'
        }
      ]
    }
  }
}
