/* global L */

import MapLibBaseService from './map-lib-base'
import config from '../config/environment'
import { LINZ_BASE_MAP_URL } from 'client/constants/mapping'

export default class MapLibService extends MapLibBaseService {
  getBaseLayers () {
    const topoLayer = L.tileLayer(`${LINZ_BASE_MAP_URL}?api=${config.ENV_VARS.LINZ_KEY}`, {
      maxZoom: 20,
      subdomains: 'abc', // This will allow Leaflet to get tiles from all subdomains
      attribution: '<a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">LINZ CC BY 4.0 © Imagery Basemap contributors</a>'
    })

    return L.layerGroup([topoLayer])
  }

  addInitialLayersAndMarkers (map) {
    // This is the place to add any additional config to the map after it has been initialised.
    // Can:
    // * Add any additional layers
    // * Add any additional markers
    // * Update exitsting controls on the map

    map.attributionControl.setPosition('bottomright')
  }

  /**
   * Default Center to use.
   * Centers on Nelson as a rough centroid for NZ.
   */
  defaultCenter = L.latLng(-40.5268616, 170.4194458)

  mapDefaultZoom = 6
  mapMinZoom = 3
  mapMaxZoom = 18
  sleep = false

  maxClusteringZoomLevel = 13

  // WGS 84 / Pseudo-Mercator -- Spherical Mercator, Google Maps, OpenStreetMap, Bing, ArcGIS, ESRI
  crs = L.CRS.EPSG3857
  defaultIcon = new L.Icon(Object.assign({ iconUrl: '/assets/markers/marker-generic.svg' }, { iconSize: new L.Point(15, 18) }))
}
