import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { LINZ_BASE_MAP_URL } from 'client/constants/mapping'
import config from '../../../config/environment'

export default class FullScreenMap extends Component {
  @inject router
  @inject farmMaps
  @inject pdfPrinter

  @tracked showMap = true
  @tracked isLoading = false
  @tracked disableExportPdfButton = false

  divId = 'full-screen-farm-map'
  tilePath = `${LINZ_BASE_MAP_URL}?api=${config.ENV_VARS.LINZ_KEY}`

  constructor () {
    super(...arguments)
    this.isLoading = true
    this.enterpriseClientId = this.args.farmMapData.parent
    this.farmMaps.zoomControlElementInserted = false
    this.farmMaps.selectedFeature = {}
  }

  get createMapAfterDomInsert () {
    return this.initMap.bind(this)
  }

  async initMap () {
    if (!this.farmMaps.farmData) {
      this.navToNotFound()
    }

    try {
      await this.farmMaps.fetchMapData(this.enterpriseClientId)
      this.farmMaps.createMap(this.divId, this.tilePath)
      this.farmMaps.addFeatures()
    } catch (e) {
      this.navToNotFound()
    } finally {
      this.isLoading = false
    }
  }

  @action backToSupplier () {
    // Route: Requires the string "supplier/" to activate the supplier workflow
    this.router.transitionTo('base.supplier-section.supplier', this.args.farmMapData.parent, 'view', `supplier/${this.args.farmMapData.formInstanceId}`)
  }

  @action async exportPdf () {
    // Adds page state to query params
    const selectedFeatureNames = this.farmMaps.configFeatures
      ? Object.values(this.farmMaps.configFeatures)
        ?.filter(feature => feature.isActive)
        ?.map((feature) => feature.name)
      : []
    const boundingBox = this.farmMaps.map.getBounds()
    try {
      this.disableExportPdfButton = true
      await this.pdfPrinter.generateFullScreenMapPdf(selectedFeatureNames, boundingBox)
    } finally {
      this.disableExportPdfButton = false
    }
  }

  navToNotFound () {
    this.router.transitionTo('base.not-found')
  }
}
