import Component from '@glimmer/component'
import { inject } from '@ember/service'
import { CARD_STATES } from '../../constants/values'

export default class InformationPanel extends Component {
  @inject router
  @inject warden

  constructor () {
    super(...arguments)
    this.status = this.args.status
  }

  get icon () {
    return CARD_STATES[this.status].iconType
  }

  get iconColor () {
    return CARD_STATES[this.status].iconColor
  }

  get cardColor () {
    return CARD_STATES[this.status].cardStatus
  }
}
