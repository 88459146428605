import TabbedSearchController from 'client/mixins/controllers/tabbed-search-controller'
import { inject } from '@ember/service'

export default class BaseHomeSearchProgrammeController extends TabbedSearchController {
  @inject featureFlags
  @inject warden

  get searchTypeKey () {
    if (this.warden.canAny(['view__search__programme_search__dairy_restricted'])) {
      return 'search-programmes-dairy-restricted'
    }
    return 'search-programmes'
  }
}
