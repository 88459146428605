import { inject } from '@ember/service'
import { hash } from 'rsvp'
import AuthenticatedRoute from '../../../authenticated-route'

export default class AddCustomFieldRoute extends AuthenticatedRoute {
  @inject customFieldsRemoteMethods

  routePermissions = [
    'edit__custom_fields'
  ]

  model () {
    return hash({
      fieldTypes: this.customFieldsRemoteMethods.fetchCustomFieldTypes()
    })
  }
}
