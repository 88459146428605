import Component from '@ember/component'
import { set, computed } from '@ember/object'
import { inject } from '@ember/service'
import { task } from 'ember-concurrency'

const MIN_CHARS = 3

export default Component.extend({
  tagName: '',
  remoteMethods: inject('generic-search-remote-methods'),

  defaultOptions: null,
  init () {
    this._super(...arguments)
    set(this, 'defaultOptions', [])
  },

  minFilterLength: computed('template', function () {
    return this.template.optionsMinFilterLength !== undefined ? this.template.optionsMinFilterLength : MIN_CHARS
  }),

  didReceiveAttrs () {
    if (this.filter && this.filter.filterValue) {
      this.remoteMethods.getLookupDisplayValue({
        searchTypeKey: this.searchTypeKey,
        optionsKey: this.template.optionsKey,
        internalValue: this.filter.filterValue
      }).then(({ lookupDisplayValueResult }) => set(this, 'selectedDisplayValue', lookupDisplayValueResult.displayValue))
    }
    if (this.minFilterLength === 0) {
      this.searchTask.perform('').then(results => set(this, 'defaultOptions', results))
    }
  },

  selected: computed('filter', 'selectedDisplayValue', function () {
    if (this.filter) {
      return {
        display_value: this.selectedDisplayValue,
        id: this.filter.filterValue
      }
    }
  }),

  selectedDisplayValue: null,

  searchTask: task(function * (filter) {
    if (filter.length < this.minFilterLength) {
      return
    }
    return this.remoteMethods.getLookupResult({
      searchTypeKey: this.searchTypeKey,
      optionsKey: this.template.optionsKey,
      filter
    }).then(({ lookupResult }) => {
      return lookupResult.rows
    })
  }).keepLatest(),

  actions: {
    setSelected (selected) {
      if (selected) {
        this.setValue({
          'filterKey': this.template.filterKey,
          'filterOperator': this.template.filterOperator || '=',
          'filterValue': `${selected.id}`
        })
        set(this, 'selectedDisplayValue', selected.display_value)
      } else {
        // when the clear (x) button is clicked, the power select component passes null to this function
        set(this, 'selectedDisplayValue', null)
        this.setValue({})
      }
    }
  }
})
