import hbs from 'htmlbars-inline-precompile'
import { withKnobs } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './icon-metric.notes.md'

export default {
  title: 'Icon Metric',
  component: 'IconMetric',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const iconMetric = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <IconMetric @iconType="user-simple">
        96 suppliers in programme
      </IconMetric>
    </ContentContainer>
  `
})

export const redIcon = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <IconMetric @iconType="error-outline" @iconColor="red">
        3 non-conformances
      </IconMetric>
    </ContentContainer>
  `
})

export const withAssociatedActions = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <IconMetric @iconType="error-outline" @iconColor="red">
        3 non-conformances
        <IconMetric::AssociatedActions>
          <TextAction @route="base">Show list</TextAction>
          <TextAction @route="base">View on map</TextAction>
        </IconMetric::AssociatedActions>
      </IconMetric>
    </ContentContainer>
  `
})
