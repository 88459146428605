import { action } from '@ember/object'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'
import { ORDERED_CHART_COLORS } from 'client/constants/values'
import { scaleLinear, scaleOrdinal } from 'd3-scale'
import { select } from 'd3-selection'
import { stack } from 'd3-shape'

export default class ChartsHorizontalComponent extends Component {
  @inject uniqueId

  @tracked height
  @tracked width
  @tracked data
  @tracked count
  @tracked dataSubgroups
  @tracked barChartIdentifier
  @tracked totalItems
  @tracked tetherTarget
  @tracked withToolTip
  @tracked tooltipIdentifier
  @tracked selectedColour
  @tracked selectedKey
  @tracked sectionAuditCount

  barChartId = 0

  constructor () {
    super(...arguments)
    this.data = this.args.data
    this.count = this.args.count
    this.width = this.args.width
    this.height = this.args.height
    this.totalItems = this.args.count
    this.dataSubgroups = this.args.dataSubgroups
    this.barChartIdentifier = this.args?.barChartIdentifier || this.uniqueId.next()
    this.colors = this.args.colors || ORDERED_CHART_COLORS
    this.legendTranslations = this.args.legendTranslations
    this.tooltipIdentifier = `${this.barChartIdentifier}-tooltip`
    this.withToolTip = this.args?.withToolTip
    this.hideLegend = this.args.hideLegend
    this.hasViewBox = this.args.hasViewBox
  }

  incrementClass () {
    const classString = `rect_${this.barChartId += 1}`
    return classString
  }

  @action
  drawChart (element) {
    const svg = select(element)
    const subgroups = this.dataSubgroups.reverse()
    const colors = this.colors.slice(0, subgroups.length).reverse()
    const withToolTip = this.withToolTip

    const stackedData = stack()
      .keys(subgroups)(this.data).map((data, index) => {
        data.map(d => {
          d.rectClass = this.incrementClass()
          d.key = subgroups[index]
          return d
        })
        return data
      })

    const maxXVal = stackedData.reduce((max, row) => {
      const colMax = Math.max(...row.map(col => col[1]))
      max = colMax > max ? colMax : max
      return max
    }, 0)

    const x = scaleLinear()
      .domain([0, maxXVal])
      .range([this.width, 0])

    this.colourFn = scaleOrdinal()
      .domain(subgroups)
      .range(colors)

    const createTooltip = this.createToolTip
    svg.append('g')
      .selectAll('g')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('fill', d => {
        return this.colourFn(d.key)
      })
      .selectAll('rect')
      // Stack all rectangles
      .data(d => d)
      .enter()
      .append('rect')
      .attr('x', d => x(d[1]))
      .attr('width', d => {
        // data object stores the start and end point of the rectangle, e.g. a width 3 rectangle might
        // be stored in d as 6-9. Hence subtracting.
        return x(d[0]) - x(d[1])
      })
      .attr('height', 40)
      .attr('class', d => d.rectClass)
      .on('click', function (d) {
        if (withToolTip) {
          createTooltip(d, this)
        }
      })
      .on('focus', function (d) {
        if (withToolTip) {
          createTooltip(d, this)
        }
      })
      .on('blur', () => {
        if (withToolTip) {
          this.hideToolTip()
        }
      })
      .on('mouseenter', function (d) {
        if (withToolTip) {
          createTooltip(d, this)
        }
      })
      .on('mouseout', () => {
        if (withToolTip) {
          this.hideToolTip()
        }
      })
  }

  createToolTip = (d, rect) => {
    this.sectionAuditCount = d.data[d.key]
    this.selectedColour = this.colourFn(d.key)
    this.selectedKey = d.key

    const tooltip = document.getElementById(this.tooltipIdentifier)
    tooltip.style.display = 'block'
    tooltip.style.left = rect.getBBox().x + (rect.getBBox().width / 2) + 'px'
    tooltip.style.top = rect.getBBox().height / 2 + 'px'
  }

  hideToolTip () {
    const tooltip = document.getElementById(this.tooltipIdentifier)
    tooltip.style.display = 'none'
  }

  get totalItemsSet () {
    return this.totalItems != null
  }
}
