import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class BulkUpdateActions extends Component {
  @tracked showBulkUpdateEditInput = false
  @tracked customFieldName = this.args.customFieldName.toLowerCase()
  @tracked bulkUpdateInput = ''

  @action
  onUpdateClick () {
    this.showBulkUpdateEditInput = !this.showBulkUpdateEditInput
  }

  @action
  onDeleteClick () {
    this.args.bulkDeleteRecords()
  }

  // This functionality to be tackled in another ticket
  @action
  onApplyClick () {

  }

  @action
  captureBulkUpdateInput () {
    this.args.bulkUpdateSave(this.bulkUpdateInput)
    this.showBulkUpdateEditInput = !this.showBulkUpdateEditInput
    this.bulkUpdateInput = ''
  }
}
