/* global L */

import Component from '@ember/component'
import { set, computed } from '@ember/object'
import { inject } from '@ember/service'
import { A } from '@ember/array'

import { task } from 'ember-concurrency'
import DS from 'ember-data'

import CommonMapMixin from '../../../mixins/components/common-map-mixin'
import { sortBy } from 'lodash'

const SIDEBAR_WIDTH = 300

export default Component.extend(CommonMapMixin, {
  classNames: ['c-map-screen'],
  router: inject(),
  mapLib: inject(),
  remoteMethods: inject('generic-search-remote-methods'),
  genericSearch: inject('generic-search'),
  gateKeeper: inject(),

  appliedFilterCount: 0,
  mapDetailUniqueIdColKey: '',

  zoomControlElementInserted: false,
  zoomControlId: 'leafletZoomControlId',

  maxClusteringZoomLevel: computed.alias('mapLib.maxClusteringZoomLevel'),

  // Used for clustering
  popupItemsIndex: 0,
  // TODO: fix the linting error below
  popupItems: [], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
  popupCurrentId: computed('popupItems', 'popupItemsIndex', 'mapDetailUniqueIdColKey', function () {
    if (!this.popupItems || typeof this.popupItemsIndex !== 'number') {
      return null
    }
    const popupItem = this.popupItems[this.popupItemsIndex]
    return popupItem.options.data[this.mapDetailUniqueIdColKey]
  }),

  // Tracks if the popup is open or closed, required so that Ember Wormhole
  // doesn't go looking for elements that don't exist in the DOM.
  popupOpen: false,

  showLoadingMessage: false,

  // ID for the Popup DOM element.
  popupElementId: computed('elementId', function () {
    return this.elementId + '-popover'
  }),

  // Should the next button show in the popup.
  showNextButton: computed('popupItems', function () {
    return this.popupItems.length > 1
  }),

  // Tracks this so that search filters can be opened or collapsed as they were
  // when the user hid the panel.
  filtersCurrentlyCollapsed: false,

  init () {
    this._super(...arguments)

    // Find the map view.
    const mapView = this.searchTemplate.views.find(({ viewKey }) => viewKey === this.searchTemplate.mapConfig.key)

    // Find the column that is the unique identifier.
    const mapDetailUniqueIdCol = mapView.table.columns.find(({ isMapDetailUniqueIdentifier }) => isMapDetailUniqueIdentifier)
    set(this, 'mapDetailUniqueIdColKey', mapDetailUniqueIdCol.elementKey)

    if (this.searchTemplate.allowsQueryParamFiltering && this.queryParamSearchFilters) {
      this.genericSearch.saveFilterSet(this.searchTypeKey, JSON.parse(this.queryParamSearchFilters))
    }

    // Build the search query up.
    set(this, 'searchQuery', {
      viewKey: mapView.viewKey,
      searchTypeKey: this.searchTypeKey,
      requestedPage: 1,
      requestedPageLength: 100000, // no limit
      filterSet: this.genericSearch.getFilterSet(this.searchTypeKey)
    })
    if (this.gateKeeper.isAuthenticated) {
      this.fetchSavedSearchList()
    }

    // Find the map detailed view.
    const mapDetailView = this.searchTemplate.views.find(({ viewKey }) => viewKey === this.searchTemplate.mapConfig.detailKey)

    // Action for the link in the popup.
    set(this, 'actionConfig', mapDetailView.table.onRowClick)

    // Extract columns relevant to the popup and sort them so they respect the
    // order in the search config.
    const mapDetailCols = []
    mapDetailView.table.columns.forEach(col => {
      if (col.type === 'hidden') return

      if (col.isMapDetailHeading) {
        set(this, 'mapDetailHeadingCol', col)
      } else if (col.isMapDetail) {
        mapDetailCols.push(col)
      }
    })
    set(this, 'mapDetailCols', sortBy(mapDetailCols, ['mapDetailOrder']))
  },

  didInsertElement () {
    this._super(...arguments)
    this.initMap()
    this.searchTask.perform()
  },

  /**
   * Initialise the map.
   */
  initMap () {
    this.createMapAndBaseLayers('.js-map-container')
    const map = this.map
    const maxClusteringZoomLevel = this.maxClusteringZoomLevel
    this.addInitialLayersAndMarkers(map)

    this.extendZoomControls(map)

    set(this, 'popup', L.popup())
    // HTML content for the Popup DOM element. Ember Wormhole will find this by the ID.
    this.popup.setContent(`<div id="${this.popupElementId}" class="c-map-popup"></div>`)

    // Event handlers to track the popup state.
    map.on('popupopen', () => {
      set(this, 'popupOpen', true)
    })
    map.on('popupclose', () => {
      set(this, 'popupOpen', false)
    })

    const component = this

    const markerClusterGroup = new L.markerClusterGroup({ // eslint-disable-line new-cap
      maxClusterRadius: (zoom) => {
        return zoom <= maxClusteringZoomLevel ? 50 : 0
      },
      iconCreateFunction: function (cluster) {
        const childMarkers = cluster.getAllChildMarkers()
        const count = Array.isArray(childMarkers) ? childMarkers.length : 0

        // Check if all the markers in the cluster are in the same location.
        let isSameLocation = false
        if (count) {
          const firstMarkerLatLng = childMarkers[0].getLatLng()
          isSameLocation = childMarkers.every(marker => firstMarkerLatLng.equals(marker.getLatLng()))
        }

        if (isSameLocation) {
          return L.divIcon({
            className: 'c-cluster__pin',
            html: `${component.mapLib.defaultIcon.createIcon().outerHTML}<span class="c-cluster__pin-counter">${count}</span>`,
            iconSize: new L.Point(15, 18)
          })
        }

        const containerClasses = ['c-cluster-icon']
        const countClasses = ['c-cluster-icon__count']
        let size
        // If the number is bigger than 4 digits, add a styling class.
        if (count < 10) {
          containerClasses.push('c-cluster-icon--small')
          countClasses.push('c-cluster-icon__count--small')
          size = 24
        } else if (count < 100) {
          containerClasses.push('c-cluster-icon--medium')
          countClasses.push('c-cluster-icon__count--medium')
          size = 28
        } else if (count < 1000) {
          containerClasses.push('c-cluster-icon--large')
          countClasses.push('c-cluster-icon__count--large')
          size = 32
        } else if (count < 10000) {
          containerClasses.push('c-cluster-icon--xlarge')
          countClasses.push('c-cluster-icon__count--xlarge')
          size = 36
        } else {
          containerClasses.push('c-cluster-icon--xxlarge')
          countClasses.push('c-cluster-icon__count--xxlarge')
          size = 40
        }
        return L.divIcon({
          className: containerClasses.join(' '),
          html: `<span class="${countClasses.join(' ')}">${count}</span>`,
          iconSize: new L.Point(size, size)
        })
      },
      spiderLegPolylineOptions: {
        weight: 2,
        color: '#fff',
        opacity: 0.8
      }
    })

    markerClusterGroup.on('clusterclick', (cluster) => {
      // All children in the cluster.
      const children = cluster.layer.getAllChildMarkers()

      // See if all the points are in the same location or if they differ.
      const first = children[0].getLatLng()
      const sameLocation = !children.some(cm => !first.equals(cm.getLatLng()))

      // See if this cluster already spidered.
      const currentSpidered = cluster.target._spiderfied === cluster.layer

      // If they are all in the same location display the popup to cycle
      // between them in the middle of the cluster icon, the location test
      // will show they're different if it's already spidered, so check for that.
      if (sameLocation || (!sameLocation && currentSpidered)) {
        set(this, 'popupItems', children)
        set(this, 'popupItemsIndex', 0)
        this.popup.setLatLng(cluster.latlng)
        this.send('nextAction')
      }
    })

    map.addLayer(markerClusterGroup)
    set(this, 'markerClusterGroup', markerClusterGroup)
    set(this, 'layers', this.getLayers())
    set(this, 'showLoadingMessage', true) // Show loading message on initial map load
  },

  resetBounds () {
    const map = this.map
    const markerClusterGroup = this.markerClusterGroup
    const padding = 20

    map.fitBounds(markerClusterGroup.getBounds(), {
      paddingTopLeft: [SIDEBAR_WIDTH + padding, padding],
      paddingBottomRight: [padding, padding],
      maxZoom: this.mapLib.maxFitBoundsZoom || undefined
    })
  },

  extendZoomControls (map) {
    // See: https://github.com/alanshaw/leaflet-zoom-min
    const resetBoundsFunction = this.resetBounds.bind(this)

    const ZoomControlClass = L.Control.extend({
      // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
      options: {
        position: 'topright'
      },
      onAdd: () => {
        const controlElementTag = 'div'
        const controlElementClass = 'c-map-screen__zoom-controls'
        const container = L.DomUtil.create(controlElementTag, controlElementClass)
        container.id = this.zoomControlId
        return container
      },
      onRemove () {}
    })

    if (map.zoomControl) {
      map.zoomControl.remove()
    }
    map.zoomControl = new ZoomControlClass({ position: 'topright' })
    map.zoomControl.addTo(this.map)
    set(this, 'zoomControlElementInserted', true)
  },

  processResults (searchResults) {
    const markerClusterGroup = this.markerClusterGroup
    markerClusterGroup.clearLayers()

    if (searchResults.rows) {
      const markers = []
      let { mapConfig: { crs, pointComponents } } = this.searchTemplate

      // Set default coordinate system to NZTM and default point components to easting/northing
      crs = crs != null ? crs : 'EPSG2193'
      pointComponents = pointComponents != null ? pointComponents : ['easting', 'northing']
      searchResults.rows.forEach((searchResult) => {
        if (!this.mapLib.validate(crs, searchResult[pointComponents[0]], searchResult[pointComponents[1]])) {
          return
        }

        // In the click handler, we need access to the component.
        const component = this

        const latLng = this.mapLib.convertToLatLng(crs, [searchResult[pointComponents[0]], searchResult[pointComponents[1]]])

        const marker = new L.Marker(latLng, {
          icon: searchResult.consentTypes ? this.mapLib.consentTypeIcons[searchResult.consentTypes] : this.mapLib.defaultIcon,
          data: searchResult // put on data so the popup can get access to other info
        })
        marker.on('click', function (e) {
          component.popup.setLatLng(this.getLatLng())
          set(component, 'popupItems', [this])
          set(component, 'popupItemsIndex', 0)
          component.send('nextAction')
        })
        markers.push(marker)
      })

      // Would like to chunk loading as per the markercluster docs, however
      // couldn't get the fit bounds to work.
      markerClusterGroup.addLayers(markers)

      if (markers.length) {
        this.resetBounds()
      }
    }
  },

  searchTask: task(function * () {
    this.genericSearch.saveFilterSet(this.searchQuery.searchTypeKey, this.searchQuery.filterSet)
    const appliedFilterCount = this.searchQuery.filterSet.filters.reduce((acc, v) => acc + v.filterSet.filters.length, 0)
    set(this, 'appliedFilterCount', appliedFilterCount)
    set(this, 'loading', true)
    set(this, 'showLoadingMessage', !appliedFilterCount)
    yield this.remoteMethods.getSearchResult(this.searchQuery)
      .then(({ searchResult }) => {
        this.processResults(searchResult)
      })
      .finally(() => {
        set(this, 'loading', false)
        set(this, 'showLoadingMessage', false)
      })
  }).restartable(),

  onSearch (searchQuery) {
    if (this.onSearchQueryChange) {
      // We need to reset the page on the list searches as the query has been changed/re-run
      this.genericSearch.resetPreviouslyDisplayedPage(this.searchTypeKey)
      this.onSearchQueryChange(searchQuery)
    }
    this.searchTask.perform()
  },

  queryModified (newSearchQueryFilters) {
    set(this, 'searchQuery', Object.assign(this.searchQuery, newSearchQueryFilters))
  },

  fetchSavedSearchList () {
    set(this, 'savedSearchList', DS.PromiseObject.create({
      promise: this.remoteMethods.fetchSavedQueryList(this.searchTemplate.searchTypeKey).then(({ savedSearches }) => ({ rows: A(savedSearches.rows) }))
    }))
  },

  fetchDetailTask: task(function * () {
    yield this.remoteMethods.fetchDetailedMapResult({
      searchTypeKey: this.searchTemplate.searchTypeKey,
      identifier: this.popupCurrentId.toString()
    }).then(result => {
      set(this, 'popupData', result)
      this.map.openPopup(this.popup)
    })
  }).restartable(),

  actions: {
    backToSearch () {
      // Set default to the current url, without a `-map` suffix
      const toRoute = (this.searchTemplate.mapConfig && this.searchTemplate.mapConfig.tabularViewEmberRoute) || this.router.currentRouteName.replace(/-map$/, '')
      this.router.transitionTo(toRoute)
    },
    onCollapseToggle (isCollapsed) {
      set(this, 'filtersCurrentlyCollapsed', isCollapsed)
    },
    loadQuery (queryId) {
      set(this, 'isLoadingQuery', true)
      this.remoteMethods.fetchSavedQuery(queryId)
        .then(({ searchQuery }) => {
          set(this, 'filtersCurrentlyCollapsed', true) // collapse the filters after reinflation
          /*
          * The saved search query should be executed with the current viewKey
          * instead of the viewKey originally saved with the query
          * */
          if ('viewKey' in searchQuery) delete searchQuery.viewKey
          this.queryModified(searchQuery)
          this.onSearch(searchQuery)
          set(this, 'isLoadingQuery', false)
        })
    },
    previousAction () {
      let nextIndex
      if (this.popupItemsIndex === 0) {
        nextIndex = this.popupItems.length - 1
      } else {
        nextIndex = (this.popupItemsIndex - 1) % this.popupItems.length
      }
      set(this, 'popupItemsIndex', nextIndex)
      this.fetchDetailTask.perform()
    },
    nextAction () {
      const nextIndex = (this.popupItemsIndex + 1) % this.popupItems.length
      set(this, 'popupItemsIndex', nextIndex)
      this.fetchDetailTask.perform()
    },
    viewAction () {
      const actionConfig = this.actionConfig
      if (actionConfig === undefined || actionConfig === null) {
        // no action configured
        return
      }
      const args = actionConfig.args.map(arg => {
        if (arg.constant !== undefined) {
          return arg.constant
        }
        if (arg.elementKey !== undefined) {
          return this.popupData[arg.elementKey]
        }
      })
 
      const type = actionConfig.type
      if (this.actions[type]) {
        this.send(type, ...args)
      } else if (this.customActions[type]) {
        this.customActions[type](...args)
      } else {
        const error = `attempted to run action.${actionConfig.type}(` + [...args].join(' ') + ')'
        console.error(error)
        throw new Error(error)
      }
    },
    transitionAction (...args) {
      this.router.transitionTo(...args)
    },
    noop (event) {
      event.stopPropagation()
    },
    zoomIn () {
      this.map.setZoom(this.map.getZoom() + 1)
    },
    zoomOut () {
      this.map.setZoom(this.map.getZoom() - 1)
    },
    zoomReset () {
      this.resetBounds()
    }
  }
})
