import Ember from 'ember'
import { isObject } from 'lodash'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import { countErrors } from '../../../../libs/validation-error-lib'
const { computed, get } = Ember

export default Ember.Component.extend(DynamicElement, {
  tagName: '',
  isNew: computed('state', function () {
    const state = get(this, 'state')
    if (!isObject(state)) return true

    const keys = Object.keys(state)
    if (!keys.length) return true

    return get(state, `${keys[0]}.0.id`) === 'new'
  }),
  errorCount: computed('state', function () {
    // Use computed property rather than handlebars helper so we only need to calculate once per component
    return countErrors(get(this, 'state'))
  })
})
