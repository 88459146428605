import RemoteMethodService from './remote-methods'
export default class MappingRemoteMethods extends RemoteMethodService {
  async fetchFarmMapBoundaries (enterpriseClientId) {
    // Fetches a geojson object with an enterprise client id
    const url = `/api/farm/geospatial/boundaries/${enterpriseClientId}`
    return this.request(url)
  }

  async fetchFarmMapFeatures (enterpriseClientId) {
    // Fetches an array of farm feature objects with an enterprise client id
    const url = `/api/farm/geospatial/features/${enterpriseClientId}`
    return this.request(url)
  }

  async fetchFarmMapFeaturesConfig () {
    // Fetches farm_map JSON filtered by what a user has permission to view
    const url = '/api/farm/configuration'
    return this.request(url)
  }
}
