// Ember intl formatting can be added here https://github.com/ember-intl/ember-intl

export default {
//   time: {
//     hhmmss: {
//       hour: 'numeric',
//       minute: 'numeric',
//       second: 'numeric'
//     }
//   },
//   date: {
//     hhmmss: {
//       hour: 'numeric',
//       minute: 'numeric',
//       second: 'numeric'
//     }
//   },
//   number: {
//     EUR: {
//       style: 'currency',
//       currency: 'EUR',
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2
//     },
//     USD: {
//       style: 'currency',
//       currency: 'USD',
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2
//     }
//   }
}
