import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input type=\"text\" name=\"custom-field-value\" class=\"c-editable-text-cell\" {{on \"change\" this.setSelected }} value={{@value}}>\n", {"contents":"<input type=\"text\" name=\"custom-field-value\" class=\"c-editable-text-cell\" {{on \"change\" this.setSelected }} value={{@value}}>\n","moduleName":"client/components/editable-text-cell/index.hbs","parseOptions":{"srcName":"client/components/editable-text-cell/index.hbs"}});
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'

export default class EditableTextCell extends Component {
  @tracked value

  get inputId () {
    return this.uniqueId.next()
  }

  @action
  setSelected (e) {
    this.value = e.target.value
    this.args.tableActions.onBlur(this.args.row.content.value.supplier_id, this.value)
  }
}
