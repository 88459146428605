import Ember from 'ember'
import { find } from 'lodash'
const { computed, get } = Ember

export default Ember.Component.extend({
  /**
   * This computed property orders the error summary data returned from the server by using the menu structure as
   * a guide.  Therefore the modal listing the forms and their status will list the forms in the same order they
   * are shown in the menu.
   */
  orderedForms: computed('errors.forms', 'menu.forms', 'excludeFormsToShow', function () {
    const menuForms = get(this, 'menu.forms')
    const errorForms = get(this, 'errors.forms')
    const excludeFormsToShow = get(this, 'excludeFormsToShow') ? get(this, 'excludeFormsToShow') : []
    const orderedForms = []

    /**
     * This function can be used recursively to match each menuForm with its counterpart in the summary errors
     * array to build up a flat array of forms/statuses
     */
    const matchMenu = (menuForms) => {
      menuForms.forEach(menuForm => {
        const matchedForm = find(errorForms, (errorForm) => menuForm.formInstanceId === errorForm.formInstanceId)

        if (matchedForm && matchedForm.templateKey !== 'consent' && !excludeFormsToShow.includes(matchedForm.templateTitle)) {
          orderedForms.push(matchedForm)
        }

        if (menuForm.forms) {
          matchMenu(menuForm.forms)
        }
      })
    }

    // Kick off with the top level
    matchMenu(menuForms)
    return orderedForms
  })
})
