import { get, computed } from '@ember/object'

import { task } from 'ember-concurrency'
import $ from 'jquery'

import RemoteMethodService from './remote-method'
import config from '../config/environment'

/**
 * A files service for managing files.
 *
 * Interacts with the server API to upload & download files and to retrieve
 * file metadata.
 */
export default RemoteMethodService.extend({
  /**
   * Upload a file to the server.
   *
   * Queues uploads and throttles them to only three files at any one time.
   *
   * @param {Object} file A file as returned from ember-file-upload.
   * @param {String} type The related model type of this file, e.g. 'application'.
   * @param {String} ref The related model reference of this file, e.g. '12345'.
   * @returns {Promise<Object>} File metadata object as returned from the API,
   *                            e.g. {id: 22, filename: 'myfile.doc', size: 9475}
   */
  uploadTask: task(function * (file, type, ref) {
    // Upload a file through the uploadBinary method ember-file-upload provides.
    // Set the "Content-Disposition" header as expected by Django REST
    // Framework's FileUploadParser. Add the CSRF token in as we're sending
    // this through ember-file-upload's method rather than through Ember AJAX.

    const queryParams = $.param({ type, ref })
    const url = `/api/files/upload?${queryParams}`

    const response = yield file.uploadBinary(url, {
      headers: {
        'X-CSRFToken': get(this, 'csrfToken'),
        'Content-Disposition': `attachment; filename=${file.blob.name}`
      }
    })
    return response.body
  }).maxConcurrency(3).enqueue(),

  /**
   * Are any uploads in progress?
   * @type {Boolean}
   */
  uploadInProgress: computed.not('uploadTask.isIdle'),

  /**
   * Upload a file, wrapper for the Ember concurrency task.
   * @param {Object} file A file as returned from ember-file-upload.
   * @param {String} type The related model type of this file, e.g. 'application'.
   * @param {String} ref The related model reference of this file, e.g. '12345'.
   * @returns {Promise<Object>} File metadata object as returned from the API,
   *                            e.g. {id: 22, filename: 'myfile.doc', size: 9475}
   */
  upload (file, type, ref) {
    return get(this, 'uploadTask').perform(file, type, ref)
  },

  /**
   * Download a file, sets the window location.
   * @param {Number} id File ID.
   */
  download (id) {
    window.location = `${config.apiNamespace}/files/download/${id}`
  },

  /**
   * Get the metadata for a file.
   * @param {Number} id File ID.
   * @returns {Promise<Object>} File metadata object as returned from the API,
   *                            e.g. {id: 22, filename: 'myfile.doc', size: 9475}
   */
  getInfo (id) {
    return this.request(`files/${id}`)
  }
})
