import { inject } from '@ember/service'
import { DASHBOARD_REPORTS_TABS } from 'client/constants/links'
import AuthenticatedRoute from '../../authenticated-route'

export default class HomeReportsRoute extends AuthenticatedRoute {
  @inject router
  @inject warden
  @inject featureFlags
  @inject router

  routePermission = [
    'view__reports_services_overview'
  ]

  get reportsTabs () {
    return this.warden.filterByAllowedActions(DASHBOARD_REPORTS_TABS, 'permissions')
  }

  async beforeModel (transition) {
    await super.beforeModel(...arguments)
    if (this.featureFlags.flagUnset('FARM_DAIRY_REPORTS')) {
      transition.abort()
      this.router.transitionTo('base.not-found')
    }
    if (this.reportsTabs.length === 0) {
      transition.abort()
      this.router.transitionTo('base.not-found')
    } else {
      // Logic for immediately opening the first route in the list
      if (!this.reportsTabs.map(v => v.route).includes(transition.to.name)) {
        transition.abort()
        this.router.transitionTo(this.reportsTabs[0].route)
      }
    }
  }

  model () {
    return {
      reportsTabs: this.reportsTabs
    }
  }
}
