import Ember from 'ember'
import { reduce, eachOf } from 'async'
import { merge, isObject } from 'lodash'
import RSVP from 'rsvp'
import { copy } from 'ember-copy'
const { inject, get } = Ember

export default Ember.Service.extend({
  defaultFunctions: inject.service(),

  /**
   * Loops through all the "formElements" and builds a default state them if one does not already exist
   * An initial state is passed in that may contain partial state for a formElement, or no state at all
   *
   * @param {Object} initialState - the hierarchical section of the state object related to the array of formElements
   * @param {Object[]} formElements - the formElements on the same hierarchical level
   * @param formElementCount - how many loops of these formElements should there be (required for repeating sections)
   * @returns {RSVP.Promise}
   */
  buildState (initialState, formElements, formElementCount = 1) {
    initialState = copy(initialState) || {}

    return new RSVP.Promise((resolve) => {
      // Loop through each formElement and make sure we have state
      reduce(formElements, {}, (tempState, formElement, callback) => {
        if (!formElement.formElements) {
          // Populate by a population function if it exists, and the state is not already set
          if (get(formElement, 'default.func') && (!initialState[formElement.name] || initialState[formElement.name][0].val == null)) {
            this.getStateFromFunction(initialState, formElement, formElementCount, tempState, callback)
          } else {
            tempState[formElement.name] = this.getStaticState(initialState, formElement)
            callback(null, tempState)
          }
        } else {
          if (formElement.repeatable && !formElement.numAutoRepeats) {
            // todo: this only makes a distinction between no numAutoRepeats, and some numAutoRepeats.  If we ever
            // have numAutoRepeats > 1, then we'll need to do some work here
            tempState[formElement.name] = initialState[formElement.name] || []
            callback(null, tempState)
          } else {
            this.processSection(initialState, formElement, formElementCount, tempState, callback)
          }
        }
      }, function (err, result) {
        if (err) { /* swallow */ }
        resolve(Object.assign({}, initialState, result))
      })
    })
  },

  /**
   * Get the initial state of the formElement from either a static value or default to null
   * @param {Object} initialState - complete initial state for this level of the hierarchy
   * @param {Object} formElement - definition of a single for element
   * @returns {Array} The values for this formElement
   */
  getStaticState (initialState, formElement) {
    if (!initialState[formElement.name]) {
      return [{ id: 'new', val: get(formElement, 'default.val') || null }]
    } else if (initialState[formElement.name][0].val == null && initialState[formElement.name].length === 1) {
      return merge(initialState[formElement.name], [{ val: get(formElement, 'default.val') || null }])
    }
    return initialState[formElement.name]
  },

  /**
   * This function attempts to populate the initial value of a formElement from a function, and not a fixed value
   * There are rules around when a function might be expected to run, in case it isn't, it'll fall back to getting
   * static state.
   * @param {Object} initialState - complete initial state for this level of the hierarchy
   * @param {Object} formElement - definition of a single for element
   * @param {number} formElementCount - the number of these formElements
   * @param {Object} tempState - the current accumulater for the state used by the async reduce
   * @param {Function} callback
   */
  getStateFromFunction (initialState, formElement, formElementCount, tempState, callback) {
    if (!('name' in get(formElement, 'default.func'))) {
      throw new Error(`Default functions must have the "name" key, for ${get(formElement, 'name')}`)
    }

    // Need to determine if this is the correct time to call the default function
    const runOnFirstOnlyAndIsFirst = formElement.default.execute === 'first-only' && formElementCount === 1
    const runAlways = formElement.default.execute == null || formElement.default.execute === 'all'

    if (runOnFirstOnlyAndIsFirst || runAlways) {
      const funcName = get(formElement, 'default.func.name')
      const args = formElement.default.func.arguments || []
      const result = get(this, 'defaultFunctions')[funcName](...args)
      // Check if the function result is a promise or not.  Duck typing check - if it has a `then` function on the
      // returned object, then it's good enough for us
      if (isObject(result) && 'then' in result && typeof result.then === 'function') {
        result.then(value => {
          this.addValueToState(value, formElement, initialState, tempState, callback)
        })
      } else {
        this.addValueToState(result, formElement, initialState, tempState, callback)
      }
    } else {
      // todo: consider tidying this code as is duplicated above
      tempState[formElement.name] = this.getStaticState(initialState, formElement)
      callback(null, tempState)
    }
  },

  processSection (initialState, formElement, formElementCount, tempState, callback) {
    // Holy Recursing, Batman!
    // This is complicated.  The initialState[formElement] returns an array of objects, or nothing
    // [{val: dfdf, errors:..dsfsd, hidden:sdfsdf}, {val:dfsdfs}]
    // Therefore, need to loop this properly as well to process each 'state' from the array
    // todo: Remove when heavily tested.  Note: might fail if defaults on sections/complex components, but this is not currently something that we do
    if (!initialState[formElement.name]) {
      // There is no initial state, there just build it from null
      return this.buildState(null, formElement.formElements, formElementCount)
        .then(state => {
          tempState[formElement.name] = [{ id: 'new', val: state }]
          callback(null, tempState)
        })
    }
    // Else, we have existing state, that we want to append to, potentially
    tempState[formElement.name] = []
    eachOf(initialState[formElement.name], (subState, index, next) => {
      this.buildState(subState.val, formElement.formElements, formElementCount)
        .then(state => {
          tempState[formElement.name][index] = merge({ id: 'new' }, subState, { val: state })
          next()
        })
    }, () => {
      callback(null, tempState)
    })
  },

  /**
   * Adds a value returned by a default function to the tempState object.
   * Abstracted to a separate function so can be called by both async and sync default functions
   * @param {any} value
   * @param {object} formElement
   * @param {object} initialState
   * @param {object} tempState
   * @param {function} callback
   */
  addValueToState (value, formElement, initialState, tempState, callback) {
    const eleState = initialState[formElement.name]
    // the initial state could be populated with an id and a null value, so need to check for that
    if (eleState && eleState[0] && eleState[0].val == null) {
      tempState[formElement.name] = merge(initialState[formElement.name], [{ val: value }])
    } else {
      // Else just create the new state
      tempState[formElement.name] = [{
        id: 'new',
        val: value
      }]
    }
    callback(null, tempState)
  }
})
