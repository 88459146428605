import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'

export default class AssessmentProgress extends Component {
  @inject router
  @inject warden
  @tracked isToolTipEnabled = true
  @tracked disableViewAuditCompletedLink = false
  @tracked disableViewAuditBookedLink = false

  // The items in the subgroup MUST MATCH the fields returned in the data object.
  // e.g. If data = {completed_audits: 5, booked_audits: 2, year_month: "202103"}, and we want to show booked and completed in the legend and
  // the bar graph, then the subgroup items must be 'completed_audits' and 'booked_audits'
  legendDataSubgroups = ['completed_audits', 'booked_audits']

  get barChartTranslations () {
    return {
      legendItemOneLabel: 'programme_insights.bar_chart_completed',
      legendItemTwoLabel: 'programme_insights.bar_chart_booked'
    }
  }

  get tetherTargetTranslations () {
    return {
      count: 'reports.bar_chart_tooltip_assessment_counts',
      completed_audits: 'reports.bar_chart_tooltip_month_assessments_completed',
      booked_audits: 'reports.bar_chart_tooltip_month_assessments_booked'
    }
  }

  get barChartIdentifier () {
    return this.args?.barChartIdentifier
  }

  get auditCompletedFilter () {
    return this.args?.auditCompletedSearchFilters
  }

  get auditBookedFilter () {
    return this.args?.auditBookedSearchFilters
  }

  @action
  viewAuditCompletedAuditSearch () {
    this.disableViewAuditCompletedLink = true
    this.router.transitionTo('base.home.search.audit', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.auditCompletedFilter)
      }
    })
  }

  @action
  viewAuditBookedAuditSearch () {
    this.disableViewAuditBookedLink = true
    this.router.transitionTo('base.home.search.audit', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.auditBookedFilter)
      }
    })
  }
}
