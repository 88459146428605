import { inject } from '@ember/service'
import FileSaverMixin from 'ember-cli-file-saver/mixins/file-saver'
import RemoteMethodService from './remote-method'
import {
  PDF_PRINT_ROUTE,
  PDF_PRINTER_BASE_URL
} from '../constants/values'
import { formatDateToOrdinalString } from '../utils/date'

/**
 * A service for printing
 *
 * Interacts with the pdf-generator NodeJS dependency that we use.
 * Sends a link to the pdf-generator to be turned into a PDF on the backend, returned, and downloaded.
 * https://github.com/mediasuitenz/msaf-pdf-generator
 */

export default class PdfPrinter extends RemoteMethodService.extend(FileSaverMixin) {
  /**
   * Generates a PDF for the specified URL
   * @param {String} url the url of the page to be printed
   * @returns {?} todo
   */

  @inject intl
  @inject pdfRemoteMethods
  @inject session
  @inject toastMessages

  get printPageUrl () {
    // Needs to be inside getter because otherwise this is set only the first time the service loads
    return `${PDF_PRINT_ROUTE}${this.router.currentURL}-print`
  }

  get todaysDateAsString () {
    return formatDateToOrdinalString(new Date())
  }

  get tradingName () {
    return this.session.currentUser.get('organisation').name
  }

  async generateFullScreenMapPdf (selectedFeatureNames, boundingBox) {
    // TODO:
    // Add center of map from leaflet to query params
    const pdfEndpointUrl = `${PDF_PRINTER_BASE_URL}/generate-full-screen-map-pdf`
    const fileName = `${this.intl.t('mapping.export_map_file_name')}.pdf`
    const encodedFeatureArray = encodeURIComponent(selectedFeatureNames)
    const encodedBoundingBox = encodeURIComponent(Object.values(boundingBox).map(bound => Object.values(bound)))
    const pageToPrint = `${this.router.currentURL}/print?boundingBox=${encodedBoundingBox}&selectedFeatures=${encodedFeatureArray}`
    const pdfExportId = await this.pdfRemoteMethods.requestPdf(pdfEndpointUrl, pageToPrint, fileName, 'MAP_PRINT_PAGE_LOADED', true)
    this.pdfRemoteMethods.downloadPdf(pdfExportId)
  }

  async generateReportsPdf (reportType, selectedMonth, selectedYear) {
    // Refactor: Change this to a switch or call out to multiple methods if more report types are added
    const isServicesReport = reportType === 'service-overview'
    const typeTranslation = isServicesReport ? 'reports.services_overview_pdf_file_name' : 'reports.visit_type_pdf_file_name'

    const pdfEndpointUrl = `${PDF_PRINTER_BASE_URL}/generate-reports-pdf`
    const fileName = `${this.intl.t(typeTranslation)}.pdf`
    let pageToPrint = `${this.printPageUrl}`

    if (isServicesReport) {
      // confirm both month and year are set
      if (!(selectedMonth && selectedYear)) {
        // Should never get to this state
        this.toastMessages.danger(this.intl.t('pdf_export.error_exporting_pdf'))
        return
      }
      // Add month and year as query params
      pageToPrint = `${this.printPageUrl}?selectedMonth=${encodeURIComponent(selectedMonth)}&selectedYear=${encodeURIComponent(selectedYear)}`
    }

    // Trigger export to pdf
    const pdfExportId = await this.pdfRemoteMethods.requestPdf(pdfEndpointUrl, pageToPrint, fileName, 'REPORTS_PRINT_PAGE_LOADED')

    // Trigger download
    await this.pdfRemoteMethods.downloadPdf(pdfExportId)
  }
}
