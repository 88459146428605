// ============================================================
// Workflow:
//
// Constants defined here are used within Dynamic forms,
// either for the core functionality or within custom components
// created for this application.
// ============================================================

export const TYPEAHEAD_COMPONENT = {
  DEBOUNCE_MS: 500, // minimum time (in milliseconds) between API lookups
  MIN_CHARS: 3 // minimum characters that must be typed before a lookup is performed using the API
}

export const MODE = {
  EDIT: 'edit',
  VIEW: 'view',
  PRINT_VIEW: 'print-view'
}

export const SIDE_MENU_PREFIX = {
  [MODE.VIEW]: '',
  [MODE.EDIT]: ''
}

export const SIDE_MENU_PREFIX_A = {
  [MODE.VIEW]: '',
  [MODE.EDIT]: ''
}

export const TOOL_TIP_MAX_CHAR_LENGTH = 4000

export const LAST = 'last'

export const FORM_STATES = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE'
}

export const FORM_STATES_DISPLAY = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'Incomplete',
  COMPLETE: 'Complete'
}

export const FORM_STATES_PILL_STATUS = {
  NOT_STARTED: '',
  IN_PROGRESS: 'alert',
  COMPLETE: 'success'
}
