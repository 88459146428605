import Table from 'ember-light-table'
import Component from '@glimmer/component'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'

export default class ReportsYtdNonConformanceInitialRatingTable extends Component {
  @inject intl

  @tracked table
  @tracked rows = this.args?.data

  constructor () {
    super(...arguments)
    this.createTable()
  }

  get columns () {
    // Get list of keys (columns) in object
    const columnsArray = Object.keys(this.rows[0])

    // Returns an array of column objects, dynamically generated by the number of keys in rows object
    return columnsArray.map(columnName => {
      let cellClassNames = 'lt-cell align-left c-services-overview-card__table-cell ember-view'
      let label = columnName
      let width

      if (columnName === 'heading') {
        cellClassNames = cellClassNames + ' c-services-overview-card__table-cell__bold'
        label = null
        width = '90px'
      }

      return {
        width: width,
        label: label,
        valuePath: columnName,
        classNames: 'c-table__cell c-services-overview-card__table-cell--heading c-table__cell--view-heading is-sorted',
        cellClassNames: cellClassNames,
        ascending: false
      }
    })
  }

  createTable () {
    this.table = Table.create({ columns: this.columns, rows: this.rows, enableSync: true })
  }
}
