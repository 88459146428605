import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class ViewSupplierProfile extends Component {
  @tracked disableNavigateAwayLink = false

  @action
  navigateAway () {
    this.disableNavigateAwayLink = true
    this.args.tableActions.navigateToSupplierProfile(this.args.row.content.enterpriseClientId)
  }
}
