import Ember from 'ember'
import { task, timeout } from 'ember-concurrency'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import ComplexElement from '../../../../mixins/components/complex-form-element'
import { TYPEAHEAD_COMPONENT } from '../../../../constants'
import { isObject } from 'lodash'

const { get, computed, set, copy, getOwner } = Ember
const { DEBOUNCE_MS, MIN_CHARS } = TYPEAHEAD_COMPONENT

export default Ember.Component.extend(DynamicElement, ComplexElement, {
  // Extended attributes, that specify where to fetch typeahead results from
  lookupServiceName: computed.alias('formElement.extendedAttributes.lookupServiceName'),
  lookupMethodName: computed.alias('formElement.extendedAttributes.lookupMethodName'),
  lookupKey: computed.alias('formElement.extendedAttributes.lookupKey'),

  // Dynamically load the lookup service
  lookupService: computed('lookupServiceName', function () {
    const owner = getOwner(this)
    return owner.lookup(`service:${this.get('lookupServiceName')}`)
  }),

  // Children element values
  assignNewNumberVal: computed.alias('formElementState.0.val.assignNewNumber.0.val'),
  assignedNumberVal: computed.alias('formElementState.0.val.assignedNumber.0.val'),

  // Shorthand properties for children form elements
  assignNewNumber: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'assignNewNumber')
  }),
  assignedNumber: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'assignedNumber')
  }),
  assignNewNumberMessage: computed('formElement.formElements.[]', function () {
    return get(this, 'formElement.formElements').find(obj => obj.name === 'assignNewNumberMessage')
  }),

  didReceiveAttrs () {
    this._super(...arguments)

    const assignedNumberVal = get(this, 'assignedNumberVal')

    if (assignedNumberVal != null) {
      const selected = {}
      selected[get(this, 'lookupKey')] = assignedNumberVal
      set(this, 'selected', selected)
    }
  },

  /**
   * Search for existing numbers.
   *
   * Note: Must have at least MIN_CHARS before hitting back end.
   */
  searchTask: task(function * (query) {
    if (query.length < MIN_CHARS) return []
    yield timeout(DEBOUNCE_MS)
    return get(this, 'lookupService')[get(this, 'lookupMethodName')](query)
  }),

  actions: {
    setSelected (selected) {
      set(this, 'selected', selected)

      const newValue = isObject(selected) ? selected[get(this, 'lookupKey')] : undefined
      const state = copy(get(this, 'formElementState'), true)
      state[0].val.assignedNumber[0].val = newValue

      // Simulate a call to updateStateKey as the child form elements would
      const name = get(this, 'formElement.name')
      get(this, 'updateStateKey')(name, state)
    }
  }
})
