import Component from '@ember/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'

export default class FullScreenMapSideBar extends Component {
  @inject farmMaps

  @tracked showFeaturesControl = true
  @tracked showExportMapControl = true
  @tracked hasStickyToolbar = true
  @tracked panelToggleButtonIcon = 'hide'
  @tracked areAllFeaturesVisible = false

  get isPanelVisible () {
    return this.farmMaps.isPanelVisible
  }

  get showPanel () {
    return this.farmMaps.showPanel
  }

  set isPanelVisible (args) {
    this.farmMaps.isPanelVisible = args
  }

  set showPanel (args) {
    this.farmMaps.showPanel = args
  }

  @action noop () {}

  @action togglePanel (panelName) {
    const showOtherPanel = !this.isPanelVisible && this.showPanel !== panelName
    const toggleCurrentPanel = panelName === this.showPanel

    if (showOtherPanel || toggleCurrentPanel || !panelName) {
      this.isPanelVisible = !this.isPanelVisible
    }

    if (!this.isPanelVisible) {
      // Add sleep if tool-pane is closing so the content doesn't disappear before it's closed
      setTimeout(() => {
        this.setPanel(panelName)
      }, 250)
    } else {
      this.setPanel(panelName)
    }
  }

  setPanel (panelName) {
    this.showPanel = this.isPanelVisible ? panelName : ''
  }

  @action closePanelOnMobile () {
    if (window.matchMedia('(max-width: 599px)').matches) {
      this.isPanelVisible = false
    }
  }

  @action toggleAllFeatures () {
    this.areAllFeaturesVisible = !this.areAllFeaturesVisible
  }
}
