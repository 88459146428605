import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Input\n  @type=\"checkbox\"\n  checked={{@row.content.value.isChecked}}\n  {{on \"input\" this.validateChecked}}\n/>\n", {"contents":"<Input\n  @type=\"checkbox\"\n  checked={{@row.content.value.isChecked}}\n  {{on \"input\" this.validateChecked}}\n/>\n","moduleName":"client/components/bulk-update-cell/index.hbs","parseOptions":{"srcName":"client/components/bulk-update-cell/index.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class BulkUpdateCell extends Component {
  @action
  validateChecked () {
    this.args.tableActions.bulkUpdateRecords(this.args.row.content?.value?.supplier_id)
  }
}
