import { isObject } from 'lodash'

function countErrors (state) {
  if (!state || !isObject(state)) return 0

  return Object.keys(state).reduce((currentCount, name) => {
    currentCount += state[name].reduce((internalCount, instanceState) => {
      // If this element has errors then add the number to the count
      if (instanceState.errors) internalCount += instanceState.errors.length

      // If this element is a container, then dig down to the next level to grab any further errors down the hierarchy
      if (isObject(instanceState.val)) internalCount += countErrors(instanceState.val)
      return internalCount
    }, 0)
    return currentCount
  }, 0)
}

/**
 * Removes duplicates from an array of Error objects
 * @param {Object[]} errors
 * @returns {string[]}
 */
function deDupErrors (errors) {
  const errorsObj = errors.reduce((acc, error) => {
    acc[error.description] = true
    return acc
  }, {})
  return Object.keys(errorsObj)
}

export { countErrors, deDupErrors }
