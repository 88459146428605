import Component from '@ember/component'
import { computed } from '@ember/object'

export default Component.extend({
  /*
    To use:
    Write a function to mutate your database result into an object, the key (columnName) being the name of column it represents. It should contain the props:
    - [columnName].data // data from your database
    - [columnName].iconType // SVG icon type
    - [columnName].iconFill // Fill colour for the SVG
    - [columnName].iconClass // Optional class to apply to the SVG

    Example Function:
    if (row[0].columnName) {
      this.searchResult.rows.map(row => {
        const rowData = row.columnName
        row.columnName = {
          data: rowData,
          iconType: 'my-svg'
          iconFill: '#000000',
          iconClass: 'my-svg-class'
        }
      })
    }
  */

  tagName: '',
  cellObject: computed(function () {
    const columnName = this.column.valuePath
    if (this.row.content[columnName]) {
      return this.row.content[columnName]
    }
  }),

  displayValue: computed(function () {
    if (this.value.data !== null && this.value.data !== undefined) {
      const valueType = typeof (this.value.data)
      let displayValue = this.value.data
      if (valueType === 'string') {
        displayValue = this.value.data.length > 0 ? this.value.data : '-'
      }
      return displayValue
    }
    return '-'
  }),

  iconType: computed(function () {
    if (this.cellObject.iconType) {
      return this.cellObject.iconType
    }
  }),

  iconFill: computed(function () {
    if (this.cellObject.iconFill) {
      return this.cellObject.iconFill
    }
  }),

  iconClass: computed(function () {
    if (this.cellObject.iconClass) {
      return this.cellObject.iconClass
    }
  })
})
