import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.mobileWidth}}\n  {{inline-svg \"logo-asure-quality-icon-only\" class=\"c-logo\"}}\n{{else}}\n  {{inline-svg \"logo-asure-quality\" class=\"c-logo\"}}\n{{/if}}\n", {"contents":"{{#if this.mobileWidth}}\n  {{inline-svg \"logo-asure-quality-icon-only\" class=\"c-logo\"}}\n{{else}}\n  {{inline-svg \"logo-asure-quality\" class=\"c-logo\"}}\n{{/if}}\n","moduleName":"client/components/aq-logo/index.hbs","parseOptions":{"srcName":"client/components/aq-logo/index.hbs"}});
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'

export default class AqLogoComponent extends Component {
  @tracked mobileWidth = false

  setSize () {
    this.mobileWidth = window.innerWidth < 767
  }

  resizeFunc = this.setSize.bind(this)

  constructor () {
    super(...arguments)
    this.setSize()
    window.addEventListener('resize', this.resizeFunc)
  }

  willDestroy () {
    window.addEventListener('resize', this.resizeFunc)
  }
}
