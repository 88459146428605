import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a\n  class=\"c-secondary-action c-table__action\"\n  {{on 'click' this.navigateAway}} href=\"javascript:void(0)\"\n  disabled={{this.disableNavigateAwayLink}}\n>\n  {{t 'non_conformance_table.view'}}\n</a>\n", {"contents":"<a\n  class=\"c-secondary-action c-table__action\"\n  {{on 'click' this.navigateAway}} href=\"javascript:void(0)\"\n  disabled={{this.disableNavigateAwayLink}}\n>\n  {{t 'non_conformance_table.view'}}\n</a>\n","moduleName":"client/components/view-non-conformance/index.hbs","parseOptions":{"srcName":"client/components/view-non-conformance/index.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'

export default class SelectAllCell extends Component {
  @tracked disableNavigateAwayLink = false
  @action
  navigateAway () {
    this.disableNavigateAwayLink = true
    this.args.tableActions.navigateToNonConformance(this.args.row.content.non_conformance_form_id)
  }
}
