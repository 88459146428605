import hbs from 'htmlbars-inline-precompile'
import { withKnobs } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './programme-summary.notes.md'

export default {
  title: 'Programme Summary',
  component: 'ProgrammeSummary',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

const insight = {
  programmeName: 'Animal Welfare Assurance',
  supplierCount: 1,
  nonConformanceCount: 0,
  validCertificationCount: 1,
  clientName: 'ANZCO',
  auditData: [
    {
      yearmonth: '202001',
      completed: 2,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202002',
      completed: 4,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202003',
      completed: 1,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202004',
      completed: 3,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202005',
      completed: 3,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202006',
      completed: 2,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202007',
      completed: 0,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202008',
      completed: 0,
      booked: 2,
      due: 0
    },
    {
      yearmonth: '202009',
      completed: 0,
      booked: 0,
      due: 0
    },
    {
      yearmonth: '202010',
      completed: 0,
      booked: 0,
      due: 0
    }
  ]
}

export const programmeSummary = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <ProgrammeSummary @insight={{insight}}></ProgrammeSummary>
    </ContentContainer>
  `,
  context: {
    insight: insight
  }
})
