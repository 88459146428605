import Helper from '@ember/component/helper'
import { get } from '@ember/object'
import { inject } from '@ember/service'

/**
 * Usage: {{#if (can 'global.test_permission_1')}}
 */
export default Helper.extend({
  gateKeeper: inject(),

  compute ([action]) {
    return get(this, 'gateKeeper').can(action)
  }
})
