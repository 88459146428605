/* eslint ember/avoid-leaking-state-in-ember-objects: off */
import Service from '@ember/service'

/*
 * Generic Search library functions
 *
 * These should be pure functions (ie. should not modify or use any state outside of the function).
 * Exposed as a service because its the only way I could find to import them into multiple components.
 */

export default Service.extend({

  // Simplifies a filter object by collapsing/removing children if they are logically redundant
  // removeEmpty: set to true to remove empty filters, so (AND ) becomes ()
  // removeSingleton: set to true to collapse singleton filters, so (AND (OR (EQ 'processingOfficer' 2)) becomes (EQ 'processingOfficer' 2)
  removeRedundantFilters (filter = {}, removeEmpty = true, removeSingleton = false) {
    // a filter has two types: a single filter (filterKey), or a set of filters (filterSet)
    if (filter.filterSet) {
      const filteredFilters = filter.filterSet.filters
        .map(f => this.removeRedundantFilters(f, removeEmpty, removeSingleton))
        .filter(f => f !== undefined)

      if (removeEmpty && filteredFilters.length === 0) {
        // remove filterSets with no filters
        return undefined
      } else if (removeSingleton && filteredFilters.length === 1) {
        // collapse filterSets with a single filter
        return filteredFilters[0]
      } else {
        // base case for filterSet - return filterSet with removeRedundantFilters(filters)
        return Object.assign({}, filter, {
          'filterSet': {
            'booleanOperator': filter.filterSet.booleanOperator,
            'filters': filteredFilters
          }
        })
      }
    } else if (filter.filterKey) {
      return filter
    }
  }
})
