export const EMAIL_CHECK = '^[^@\\s]+@[^@\\s]+(\\.[^@\\s]+)+$'
export const DRF_LOGIN_NEEDED_ERROR = Object.freeze('Authentication credentials were not provided.')
export const CUSTOM_FIELD_LIST_TYPE_ID = -14
export const NUM_DAYS_WARNING_FOR_CERTIFICATION_EXPIRY = 60
export const MILKING_OBSERVED_YES = 'Yes'

// Used primarily for non-conformance insights.
// In this screen we specifically only wanted to represent Critical, Major, or Minor NCs so these needed to be hardcoded with their associated status.
// If the underlying data changes, for example if associatedDatabaseValue "Critical" is updated to "Critical level" or similar, we will need to update this here.
export const NON_CONFORMANCE_CATEGORIES = {
  CRITICAL: { translation: 'non_conformance_insights.critical_insights_heading', status: 'error-major', associatedDatabaseValue: 'Critical' },
  MAJOR: { translation: 'non_conformance_insights.major_insights_heading', status: 'warning', associatedDatabaseValue: 'Major' },
  MINOR: { translation: 'non_conformance_insights.minor_insights_heading', status: 'neutral', associatedDatabaseValue: 'Minor' }
}

export const EXTENDED_NON_CONFORMANCE_CATEGORIES = {
  CRITICAL: { translation: 'non_conformance_insights.critical_insights_heading', status: 'error-major', associatedDatabaseValue: 'Critical' },
  RETURN_MAJOR: { translation: 'non_conformance_insights.return_major_insights_heading', status: 'return-major', associatedDatabaseValue: 'Return Major' },
  MAJOR: { translation: 'non_conformance_insights.major_insights_heading', status: 'warning', associatedDatabaseValue: 'Major' },
  MINOR: { translation: 'non_conformance_insights.minor_insights_heading', status: 'neutral', associatedDatabaseValue: 'Minor' }
}

export const AUTH_EXCEPTIONS = {
  NOT_EXISTS: 'messages.user_doesnt_exist',
  ARCHIVED: 'messages.user_archived'
}

export const FDA_VISIT_TYPES = {
  REVISIT: 'REVISIT',
  APPROVAL: 'APPROVAL',
  'NON-NOTIFIED': 'NON-NOTIFIED',
  ROUTINE_ANNUAL_ASSESSMENT: 'ROUTINE_ANNUAL_ASSESSMENT'
}

export const FDA_SEASON_TARGETS_TRANSLATIONS = {
  ROUTINE_ANNUAL_ASSESSMENT: 'season_targets.routine_annual_assessment_progress',
  NON_NOTIFIED: 'season_targets.non_notified_assessment_progress',
  APPROVAL: 'season_targets.approvals_completed_during_milking_season',
  REVISIT: 'season_targets.revisits_completed_during_milking_season',
  TOTAL_DAIRY_ASSESSMENT: 'season_targets.assessments_completed_during_milking_season'
}

export const FDA_SEASON_TARGETS_VISIBLE_ASSESSMENTS = ['ROUTINE_ANNUAL_ASSESSMENT', 'NON_NOTIFIED', 'TOTAL_DAIRY_ASSESSMENT']

export const REPORTS_ASSESSMENT_COLORS = ['#47A23F', '#A3D09F', '#34742E', '#C5B9AB', '#015152', '#078080', '#85ADAD', '#44788C', '#02436B', '#CDCDCD', '#676E6E', '#444444', '#000000']

export const ORDERED_CHART_COLORS = ['#47A23F', '#015151', '#EBA41B']

export const NON_CONFORMANCE_CATEGORY_COLOURS = {
  Minor: '#C5B9AB',
  Major: '#EBA41B',
  Critical: '#FF585D',
  'Return major': '#FFD054'
}

export const ROUTINE_ANNUAL_NON_CONFORMANCE_ABBREVIATIONS = {
  'Sanitation of Plant and Premise (SPP)': 'SPP',
  'Structures and Facilities (SF)': 'SF',
  'Regulatory Requirements (RR)': 'RR',
  'Quality Management (QM)': 'QM',
  'Animal Health and Welfare (AHW)': 'AHW',
  'Environmental (ENV)': 'ENV'
}

export const USER_INVITE_TYPE = {
  asurequality: 'asurequality',
  client_company: 'client_company',
  auditee: 'auditee'
}

export const PDF_PRINTER_BASE_URL = '/api/print'
export const PDF_PRINT_ROUTE = '/pdf-print'

export const AUDITEE_CARD_STATES = {
  ERROR_CRITICAL: 'ERROR_CRITICAL',
  ERROR_MAJOR: 'ERROR_MAJOR',
  ERROR_MINOR: 'ERROR_MINOR',
  OK: 'OK',
  INFO: 'INFO',
  INFO_NO_ICON: 'INFO_NO_ICON'
}

export const CARD_STATES = {
  [AUDITEE_CARD_STATES.ERROR_CRITICAL]: {
    iconColor: 'red',
    iconType: 'exclamation-triangle',
    cardStatus: 'error-major'
  },
  [AUDITEE_CARD_STATES.ERROR_MAJOR]: {
    iconColor: 'orange',
    iconType: 'exclamation-triangle',
    cardStatus: 'warning'
  },
  [AUDITEE_CARD_STATES.ERROR_MINOR]: {
    iconColor: 'neutral',
    iconType: 'exclamation-triangle',
    cardStatus: 'neutral'
  },
  [AUDITEE_CARD_STATES.OK]: {
    iconColor: 'info',
    iconType: 'tick',
    cardStatus: 'success'
  },
  [AUDITEE_CARD_STATES.INFO]: {
    iconColor: 'success-alt',
    iconType: 'tick',
    cardStatus: 'success-alt'
  },
  [AUDITEE_CARD_STATES.INFO_NO_ICON]: {
    iconColor: null,
    iconType: null,
    cardStatus: 'success-alt'
  }
}
