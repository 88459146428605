import Route from '@ember/routing/route'
import { inject } from '@ember/service'

export default class AuthenticatedRoute extends Route {
  @inject session
  @inject warden
  @inject toastMessages
  @inject intl
  @inject router

  routePermissions = null

  beforeModel (transition) {
    return this.session.currentUser.then(() => {
      if (this.session.isArchived) {
        transition.abort()
        this.toastMessages.danger(this.intl.t('messages.user_is_archived'))
        return this.router.transitionTo('login')
      }
      if (!this.session.isAuthenticated) {
        transition.abort()
        return this.router.transitionTo('login', {
          queryParams: {
            returnTo: encodeURIComponent(window.location.pathname)
          }
        })
      } else {
        if (!this.session.hasAgreedToTerms && !(transition.to.name === 'base.terms-and-conditions')) {
          // This user has not agreed to the terms and conditions, so navigate them to the terms and conditions page
          // store their original transition to enable redirecting them after terms are accepted.
          const termsAndConditionsController = this.controllerFor('base.terms-and-conditions')
          termsAndConditionsController.set('previousTransition', transition)
          return this.router.transitionTo('base.terms-and-conditions')
        }
        if (this.routePermissions) {
          if (!this.warden.canAny(this.routePermissions)) {
            // Redirect to not found page
            transition.abort()
            return this.router.transitionTo('base.not-found')
          }
        }
      }
    })
  }
}
