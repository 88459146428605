import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { NON_CONFORMANCE_CATEGORY_COLOURS } from 'client/constants/values'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'
import { kebabCase } from 'lodash'

export default class ReportsServicesOverviewCard extends Component {
  @inject router
  @inject warden
  @tracked startDate = this.args?.routineAnnualNonConformances?.seasonDates[0]
  @tracked endDate = this.args?.routineAnnualNonConformances?.seasonDates[1]
  @tracked auditProgrammeId = this.args?.routineAnnualNonConformances?.auditProgrammeId
  @tracked visitTypeMappings = this.args?.routineAnnualNonConformances?.visitTypeMappings
  @tracked isExpanded = false
  @tracked disableViewNcInSearchLink = false
  @tracked disableViewCriticalNcInSearchLink = false

  chartColors = NON_CONFORMANCE_CATEGORY_COLOURS

  translations = {
    legendTitle: 'services_overview.initial_rating'
  }

  get barChartIdentifier () {
    return kebabCase('bar-chart-ytd-routine-annual-assessment')
  }

  get isAdmin () {
    return this.warden.canAny(['view__reports_services_overview'])
  }

  get routineAnnualAssessmentVisitTypes () {
    const visitTypeMappings = this.args?.routineAnnualNonConformances?.visitTypeMappings.map((mapping) => mapping[1])
    // remove any duplicate names so that we don't have duplicate filters
    return [...new Set(visitTypeMappings)]
  }

  @action
  viewCriticalNcInSearch () {
    this.disableViewCriticalNcInSearchLink = true
    const searchFilters = {
      programme: [this.auditProgrammeId],
      visitType: this.routineAnnualAssessmentVisitTypes,
      issueDate: [this.startDate, this.endDate],
      initialCategory: ['Critical']
    }

    this.router.transitionTo('base.home.search.non-conformance', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(searchFilters)
      }
    })
  }

  @action
  viewNcInSearch () {
    this.disableViewNcInSearchLink = true
    const searchFilters = {
      programme: [this.auditProgrammeId],
      visitType: this.routineAnnualAssessmentVisitTypes,
      issueDate: [this.startDate, this.endDate]
    }

    this.router.transitionTo('base.home.search.non-conformance', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(searchFilters)
      }
    })
  }
}
