import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
const { computed, get } = Ember

export default Ember.Component.extend(DynamicElement, {
  classNames: ['c-view-text'],
  checkedValues: computed('stateValue', 'formElement', function () {
    const stateValue = get(this, 'stateValue')
    if (!stateValue) return ''

    const values = get(this, 'formElement.formElements')
      .filter(item => stateValue[item.name] && stateValue[item.name][0].val && stateValue[item.name][0].val !== 'false')
      .map(item => item.label)

    if (get(this, 'formElement.extendedAttributes.renderAsList')) {
      return values.join('<br>')
    } else {
      return values.join(', ')
    }
  })
})
