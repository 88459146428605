import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import { TOOL_TIP_MAX_CHAR_LENGTH } from '../../../../constants'

const { get, computed } = Ember

export default Ember.Component.extend(DynamicElement, {
  classNames: ['c-tool-tip'],
  classNameBindings: ['isToolTipEmpty:c-tool-tip--empty'],
  isToolTipEmpty: computed('toolTipText', function () {
    return !get(this, 'toolTipText')
  }),
  toolTipMaxLength: TOOL_TIP_MAX_CHAR_LENGTH,
  iconType: 'info',
  iconWidth: '12px',
  iconHeight: '12px'
})
