import Mixin from '@ember/object/mixin'
import { get, set } from '@ember/object'
import { inject } from '@ember/service'

/**
 * Cache Mixin
 *
 * Mixin into an object to provide a cache object. Set 'cacheLengthSec'
 * property on your object to change the caching time.
 *
 * See the dashboard service for a usage example.
 */
export default Mixin.create({
  cacheFactory: inject(),

  // The cache object, provides 'save(key, value)' and 'fetch(key)' functions.
  // See cache factory for details.
  cache: null,

  // Default to 15min, can set this in the class this is mixed into to change.
  cacheLengthSec: 15 * 60,

  /**
   * Initialise the cache object.
   */
  init () {
    this._super(...arguments)
    const cache = get(this, 'cacheFactory').create(get(this, 'cacheLengthSec'))
    set(this, 'cache', cache)
  },

  /**
   * Flush the entire cache.
   */
  cacheFlushAll () {
    get(this, 'cache').flushAll()
  }
})
