import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import { action } from '@storybook/addon-actions'
import markdown from './button.notes.md'

export default {
  title: 'Button',
  component: 'Button',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const button = () => ({
  template: hbs`<Button @clickAction={{action clickAction}}>{{label}}</Button>`,
  context: {
    label: text('Label text', 'Label'),
    clickAction: action('button-click')
  }
})

export const LinkTo = () => ({
  template: hbs`<Button @route='index'>{{label}}</Button>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const ahref = () => ({
  template: hbs`<Button @url='https://www.google.com/'>{{label}}</Button>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const disabled = () => ({
  template: hbs`<Button @clickAction={{action clickAction}} @isDisabled={{true}}>{{label}}</Button>`,
  context: {
    label: text('Label text', 'Label'),
    clickAction: action('button-click')
  }
})

export const fullWidth = () => ({
  template: hbs`<Button @clickAction={{action clickAction}} @isFullWidth={{true}}>{{label}}</Button>`,
  context: {
    label: text('Label text', 'Label'),
    clickAction: action('button-click')
  }
})

export const withIcon = () => ({
  template: hbs`<Button @clickAction={{action clickAction}} @iconType="user-simple">{{label}}</Button>`,
  context: {
    label: text('Label text', 'Label'),
    clickAction: action('button-click')
  }
})
