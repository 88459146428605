import { inject as service } from '@ember/service'
import { DASHBOARD_SEARCH_VIEWS } from 'client/constants/links'
import AuthenticatedRoute from '../../authenticated-route'

export default class HomeSearchRoute extends AuthenticatedRoute {
  @service router
  @service warden
  @service router

  routePermissions = [
    'view__search__admin',
    'view__search__programme_search',
    'view__search__programme_search__dairy_restricted',
    'view__search__audit_search',
    'view__search__non_conformance_search'
  ]

  isNullOrEmpty = (val) => val == null || val === ''

  get searchableViews () {
    return this.warden.filterByAllowedActions(DASHBOARD_SEARCH_VIEWS.filter(view => {
      // View is invalid, don't show it
      if (this.isNullOrEmpty(view.key)) return false

      return view.enabledForSearch
    }), 'permissions')
  }

  beforeModel (transition) {
    super.beforeModel(...arguments).then(() => {
      if (this.searchableViews.length === 0) {
        transition.abort()
        this.router.transitionTo('not-found')
      } else {
        if (!this.searchableViews.map(v => v.route).includes(transition.to.name)) {
          transition.abort()
          this.router.transitionTo(this.searchableViews[0].route)
        }
      }
    })
  }

  model () {
    return {
      searchableViews: this.searchableViews
    }
  }
}
