import AuthenticatedRoute from 'client/routes/authenticated-route'
import { action } from '@ember/object'

export default class BaseMapSearchAuditRoute extends AuthenticatedRoute {
  routePermission = [
    'view__search__non_conformance_search'
  ]

  @action
  onSearchQueryChange (...args) {
    this.controller.onSearchQueryChange(...args)
  }

  afterModel (resolvedModel, transition) {
    super.afterModel(resolvedModel, transition)
    this.controller && this.controller.buildSearchQuery()
  }

  setupController (controller) {
    // used to delay initial search until query params have had a chance to be read from url
    controller.initialLoad()
  }
}
