import Service from '@ember/service'

export default Service.extend({
  create (timeoutInSecs) {
    const store = new Map()

    const unixtime = () => Math.floor(Date.now() / 1000)

    const removeExpired = () => {
      const now = unixtime()
      store.forEach((value, key) => {
        if ((now - timeoutInSecs) > value.time) {
          store.delete(key)
        }
      })
    }

    const save = (key, value) => {
      store.set(key, { value, time: unixtime() })
    }

    const has = () => store.has()

    const fetch = (key) => {
      removeExpired()
      return store.has(key) && store.get(key).value
    }

    const flush = (key) => store.delete(key)

    const flushAll = () => store.clear()

    const flushMatch = (regexp) => {
      // remove key/value pairs where the key matches the regular expression
      store.forEach((value, key) => {
        if (typeof key === 'string' || key instanceof String) {
          if (key.match(regexp)) {
            store.delete(key)
          }
        }
      })
    }

    return {
      removeExpired,
      save,
      has,
      fetch,
      flush,
      flushAll,
      flushMatch
    }
  }
})
