import { inject } from '@ember/service'
import RemoteMethodService from './remote-methods'
import {
  FDA_SEASON_TARGETS_TRANSLATIONS,
  FDA_SEASON_TARGETS_VISIBLE_ASSESSMENTS
} from 'client/constants/values'
export default class SeasonTargetsRemoteMethods extends RemoteMethodService {
  @inject intl
  @inject session

  fdaSeasonTargetsUrl = '/api/audit/fda-season-targets'

  async fetchFdaSeasonTargets (clientCompanyOrgId, seasonModifier) {
    const response = await this.requestNoHandle(`${this.fdaSeasonTargetsUrl}/get/${clientCompanyOrgId}/${seasonModifier}`)
    // Remove any assessments that are not meant to be visible
    const filterResponse = this.filterVisibleAssessments(response)
    // Insert translations that match visit type into response object
    const responseWithTranslations = this.insertTranslationsForVisitTypes(filterResponse)
    return responseWithTranslations
  }

  async saveFdaSeasonTargets (seasonTargets, clientCompanyOrgId) {
    let response
    if (this.doSeasonTargetsExistInDatabase(seasonTargets)) {
      const url = `${this.fdaSeasonTargetsUrl}/put/${clientCompanyOrgId}`
      response = await this.put(url, { data: seasonTargets })
    } else {
      const url = `${this.fdaSeasonTargetsUrl}/post/${clientCompanyOrgId}`
      response = await this.post(url, { data: seasonTargets })
    }
    const responseWithTranslations = this.insertTranslationsForVisitTypes(response)
    return responseWithTranslations
  }

  doSeasonTargetsExistInDatabase (seasonTargets) {
    return Object.keys(seasonTargets).map(visitType => {
      return seasonTargets[visitType].some(target => target.id)
    }).some(value => value === true)
  }

  insertTranslationsForVisitTypes (response) {
    Object.keys(response.data.rows).map(visitType => {
      response.data.rows[visitType].translation = FDA_SEASON_TARGETS_TRANSLATIONS[visitType]
    })
    return response
  }

  filterVisibleAssessments (response) {
    Object.keys(response.data.rows).map(visitType => {
      if (!FDA_SEASON_TARGETS_VISIBLE_ASSESSMENTS.includes(visitType)) {
        delete response.data.rows[visitType]
      }
    })
    return response
  }
}
