import AuthenticatedRoute from '../../authenticated-route'
import { inject } from '@ember/service'
import { SETTINGS_TABS } from '../../../constants/links'
import { action, set } from '@ember/object'

export default class SeasonTargetsSettingsRoute extends AuthenticatedRoute {
  @inject warden
  @inject featureFlags
  @inject router

  routePermissions = this.warden.flattenPermissions(SETTINGS_TABS, 'base.settings.season-targets')

  async beforeModel (transition) {
    await super.beforeModel(...arguments)
    if (this.featureFlags.flagUnset('FARM_DAIRY_REPORTS')) {
      transition.abort()
      this.router.transitionTo('base.not-found')
    }
  }

  @action
  onSearchQueryChange () {
    set(this, 'q', null)
  }
}
