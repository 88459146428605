import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a\nhref=\"javascript:void(0)\"\n{{on 'click' this.deleteCustomField}} class=\"c-secondary-action c-table__action c-text-action--listed-right\">\n  <SvgIcon @type=\"trash\"/>\n</a>\n", {"contents":"<a\nhref=\"javascript:void(0)\"\n{{on 'click' this.deleteCustomField}} class=\"c-secondary-action c-table__action c-text-action--listed-right\">\n  <SvgIcon @type=\"trash\"/>\n</a>\n","moduleName":"client/components/delete-cell/index.hbs","parseOptions":{"srcName":"client/components/delete-cell/index.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'

export default class DeleteCell extends Component {
  @action
  deleteCustomField () {
    this.args.tableActions.deleteRecord(this.args.row.content?.value?.supplier_id)
  }
}
