import { inject } from '@ember/service'
import { set } from '@ember/object'
import Component from '@ember/component'

export default Component.extend({
  remoteMethods: inject('generic-search-remote-methods'),
  searchConfig: null,

  init () {
    this._super(...arguments)
    this.remoteMethods.getSearchConfig(this.searchTypeKey).then(({ searchTemplate }) => {
      set(this, 'searchTemplate', searchTemplate)
    })
  }
})
