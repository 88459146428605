import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import markdown from './call-to-action.notes.md'

export default {
  title: 'Call to action',
  component: 'call-to-action',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const CallToAction = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <CallToAction
        @buttonLabel={{buttonLabel}}
        @iconType="user-simple"
        @route="index"
      >
        {{ctaText}}
      </CallToAction>
    </ContentContainer>
  `,
  context: {
    ctaText: text('Text', 'Need to add a new user?'),
    buttonLabel: text('Button label', 'Invite user')
  }
})

export const Stacked = () => ({
  template: hbs`
    <ContentContainer @hideFooter={{true}}>
      <CallToAction
        @buttonLabel={{buttonLabel}}
        @iconType="user-simple"
        @route="index"
      >
        {{ctaText}}
      </CallToAction>
      <CallToAction
        @buttonLabel={{buttonLabel}}
        @iconType="user-simple"
        @route="index"
      >
        {{ctaText}}
      </CallToAction>
    </ContentContainer>
  `,
  context: {
    ctaText: text('Text', 'Need to add a new user?'),
    buttonLabel: text('Button label', 'Invite user')
  }
})
