import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

export default Ember.Component.extend(DynamicElement, {
  classNames: ['c-form-text-field'],
  uniqueId: Ember.inject.service(),
  inputId: Ember.computed(function () {
    return this.uniqueId.next()
  })
})
