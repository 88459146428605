import { get, computed } from '@ember/object'
import Component from '@ember/component'

export default Component.extend({
  curPage: 0,
  pageSize: 10,
  maxPageLinksShown: 5,
  lastPage: computed('total', 'pageSize', function () {
    const lastPage = Math.ceil(get(this, 'total') / get(this, 'pageSize'))
    return lastPage && lastPage > 0 ? lastPage : 1
  }),
  pageRange: computed('lastPage', 'curPage', function () {
    const pages = []

    const curPage = get(this, 'curPage')
    const numPages = get(this, 'lastPage')
    const maxPageLinks = numPages > get(this, 'maxPageLinksShown') ? get(this, 'maxPageLinksShown') : numPages
    const numLinksEachSideOfActive = Math.ceil((maxPageLinks - 1) / 2)

    // Default range
    let i = 1
    let lastPageLink = maxPageLinks

    // Alter range so that current page link in in center once possible:
    if (curPage > numLinksEachSideOfActive) {
      // If at end of list, current page link can't be in center:
      if (curPage + numLinksEachSideOfActive > numPages) {
        i = numPages - (maxPageLinks - 1)
        lastPageLink = numPages
      // Otherwise, set range to 2 links either side of current page:
      } else {
        i = curPage - numLinksEachSideOfActive
        lastPageLink = curPage + numLinksEachSideOfActive
      }
    }

    for (i; i <= lastPageLink; i++) {
      pages.push(i)
    }
    return pages
  })
})
