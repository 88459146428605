import Ember from 'ember'
import moment from 'moment'
import config from '../config/environment'

const { get, inject } = Ember

/**
 *  All the functions that can be used to determine the default value for a form Element.  Called during the initial
 *  population of the form.
 *  Functions can either return a value, or a Promise.  The calling side has the responsibility for sorting that out.
 *
 *  Note: if the function is returning an empty string, it might make sense to instead send `null` so that the state
 *  does not also populate with an empty string
 *
 *  Usage with json config:
 *  ```
 *  {
 *    type: "text",
 *    ...
 *    default: {
 *       func: {
 *         name: 'funcName'
 *         arguments: ['value1']
*         }
 *    }
 *  }
 *  ```
 */
export default Ember.Service.extend({
  session: inject.service(),

  getFirstName () {
    return get(this, 'session.currentUser.firstName') || null
  },

  getLastName () {
    return get(this, 'session.currentUser.lastName') || null
  },

  getEmail () {
    return get(this, 'session.currentUser.email') || null
  },

  getCurrentDate () {
    return moment().format(config.dateFormat)
  }
})
