import Ember from 'ember'
import { FORM_STATES_DISPLAY } from '../constants'

export function getFormStatus ([status]) {
  if (!status) return ''

  return FORM_STATES_DISPLAY[status]
}

export default Ember.Helper.helper(getFormStatus)
