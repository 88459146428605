// NOTE: As these are used primarily for the Ember-light-table config,
// these do not match the breakpoints defined in `styles/1-tools/_breakpoints.scss`
// The largest size for this is 1024, which is the max width of the content container
// as at 20/02/2020
export default {
  phoneOnly: '(max-width: 599px)',
  tabletUp: '(min-width: 600px)',
  tabletDown: '(max-width: 767px)',
  tabletOnly: '(min-width: 600px) and (max-width: 767px)',
  ipadPortraitUp: '(min-width: 768px)',
  smallDesktopUp: '(min-width: 850px)',
  mediumDesktopUp: '(min-width: 900px)',
  upToLargeDesktop: '(max-width: 1023px)',
  bigDesktopUp: '(min-width: 1024px)',
  collapsedByDefault: '(min-width: 10240px)' // deliberately unreasonably large as a generic search hack to ensure columns are always collapsed.
}
