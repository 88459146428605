import { action } from '@ember/object'
import { inject } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import Component from '@glimmer/component'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'
import { kebabCase } from 'lodash'
import { NON_CONFORMANCE_CATEGORIES, EXTENDED_NON_CONFORMANCE_CATEGORIES } from '../../constants/values'

export default class NonConformanceInsight extends Component {
  @inject router
  @tracked disableViewOpenNcsLink = false
  @tracked disableViewOverdueNCsLink = false

  constructor () {
    super(...arguments)
    this.category = this.args?.insight?.nonConformanceCategory
  }

  get dialChartIdentifier () {
    return kebabCase(`dial-chart-${this.args?.insight?.nonConformanceCategory}`)
  }

  get dialChartTranslations () {
    return {
      lineOne: 'non_conformance_insights.dial_chart_line_1',
      lineTwo: 'non_conformance_insights.dial_chart_line_2',
      hover: 'non_conformance_insights.nc_or_ncs',
      failed: 'non_conformance_insights.overdue',
      passed: 'non_conformance_insights.not_overdue'
    }
  }

  get nonConformanceCategoryTranslations () {
    if (this.category === 'RETURN_MAJOR') {
      return EXTENDED_NON_CONFORMANCE_CATEGORIES
    } else {
      return NON_CONFORMANCE_CATEGORIES
    }
  }

  get state () {
    return this.nonConformanceCategoryTranslations[this.category && this.category.toUpperCase()]?.status
  }

  get title () {
    return this.nonConformanceCategoryTranslations[this.category && this.category.toUpperCase()]?.translation
  }

  get dialChartIcon () {
    return {
      passed: { dialIcon: 'tick', iconColor: 'green' },
      failed: { dialIcon: 'error-outline', iconColor: 'red' }
    }
  }

  @action
  viewOpenNcsInNonConformanceSearch (nonConformanceCategory) {
    // Return Major is actually a "type" that falls under the "category" Major, therefore we need an additional filter
    this.disableViewOpenNcsLink = true
    const internalUserFiltersReturnMajor = {
      type: [this.nonConformanceCategoryTranslations[nonConformanceCategory].associatedDatabaseValue],
      isOpen: [1],
      category: [this.nonConformanceCategoryTranslations.MAJOR.associatedDatabaseValue]
    }

    const internalUserFilters = {
      isOpen: [1],
      category: [this.nonConformanceCategoryTranslations[nonConformanceCategory].associatedDatabaseValue]
    }

    this.router.transitionTo('base.home.search.non-conformance', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.category === 'RETURN_MAJOR' ? internalUserFiltersReturnMajor : internalUserFilters)
      }
    })
  }

  @action
  viewOverdueNCsInNonConformanceSearch (nonConformanceCategory) {
    // Return Major is actually a "type" that falls under the "category" Major, therefore we need an additional filter
    this.disableViewOverdueNCsLink = true
    const internalUserFiltersReturnMajor = {
      type: [this.nonConformanceCategoryTranslations[nonConformanceCategory].associatedDatabaseValue],
      isOpen: [1],
      isOverdue: ['Yes'],
      category: [this.nonConformanceCategoryTranslations.MAJOR.associatedDatabaseValue]

    }

    const internalUserFilters = {
      isOpen: [1],
      isOverdue: ['Yes'],
      category: [EXTENDED_NON_CONFORMANCE_CATEGORIES[nonConformanceCategory].associatedDatabaseValue]
    }
    this.router.transitionTo('base.home.search.non-conformance', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.category === 'RETURN_MAJOR' ? internalUserFiltersReturnMajor : internalUserFilters)
      }
    })
  }
}
