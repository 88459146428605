import hbs from 'htmlbars-inline-precompile'
import { withKnobs, text } from '@storybook/addon-knobs'
import { withA11y } from '@storybook/addon-a11y'
import { action } from '@storybook/addon-actions'
import markdown from './text-action.notes.md'

export default {
  title: 'Text Action',
  component: 'TextAction',
  decorators: [
    withKnobs,
    withA11y
  ],
  parameters: {
    notes: { markdown }
  }
}

export const button = () => ({
  template: hbs`<TextAction @clickAction={{action clickAction}}>{{label}}</TextAction>`,
  context: {
    label: text('Label text', 'Label'),
    clickAction: action('button-click')
  }
})

export const linkTo = () => ({
  template: hbs`<TextAction @route='index'>{{label}}</TextAction>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const aHref = () => ({
  template: hbs`<TextAction @url='https://google.com/'>{{label}}</TextAction>`,
  context: {
    label: text('Label text', 'Label')
  }
})

export const disabled = () => ({
  template: hbs`<TextAction @clickAction={{action clickAction}} @isDisabled={{true}}>{{label}}</TextAction>`,
  context: {
    label: text('Label text', 'Label'),
    clickAction: action('button-click')
  }
})
