import { action } from '@ember/object'
import { inject } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { buildQueryParamsForFilters } from 'client/utils/generic-search-utils'
import { kebabCase } from 'lodash'

export default class ProgrammeSummary extends Component {
  @inject router
  @inject warden
  @tracked isExpanded = false
  @tracked isToolTipEnabled = true
  @tracked disableViewSuppliersInProgrammeSearchLink = false
  @tracked disableViewSuppliersinProgrammeMapSearchLink = false
  @tracked disableViewSuppliersInNonConformanceSearchLink = false
  @tracked disableViewSuppliersinNonConformanceMapSearchLink = false

  // The items in the subgroup MUST MATCH the fields returned in the data object.
  // e.g. If data = {completed: 5, due: 2, year_month: "202103"}, and we want to show booked and completed in the legend and
  // the bar graph, then the subgroup items must be 'completed' and 'due'
  legendDataSubgroups = ['completed', 'due']

  get dialChartIdentifier () {
    return kebabCase(`dial-chart-${this.args?.insight?.programmeName}-${this.args?.insight?.clientName}-${this.args?.insight?.clientCompany}`)
  }

  get barChartIdentifier () {
    return kebabCase(`bar-chart-${this.args?.insight?.programmeName}-${this.args?.insight?.clientName}`)
  }

  get isAdmin () {
    return this.warden.canAny(['view__programme_insights__admin'])
  }

  get dialChartTranslations () {
    return {
      lineOne: 'programme_insights.dial_chart_line_1',
      lineTwo: 'programme_insights.certified',
      hover: 'programme_insights.supplier_or_suppliers',
      passed: 'programme_insights.certified',
      failed: 'programme_insights.not_certified'
    }
  }

  get barChartTranslations () {
    return {
      legendTitle: 'programme_insights.bar_chart_audits',
      legendItemOneLabel: 'programme_insights.bar_chart_completed',
      legendItemTwoLabel: 'programme_insights.bar_chart_due'
    }
  }

  get tetherTargetTranslations () {
    return {
      count: 'programme_insights.bar_chart_tooltip_audits',
      completed: 'programme_insights.bar_chart_tooltip_audits_completed',
      due: 'programme_insights.bar_chart_tooltip_audits_due'
    }
  }

  get dialChartIcon () {
    return {
      passed: { dialIcon: 'tick', iconColor: 'green' },
      failed: { dialIcon: 'error-outline', iconColor: 'red' }
    }
  }

  @action
  toggleDetails () {
    this.isExpanded = !this.isExpanded
  }

  @action
  viewSuppliersInProgrammeSearch (clientCompany, programmeId) {
    this.disableViewSuppliersInProgrammeSearchLink = true
    const internalUserFilters = {
      programme: [programmeId.toString()]
    }
    const adminUserFilter = {
      clientCompany: [clientCompany.toString()],
      programme: [programmeId.toString()]
    }

    this.router.transitionTo('base.home.search.programme', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.isAdmin ? adminUserFilter : internalUserFilters)
      }
    })
  }

  @action
  viewSuppliersInNonConformanceSearch (clientCompany, programmeId) {
    this.disableViewSuppliersInNonConformanceSearchLink = true
    const internalUserFilters = {
      programme: [programmeId.toString()],
      isOpen: [1]
    }

    const adminUserFilter = {
      programme: [programmeId.toString()],
      isOpen: [1],
      clientCompany: [clientCompany.toString()]
    }

    this.router.transitionTo('base.home.search.non-conformance', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.isAdmin ? adminUserFilter : internalUserFilters)
      }
    })
  }

  @action viewSuppliersinProgrammeMapSearch (clientCompany, programmeId) {
    this.disableViewSuppliersinProgrammeMapSearchLink = true
    const internalUserFilters = {
      programme: [programmeId.toString()]
    }

    const adminUserFilter = {
      clientCompany: [clientCompany.toString()],
      programme: [programmeId.toString()]
    }

    this.router.transitionTo('base.map-search.programme', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.isAdmin ? adminUserFilter : internalUserFilters)
      }
    })
  }

  @action viewSuppliersinNonConformanceMapSearch (clientCompany, programmeId) {
    this.disableViewSuppliersinNonConformanceMapSearchLink = true
    const internalUserFilters = {
      programme: [programmeId.toString()],
      isOpen: [1]
    }

    const adminUserFilter = {
      programme: [programmeId.toString()],
      isOpen: [1],
      clientCompany: [clientCompany.toString()]
    }

    this.router.transitionTo('base.map-search.non-conformance', {
      queryParams: {
        queryParamSearchFilters: buildQueryParamsForFilters(this.isAdmin ? adminUserFilter : internalUserFilters)
      }
    })
  }
}
