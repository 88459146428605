import Ember from 'ember'
import { task, timeout } from 'ember-concurrency'
import DynamicElement from '../../../../../mixins/components/dynamic-form-element'
import SortingMixin from '../../../../../mixins/components/form-link-table-fetch-data-sorting'
import Table from 'ember-light-table'
import config from '../../../../../config/environment'
import { formatDate } from '../../../../../helpers/format-date'

const { computed, get, set, inject } = Ember

export default Ember.Component.extend(DynamicElement, SortingMixin, {
  dynamicFormsMethods: inject.service(),
  router: inject.service(),
  table: null,
  page: 1,
  pageSize: config.embeddedTablePageSize,
  total: 0,
  isLoading: computed.oneWay('fetchRecords.isRunning'),
  tableData: null,
  filters: [],

  // Note: mixin contains sorting properties and actions.

  /**
   * Instantiate the Ember Light Table.
   */
  init () {
    this._super(...arguments)
    const table = Table.create({ columns: [], rows: [] })
    set(this, 'table', table)
    set(this, 'table.viewAction', (row) => {
      this.preTransitionToChildForm(row)
    })
    get(this, 'fetchRecords').perform()

    // This is the section element, and we want to look into the extendedAttributes for the form-link-table element
    // This should always be the only child of this section
    set(this, 'sort', this.formElement.formElements[0].extendedAttributes?.initialSortColumn)
    set(this, 'dir', this.formElement.formElements[0].extendedAttributes?.initialSortDirection?.toLowerCase())
  },

  /**
   * Table columns in the format that Ember Light Table uses.
   */
  columns: computed('tableData.metadata', function () {
    const metadata = get(this, 'tableData.metadata')
    if (!metadata) return []

    const getSortable = get(this, 'sortDisabled') !== true

    let hasBreakpoints = false

    const columns = metadata
      .filter(column => column.hidden !== true)
      .map(column => {
        const { elementKey, columnHeading, width, breakpoints, type } = column
        if (breakpoints && breakpoints.length) hasBreakpoints = true
        const cellComponent = (type === 'warning_icon') ? 'dynamic-form/light-table-components/cell-with-icon' : 'dynamic-form/light-table-components/cell-with-title'
        const sortable = (type === 'warning_icon') ? false : getSortable
        const columnDef = {
          label: columnHeading,
          sortable,
          valuePath: elementKey,
          width: width,
          breakpoints: breakpoints,
          cellComponent,
          classNames: 'c-table__cell c-table__cell--heading',
          cellClassNames: 'c-table__cell c-table__row--clickable',
          format: type === 'date' ? ((date) => formatDate([date])) : undefined,
          dateSortingFormat: column.dateSortingFormat
        }

        if (this.sort && this.dir && this.sort === elementKey) {
          columnDef.ascending = this.dir.toLowerCase() === 'asc'
          columnDef.sorted = true
        }

        return columnDef
      })

    /**
     * If columns show/hide at specific breakpoints, add in column for toggle button to
     * expand/contract hidden properties.
     */
    if (hasBreakpoints) {
      columns.unshift({
        sortable: false,
        width: '30px',
        cellComponent: 'dynamic-form/light-table-components/row-toggle',
        classNames: 'c-table__cell c-table__cell--heading',
        cellClassNames: 'c-table__cell c-table__row--clickable c-table__row--toggle-column c-table__row--toggle-column',
        breakpoints: ['upToLargeDesktop']
      })
    }

    if (!get(this, 'hideViewMode')) {
      // Add the action
      columns.push({
        label: 'Actions',
        width: '60px',
        sortable: false,
        cellComponent: 'dynamic-form/view/section/form-link-table-fetch-data/table-actions',
        align: 'left',
        classNames: 'c-table__cell c-table__cell--heading c-table__cell--actions',
        cellClassNames: 'c-table__cell c-table__cell--actions'
      })
    }

    return columns
  }),

  /**
   * Retrieve records for the table.
   */
  fetchRecords: task(function * () {
    const dynamicFormsMethods = get(this, 'dynamicFormsMethods')
    const formElementName = get(this, 'formElement.formElements.0.name')
    const formInstanceId = get(this, 'formInstanceId')

    const tableData = yield dynamicFormsMethods.getFormLinkTableData(formInstanceId, formElementName)
    set(this, 'tableData', tableData)
    yield get(this, 'setColumns').perform()
    yield get(this, 'setFilters').perform()
    yield get(this, 'setRows').perform()
  }).restartable(),

  setColumns: task(function * (debounceMs = 0) {
    yield timeout(debounceMs)
    this.get('table').setColumns(get(this, 'columns'))
  }),

  setRows: task(function * (debounceMs = 200) {
    yield timeout(debounceMs)

    const sortedRows = get(this, 'sortedRows')

    // use the `filters` array to remove rows we're not interested in
    const filters = get(this, 'filters')
    const filteredRows = sortedRows.filter(row => {
      return filters.some(({ elementKey, selected }) => {
        return (selected !== undefined && row[elementKey] !== selected)
      }) === false
    })

    // pagination
    set(this, 'total', filteredRows.length)
    const page = get(this, 'page')
    const pageSize = get(this, 'pageSize')
    const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize)

    get(this, 'table').setRows(paginatedRows)
  }),

  setFilters: task(function * () {
    const columns = get(this, 'tableData.metadata')
    const data = get(this, 'tableData.data')

    // create the `filters` array using the configuration specified in the column metadata
    const filters = columns.filter(column => column.filterBy === true)
      .map(column => {
        // each item in the filters array has a `uniqueValues` array containing distinct values from the table data
        const uniqueValueMap = {}
        data.forEach(row => {
          const value = row[column.elementKey] || ''
          uniqueValueMap[value] = true
        })
        column.uniqueValues = Object.keys(uniqueValueMap).sort()

        // the filter defaults to either the `filterDefaultValue` specified in the metadata or the first unique value
        column.selected = column.uniqueValues.indexOf(column.filterDefaultValue) !== -1
          ? column.filterDefaultValue
          : column.uniqueValues[0]
        return column
      })

    set(this, 'filters', filters)
  }),

  /**
   * If the child_form_instance_id is null, we need to ask the server
   * to resolve it, which may involve reinflating a form.
   */
  preTransitionToChildForm (row) {
    const id = get(row, 'child_form_instance_id')
    if (id == null) {
      const formElementName = get(this, 'formElement.formElements.0.name')
      const formInstanceId = get(this, 'formInstanceId')
      const baseId = get(row, 'id')
      return get(this, 'dynamicFormsMethods')
        .getTableChildFormInstanceId(formElementName, formInstanceId, baseId)
        .then((childFormInstanceId) => this.get('dynamicFormsMethods').transitionToForm({ id: childFormInstanceId }))
    } else {
      this.get('dynamicFormsMethods').transitionToForm({ id })
    }
  },

  actions: {
    onRowClick (row) {
      if (!get(this, 'hideViewMode')) {
        this.preTransitionToChildForm(row)
      }
    },
    setPage (page) {
      set(this, 'page', page)
      get(this, 'setRows').perform()
    },
    setFilterValue (filter, value) {
      set(filter, 'selected', value)
      set(this, 'page', 1)
      get(this, 'setRows').perform()
    },
    onAfterResponsiveChange (matches) {
      if (matches.indexOf('largeDesktop') > -1) {
        this.get('table.expandedRows').setEach('expanded', false)
      }
    }
  }
})
