import Ember from 'ember'
const { get, inject } = Ember

export default Ember.Mixin.create({
  router: inject.service(),
  dynamicFormsMethods: inject.service(),

  // Handle the click event on the row
  click () {
    this.transitionToConsent()
  },

  // Navigate to the clicked on form
  transitionToConsent () {
    const name = get(this, 'formElement.formElements.0.name')
    const id = get(this, `value.${name}.0.val`)
    this.dynamicFormsMethods.transitionToForm({ id })
  },

  actions: {
    // Handle an event from the button click
    view (event) {
      // don't sent the click to the row click as well - it might confuse Ember
      event.stopPropagation()
      this.transitionToConsent()
    },

    delete (formElement, index, event) {
      // don't sent the click to the row click as well
      event.stopPropagation()
      get(this, 'delete')(formElement, index)
    }
  }
})
