import Application from '@ember/application'
import Resolver from 'ember-resolver'
import loadInitializers from 'ember-load-initializers'
import config from './config/environment'
import 'core-js/actual/array/flat'
import 'core-js/actual/array/flat-map'

export default class App extends Application {
  modulePrefix = config.modulePrefix
  podModulePrefix = config.podModulePrefix
  Resolver = Resolver
}
// Variables from the server. Remove once assigned to config
config.ENV_VARS = window.ENV_VARS
delete window.ENV_VARS

if (config.ENV_VARS && config.ENV_VARS.RAYGUN_API_KEY) {
  config.raygun.apiKey = config.ENV_VARS.RAYGUN_API_KEY
} else {
  config.raygun.enabled = false
  config.raygun.beQuiet = true
}

loadInitializers(App, config.modulePrefix)
