import { get } from '@ember/object'

import RSVP from 'rsvp'

import { MODE, FULL_WORKFLOW_TEXT } from 'client/constants/workflow'

import { inject } from '@ember/service'

/**
 * Define different workflow types. This is used by the workflow-tab route mixin
 * to handle the different types.
 *
 * Currently supports:
 *  - form             Typical dynamic form workflow.
 *  - component        A non-dynamic form workflow e.g. Documents.
 *
 * Workflow types need to implement the following interface:
 *  - getFormInstance  Returns a promise that resolves to an object with a form instance ID e.g. {formInstanceId: 12345}.
 *  - getMenu          Return the menu object.
 *  - getModel         Returns the model for the workflow.
 *  - afterModel       Optional: After model hook for this workflow.
 *
 * NOTE: These are called using 'call' so can reference 'this' as if it's the route.
 *       e.g. workflowType.getFormInstance.call(this, {wf, parentId})
 */

// Recursively tests if a form instance ID is within workflow forms.
const isFormInstanceIdInWorkflow = (form, id) => {
  if (form.formInstanceId === parseInt(id, 10)) {
    return true
  }
  if (Array.isArray(form.forms)) {
    return form.forms.some((childForm) => isFormInstanceIdInWorkflow(childForm, id))
  }
  return false
}

// Different types of supported workflows.
const workflowTypes = {
}

// Dynamic form workflow.
workflowTypes.form = {
  router: inject(),

  getFormInstance ({ wf, parentId }) {
    const dynamicFormsMethods = this.dynamicFormsMethods
    return dynamicFormsMethods.retrieveFormInstance({ parentId, formKey: wf.key })
  },

  getMenu ({ parentFormInstance }) {
    const dynamicFormsMethods = this.dynamicFormsMethods
    const parentFormInstanceId = parentFormInstance.formInstanceId

    if (parentFormInstanceId) {
      return dynamicFormsMethods.fetchMenu(parentFormInstanceId).then(result => result.menu)
    }

    // Return an empty menu if the form instance ID isn't set.
    return RSVP.resolve({ menu: [] })
  },

  // This extends the model defined in the mixin below
  getModel ({ activeWorkflow, parentId, path, transition }) {
    const dynamicFormsMethods = this.dynamicFormsMethods

    const topLevelFormInstanceId = activeWorkflow.menu.formInstanceId

    // If the last part of the path is a number, it's the form instance ID.
    let formInstanceId = null
    let fullWorkflow = false
    const parts = path.split('/')
    const lastSegment = parts[parts.length - 1]
    if (/^\d+$/.test(lastSegment)) {
      formInstanceId = parts.pop()
    } else if (lastSegment === FULL_WORKFLOW_TEXT) {
      formInstanceId = topLevelFormInstanceId
      fullWorkflow = true
      parts.pop()
    }
    let tidiedPath = parts.join('/')
    if (!tidiedPath) {
      tidiedPath = activeWorkflow.config.path
    }

    // Validate that the form instance ID is present in the menu, if not transition.
    if (formInstanceId && !isFormInstanceIdInWorkflow(activeWorkflow.menu, formInstanceId)) {
      // eslint-disable-next-line no-console
      console.log(`Form instance ID "${formInstanceId}" not found in workflow`, activeWorkflow.menu.forms)

      // The line below has been commented out, because - in the case of tables with hideStateFromClient
      // set in config), the menu will have child form instances suppressed (i.e. ommitted from the state
      // by the server), so this test would incorrectly fail.

      // return this.transitionTo(get(transition, 'targetName'), parentId, MODE.VIEW, tidiedPath)
    }

    // Only support fullWorkflow in print view
    if (fullWorkflow && this.mode !== MODE.PRINT_VIEW) {
      // eslint-disable-next-line no-console
      console.log('Full workflow only supported in print view, redirecting to default form')
      return this.router.transitionTo(get(transition, 'targetName'), parentId, MODE.VIEW, tidiedPath)
    }

    // If no form instance ID has been extracted from the path, use the first
    // form instance ID from the menu.
    let id = null
    if (formInstanceId) {
      id = formInstanceId
    } else if (activeWorkflow.menu.forms) {
      id = activeWorkflow.menu.forms[0].formInstanceId
    }

    let formInstance = null

    return RSVP.resolve()
      .then(() => {
        if (id) {
          return dynamicFormsMethods.fetchInstance(id, fullWorkflow)
            .then(x => {
              formInstance = x
            })
        }
      })
      .then(() => {
        // Determine the template to use for rendering this form
        const status = activeWorkflow.parentFormInstance.status
        const templates = {
          ERROR: 'workflow/error',
          NOT_STARTED: 'workflow/start',
          CREATED: 'workflow/form',
          IN_PROGRESS: 'workflow/form'
        }
        const templateName = templates[status] || templates.error

        /**
         * Returns a function that performs a depth-first search of an array of formElements which may contain arrays of formElements.
         * Useful for returning the first element on the page that passes a given test.
         * @param test A function that takes a form element and returns true if it matches, eg. (element) => (element.type === 'page-heading')
         * @returns A function that takes an array of form elements and returns the first one in the tree that matches the test.
         */
        const getFirstFormElementMatching = (test) => {
          const getFirstFormElementMatchingTest = (formElements) => {
            for (let i = 0; i < formElements.length; i++) {
              const element = formElements[i]
              if (element === undefined) {
                continue
              }
              const isMatch = test(element)
              if (isMatch === true) {
                return element
              }
              if (Array.isArray(element.formElements)) {
                const childMatch = getFirstFormElementMatchingTest(element.formElements)
                if (childMatch !== undefined) {
                  return childMatch
                }
              }
            }
          }
          return getFirstFormElementMatchingTest
        }

        // Get the page heading used in print view
        let pageHeading
        if (this.mode === MODE.PRINT_VIEW) {
          pageHeading = activeWorkflow.config.printViewPageHeading

          if (pageHeading === undefined) {
            // The page heading wasn't specified in the workflow config so use the first page heading element in the form
            // or if that doesn't exist use the title of the workflow.
            const getFirstPageHeadingElement = getFirstFormElementMatching((element) => (element.type === 'page-heading'))
            const firstPageHeadingElement = formInstance !== null ? getFirstPageHeadingElement(formInstance.formElements) : undefined
            firstPageHeadingElement.suppressInView = true
            pageHeading = firstPageHeadingElement !== undefined ? firstPageHeadingElement.markup : activeWorkflow.title
          }
        }

        return {
          pageHeading,
          parentFormInstanceId: activeWorkflow.parentFormInstanceId,
          id,
          topLevelFormInstanceId,
          form: formInstance,
          menu: activeWorkflow.menu,
          formRootPath: tidiedPath,
          templateName
        }
      })
  },

  afterModel (model, transition) { // eslint-disable-line no-unused-vars
    // readOnly forms can't be edited so redirect them to view mode
    const isReadOnly = get(model, 'form.formInstance.readOnly') ?? false === true
    if (model.mode === MODE.EDIT && isReadOnly) {
      this.router.transitionTo(model.formRootRoute, MODE.VIEW, model.path)
    }
  }
}

// A non-dynamic form workflow.
workflowTypes.component = {
  getFormInstance () {
    return RSVP.resolve({ formInstanceId: null })
  },

  getMenu ({ wf, path, active }) {
    return RSVP.resolve(wf.getMenu.call(this, { path, active }))
  },

  // This extends the model defined in the mixin below
  getModel ({ activeWorkflow, parentId, path }) {
    return RSVP.hash({
      menu: activeWorkflow.menu,
      component: activeWorkflow.config.component,
      parentId,
      id: -1, // required to get the menu to display
      formRootPath: path,
      templateName: 'workflow/component'
    })
  }
}

export {
  workflowTypes
}
