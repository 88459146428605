import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import FormLabel from '../../../../mixins/components/form-label'
const { get } = Ember

export default Ember.Component.extend(DynamicElement, FormLabel, {
  init () {
    // This section generates the id that can be navigated to from the top menu.
    // The top menu is currently not used.
    // todo: code candidate for removing once feature complete

    this._super(...arguments)

    const instanceId = get(this, 'formElementState.0.formInstanceId')
    if (instanceId) {
      this.set('elementId', instanceId + get(this, 'formElement.name'))
    }
  }
})
