import Ember from 'ember'
import { task, timeout } from 'ember-concurrency'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'
import { TYPEAHEAD_COMPONENT } from '../../../../constants'
import { isObject } from 'lodash'

const { get, computed, set, getOwner } = Ember
const { DEBOUNCE_MS, MIN_CHARS } = TYPEAHEAD_COMPONENT

export default Ember.Component.extend(DynamicElement, {
  uniqueId: Ember.inject.service(),
  inputId: Ember.computed(function () {
    return this.uniqueId.next()
  }),

  // Extended attributes, that specify where to fetch typeahead results from
  lookupServiceName: computed.alias('formElement.extendedAttributes.lookupServiceName'),
  lookupMethodName: computed.alias('formElement.extendedAttributes.lookupMethodName'),
  lookupKey: computed.alias('formElement.extendedAttributes.lookupKey'),

  // Dynamically load the lookup service
  lookupService: computed('lookupServiceName', function () {
    const owner = getOwner(this)
    return owner.lookup(`service:${this.get('lookupServiceName')}`)
  }),

  didReceiveAttrs () {
    this._super(...arguments)

    const value = get(this, 'value')

    if (value != null) {
      const selected = {}
      selected[get(this, 'lookupKey')] = value
      set(this, 'selected', selected)
    }
  },

  /**
   * Search for existing numbers.
   *
   * Note: Must have at least MIN_CHARS before hitting back end.
   */
  searchTask: task(function * (query) {
    if (query.length < MIN_CHARS) return []
    yield timeout(DEBOUNCE_MS)
    return get(this, 'lookupService')[get(this, 'lookupMethodName')](query)
  }),

  actions: {
    setSelected (selected) {
      set(this, 'selected', selected)

      const newValue = isObject(selected) === true ? selected[get(this, 'lookupKey')] : undefined

      if (newValue) {
        this.setValue(newValue)
      } else {
        this.clearValue()
      }
    }
  }
})
