import Ember from 'ember'
import DynamicElement from '../../../../mixins/components/dynamic-form-element'

export default Ember.Component.extend(DynamicElement, {
  classNames: ['c-view-text'],
  classNameBindings: [
    'formElement.suppressLabelInView:c-view-text--hide-label',
    'isAddress:c-view-text--no-emphasis'
  ]
})
