import AuthenticatedRoute from '../authenticated-route'
import { hash, Promise } from 'rsvp'
import { set } from '@ember/object'
import { inject } from '@ember/service'
import { MODE } from '../../constants/workflow'

const PAGE_CRUMBS = [
  { route: 'base.home.search', label: 'tabs.search' },
  { label: 'suppliers.profile' }
]

export default AuthenticatedRoute.extend({
  gateKeeper: inject(),
  supplierProfileRemoteMethods: inject(),
  router: inject(),

  // This is to delay processing of the route until the session is loaded
  beforeModel () {
    set(this, 'routePermissions', ['view__supplier_profile', 'view__supplier_profile__admin'])
    return this._super(...arguments).then(() => this.gateKeeper.isSessionLoaded())
  },

  model ({ parent, mode }) {
    if (!Object.values(MODE).includes(mode)) {
      this.router.transitionTo('base.not-found')
    }

    // eslint-disable-next-line camelcase
    set(this, 'parentId', parent)

    return hash({
      pageInfo: new Promise((resolve, reject) => {
        return this.supplierProfileRemoteMethods.fetchEnterprisePageInfo(this.parentId)
          .then(result => {
            resolve({
              crumbs: PAGE_CRUMBS,
              ...result
            })
          })
          .catch(reject)
      }),
      parentId: this.parentId,
      mode,
      tabConfig: [
        {
          key: 'supplier',
          title: 'Supplier',
          visible: true,
          workflows: [
            {
              type: 'form',
              key: 'supplier',
              title: 'Supplier',
              path: 'supplier',
              visible: true
            }
          ]
        }
      ]
    })
  },

  actions: {
    refreshModel () {
      this.refresh()
    }
  }
})
