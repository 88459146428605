import Ember from 'ember'
const { set } = Ember

export default Ember.Component.extend({
  actions: {
    confirm (confirmSuccessFunction) {
      set(this, 'displayModal', false)
      confirmSuccessFunction()
    }
  }
})
