import { action } from '@ember/object'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'

export default class FullScreenMapComponentLegend extends Component {
  @tracked showLegend = true

  // Store the initial state of configFeatures so SVG icons don't re-render when features are toggled
  storedConfigFeatures = this.args.configFeatures

  @action toggleLegend (e) {
    e.preventDefault()
    this.showLegend = !this.showLegend
  }
}
