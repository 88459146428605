/* global Raygun */
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { inject } from '@ember/service'
import { action } from '@ember/object'

export default class AuditDocumentsTableFileLink extends Component {
  // Table component for holding multiple icons and actions

  @inject auditDocumentRemoteMethods
  @inject toastMessages

  @tracked isDownloading = this.args.row.content.isDownloading
  @tracked isUploading = this.args.row.content.isUploading

  @action
  getAuditDocument (selectedFile) {
    try {
      this.isDownloading = true
      this.auditDocumentRemoteMethods.fetchAuditProgrammeDocument(selectedFile.casdbEnterpriseAuditId, selectedFile.id, selectedFile.name)
    } catch (e) {
      this.toastMessages.danger(this.intl.t('supplier_summary.error_retrieving_documents'))
      Raygun.send(e)
    } finally {
      this.isDownloading = false
    }
  }
}
